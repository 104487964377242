import { Favorite, FavoriteBorderRounded } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useProgressiveImage } from "../../Posters/LazyBackground";
import { useUser } from "../../store";
import { IInfoCard } from "../class";

export interface InfoCardProps<T extends IInfoCard> {
    data: T;
    simpleMode?: boolean; //是否為小卡模式
    editable?: boolean;
    fixed?: boolean; //固定顯示方式，不做簡介rwd
    className?: string;

    onClick?: (data: T) => void; //小卡模式時，點擊小卡本身觸發
    onClickEdit?: (data: T) => void; //非小卡模式時，放入編輯按鈕並觸發此函式
    onClickFollow?: (data: T) => void;
    onClickLinkBooth?: (event: React.MouseEvent<HTMLElement>, data: T) => void;
    onClickShowMore?: (event: React.MouseEvent<HTMLElement>, data: T) => void;
    
    disable?: boolean; //小卡模式時，不允許點擊小卡本身。預設disable=true時，將顯示「更多」按鈕
    disableFollow?: boolean; //不顯示「❤️」按鈕
    disableShowBooth?: boolean; //不顯示攤位
    disableShowBoothOnSimpleMode?: boolean; //只有小卡模式不顯示攤位
    disableLinkBooth?: boolean; //小卡模式並顯示攤位時，攤位只可看不可點
    disableShowMore?: boolean; //小卡模式並disable=true時，仍不顯示「更多」按鈕
}

export function InfoCard<T extends IInfoCard>(props: InfoCardProps<T>) {
    const { data, simpleMode, editable, fixed, className, onClick, onClickEdit, onClickFollow, onClickLinkBooth, onClickShowMore, disable, disableFollow, disableShowBooth, disableShowBoothOnSimpleMode, disableLinkBooth, disableShowMore } = props;
    const { t, i18n } = useTranslation();

    const user = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //按鈕功能
    function handleClick(event: React.MouseEvent<HTMLElement>) {
        if (data?.dataId) {
            if (onClick) onClick(data);
        }
        else console.log(`dataId is null`);
    }
    function handleClickEdit(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        if (data?.dataId) {
            if (onClickEdit) onClickEdit(data);
        }
        else console.log(`dataId is null`);
    }
    function handleClickFollow(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        if (data?.dataId) {
            if (onClickFollow) onClickFollow(data);
        }
        else console.log(`dataId is null`);
    }
    function handleClickLinkBooth(event: React.MouseEvent<HTMLElement>) {
        if (data?.dataId) {
            if (onClickLinkBooth) onClickLinkBooth(event, data);
        }
        else console.log(`dataId is null`);
    }
    function handleClickShowMore(event: React.MouseEvent<HTMLElement>) {
        if (data?.dataId) {
            if (onClickShowMore) onClickShowMore(event, data);
        }
        else console.log(`dataId is null`);
    }

    const img = useProgressiveImage(data?.imgUrl);
    const [imgSize, setImgSize] = React.useState<{ width: number; height: number }>({ width: 320, height: 180 });
    const container = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            let width = entries[0].target.clientWidth;
            if (width <= 900)
                setImgSize({ width: 320, height: 180 });
            else
                setImgSize({ width: 320, height: 180 });
        });
        resizeObserver.observe(container.current)
        return (() => {
            resizeObserver.disconnect();
        })
    }, []);
    return (
        <Box border={simpleMode && disable && `1.5px solid ${theme.palette.grey[400]}`}
            boxShadow={!disable && theme.shadows[3]}
            borderRadius={simpleMode && 3}
            className={className}
            onClick={!disable && handleClick}
            sx={!disable && simpleMode && { cursor: "pointer" }}
        >
            {/*編輯 or ❤️*/}
            <Box display="flex" justifyContent="flex-end" alignItems="flex-start" height={simpleMode ? "16px" : "30px"}
                pl={simpleMode && 2}
            >
                {editable && data && user ?
                    <Button variant="text" onClick={handleClickEdit}>{t('booth.button.edit')}</Button>
                    : !disableFollow && data && user ?
                        <Box bgcolor="background.paper" zIndex={1} borderRadius="50%">
                            <IconButton onClick={handleClickFollow}>
                                {user.saveInfoCardIds.includes(data.dataId) ?
                                    <Favorite style={{ color: '#fa6980' }} /> :
                                    <FavoriteBorderRounded style={{ color: '#545569' }} />
                                }
                            </IconButton></Box>
                        : null
                }
            </Box>
            <Box ref={container} display="grid" gridTemplateColumns="1fr" gap={2} height={simpleMode && "calc(100% - 16px)"}>
                {/*圖片*/}
                <Box px={2}>
                    <Box position="relative" width={"100%"/*isMobile ? "100%" : logoSize.width*/} maxWidth="100%" paddingTop={(imgSize.height / imgSize.width * 100) + "%"}>
                        <Box position="absolute" top={0} right={0} bottom={0} left={0} borderRadius={3}>
                            {data && data.imgUrl && data.imgUrl !== "" && img ?
                                <img src={img} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                : data && (data.imgUrl === "" || data.imgUrl === null) ?
                                    <Box bgcolor="grey.200" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                        <Typography>NO IMAGE</Typography>
                                    </Box>
                                    : <Skeleton variant="rectangular" width="100%" height="100%" />
                            }
                        </Box>
                    </Box>
                </Box>
                {/*內容*/}
                <Stack justifyContent="space-between">
                    {/*名字 and 攤位*/}
                    {simpleMode ? <>
                        {data ? <>
                            <Box px={2}>
                                <Typography variant="h6" gutterBottom>
                                    {data.title || " "}
                                </Typography>
                                <Typography color="text.secondary" mb={2} sx={{/*超過3行即隱蔽*/ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {data.content?.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ") || " "}
                                </Typography>
                            </Box>
                            {!disableShowBooth && !disableShowBoothOnSimpleMode && <>
                                {!disableLinkBooth ? <>
                                    <Box component="a" href={`/Booth?id=${data.boothId}`} onClick={handleClickLinkBooth} color="inherit"
                                        sx={{ '&:hover': { color: "inherit", backgroundColor: theme.palette.action.hover, textDecoration: "none" }, '&:active': { color: "inherit" } }}>
                                        <Divider sx={{ height: "0px !important" }} />
                                        <Box display="flex" m={2}>
                                            <Avatar src={data.boothLogoUrl} sx={{ mr: 1 }} />
                                            <Box>
                                                <Typography>{data.boothAbbrev || data.boothChName || " "}</Typography>
                                                <Typography variant="subtitle2" color="text.secondary">{data.boothEnName || ""}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </> : <Box>
                                    <Divider sx={{ height: "0px !important" }} />
                                    <Box display="flex" m={2}>
                                        <Avatar src={data.boothLogoUrl} sx={{ mr: 1 }} />
                                        <Box>
                                            <Typography>{data.boothAbbrev || data.boothChName || " "}</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">{data.boothEnName || ""}</Typography>
                                        </Box>
                                    </Box>
                                </Box>}
                            </>}
                        </> : <>
                            <Box px={2}>
                                <Typography variant="h6"><Skeleton variant="text" /></Typography>
                                <Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
                            </Box>
                        </>}</> : <>
                        {data ? <>
                            <Box px={{ xs: 2.5, md: 5 }} mb={3}>
                                <Typography variant="h5" fontWeight="bold">{data.title || " "}</Typography>
                                {!disableShowBooth &&
                                    <Box display="flex" my={2}>
                                        <Avatar src={data.boothLogoUrl} sx={{ mr: 1 }} />
                                        <Box>
                                            <Typography>{data.boothAbbrev || data.boothChName || " "}</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">{data.boothEnName || ""}</Typography>
                                        </Box>
                                    </Box>
                                }
                                <div className="ck-content"
                                    dangerouslySetInnerHTML={{ __html: data?.content }}
                                />
                            </Box>
                        </> : <>
                            <Box px={{ xs: 2.5, md: 5 }} mb={3}>
                                <Typography variant="h5"><Skeleton variant="text" /></Typography>
                                <Typography variant="h6"><Skeleton variant="text" /></Typography>
                            </Box>
                        </>}
                    </>}
                </Stack>
            </Box>
        </Box>
    )
}