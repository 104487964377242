import { Box, Button, Link, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BoothManageInvitation } from '../components/class';

export default function VerifyPage(props: VerifyPageProps) {
    const { code, data, handleSubmitVerify } = props;
    const { t } = useTranslation();
    return (
        <div>
            <Stack width={280} spacing={2} justifyContent="stretch" alignItems="stretch">
                <div>
                    <Typography gutterBottom>
                        <Trans
                            i18nKey="invite.invitation"
                            values={{ name: data?.inviter.name, booth: data?.booth.chName }}
                            //components={[<span style={{ color: theme.palette.error.light }}></span>]}
                        />
                    </Typography>
                    <Typography>
                        {t("invite.expiredTime")}
                    </Typography>
                    <Typography mb={1}>
                        {data && new Date(data.expireTime).toLocaleString()}
                    </Typography>
                </div>
                <Button variant='contained' color='primary' onClick={() => handleSubmitVerify(true)}>{t("invite.button.accept")}</Button>
                <Box display="flex" justifyContent="center">
                    <Typography variant="body2">
                        {t("invite.ifNotAccept")}
                        <Link onClick={() => handleSubmitVerify(false)}>{t("invite.button.notAccept")}</Link>
                    </Typography>
                </Box>
            </Stack>
        </div >
    );
}
interface VerifyPageProps {
    code: string;
    data: BoothManageInvitation;
    handleSubmitVerify: (toUse: boolean) => Promise<string>;
}