import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Box, BoxProps, Button, Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormControlProps, FormGroup, FormGroupProps, FormHelperText } from '@mui/material';
import { MenuItem, Radio, RadioGroup, styled, TextField, TextFieldProps, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import { important, TextField as NoLegendTextField } from './responsive-components';
import { RegisterOptions } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { fieldPrivacyList } from './class';
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { CheckItemType, employmentStatusList, jobHuntingList, languageSkillList, MenuItemType, workPlaceList } from './selectOptions';

export const uniqueUserFieldList: string[] = [

]
export const uniqueBoothFieldList: string[] = [
	'edm', 'poster','video'
]
export const userCustomFieldList: CustomFieldElement[] = [
	//職稱
	{
		id: 'jobTitle',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.primary.main }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (<>
			<TextField
				size="small"
				{...props}
				required
			/>
		</>),
		validation: {
			required: "必填項目",
			maxLength: {
				value: 1000,
				message: '最多1000個字'
			}
		}
	},
	//年齡
	{
		id: 'age',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.primary.main }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (<>
			<TextField
				size="small"
				{...props}
			/>
		</>),
		validation: {
		//	required: "必填項目",
			maxLength: {
				value: 20,
				message: '最多20個字'
			}
		}
	},
	//性別
	{
		id: 'gender',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.primary.main }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (<>
			<TextField
				size="small"
				{...props}
			/>
		</>),
		validation: {
		//	required: "必填項目",
			maxLength: {
				value: 20,
				message: '最多20個字'
			}
		}
	},
	//學歷
	{
		id: 'education',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.primary.main }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (<>
			<TextField
				size="small"
				{...props}
			/>
		</>),
		validation: {
		//	required: "必填項目",
			maxLength: {
				value: 20,
				message: '最多20個字'
			}
		}
	},
	//學校/單位
	{
		id: 'school/organization',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.primary.main }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (<>
			<TextField
				size="small"
				{...props}
				required
			/>
		</>),
		validation: {
			required: "必填項目",
			maxLength: {
				value: 20,
				message: '最多20個字'
			}
		}
	},
	
];
export const boothCustomFieldList: CustomFieldElement[] = [
	//公司簡介
	{
		id: 'intro',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 5000,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={5000}>Max 5000</Trans>)
			}
		}
	},
	//公司簡介(英文)
	{
		id: 'enIntro',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 5000,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={5000}>Max 5000</Trans>)
			}
		}
	},
	//公司福利
	{
		id: 'welfare',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 300,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={300}>Max 300</Trans>)
			}
		}
	},
	//薪資福利
	{
		id: 'salary',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 300,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={300}>Max 300</Trans>)
			}
		}
	},
	//影片連結
	{
		id: 'video',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Button href={displayValue} target="_blank" sx={{
				maxWidth: '110px',
				fontWeight: 'bold',
				color: theme => theme.palette.primary.contrastText,
				backgroundColor: theme => theme.palette.primary.main,
				border: 'none',
				'&:hover': {
					color: theme => theme.palette.primary.contrastText,
					backgroundColor: theme => theme.palette.primary.dark,
				},
				'&:focus': {
					outline: 'none',
				},
				'&.Mui-disabled	': {
					backgroundColor: 'rgba(0, 0, 0, 0.04)',
				}
			}}>
				{displayTitle}
			</Button>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				placeholder={'https://'}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 200,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={200}>Max 200</Trans>)
			}
		}
	},
	//面談時間
	{
		id: 'interviewTime',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			{displayTitle &&
				<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
					{`${displayTitle} ｜ `}
				</Typography>
			}
			<Typography variant='h6' whiteSpace='break-spaces'>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='manage.editInterviewTime.placeholder'>placeholder</Trans>)}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 1000,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={1000}>Max 1000</Trans>)
			}
		}
	},
];
interface CustomFieldElement {
	id: string;
	displayComponent: (props: TypographyProps & CustomProps) => JSX.Element;
	editComponent: (props: TextFieldProps & { displayTitle?: string, errorMessage?: string }) => JSX.Element;
	validation?: RegisterOptions;
}
interface CustomProps {
	displayTitle?: string,
	displayValue?: string
}

export function SelectPrivacyField(props: SelectPrivacyFieldProps) {
	const { ...other } = props;
	const { t, i18n } = useTranslation();
	return (
		<Tooltip title={t('user.editDialog.fieldPrivacy.tooltip')} placement='top' sx={{ ml: 'auto', mt: '0px', mb: '0px' }}>
			<NoLegendTextField
				variant="outlined"
				size="small"
				margin="dense"
				select
				SelectProps={{
					renderValue: (selected) => t(fieldPrivacyList.find(x => x.value === selected)?.nameKey),
					inputProps: { sx: { paddingTop: '4px', paddingBottom: '4px' } }
				}}
				InputProps={{ style: { color: '#757575', borderRadius: '0px' } }}
				style={{ backgroundColor: '#f3f3f3' }}
				{...other}
			>
				{fieldPrivacyList.map((option) => (
					<MenuItem key={option.value} value={option.value}
						style={{ backgroundColor: '#f3f3f3', color: '#757575' }}>
						{t(option.fullNameKey)}
					</MenuItem>
				))}
			</NoLegendTextField>
		</Tooltip>
	)
}
type SelectPrivacyFieldProps = CustomSelectPrivacyFieldProps & TextFieldProps;
interface CustomSelectPrivacyFieldProps {

}

export function CheckListDisplay(props: CheckListDisplayProps) {
	const { checkList, displayTitle, displayValue } = props;
	const { t, i18n } = useTranslation();

	const theme = useTheme();
	const checkedColor = props.checkedColor ?? theme.palette.success.light;
	const uncheckColor = props.uncheckColor ?? theme.palette.grey[400];

	const [checkListState, setCheckListState] = React.useState<CheckState[]>([]);
	function valueToState(value: string): CheckState[] {
		let newList: CheckState[] = [];
		for (let item of checkList) {
			newList.push({ id: item.id, nameKey: item.nameKey, value: false });
		}
		if (value && value != '') {
			let tempArray = value.split(',');
			for (let temp of tempArray) {
				let index = newList.findIndex(x => x.id === temp);
				if (index !== -1)
					newList[index].value = true;
			}
		}
		return newList;
	}

	React.useEffect(() => {
		if (checkList) {
			let newCheckList: CheckState[] = [];
			for (let check of checkList) {
				newCheckList.push({ id: check.id, nameKey: check.nameKey, value: false });
			}
			if (displayValue) {
				newCheckList = valueToState(displayValue);
			}
			setCheckListState(newCheckList);
		}
	}, [checkList, displayValue])

	const checkIcon = (isCheck: boolean): JSX.Element => {
		if (isCheck) {
			return (
				<CheckCircle sx={{ color: checkedColor }} />
			)
		}
		else {
			return (
				<RemoveCircle sx={{ color: uncheckColor }} />
			)
		}
	}

	const StyledBox: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		marginRight: '12px',
		'&:last-child': {
			marginRight: '0px',
		},
	}));

	return (<>
		{checkListState.map((check) => (
			<StyledBox key={check.id} className='d-flex align-items-center'>
				{checkIcon(check.value)}
				<Typography sx={{ color: check.value ? 'inherit' : uncheckColor }}>
					{t(check.nameKey)}
				</Typography>
			</StyledBox>
		))}
	</>)
}
type CheckListDisplayProps = CustomCheckListDisplayProps & CustomProps & TypographyProps;
interface CustomCheckListDisplayProps {
	checkList: CheckItemType[];
	checkedColor?: string;
	uncheckColor?: string;
}

export function CheckListField(props: CheckListFieldProps) {
	const { checkList, style, sx, value, onChange, inputProps,
		formControlProps, formGroupProps, formControlLabelProps, checkboxProps, required, helperText, error, ...other } = props;
	const { t, i18n } = useTranslation();

	const [checkListState, setCheckListState] = React.useState<CheckState[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let tempList = [...checkListState];
		let index = tempList.findIndex(x => x.id === event.target.name);
		if (index !== -1) {
			tempList[index].value = event.target.checked;
		}
		setCheckListState([...tempList]);
		onChange({ ...event, target: { ...event.target, value: stateToValue(tempList) } });
	};

	function valueToState(value: string): CheckState[] {
		let newList: CheckState[] = [];
		for (let item of checkList) {
			newList.push({ id: item.id, nameKey: item.nameKey, value: false });
		}
		if (value != '') {
			let tempArray = value.split(',');
			for (let temp of tempArray) {
				let index = newList.findIndex(x => x.id === temp);
				if (index !== -1)
					newList[index].value = true;
			}
		}
		return newList;
	}
	function stateToValue(list: CheckState[]): string {
		let newValue = '';
		for (let item of list) {
			if (item.value === true) {
				let startElement = newValue !== '' ? ',' : '';
				newValue = newValue.concat(startElement, item.id);
			}
		}
		return newValue;
	}

	React.useEffect(() => {
		if (checkList) {
			let newCheckList: CheckState[] = [];
			for (let check of checkList) {
				newCheckList.push({ id: check.id, nameKey: check.nameKey, value: false });
			}
			if (value) {
				newCheckList = valueToState(String(value));
			}
			setCheckListState(newCheckList);
		}
	}, [checkList, value])

	return (
		<FormControl
			required={required}
			error={error}
			//component="fieldset"
			variant="standard"
			style={{ ...style }}
			{...formControlProps}
		>
			<FormGroup
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
				{...formGroupProps}
			>
				{checkListState.map((check) => (
					<FormControlLabel
						control={
							<Checkbox
								checked={check.value}
								onChange={handleChange}
								name={check.id}
								{...checkboxProps}
							/>
						}
						label={`${t(check.nameKey)}`}
						key={check.id}
						sx={{ mb: '0px' }}
						{...formControlLabelProps}
					/>
				))}
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}
type CheckListFieldProps = CustomCheckListFieldProps & TextFieldProps;
interface CustomCheckListFieldProps {
	checkList: CheckItemType[];
	formControlProps?: FormControlProps;
	formGroupProps?: FormGroupProps;
	formControlLabelProps?: FormControlLabelProps;
	checkboxProps?: CheckboxProps;
}
interface CheckState {
	id: string;
	nameKey: string;
	value: boolean;
}

export function MultipleSelectField(props: MultipleSelectFieldProps) {
	const { selectList, value, onChange, displayTitle, sx, style, SelectProps, inputProps, InputProps, error, helperText, FormHelperTextProps, ...other } = props;
	const { t, i18n } = useTranslation();

	const [selectItems, setSelectItems] = React.useState<string[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectItems(typeof event.target.value === 'string' ? event.target.value.split(',').filter(x => x !== '') : event.target.value);
		onChange({
			...event, target: {
				...event.target,
				value: typeof event.target.value === 'object' ? (event.target.value as string[]).filter(x => x !== '').join(',') : event.target.value
			}
		});
	};

	React.useEffect(() => {
		if (selectList) {
			setSelectItems(String(value).split(',').filter(x => x !== ''));
		}
	}, [selectList])

	const noLegendSx = {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				top: 0,
			},
			'& fieldset legend': {
				display: 'none',
			},
		}
	}

	return (
		<TextField
			hiddenLabel={!props.displayTitle}
			label={props.displayTitle}
			variant="outlined"
			size="small"
			margin="dense"
			select
			SelectProps={{
				multiple: true,
				renderValue: (selected) => {
					let tempList: string[] = [];
					for (let select of selected as string[]) {
						let temp = selectList.find(x => x.value == select);
						if (temp) tempList.push(t(temp.nameKey));
					}
					return tempList.join(', ');
				},
				MenuProps: { sx: { maxHeight: '300px', maxWidth: '100%' } }
				, ...SelectProps
			}}
			value={selectItems}
			onChange={handleChange}
			sx={!props.displayTitle ? { ...noLegendSx, ...sx } : { ...sx }}
			style={style}
			error={error}
			helperText={helperText}
			FormHelperTextProps={FormHelperTextProps}
			inputProps={{ ref: inputProps.ref }}
			InputProps={InputProps}
			{...other}
		>
			{selectList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					<Checkbox checked={selectItems.indexOf(item.value) > -1} />
					<Trans i18nKey={item.nameKey}>
						{item.value}
					</Trans>
				</MenuItem>
			))}
		</TextField>
	)
}
type MultipleSelectFieldProps = CustomMultipleSelectFieldProps & TextFieldProps;
interface CustomMultipleSelectFieldProps {
	selectList: MenuItemType[];
	displayTitle?: string;
}

/*
export function InternshipTimeTextField(props: TextFieldProps & { displayTitle?: string, errorMessage?: string }) {
	const { value, onChange, displayTitle, ...other } = props;

	const [inputText, setInputText] = React.useState('');

	return (<>
		<Typography sx={{
			fontSize: '16px',
			fontWeight: 'bold',
			color: theme => theme.palette.text.primary,
		}}>
			{props.displayTitle} {important}
		</Typography>
		<Box display='flex'>
			<NoLegendTextField
				variant="outlined"
				size="small"
				margin="dense"
				select
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				SelectProps={{
					displayEmpty: true
				}}
				InputProps={{ style: { backgroundColor: '#ffffff' } }}
				sx={{ flex: 1 }}
				{...props}
			>
				<MenuItem disabled value={null}>
					<em><Trans i18nKey='customField.choose' /></em>
				</MenuItem>
				{internshipTimeList.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						<Trans i18nKey={item.nameKey}>
							{item.value}
						</Trans>
					</MenuItem>
				))}
			</NoLegendTextField>
			{(value === "" || (!isNullOrUndefined(value) && internshipTimeList.findIndex(x => x.value == value) === -1)) &&
				<NoLegendTextField
					variant="outlined"
					size="small"
					margin="dense"
					value={inputText}
					onChange={onChange}
					InputProps={{ style: { backgroundColor: '#ffffff' } }}
					sx={{ marginLeft: '12px' }}
				/>
			}
		</Box>
	</>)
}
*/