import * as React from 'react';
import { useUser } from '../../store';
import BoothArticleList from '../../components/Article/manage/BoothArticleList';
import { SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../components/class';

export default function BoothArticleListManage(props: BoothArticleListManageProps) {
    const { boothId } = props;
    const { t, i18n } = useTranslation();
    const user = useUser();

    const [isManage, setIsManage] = React.useState(false);
    React.useEffect(() => {
        if (user) {
            let isboothMember = false;
            if (user.boothIds.includes(boothId) && user.role >= UserRole.normal) {
                isboothMember = true;
            }
            else if (user.role >= UserRole.administrator) {
                isboothMember = true;
            }
            setIsManage(isboothMember);
        }
    }, [user])

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.page.prepTool.boothArticleList')}
            />
            {user &&
                <BoothArticleList boothId={boothId} isManage={isManage} />
            }
        </div>
    )
};
interface BoothArticleListManageProps {
    boothId: string;
}