import * as React from "react";
import { Avatar, Box, BoxProps, Button, ClickAwayListener, darken, Drawer, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { AppointmentTextObject, AutoTextObject, CardInfo, ExchangeCardTextObject, InteractionTextObject, InteractionType, MessageContent, PlainTextObject, TextCode } from "../../class";
import { Notifications } from "@mui/icons-material";
import { MessageData, pileUpProps } from "./ChatDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useGetAppointmentQuery, useGetExchangeCardQuery } from "../../services/chat";
import { format } from "date-fns";
import { AppointmentButton, ExchangeCardButton, JoinRoomButton, MoreContent, MoreIconButton } from "./ChatMessageButton";
import enUS from "date-fns/locale/en-US";
import zhTW from "date-fns/locale/zh-TW";
import { useTranslation } from "react-i18next";
import { useSystemConnection } from "../../../store";
import { SystemControlHub } from "../../react-signalr/systemControlHub";
import Linkify from 'react-linkify';
import { CheckIsMobile } from "../../Admin/Utils";
import { declineReasons, t_copy, t_copy_message, t_delete } from "./translationKey";

function formati18n(date: Date, language) {
    let options = { locale: enUS };
    let dayOfWeek = "E"
    if (language == "ch") {
        options = { locale: zhTW };
        dayOfWeek = "EEEEE";
    }
    return date.getFullYear() === new Date().getFullYear() ?
        format(date, `MM/dd(${dayOfWeek}) HH:mm`, options) : format(date, `yyyy/MM/dd(${dayOfWeek}) HH:mm`, options);
}

interface ChatMessageProps {
    message: MessageData;
    userDatas: CardInfo[];
    onDelete: (chatId: string, messageId: string) => void;
}

export function ChatMessage(props: ChatMessageProps) {
    const { message, userDatas, onDelete } = props;
    const { t, i18n } = useTranslation();
    const [senderName, setSenderName] = React.useState(null);
    const [senderLogoUrl, setSenderLogoUrl] = React.useState(null);
    React.useEffect(() => {
        if (userDatas && message) {
            if (message.senderId && message.senderId != "") {
                let index = userDatas.findIndex(x => x.userId === message.senderId);
                if (index > -1) {
                    setSenderName(userDatas[index].alias);
                    setSenderLogoUrl(userDatas[index].logoUrl);
                }
            }
        }
    }, [userDatas, message]);

    const [exchangeCardId, setExchangeCardId] = React.useState<string>();
    const { exchangeCard, refetch: getExchangeCard } = useGetExchangeCardQuery(exchangeCardId, {
        selectFromResult: ({ data }) => ({ exchangeCard: data?.data })
    });
    const [appointmentId, setAppointmentId] = React.useState<string>();
    const { appointment, refetch: getAppointment } = useGetAppointmentQuery(appointmentId, {
        selectFromResult: ({ data }) => ({ appointment: data?.data })
    });
    React.useEffect(() => {
        if (!message.isDeleted) {
            if (message.textCode === TextCode.交換名片) {
                setExchangeCardId((message.textObject as ExchangeCardTextObject).actionId);
            }
            else if (message.textCode === TextCode.預約面談) {
                setAppointmentId((message.textObject as AppointmentTextObject).actionId)
            }
            else if (message.textCode === TextCode.系統通知) {
                setAppointmentId((message.textObject as AutoTextObject).appointmentId)
            }
        }
    }, [message]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到訊息更新，更新資料
            hubRef.current.addHandler.UpdateMessage((json) => {
                let _message = JSON.parse(json) as MessageContent;
                if (_message.messageId == message.messageId) {
                    if (_message.textCode === TextCode.交換名片) {
                        getExchangeCard();
                    }
                    else if (_message.textCode === TextCode.預約面談) {
                        getAppointment();
                    }
                }
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.UpdateMessage();
            }
        })
    }, [systemConnection]);

    //更多面板
    function handleDelete() {
        onDelete(message.chatId, message.messageId);
    }
    function handleCopyContent() {
        let content = (message.textObject as PlainTextObject).content;
        navigator.clipboard.writeText(content)
    }
    function handleCopyExchangeCard() {
        navigator.clipboard.writeText(exchangeCard.message);
    }
    function handleCopyAppointment() {
        navigator.clipboard.writeText(appointment.message);
    }
    let moreContents: MoreContent[] = [];
    if (message.isDeleted) {
        return (
            <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="deleted" moreContents={moreContents}>
                <Typography>{message.fromMe ? t('chat.message.you_deleted') : t('chat.message.other_deleted')}</Typography>
            </ChatMessageContent>
        )
    }
    switch (message.textCode) {
        case TextCode.系統通知:
            return (
                <ChatMessageContent fromMe={false} name={t('chat.message.system')} avatarIcon={<Notifications />} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="border" moreContents={moreContents}>
                    <Box display="flex" flex="0 0 39px" width="39px" height="28px" alignItems="center" sx={{ color: 'primary.main', fontSize: "24px" }}>
                        <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box>
                    <Stack>
                        <Typography>{t('chat.message.appointment_start')}</Typography>
                        {appointment ? <>
                            <Typography variant="body2">{t('chat.message.appointment_time')}</Typography>
                            <Typography variant="body2">{appointment && formati18n(new Date(appointment.appointedTime), i18n.language)}</Typography>
                            {appointment.message && appointment.message.length > 0 && <>
                                <Typography variant="caption" color="text.secondary" marginLeft={1} marginTop={1}>
                                    {message.fromMe ? t('chat.message.you_message') : t('chat.message.other_message')}
                                </Typography>
                                <Typography variant="caption" color="primary.main" marginLeft={1}>{appointment.message}</Typography>
                            </>}
                            <JoinRoomButton chatId={message.chatId} /></>
                            : <>
                                <Skeleton />
                                <Skeleton width="60%" />
                                <Skeleton />
                            </>
                        }
                    </Stack>
                </ChatMessageContent>
            )
        case TextCode.純文字及連結:
            let content = (message.textObject as PlainTextObject).content;
            moreContents = [
                { key: t_delete, onClick: handleDelete, disabled: !message.fromMe },
                { key: t_copy, onClick: handleCopyContent }
            ];
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} moreContents={moreContents}>
                    <Linkify componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                        <a target='_blank' style={{ color: '#007bff' }} href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}><Typography>{content}</Typography></Linkify>
                </ChatMessageContent>
            )
        case TextCode.互動訊息:
            let interactionType = (message.textObject as InteractionTextObject).interactionType;
            let isAccept = !(interactionType & 1);
            let type = interactionType >> 1;
            let verbText = <Typography component="span" color={isAccept ? "success.light" : "error.light"}>
                {isAccept ? t('chat.message.accept') : t('chat.message.decline')}
            </Typography>;
            let typeText, caption;
            switch (type) {
                case 0:
                    typeText = <Typography component="span" fontWeight="bold">{t('chat.message.exchange')}</Typography>;
                    break;
                case 1:
                    typeText = <Typography component="span" fontWeight="bold">{t('chat.message.appointment')}</Typography>;
                    if (isAccept) {
                        caption = t('chat.message.appointment_tip')
                    }
                    else {
                        let reason = (message.textObject as InteractionTextObject).message;
                        let findIndex = declineReasons.findIndex(x => x.key === reason);
                        if (findIndex > -1)
                            caption = t('chat.message.appointment_declineReply') + t(declineReasons[findIndex].value);
                        else
                            caption = t('chat.message.appointment_declineReply') + reason;
                    }
                    break;
            }
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} moreContents={moreContents}>
                    <Stack>
                        {message.fromMe?
                            <Typography>{t('chat.message.you_invite_front')}{verbText}{typeText}{t('chat.message.you_invite_back')}</Typography>
                            :
                            <Typography>{t('chat.message.other_invite_front')}{verbText}{t('chat.message.other_invite_mid')}{typeText}{t('chat.message.other_invite_back')}</Typography>
                        }
                        <Typography variant="caption" color="text.secondary">{caption}</Typography>
                    </Stack>
                </ChatMessageContent>
            )
        case TextCode.貼圖:
            moreContents = [
                { key: t_delete, onClick: handleDelete, disabled: !message.fromMe }
            ];
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="empty" moreContents={moreContents}>
                </ChatMessageContent>
            )

        case TextCode.交換名片:
            moreContents = [
                { key: t_delete, onClick: handleDelete, disabled: !message.fromMe },
                { key: t_copy_message, onClick: handleCopyExchangeCard, disabled: !exchangeCard?.message || exchangeCard.message.length === 0 }
            ];
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="border" moreContents={moreContents}>
                    <Box display="flex" flex="0 0 39px" width="39px" height="28px" alignItems="center" sx={{ color: 'primary.main', fontSize: "24px" }}>
                        <FontAwesomeIcon icon={faUserPlus} color="inherit" fixedWidth /></Box>
                    <Stack>
                        <Typography>{t('chat.message.invite_exchange')}</Typography>
                        {exchangeCard ? <>
                            {exchangeCard.message && exchangeCard.message.length > 0 && <>
                                <Typography variant="caption" color="text.secondary" marginLeft={1} marginTop={1}>
                                    {message.fromMe ? t('chat.message.you_message') : t('chat.message.other_message')}
                                </Typography>
                                <Typography variant="caption" color="primary.main" marginLeft={1}>{exchangeCard.message}</Typography>
                            </>}
                            <ChatMessageActionArea>
                                <ExchangeCardButton chatId={message.chatId} actionId={(message.textObject as ExchangeCardTextObject).actionId} fromMe={message.fromMe} status={exchangeCard.status} />
                            </ChatMessageActionArea></>
                            : <>
                                <Skeleton />
                                <Skeleton width="60%" />
                                <Skeleton />
                            </>
                        }
                    </Stack>
                </ChatMessageContent>
            )
        case TextCode.預約面談:
            moreContents = [
                { key: t_delete, onClick: handleDelete, disabled: !message.fromMe },
                { key: t_copy_message, onClick: handleCopyAppointment, disabled: !appointment?.message || appointment.message.length === 0 }
            ];
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="border" moreContents={moreContents}>
                    <Box display="flex" flex="0 0 39px" width="39px" height="28px" alignItems="center" sx={{ color: 'primary.main', fontSize: "24px" }}>
                        <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box>
                    <Stack>
                        <Typography>{t('chat.message.invite_appointment')}</Typography>
                        {appointment ? <>
                            <Typography variant="body2">{t('chat.message.appointment_time')}</Typography>
                            <Typography variant="body2">{appointment && formati18n(new Date(appointment.appointedTime), i18n.language)}</Typography>
                            {appointment.message && appointment.message.length > 0 && <>
                                <Typography variant="caption" color="text.secondary" marginLeft={1} marginTop={1}>
                                    {message.fromMe ? t('chat.message.you_message') : t('chat.message.other_message')}
                                </Typography>
                                <Typography variant="caption" color="primary.main" marginLeft={1}>{appointment.message}</Typography>
                            </>}
                            <ChatMessageActionArea>
                                <AppointmentButton chatId={message.chatId} actionId={(message.textObject as AppointmentTextObject).actionId} fromMe={message.fromMe} status={appointment.status} />
                            </ChatMessageActionArea></>
                            : <>
                                <Skeleton />
                                <Skeleton width="60%" />
                                <Skeleton />
                            </>
                        }
                    </Stack>
                </ChatMessageContent>
            )
        case TextCode.視訊邀請:
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="border" moreContents={moreContents}>
                </ChatMessageContent >
            )
        case TextCode.來自電腦的完整檔案:
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="empty" moreContents={moreContents}>
                </ChatMessageContent>
            )
        case TextCode.來自電腦的縮圖版本檔案:
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="empty" moreContents={moreContents}>
                </ChatMessageContent>
            )
        case TextCode.來自Google雲端的完整檔案:
            return (
                <ChatMessageContent fromMe={message.fromMe} name={senderName} avatarUrl={senderLogoUrl} pileUp={message.pileUp} timestamp={message.createTime} hasBeenRead={message.hasBeenRead} variant="empty" moreContents={moreContents}>
                </ChatMessageContent>
            )
    }
}

interface ChatMessageContentProps {
    fromMe: boolean;
    name: string;
    avatarUrl?: string;
    avatarIcon?: JSX.Element;
    pileUp: pileUpProps;
    timestamp: string;
    hasBeenRead: boolean | number; //是否已讀
    variant?: "default" | "border" | "empty" | "deleted";
    moreContents: MoreContent[];
    children?: React.ReactNode;
}

function ChatMessageContent(props: ChatMessageContentProps) {
    const { fromMe, name, avatarUrl, avatarIcon, pileUp, timestamp, hasBeenRead, variant, moreContents, children } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const textBackgroundColor = "#EFEFEF";
    const backgroundColor = theme.palette.background.paper;
    const borderColor = "#707070";
    const attachedColor = "#B7B7B7";

    const defaultBorderRadius = "20px";
    const defaultSmallBorderRadius = "4px";
    const borderTopLeftRadius = (!fromMe && (pileUp && !pileUp.head)) ? defaultSmallBorderRadius : defaultBorderRadius;
    const borderTopRightRadius = (fromMe && (pileUp && !pileUp.head)) ? defaultSmallBorderRadius : defaultBorderRadius;
    const borderBottomRightRadius = (fromMe && (pileUp && !pileUp.tail)) ? defaultSmallBorderRadius : defaultBorderRadius;
    const borderBottomLeftRadius = (!fromMe && (pileUp && !pileUp.tail)) ? defaultSmallBorderRadius : defaultBorderRadius;

    //控制浮動的按鈕
    const [hovered, setHovered] = React.useState<boolean>(false);
    const [active, setActive] = React.useState<boolean>(false);
    const handleToggle = (event: React.MouseEvent) => {
        event.preventDefault();
        setActive((prev) => !prev);
    };
    const handleHide = () => {
        setActive(false);
    };
    const handleOnMouseEnter = () => {
        if (!CheckIsMobile())
            setHovered(true);
    };
    const handleOnMouseLeave = () => {
        if (!CheckIsMobile())
            setHovered(false);
    };

    //控制手機版浮動的按鈕
    const tapZoneRef = React.useRef<HTMLDivElement>();
    const [touched, setTouched] = React.useState<boolean>(false);
    const [longPressTriggered, setLongPressTriggered] = React.useState<boolean>(false);
    const threshold = 800;
    const timeoutEvent = React.useRef<NodeJS.Timeout | undefined>();
    React.useEffect(() => {
        const tapZone = tapZoneRef.current;
        const detectTouch = (event) => {
            if (tapZoneRef.current && !tapZoneRef.current.contains(event.target)) setTouched(false);
            else setTouched(true);
        };
        const start = (event) => {
            if (moreContents.length > 0)
                timeoutEvent.current = setTimeout(() => { setLongPressTriggered(true) }, threshold);
        };
        const move = (event) => { if (timeoutEvent.current) clearTimeout(timeoutEvent.current) };
        const end = (event) => { if (timeoutEvent.current) clearTimeout(timeoutEvent.current) };

        if (CheckIsMobile()) {
            //拿掉Android長按的事件
            tapZone.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });
            //拿掉複製文字功能
            tapZone.style.userSelect = "none";

            //Passive Event Listeners–被動事件監聽器偵測
            var passiveSupported = false;
            try {
                var options = Object.defineProperty({}, "passive", {
                    get: function () {
                        passiveSupported = true;
                    }
                });
                window.addEventListener("test", null, options);
            } catch (err) { }

            //偵測touch
            document.addEventListener('touchstart', detectTouch, passiveSupported ? { passive: true } : false);

            //放入事件
            tapZone.addEventListener('touchstart', start, passiveSupported ? { passive: true } : false);
            tapZone.addEventListener('touchmove', move, passiveSupported ? { passive: true } : false);
            tapZone.addEventListener('touchend', end, passiveSupported ? { passive: true } : false);
        }
        return () => {
            document.removeEventListener('touchstart', detectTouch);
            tapZone.removeEventListener('touchstart', start);
            tapZone.removeEventListener('touchmove', move);
            tapZone.removeEventListener('touchend', end);
        };
    }, [tapZoneRef.current]);

    const tapStyle = {
        backgroundColor: "#eaf4fe",
        //filter: "brightness(0.95)",
        borderColor: "transparent", 
        outline: `2px solid ${theme.palette.primary.main}`
    };

    const handleClose = () => {
        setLongPressTriggered(false);
    };

    function handleClickAway() {
        setActive(false);
        setTouched(false);
    }

    return (<>
        <ClickAwayListener onClickAway={handleClickAway}>
            <ListItem disablePadding sx={{ alignItems: "stretch", marginBottom: (!pileUp || pileUp.tail) ? "7px" : "2px", }}
                onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                {fromMe &&
                    <Box flex="0 0 14px" width="14px" />
                }
                {/*頭像*/}
                {!fromMe &&
                    <ListItemAvatar sx={{ display: "flex", justifyContent: "center", minWidth: "58px", paddingInline: "12px 8px" }}>
                        {(!pileUp || (pileUp && pileUp.head)) &&
                            <>
                                {avatarUrl ?
                                    <Avatar src={avatarUrl} sx={{ width: "38px", height: "38px" }} /> :
                                    avatarIcon ?
                                        <Avatar sx={{ width: "38px", height: "38px", backgroundColor: "warning.light" }}> {avatarIcon}</Avatar> :
                                        <Skeleton variant="circular" width="38px" height="38px" />
                                }
                            </>
                        }
                    </ListItemAvatar>
                }
                <Stack flex="1 1" sx={{ maxWidth: "calc(100% - 14px - 20px)" }}>
                    {/*名字*/}
                    {!fromMe && (!pileUp || (pileUp && pileUp.head)) &&
                        <>{name ?
                            <Typography variant="caption">{name}</Typography> :
                            <Skeleton variant="text" />
                        } </>
                    }
                    {/*文字內容*/}
                    <Box display="flex" sx={{ flexDirection: fromMe ? "row-reverse" : "row" }}>
                        {children ?
                            <Box display="flex" maxWidth="180px" padding="8px 12px 8px 12px" sx={variant == "border" ? { background: backgroundColor, border: `1px ${borderColor} solid` } :
                                variant == "empty" ? { background: backgroundColor, border: "none" } :
                                    variant == "deleted" ? { background: backgroundColor, border: `1px ${darken(textBackgroundColor, 0.25)} solid`, color: darken(textBackgroundColor, 0.25) } : { background: textBackgroundColor }}
                                style={{ borderRadius: `${borderTopLeftRadius} ${borderTopRightRadius} ${borderBottomRightRadius} ${borderBottomLeftRadius}`, ...(touched ? tapStyle : {}) }}
                                ref={tapZoneRef}
                            >
                                {children}
                            </Box>
                            :
                            <Skeleton variant="rectangular" />
                        }
                        <Box display="flex" flex="0 1 40px" width="40px" minHeight="40px" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" px={0.5} >
                            {fromMe && hasBeenRead &&
                                <Typography variant="caption" color={attachedColor}>
                                    {t('chat.message.read')}{typeof hasBeenRead === "number" && hasBeenRead}
                                </Typography>
                            }
                            {/*時間標記*/}
                            {timestamp &&
                                <Typography variant="caption" color={attachedColor}>
                                    {format(new Date(timestamp), "kk:mm")}
                                </Typography>
                            }
                        </Box>
                        <Box display="flex" flex="0 1 27px" width="27px" minHeight="40px" flexDirection="row" justifyContent="flex-start" alignItems="flex-end" >
                            {(hovered || active) && moreContents.length > 0 &&
                                <MoreIconButton contents={moreContents} onClick={handleToggle} onClose={handleHide} />
                            }
                        </Box>
                        {/*<Box flex="0 1 27px" width="27px" />*/}
                        <Box flex="1 1" />
                    </Box>
                </Stack>
                <Box flex="0 0 20px" width="20px" />
            </ListItem>
        </ClickAwayListener>
        {CheckIsMobile() &&
            <Drawer anchor="bottom" open={longPressTriggered} onClose={handleClose}>
                <List>
                    {moreContents.map((element) => (
                        <ListItemButton key={element.key} onClick={() => { element.onClick(); handleClose() }} disabled={element.disabled}>
                            <ListItemText>
                                {t(element.key)}
                            </ListItemText>
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
        }
    </>)
}

interface ChatMessageActionAreaProps extends BoxProps {}
function ChatMessageActionArea(props: ChatMessageActionAreaProps) {
    const { children, ...other } = props;
    return (
        <Box display="flex" flexWrap="nowrap" minWidth="calc(180px - calc(12px * 2) - calc(1px * 2) - 39px)" {...other}>{children}</Box>
    )
}