import { Preview } from "@mui/icons-material";
import { Dialog, DialogContentText, Toolbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { zhTWGrid } from "../../ManageBoard/data-grid-locale";
import { useDispatchWithType, useUser } from "../../store";
import {  User,  UserSign } from "../class";
import { Button, DialogActions, DialogContent, DialogTitle } from "../responsive-components";
import { SubTitle } from "../responsive-components/Typography";
import { AllBoothQRCodeHistoryList, SignInAgenda, useGetAllAgendaSignInHistoryQuery, useGetAllBoothQRCodeHistoryQuery, useLazyGetBoothQRCodeHistoryQuery, useGetEveryBoothQRCodeHistoryQuery, UserRecordWithBooth, BoothRecord, UserRecord, } from "../services/qrcode";
import { useGetUserListQuery, UserDetailData } from "../services/user";

export function AllSignInHistoryList() {
    const user = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatchWithType

   const { t, i18n } = useTranslation();

    return <>

        <AgendaSignInHistoryList />


        <Toolbar />
    </>
}

function CustomToolbar(props: { fileName?: string }) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{ utf8WithBom: true, fileName: props.fileName ?? "匯出檔案", }} />
        </GridToolbarContainer>
    );
}

interface AllBoothQRCodeHistoryGridDataRow extends AllBoothQRCodeHistoryList {
    id: string,
    boothName:string,
}

export function AllBoothSignInHistoryList() {
    const { t, i18n } = useTranslation();
    const { data, isLoading } = useGetAllBoothQRCodeHistoryQuery()
    const dataList: AllBoothQRCodeHistoryGridDataRow[] = data?.data.map((data) => ({
        ...data,
        id: data.booth.boothId,
        boothName: data.booth.chName,
    })) ?? [];
    const [userSigns, setUserSigns] = React.useState([]);
    const [tableName, setTableName] = React.useState("");
    const [getBoothQRCodeHistory, { isLoading: getBoothQRCodeHistoryIsLoading }] =useLazyGetBoothQRCodeHistoryQuery();
    const columns: GridEnrichedColDef<AllBoothQRCodeHistoryGridDataRow>[] = [
        {
            field: 'boothName',
            headerName: t('manage.title.qrcodeHistory.agenda'),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'scanedNumber',
            headerName: "被掃描次數",
            width: 120
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<AllBoothQRCodeHistoryGridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={t("manage.title.qrcodeHistory.actions.preview")}>
                            <Preview />
                        </Tooltip>
                    }
                    onClick={() => {
                        getBoothQRCodeHistory(row.booth.boothId).unwrap().then(res => {
                            if (res.isSuccess) {
                                handleClickOpen();
                                setUserSigns(res.data.userRecords.map(record => {
                                    return  {
                                        id: record.user.userId,
                                        level: record.user.role,
                                        name: record.user.name,
                                        email: record.user.email,
                                        college: record.user.infoFields.find(x => x.fieldId=='college')?.value??"無資料",
                                        department: record.user.infoFields.find(x => x.fieldId == 'department')?.value ?? "無資料",
                                        time: record.time
                                    }
                                }))
                                setTableName(row.booth.chName + "掃描名單")
                            }
                        })
                    }}
                    label={t("manage.title.qrcodeHistory.actions.preview")}
                />,
            ]
        }
    ];

    //dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <SubTitle
            icon='circle'
            text={t('manage.title.qrcodeHistory.boothScanedHistory')}
        />
        <DataGrid
            rows={dataList}
            columns={columns}
            pageSize={10}
            getRowId={(data) => data.booth.boothId}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={isLoading || getBoothQRCodeHistoryIsLoading}
            components={{
                Toolbar: () => <CustomToolbar fileName={"攤位被掃描紀錄" }></CustomToolbar>,
            }}
        />

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='lg'
        >
            <DialogContent>
                <SignInTable userSigns={userSigns} tableName={tableName} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>確認</Button>
            </DialogActions>
        </Dialog>

        <Toolbar />

        <AllSignInTable />
    </>
}

interface AgendaGridDataRow extends SignInAgenda {
    id: string,
    title: string;
    signInNum: number;
    startTime: string;
    endTime: string;
}
function AgendaSignInHistoryList() {
    const { t, i18n } = useTranslation();
    const { data, isLoading } = useGetAllAgendaSignInHistoryQuery()
    const dataList: AgendaGridDataRow[] = data?.data.map((data) => ({
        ...data,
        id: data.agenda.agendaId,
        title: data.agenda.title,
        startTime: data.agenda.startTime,
        endTime: data.agenda.endTime,
        signInNum: data.userSigns.length,
    })) ?? [];
    const [userSigns, setUserSigns] = React.useState([]);
    const [tableName, setTableName] = React.useState("");

    const columns: GridEnrichedColDef<AgendaGridDataRow>[] = [
        {
            field: 'title',
            headerName: t('manage.title.qrcodeHistory.agenda'),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'startTime',
            headerName: t('manage.title.qrcodeHistory.startTime'),
            type: 'dateTime',
            width: 140,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm")
        },
        {
            field: 'endTime',
            headerName: t('manage.title.qrcodeHistory.endTime'),
            type: 'dateTime',
            width: 140,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm"),
        },
        {
            field: 'signInNum',
            headerName: t('manage.title.qrcodeHistory.signInNum'),
            width: 120
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<AgendaGridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={t("manage.title.qrcodeHistory.actions.preview")}>
                            <Preview />
                        </Tooltip>
                    }
                    onClick={() => {
                        handleClickOpen();
                        setUserSigns(row.userSigns)
                        setTableName(row.title +"報到名單")
                        console.log(row.userSigns)
                    }}
                    label={t("manage.title.qrcodeHistory.actions.preview")}
                />,
            ]
        }
    ];

    //dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    return <>
        <SubTitle
            icon='circle'
            text={t('manage.title.qrcodeHistory.userAgendaSignInHistory')}
        />
        <DataGrid
            rows={dataList}
            columns={columns}
            pageSize={10}
            getRowId={(data) => data.agenda.agendaId}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={isLoading}
            components={{
                Toolbar: CustomToolbar,
            }}
        />
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='lg'
        >
            <DialogContent>
                <SignInTable userSigns={userSigns} tableName={tableName} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>確認</Button>
            </DialogActions>
        </Dialog>
    </>
}

function SignInTable(props: { userSigns: UserSign[], tableName :string}) {
    const {  } = props;
    const { t, i18n } = useTranslation();
    const dataList = props.userSigns ?? [];
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: t('manage.title.qrcodeHistory.user'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'name',
            headerName: t('manage.title.qrcodeHistory.name'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'email',
            headerName: t('manage.title.qrcodeHistory.email'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'college',
            headerName: t('manage.title.qrcodeHistory.college'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'department',
            headerName: t('manage.title.qrcodeHistory.department'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'time',
            headerName: t('manage.title.qrcodeHistory.time'),
            flex: 1,
            minWidth: 180,
            valueFormatter: (params) => new Date(params.value).toLocaleString('zh-tw')
        },
    ];
    return <>
        <SubTitle
            icon='circle'
            text={t('manage.title.qrcodeHistory.user')}
        />
        <DataGrid
            rows={dataList}
            columns={columns}
            pageSize={10}
            getRowId={(data) => data.time}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            components={{
                Toolbar: () => <CustomToolbar fileName={props.tableName } />,
            }}
        />
    </>
}

function AllSignInTable(props: {}) {
    const { } = props;
    const { t, i18n } = useTranslation();

    const { data, isLoading } = useGetEveryBoothQRCodeHistoryQuery();
    const dataList = data?.data.map((data) => ({
        id: data.user?.userId,
        name: data.user?.name,
        email: data.user?.email,
        college: data.user?.infoFields.find(x => x.fieldId === 'college')?.value,
        department: data.user?.infoFields.find(x => x.fieldId === 'department')?.value,
        time: data.time,
        boothName: data.booth?.chName,
        boothLocation: data.booth?.boothLocation,
    })) ?? [];

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: t('manage.title.qrcodeHistory.user'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'name',
            headerName: t('manage.title.qrcodeHistory.name'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'email',
            headerName: t('manage.title.qrcodeHistory.email'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'college',
            headerName: t('manage.title.qrcodeHistory.college'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'department',
            headerName: t('manage.title.qrcodeHistory.department'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'time',
            headerName: t('manage.title.qrcodeHistory.time'),
            flex: 1,
            minWidth: 180,
            valueGetter: (params) => new Date(params.value).toLocaleString('zh-tw')
        },
        {
            field: 'boothName',
            headerName: '公司名稱',
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'boothLocation',
            headerName: "展區",
            flex: 1,
            minWidth: 180,
        },
    ];
    return <>
        <SubTitle
            icon='circle'
            text="全部攤位被掃描紀錄"
        />
        <DataGrid
            rows={dataList}
            loading={isLoading}
            columns={columns}
            pageSize={10}
            getRowId={(data) => data.time}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            components={{
                Toolbar: () => <CustomToolbar fileName={'全部攤位被掃描紀錄'} />,
            }}
        />
    </>
}