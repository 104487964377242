import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

export default function ErrorPage() {
    return (
        <Box display='flex' sx={{ height: 'calc(100vh - 56px - 100px)' }}>
            <Stack alignItems='center' spacing={2} m='auto'>
                <Typography variant='h2'>
                    <ErrorOutline fontSize="inherit" />
                </Typography>
                <Typography variant='h5' align='center' fontWeight="bold">
                    出錯囉! 麻煩檢查您的網際網路連線並重新整理
                </Typography>
            </Stack>
        </Box>
    )
}