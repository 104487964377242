import * as React from 'react';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../../components/responsive-components';
import { Backdrop, Box, Checkbox, CircularProgress, Dialog as HintDialog, DialogActions as HintDialogActions, FormControlLabel, Grid, Stack, TextField as OriginField, Typography } from '@mui/material';
import { BoothQandA } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper } from '@fortawesome/free-solid-svg-icons';

interface AddBoardCardDialogProps {
    open: boolean;
    handleSubmit: (value: formData) => Promise<void>;
    handleClose: () => void;
}

type formData = {
    question: string;
}
export default function AddBoardCardDialog(props: AddBoardCardDialogProps) {
    const { t, i18n } = useTranslation();

    const { open, handleSubmit, handleClose } = props;
    const schema = yup.object().shape({
        question: yup.string().required("必填").max(50, "超過50字")
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, reset } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            question: '',
        }
    });
    const [isLoading, setIsLoading] = React.useState(false);
    function onSubmit(data: formData) {
        setIsLoading(true);
        handleSubmit(data).then(() => {
            handleOnClose()
        }).finally(() => setIsLoading(false));
    }

    const [openAsk, setOpenAsk] = React.useState(false);
    const handleCancel = () => {
        setOpenAsk(true);
    };

    const handleOnClose = () => {
        handleClose();
        reset();
    }

    return (
        <>
            <Dialog open={open} onClose={handleCancel} maxWidth={"sm"} fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main', mr: 2 }}>
                            <FontAwesomeIcon icon={faHandPaper} color="inherit" fixedWidth /></Box>
                        <Typography variant="h6" fontWeight="bold">我要提問</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                    <DialogContent sx={{ py: 0 }}>
                        <Stack>
                            <Typography>{important}{`${"撰寫問題"}：`}</Typography>
                            <TextField
                                variant="outlined"
                                inputRef={register('question').ref}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 50 }}
                                placeholder={"(上限50字)"}
                                error={!!errors.question}
                                helperText={errors.question ? errors.question.message : ' '}
                                {...register("question")}
                                disabled={isLoading}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton type="submit" loading={isLoading}>{t('boardCard.addDialog.submit')}</ConfirmButton>
                    </DialogActions>
                </Box>
                <HintDialog open={openAsk}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {t('boardCard.hint.notSave')}
                        </span>
                    </div>
                    <HintDialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpenAsk(false); handleOnClose(); }}>{t('boardCard.hint.yes')}</Button>
                        <Button onClick={() => { setOpenAsk(false); }}>{t('boardCard.hint.no')}</Button>
                    </HintDialogActions>
                </HintDialog>
            </Dialog>
            {
                isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    );
}