import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../store';
import { UserRole } from './class';

export interface BlockAnonymousProps {
    children: React.ReactElement
}
export default function BlockAnonymous(props: BlockAnonymousProps) {
    const user = useUser();
    const history = useHistory();
    const { t } = useTranslation();

    const matches = [/\/(\?.+)?$/, '/login', '/register', '/verify', '/forgotpassword', '/resetpassword', '/invite', '/booth', '/event', '/venue','/transportation', '/boothlist', '/articlelist',"/comingsoon"];
    const pathName = useLocation().pathname;
    const search = useLocation().search;
    const path = pathName.toLowerCase();

    /*const [previousPage, setPreviousPage] = React.useState<string[]>([]);
    const handleGoBack = () => {
        history.goBack();
        if (previousPage.length > 0) setPreviousPage(previousPage.slice(0, previousPage.length - 1));
    }*/

    const handleGoToLogin = () => {
        history.push('/Login' + (pathName ? "?redirect=" + encodeURIComponent(pathName + search) : ""));
        //setPreviousPage((state) => [...state, '/Login' + (pathName ? "?redirect=" + pathName + search : "")]);
    }

    const handleGoToHome = () => {
        history.push('/');
        //setPreviousPage((state) => [...state, '/']);
    }

    //Not anonymous or path match ignore list
    if ((user && user.role != UserRole.anonymous) || matches.some(match => path.match(match)))
        return props.children;
    else {
        return (
            <Dialog open={true}>
                <DialogContent sx={{ pb: 1 }}>
                    {t("blockAnonymous.text")}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleGoToLogin}>{t("blockAnonymous.button")}</Button>
                    {/*previousPage.length > 0 ?
                        <Button variant="contained" onClick={handleGoBack}>{t("blockAnonymous.back")}</Button> :*/
                        <Button variant="contained" onClick={handleGoToHome}>{t("blockAnonymous.home")}</Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}