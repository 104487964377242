import { Backdrop, Box, Button, ButtonBase, Card, CardActions, CardContent, CircularProgress, Container, Grid, Paper, PaperProps, Stack, styled, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeStepper from "./HomeStepper";
import { useDispatchWithType, useSystemConnection, useUser, useWatchedStartAnounce } from "../store";
import { SystemControlHub } from "../components/react-signalr/systemControlHub";
import { closeStartAnounce } from "../store/rootReducer";
import { SubTitle } from "../components/responsive-components/Typography";
import { AgendaList } from "../Booth/Seminar/AgendaList";
import { useLazyGetAllSeminarsQuery } from "../components/services/seminar";
import { logEvent } from "../components/Analytics";
import { Carousel } from 'react-responsive-carousel/lib/js';
import { imageFolder } from "../config";
import HomeSlider from "./HomeSlider";
const HomeImg = "images/2023 TSGDF網頁首頁主視覺banner.png";
const GameImage = imageFolder + "遊戲逛展_圖.svg";
const BoothListImage = imageFolder + "多元交流_圖.svg";
const SeminarImage = imageFolder + "多項議程_圖.svg";
const PrizeImage = imageFolder + "集點好禮_圖.svg";

export function Home() {
    const history = useHistory();

    const theme = useTheme();
    const isMatchesSm = useMediaQuery(theme.breakpoints.up('sm'));

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 0, refId: null });
        }
    }, [systemConnection]);

    const { t, i18n } = useTranslation();

    //簽到相關
    const watchedStartAnounce = useWatchedStartAnounce();
    const dispatch = useDispatchWithType();
    const [openStepper, setOpenStepper] = React.useState<boolean>(false);
    React.useEffect(() => { if (!watchedStartAnounce) setOpenStepper(true) }, [watchedStartAnounce]);
    const handleCloseStepper = () => {
        setOpenStepper(false);
        dispatch(closeStartAnounce())
    }

    //說明會
    const [getSeminars, { data: seminars }] = useLazyGetAllSeminarsQuery();
    React.useEffect(() => { getSeminars() }, []);

    //主視覺
    const [index, setIndex] = React.useState<number>(0);
    const images = [];
    const CarouselView = () => (
        <Box>
            <Carousel
                autoPlay
                showArrows={isMatchesSm}
                showStatus
                showIndicators
                showThumbs={false}
                useKeyboardArrows={false}
                emulateTouch={true}
                selectedItem={index}
                infiniteLoop
            >
                {images.map((imageSrc, index) => (
                    <Box height={isMatchesSm ? "640px" : "30vh"} key={imageSrc}>
                        <img src={imageSrc} width="100%" height="100%" style={{ objectFit: "contain" }} />
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
    const Banner = () => (
        //尺寸1800*840
        <Box height={isMatchesSm ? "640px" : "30vh"}>
            <img src={HomeImg} width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
    );

    //跳轉
    const handleClickLink = (link: string) => {
        history.push(link);
    }
    const Divider = () => <Box sx={{
        borderLeft: { xs: '', md: '1px solid #D8D8D8' },
        borderTop: { xs: '1px solid #D8D8D8', md: '' },
        height: { xs: '', md: '200px' },
        width: { xs: '200px', md: '' }
    }} />

    return (<>
        <HomeStepper open={openStepper} onClose={handleCloseStepper} />
        <Box >
            <HomeSlider contents={[<Banner />, <Banner />]} />
        </Box>

        <Box style={{ margin: "2rem 0px" }}>
            <Container>
                <Typography variant='h6' align='center' gutterBottom fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>
                    {/*t('home.title.part1')*/}&#10022;2023 TSGDF 臺灣學生遊戲開發者論壇&#10022;
                </Typography>
                <Box style={{ backgroundColor: '#fff', borderRadius: "15px" }}>
                    {i18n.language == 'ch' ?
                    <Typography sx={{ maxWidth: '1000px', margin: 'auto' }} p={'40px'}>
                        &#127793; 2023 𝐓𝐒𝐆𝐃𝐅&#128161;<br />
                        &#9658; 活動時間：4/28(五)、4/29(六)<br />
                        &#9658; 活動地點：桃園市青年事務局（桃園市中壢區環北路390號）<br />
                        &#9658; 活動&#10024;免費參與&#10024;<br />
                        &#9658; 線上亦可參與議程直播＆遊戲逛展<br />
                        舉起寶劍，來創造你的冒險與邂逅！                
                        </Typography> :
                        <Typography sx={{ maxWidth: '1000px', margin: 'auto' }} p={'40px'}>
                            &#127793; 2023 𝐓𝐒𝐆𝐃𝐅&#128161;<br />
                            &#9658; Event Date: 4/28 (Fri), 4/29 (Sat)<br />
                            &#9658; Event Location: Taoyuan City Department of Youth Affairs (No. 390, Huanbei Rd., Zhongli Dist., Taoyuan City)<br />
                            &#9658; &#10024; Free admission to the event &#10024;<br />
                            &#9658; The event program can also be viewed online through live streaming and game exhibitions.<br />
                            Raise your sword and come create your adventure and encounters!
                        </Typography>
                        }
                </Box>
            </Container>
        </Box>

        <Box style={{ backgroundColor: '#f5f5f5', minHeight: 530 }}>
            <Container style={{ paddingTop: '20px', paddingBottom: '6px' }}>
                <SubTitle icon='circle' text={t('home.subTitle.cardButton.title')} />
                {/*
                <SubTitle icon='circle' text={t('home.subTitle.cardButton.title')}
                    subText={t('home.subTitle.cardButton.subText')} />
                 */ }
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 189px 1px) 189px" }}
               
                    sx={{
                        alignItems: 'center',
                        padding: "60px",
                        background: "#fff",
                        borderRadius: "15px",
                        gridColumnGap: '22px',
                        gridRowGap: '22px',
                        justifyContent:"center"
                    }}
                >
                    <CardButton
                        icon={GameImage}
                        titleText={t('home.cardButton.game.title')}
                        contentText={t('home.cardButton.game.content')}
                        buttonText={t('home.cardButton.game.button')}
                        link={'./Game'}
                      //  disabled
                    />
                    <Divider />
                    <CardButton
                        icon={SeminarImage}
                        titleText={t('home.cardButton.seminar.title')}
                        contentText={t('home.cardButton.seminar.content')}
                        buttonText={t('home.cardButton.seminar.button')}
                        link={'./Event'}
                    />
                    <Divider />
                    <CardButton
                        icon={BoothListImage}
                        titleText={t('home.cardButton.booth.title')}
                        contentText={t('home.cardButton.booth.content')}
                        buttonText={t('home.cardButton.booth.button')}
                        link={'./BoothList'}
                    />
                   

                    {/*
                       <Divider/>
                        <CardButton
                            icon={PrizeImage}
                            titleText={t('home.cardButton.prize.title')}
                            contentText={t('home.cardButton.prize.content')}
                            buttonText={t('home.cardButton.prize.button')}
                            link={'./'}
                        />
                    */}
                </Box>
            </Container>
            <Toolbar />
        </Box>
        {/* 特別感謝 */ }
        <ThanksSection />
        {/*
        <Box>
            <Container style={{ minHeight: 500 }}>
                <SubTitle icon='square' text={t('home.subTitle.news.title')} link={'./ArticleList'} linkText={t('home.subTitle.news.link')} />
                <BoothArticleList boothId={null} showPin isManage={false} />
            </Container>
            <Toolbar />
        </Box>
        */ }
        { /* <Box>
            <Container style={{ paddingTop: '20px', paddingBottom: '6px', minHeight: 530 }}>
                <SubTitle icon='square' text={t('home.subTitle.article.title')} link={'./Stage?id=Seminar-LpG86yvp'} linkText={t('home.subTitle.article.link')} />
                <AgendaList seminars={seminars?.data ?? []} showTitle={false} />
            </Container>
            <Toolbar />
        </Box>*/}
    </>)
}

function CardButton(props: CardButtonProps) {
    const { icon, matches, titleText, contentText, buttonText, link, disabled } = props;
    const history = useHistory();
    function handleClickButton() {
        logEvent(`Home`, `ClickCardLink`, `${link}`);
        history.push(link);
    }

    return (
        <Card sx={{ boxShadow: 'none' } }>
            <CardContent sx={{ display: "flex", pt: 3, pb: 0 }}>
                <img src={icon} style={{ margin: 'auto', height:'140px' }} />
            </CardContent>
            <CardContent sx={{ height: "calc(100% - 250.5px)"}}>
                <Typography variant='h5' color="#707070" gutterBottom textAlign='center' fontWeight='revert' >
                    {titleText}
                </Typography>
                {/*<Typography variant="body2" color="text.secondary">
                    {contentText}
                </Typography>*/}
            </CardContent>
            <CardActions sx={{ justifyContent:'center' } } >
                <Button
                    variant='outlined'
                    onClick={handleClickButton}
                    sx={{
                        "border": "1px solid #0060CE",
                        "boxShadow": "0px 3px 2px rgba(0, 0, 0, 0.16)",
                        "borderRadius": "30px",
                        "padding": "10px 20px",
                        color: "#0060CE"
                    }}
                    disabled={disabled}
                >
                    <Typography variant='h5'  >
                    {buttonText}
                        <img src={imageFolder + 'card_button_arrow.svg'} style={{ "maxWidth": "100%", "verticalAlign": "top", "marginLeft": "5px" }} />
                    </Typography>
                </Button>
            </CardActions>
        </Card>
    )
}
interface CardButtonProps {
    icon: string;
    titleText: string;
    contentText: string;
    buttonText: string;
    link: string;
    matches?: boolean;
    disabled?: boolean;
}


function ThanksSection() {
    const history = useHistory();
    const { t } = useTranslation();
    //特別感謝
    const advisedByList: byListType[] = [
        /*   {
               nameKey: "數位發展部數位產業署",
               img: "",
               href: ""
           },*/
        {
            nameKey: "桃園市政府青年事務局",
            img: "images/home/贊助商_logo_04 青年事務局.png",
            href: "https://youth.tycg.gov.tw/"
        },
        {
            nameKey: "教育部",
            img: "images/home/贊助商_logo_03 教育部.png",
            href: "https://www.edu.tw/Default.aspx"
        },
        {
            nameKey: "經濟部中小企業處",
            img: "images/home/贊助商_logo_02 經濟部中小企業處.png",
            href: "https://www.moeasmea.gov.tw/masterpage-tw"
        }];
    const hostedByList: byListType[] = [
        {
            nameKey: "home.hostedBy.1",
            img: "images/home/2023 TSGDF_執行委員會logo.png",
            href: "https://2023tsgdf.tempestdigi.com/"
        }, {
            nameKey: "台灣遊戲產業合作發展協會",
            img: "images/home/台灣遊戲產業合作發展協會.png",
            href: "http://www.tgcda.org/"
        }];


    const cohostedByList: byListType[] = [
        {
            nameKey: "Digi⁺數位經濟產業推動辦公室",
            img: "images/home/數位經濟產業推動辦公室_logo.jpg",
            href: "https://deipo.org.tw/"
        },
        {
            nameKey: "財團法人資訊工業策進會",
            img: "images/home/資策會-iii_Logo_白底框.png",
            href: "https://www.iii.org.tw/"
        },
        {
            nameKey: "社團法人台灣遊戲產業振興會",
            img: "images/home/TGIPA logo-6@10x.png",
            href: "https://www.tgipa.org.tw/"
        },
        {
            nameKey: "國立中央大學",
            img: "images/home/贊助商_logo_08 中央大學.png",
            href: "https://www.ncu.edu.tw/tw/"
        },
        {
            nameKey: "國立中央大學產學營運中心",
            img: "images/home/贊助商_logo_10 國立中央大學產學營運中心.png",
            href: "http://www.caic.ncu.edu.tw/"
        },
        {
            nameKey: "新明青創基地",
            img: "images/home/新明清創基地LOGO-1.png",
            href: "https://tyc.tycg.gov.tw/"
        },
        {
            nameKey: "桃園青創指揮部",
            img: "images/home/青創指揮部LOGO-1.png",
            href: "https://www.facebook.com/taoyuanyouthcan/?"
        },
        {
            nameKey: "NCU USR 桃海三生",
            img: "images/home/贊助商_logo_09 桃海三生.png",
            href: "https://taocoast.in.ncu.edu.tw/about"
        },
        {
            nameKey: "創夢客",
            img: "images/home/創夢客logo.png",
            href: "https://dreamaker.tw"
        }];

    //跳轉
    const handleClickGoTo = (link: string, event) => {
        event.preventDefault();
        if (link.startsWith("http"))
            window.open(link, "_blank");
        else
            history.push(link);
    };

    type byListType = { nameKey: string; img: string; href?: string; };
    const LinkCard = styled(({ nameKey, img, href, ...paperProps }: PaperProps & byListType) =>
        <Paper sx={{ flex: { xs: "1 1 100%", md: "0 1 25%" }, "alignItems": "center", "display": "flex", minHeight: "123px" }} {...paperProps} >
            <ButtonBase href={href} target="_blank" sx={{ position: "relative", display: "block", width: "100%" }}>
                <Stack flexDirection={{ xs: "row", sm: "column" }} alignItems="center" p={1.5}>
                    {img != "" && <Box width={{ xs: "100px", sm: "unset" }} mx={1} my={0.5} >
                        <img src={img} width="100%" height="100%" style={{ objectFit: "contain", maxHeight: "60px" }} />
                    </Box>}
                    <Typography textAlign={{ xs: "left", sm: "center" }} fontFamily="'Noto Serif TC'" mx={1} my={0.5}>
                        <Trans i18nKey={nameKey} />
                    </Typography>
                </Stack>
            </ButtonBase>
        </Paper>
    )(({ theme }) => ({ "& a:hover": { color: theme.palette.text.secondary, textDecoration: "none" } }));


    return (
        <Box bgcolor="grey.100" pb={4} mb={-2}>
            <Container>
                <DecorationText text={t("home.title.specialThanks")} decorationColor="#42b2f3" />
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# ${t("home.advisedBy.title")}`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
                    {advisedByList.map((element) => <LinkCard key={element.nameKey} {...element} />)}
                </Box>
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# ${t("home.hostedBy.title")}`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
                    {hostedByList.map((element) => <LinkCard key={element.nameKey} {...element} />)}
                </Box>
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# 執行單位`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} columnGap={2} rowGap={1} py={2}>
                    <Box sx={{ gridColumnStart: { sm: "span 3" } }}>
                        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} mb={1}>
                            <LinkCard nameKey="暴風數位股份有限公司" img={"images/home/贊助商_logo_05 暴風數位股份有限公司.png"} href="https://tempestdigi.com/" />
                        </Box>
                    </Box>
                </Box>
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# ${t("home.cohostedBy.title")}`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
                    {cohostedByList.map((element) => <LinkCard key={element.nameKey} {...element} />)}
                </Box>
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# ${t("home.sponsoredBy.title")}`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} columnGap={2} rowGap={1} py={2}>
                    <Box sx={{ gridColumnStart: { sm: "span 3" } }}>
                        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} mb={1}>
                            <LinkCard nameKey="宇峻奧汀" img={"images/home/logo_宇峻_彩色_白底用.png"} href="https://www.userjoy.com.tw/" />
                            <LinkCard nameKey="唯晶科技" img={"images/home/winking corp logo_01_R.png"} href="https://www.winkingworks.com/zh-TW/" />
                            <LinkCard nameKey="微軟Surface" img={"images/home/贊助商_logo_16 微軟.png"} href="https://www.microsoft.com/zh-tw/surface" />
                            <LinkCard nameKey="AMD" img={"images/home/AMD_LOGO.png"} href="https://www.amd.com/zh-tw.html" />
                        </Box>
                    </Box>
                </Box>
            
                <Typography variant="h5" textAlign="center" color="primary.main" m="auto">
                    {`# 合作社團`}
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} columnGap={2} rowGap={1} py={2}>
                    <Box sx={{ gridColumnStart: { sm: "span 3" } }}>
                        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} mb={1}>
                            <LinkCard nameKey="元智遊創" img={"images/home/YZUGC元智遊創.png"} href="" />
                            <LinkCard nameKey="淡江遊創" img={"images/home/淡江遊創.png"} href="" />
                            <LinkCard nameKey="清大遊創" img={"images/home/清大遊創.jpg"} href="" />
                            <LinkCard nameKey="中央創遊" img={"images/home/gc_black.png"} href="" />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

function DecorationText(props: { decorationColor: string, text: string }) {
    const { decorationColor, text } = props;
    return (
        <Box display="flex" alignItems="center" color={decorationColor} py={3}>
            <Box flex="1 1 80px" height="1px" bgcolor={decorationColor } />
            <Box display="inline-block" width="12px" height="12px" bgcolor={decorationColor} borderRadius="50%" mr={2} />
            <Typography variant="h4" textAlign="center" fontFamily="'Noto Serif TC'">{text}</Typography>
            <Box display="inline-block" width="12px" height="12px" bgcolor={decorationColor} borderRadius="50%" ml={2} />
            <Box flex="1 1 80px" height="1px" bgcolor={decorationColor } />
        </Box>
        )
}