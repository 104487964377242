import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, IconButton, Snackbar, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useUser } from '../store';
import { useLazyCheckServerStateQuery } from './services/system';

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}
export default function ServerChecker(props: ServerCheckerProps) {
    const { interval = (1000 * 60 * 5) } = props;

    const user = useUser();
    const theme = useTheme();
    const [ckeckIsLogin, { }] = useLazyCheckServerStateQuery();

    async function handleCheckIsAlive() {
        try {
            await ckeckIsLogin().unwrap().then((result) => {
                //setReconnectTime(0);
            });

            return true;
        } catch (error) {
            return false;
        }
    }

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        if (!user) {
            console.log(`not login`);
            return;
        }

        const timerID = setInterval(async () => {
            var result = await handleCheckIsAlive();

            if (result === false) {
                //斷線重連狀況
                console.log(`reconnect`);
                let isAlive = false;
                await delay(5000);

                //第一次重連
                console.log(`reconnectTime: 1`);
                var checkAlive = await handleCheckIsAlive();
                console.log(`checkAlive: ${checkAlive}`);
                if (checkAlive === true) {
                    console.log(`isAlive`);
                    return;
                }
                await delay(5000);

                //第二次重連
                console.log(`reconnectTime: 2`);
                var checkAlive = await handleCheckIsAlive();
                console.log(`checkAlive: ${checkAlive}`);
                if (checkAlive === true) {
                    console.log(`isAlive`);
                    return;
                }
                if (isAlive === false) {
                    console.log(`isDead`);
                    setOpenDialog(true);
                    clearInterval(timerID);
                }
            }
        }, interval);

        return () => {
            console.log(`clearInterval`);
            clearInterval(timerID);
        }
    }, [user])

    return (
        <Snackbar open={openDialog} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ top: '72px' }}>
            <Alert
                variant="filled"
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpenDialog(false);
                        }}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                }
                sx={{
                    color: theme.palette.text.primary,
                    backgroundColor: '#ffb519'
                }}
            >
                <AlertTitle >與伺服器連線已中斷</AlertTitle>
                <Typography variant='body2' gutterBottom>
                    請重新整理或確認網路狀態
                </Typography>
                <Button color="inherit" variant='outlined' onClick={() => window.location.reload()}>重新整理</Button>
            </Alert>
        </Snackbar>
    );
}
interface ServerCheckerProps {
    interval?: number; //間隔的毫秒數
}