import * as React from 'react';
import { Box, Dialog, dialogClasses, DialogContent, IconButton, useTheme } from "@mui/material";
import { CardInfo } from '../class';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { BussinessCard } from './BussinessCardInfo';

interface OnlineCardInfo extends CardInfo { isOnline: boolean };
interface UserCardDialogProps {
	data: OnlineCardInfo;
	open: boolean;
	onClose: () => void;
	handleChangePrev?: () => void;
	handleChangeNext?: () => void;
	onClickExchange?: (data: OnlineCardInfo) => void;
	onClickSendMessage?: (data: OnlineCardInfo) => void;
	onClickReport?: (data: OnlineCardInfo) => void;
}
export function UserCardDialog(props: UserCardDialogProps) {
	const { data, open, onClose, handleChangePrev, handleChangeNext, onClickExchange, onClickSendMessage, onClickReport } = props;

	function handleClose() {
		onClose();
	}
	function handleClickExchange(data: OnlineCardInfo) {
		if (onClickExchange) onClickExchange(data);
		onClose();
	}
	function handleClickSendMessage(data: OnlineCardInfo) {
		if (onClickSendMessage) onClickSendMessage(data);
		onClose();
	}
	function handleClickReport(data: OnlineCardInfo) {
		if (onClickReport) onClickReport(data);
		onClose();
	}

	return (
		<Dialog open={open} onClose={handleClose}
			fullWidth maxWidth="xs"
			PaperProps={{
				sx: {
					overflow: "initial",
					[`&.${dialogClasses.paper}`]: {
						background: "transparent"
					}
				}
			}}
			scroll="paper"
		>
			{handleChangePrev &&
				<Box position="absolute" top="50%" left="-3rem">
					<IconButton color="secondary" onClick={handleChangePrev}>
						<KeyboardArrowLeft />
					</IconButton>
				</Box>
			}
			{handleChangeNext &&
				<Box position="absolute" top="50%" right="-3rem">
					<IconButton color="secondary" onClick={handleChangeNext} >
						<KeyboardArrowRight />
					</IconButton>
				</Box>
			}
			<IconButton onClick={handleClose}
				style={{
					position: 'absolute', top: '0px', right: '-54px',
					backgroundColor: 'transparent', color: 'white'
				}}
			>
				<Close fontSize='large' />
			</IconButton>

			<DialogContent style={{ padding: "0" }}>
				{data &&
					<BussinessCard
						data={data}
						isOnline={data?.isOnline}
						withBorder
						onClickExchange={handleClickExchange}
						onClickSendMessage={handleClickSendMessage}
						onClickReport={handleClickReport}
					/>
				}
			</DialogContent>
		</Dialog>
	)
}