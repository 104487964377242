import { Paper, PaperTypeMap } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useState } from 'react';
import ReactPlayer from 'react-player'
import { Button } from './responsive-components';

export default function VideoPlayer(props: VideoPlayerProps) {
    const { url, width, height, enableControls, handleVideoReady, handlePlayProgress, enableTestLinkBar, style } = props;

    const youtubeTest = 'https://www.youtube.com/watch?v=z93qRyEY_wQ';
    const twitchTest = 'https://www.twitch.tv/muse_tw';
    const [testUrl, setTestUrl] = useState<string>('');
    function handleChangeTestUrl(link: string) {
        setTestUrl(link);
    }

    return (
        <>
            {enableTestLinkBar &&
                <div>
                    <Button onClick={() => handleChangeTestUrl(youtubeTest)}>YoutubeTest</Button>
                    <Button onClick={() => handleChangeTestUrl(twitchTest)}>TwitchTest</Button>
                </div>
            }
            <ReactPlayer
                url={enableTestLinkBar ? testUrl : url}
                width={width}
                height={height}
                controls={enableControls}
                onReady={() => handleVideoReady ? handleVideoReady() : {}}
                onProgress={(state) => handlePlayProgress ? handlePlayProgress(state.playedSeconds) : {}}
                style={style}
            />
        </>
    );
}
interface VideoPlayerProps {
    url: string;
    width?: string | number;
    height?: string | number;
    enableControls?: boolean;
    handleVideoReady?: () => void;
    handlePlayProgress?: (number) => void;
    enableTestLinkBar?: boolean;
    style?: React.CSSProperties;
}