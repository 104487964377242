import * as React from 'react';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { zhTWGrid } from '../../data-grid-locale';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useGetServiceBellRecordQuery } from '../../../components/services/user';
import { RoomService } from '@mui/icons-material';
import { optionType } from '../../../components/Modal/ServiceBellModal';
import { ConfirmButton } from '../../../components/responsive-components';
import { useHistory } from 'react-router';
import { renderCellExpand } from '../../../components/DataGrid/renderCellExpand';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function CallBell() {
    const { t, i18n } = useTranslation();

    const { callList = [], isLoading } = useGetServiceBellRecordQuery(null, {
        selectFromResult: (result) => ({ callList: result.data?.data, ...result })
    });

    return (<>
        <SubTitle
            icon={<RoomService sx={{ color: theme => theme.palette.primary.main }} />}
            text={t('manage.title.profile.bell')}
        />
        {callList &&
            <CallBellTable dataList={callList.map((callBell) => {
                let senderData = callBell.sender;
                return ({
                    id: callBell.dataId,
                    time: callBell.createTime,
                    senderName: senderData.name,
                    companyName: i18n.language == "ch" ? callBell.booth.chName : callBell.booth.enName,
                    service: callBell.option == 0 ? optionType[optionType.想與企業人員文字聯絡] :
                        callBell.option == 1 ? optionType[optionType.告知企業人員我在視訊聊天室] :
                            optionType[optionType.其他],
                    message: callBell.message,
                    link: `Booth?id=${callBell.booth.boothId}`
                })
            })} loading={isLoading} />
        }
    </>)
};

interface GridDataRow {
    id: string;
    time: string;
    senderName: string;
    companyName: string;
    service: string;
    message: string;
    link: string;
}

function CallBellTable(props: { dataList: GridDataRow[], loading: boolean }) {
    const { dataList, loading } = props;
    const { t, i18n } = useTranslation();

    const history = useHistory();

    const columns: GridColDef[] = [
        {
            field: 'time',
            headerName: t('manage.callBellTable.time'),
            type: 'dateTime',
            flex: 0.5,
            minWidth: 140,
            renderCell: (params) => <>{new Date(params.value.toString()).toLocaleString(
                i18n.language == "ch" ? "zh-TW" : 'en-US',
                { month: '2-digit', day: '2-digit', weekday: 'narrow', hour12: false, hour: '2-digit', minute: '2-digit' }
            )}</>
        },
        {
            field: 'senderName',
            headerName: t('manage.callBellTable.senderName'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'companyName',
            headerName: t('manage.callBellTable.companyName'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'service',
            headerName: t('manage.callBellTable.service'),
            flex: 0.8,
            minWidth: 140,
            renderCell: renderCellExpand,
        },
        {
            field: 'message',
            headerName: t('manage.callBellTable.message'),
            flex: 1,
            minWidth: 200,
            renderCell: renderCellExpand,
        },
        {
            field: 'link',
            headerName: t('manage.callBellTable.link'),
            flex: 1,
            minWidth: 100,
            renderCell: (params) =>
                <ConfirmButton onClick={() => history.push(params.value.toString())}>
                    {t('manage.callBellTable.button')}
                </ConfirmButton>
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid rows={dataList} columns={columns}
                loading={loading}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
            />
        </ThemeProvider>
    )
}