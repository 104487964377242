import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Close, Videocam } from "@mui/icons-material";
import { alpha, Backdrop, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, Tab, Toolbar, ToolbarProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BoothType } from "../../../components/class";
import { useHistory, useLocation } from "react-router-dom";
import {  EditorState,convertFromRaw } from 'draft-js';

//import DraftEditor from "../components/Editor/Draft";
//import { BoothAgendaList } from "./Seminar/BoothAgendaList";

import { scroller } from 'react-scroll'
import { BoothCard2022 as BoothCard } from "./BoothCard2022";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReactPlayer from "react-player";
import { Booth, theme2022 } from "./BoothList2022";
import { Editor, EditorProps } from "react-draft-wysiwyg";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function BoothIndex(props: { booth: Booth }) {
    const { booth } = props;
    const { t } = useTranslation();

    const theme = useTheme();

    let query = useQuery();
    const location = useLocation()
    const history = useHistory();

    const boothId = booth.boothId;


    React.useEffect(() => {
        window.scrollTo({top:0})
    },[])



    if (booth) {
        return (<>
   
            <Toolbar sx={{
                backgroundImage: `radial-gradient(ellipse at 10% 100%, ${'#00003E'} 0%, ${theme2022.palette.background.default}00 80%),
                    radial-gradient(circle at 10% 0, ${theme2022.palette.primary.main}80 0%, ${theme2022.palette.primary.main}00 100%),
                    radial-gradient(circle at 93.3% 5%, ${theme2022.palette.secondary.main}DD 0%, ${theme2022.palette.secondary.main}00 50%)`,
                justifyContent: "center"
            }}>
                <Typography variant='h5' fontWeight='bold' color='primary.contrastText'>
                    {`■ ${t("title.booth")} ■`}
                </Typography>
            </Toolbar>

    

            {/*簡介*/}
            <IntroSection data={booth} />


            {/*詳細資料*/}
            <DraftSection data={booth} />

        </>)
    }  else {
        return (
            <Backdrop open sx={{ color: "#fff" }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}
const CloseMediaButton = styled(Button)(({ theme }) => ({
    transform: "skewX(-20deg)",
    backgroundColor: '#FAFD4C',
    borderRadius: 0,
    height: "32px",
    maxWidth: "150px",
    padding: "6px 18px",
    "& > p": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `10px 7px ${theme2022.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(8.2px, 6.4px)",
        backgroundColor: '#FAFD4C',
        boxShadow: `6.8px 5.6px ${theme2022.palette.primary.main}`,
    },
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3)
}));

const StyledToolbar: (props: ToolbarProps) => JSX.Element = styled(Toolbar)(({ theme }) => ({
    boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px -2px',
}));

export function IntroSection(props: IntroSectionProps) {
    const { data } = props;
    const { t } = useTranslation();
    const handleClickFavorite = () => {
    };

    const history = useHistory();
  
    let videoLink = data?.fieldList?.find(x => x.fieldId === 'video')?.value ?? "";
    const [openVideo, setOpenVideo] = React.useState<boolean>(false);
    const handleOpen = () => setOpenVideo(true), handleClose = () => setOpenVideo(false);

    return (
        <Box bgcolor={theme => '#00003E'} py={2}>
            <Container>
                <BoothCard
                    data={data}
                    darkMode
                    onClickFollow={handleClickFavorite}
                />
                <Box display="inline-flex" gap={2}>
                    {videoLink != "" &&
                        <GoToButton onClick={handleOpen}>
                            <Typography>
                                {t("booth.button.video")}
                            </Typography>
                        </GoToButton>
                    }
                </Box>
                <Dialog open={openVideo} onClose={handleClose} fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" fontWeight="bold">
                                {t("booth.button.video")}
                            </Typography>
                            <IconButton onClick={handleClose} sx={{ mt: -1, mr: -1 }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <ReactPlayer
                            url={videoLink}
                            width="100%"
                            controls
                        />
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            </Container>
        </Box>
    )
}
interface IntroSectionProps {
    data: Booth
}
const GoToButton = styled(Button)(({ theme }) => ({
    transform: "skewX(-20deg)",
    backgroundColor: '#FAFD4C',
    borderRadius: 0,
    height: "32px",
    maxWidth: "150px",
    padding: "6px 18px",
    "& > p": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `10px 7px ${theme2022.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(8.2px, 6.4px)",
        backgroundColor: '#FAFD4C',
        boxShadow: `6.8px 5.6px ${theme2022.palette.primary.main}`,
    },
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3)
}));

function DraftSection(props: DraftSectionProps) {
    const { data } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    let contents = [
        { fieldId: "gameIntro", nameKey: "booth.title.gameIntro", type: BoothType.一般攤位 },
        { fieldId: "teamIntro", nameKey: "booth.title.teamIntro", type: BoothType.一般攤位 },
        { fieldId: "companyIntro", nameKey: "booth.title.companyIntro", type: BoothType.廣告贊助 },
        { fieldId: "eventIntro", nameKey: "booth.title.eventIntro", type: BoothType.服務台 }
    ];
    contents = contents.filter(x => x.type === data?.type);

    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState(contents[0]?.fieldId);
    function handleChange(event: React.SyntheticEvent, newValue: string) {
        setValue(newValue);
    };

    if (contents?.length > 0)
        return (<Box bgcolor="rgb(51, 27, 114)">

  
            <Container>
                <Box bgcolor="rgb(51, 27, 114)" color="#fff" py={6}>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{
                            style: {
                                display: "none",
                            }
                        }} style={{ transform: `translateY(-${theme.spacing(1.5)})` }}>
                            {contents.map((item, index) => (
                                <TabButton key={index.toString()}
                                    icon={<Typography variant="h6">{t(item.nameKey)}</Typography>}
                                    value={item.fieldId}
                                    disableRipple
                                />
                            ))}
                        </TabList>
                        {contents.map((item, index) => (
                            <TabPanel key={index.toString()} value={item.fieldId} sx={{ pt: 0, px: 2 }}>
                                <DraftEditor draftText={data.fieldList?.find(x => x.fieldId === item.fieldId)?.value} />
                            </TabPanel >
                        ))}
                    </TabContext>
                </Box>
            </Container>
        </Box>
        )
    else return null;
}
interface DraftSectionProps {
    data: Booth
}
const TabButton = styled(Tab)(({ theme }) => ({
    transform: "skewX(-20deg)",
    backgroundColor: '#FAFD4C',
    borderRadius: 0,
    minHeight: "46px",
    height: "46px",
    minWidth: "150px",
    "& > p, & > h6, & > h5": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `12px 9px ${theme2022.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(10.2px, 8.4px)",
        backgroundColor: '#FAFD4C',
        boxShadow: `6.8px 5.6px ${theme2022.palette.primary.main}`,
    },
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

function DraftEditor(props: { draftText: string; }) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    return <>{props.draftText&&<Editor
        readOnly={true}
        toolbarHidden={true}
        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(props.draftText))) }
      //  onEditorStateChange={onEditorStateChange}
        placeholder={t('draft.placeholder')}
      /*  localization={
            i18n.language == 'ch' ?
                { locale: 'zh', translations: editorLabels } :
                { locale: 'en' }
        }*/
        toolbar={{
            options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'embedded',
                'emoji',
                'image',
                'remove',
                'history'
            ],
            image: {
                //alignmentEnabled: false,
                uploadEnabled: true,
                alignmentEnabled: true,
                previewImage: true,
                inputAccept: 'image/*',
                alt: { present: false, mandatory: false },
                defaultSize: {
                    height: 'auto',
                    width: 'auto',
                },
            },
            colorPicker: {
                colors: [theme.palette.text.primary, theme2022.palette.text.white, theme2022.palette.primary.main, theme2022.palette.secondary.main,
                    , '#FAFD4C', '#27145B'
                ]
            }
        }}
       // customBlockRenderFunc={(props) => { return BlockRenderer(props, !isEdit); }}
        //editorStyle={{ border: isEdit ? '1px solid #F1F1F1' : '0px', padding: '0px 12px 6px' }}
      //  {...editorProps}
        />
        }
    </>
}