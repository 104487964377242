import { Card, CardActions, CardContent, styled } from "@mui/material";
import { CardActionsProps, CardContentProps, CardProps } from "@mui/material";

const colorArray = [
    '#C8EAD1', //前往連結
    '#E9E9E9', //無連結
    '#E9E9E9' //已結束
]
export interface InfoCardProps {
    ismobile: "true" | "false";
}
export const InfoCard: (props: CardProps & InfoCardProps) => JSX.Element = styled(Card)<InfoCardProps>(({ ismobile, ...other }) => ({
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#F6F6F6',
    width: "100%",
    maxWidth: 500,
    height: ismobile === "true" ? 300 : 350
}));
export const InfoCardContent: (props: CardContentProps) => JSX.Element = styled(CardContent)({
    padding: "1.5rem 2rem 0 2rem",
    flex: "1 1 100px",
});

export interface InfoCardActionsProps {
    state: InfoCardState;
}
export enum InfoCardState {
    haveLink = 0,
    noLink,
    overdue
}
export const InfoCardActions: (props: CardActionsProps & InfoCardActionsProps) => JSX.Element = styled(CardActions)<InfoCardActionsProps>(({ state }) => ({
    width: '100%',
    padding: 0,
    flex: "0 0 50px",
   // backgroundColor: colorArray[state]
}));
