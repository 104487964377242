import { Box, Checkbox, Chip, MenuItem, TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomField } from '.';
import { toRecord } from './elementFactory';

export interface MenuItemType {
	nameKey: string,
	value: string
}
interface MultipleSelectProps {
	selectList: MenuItemType[];
	textFieldProps?: TextFieldProps;
}
export function MultipleSelect(props: MultipleSelectProps) {
	const { selectList, textFieldProps: {value, onChange, ...textFieldProps} } = props;
	const { t } = useTranslation();

	const dictionary = toRecord(selectList, "value");
	const [selectItems, setSelectItems] = React.useState<string[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectItems(typeof event.target.value === 'string' ? event.target.value.split(',').filter(x => x !== '') : event.target.value);
		onChange({
			...event, target: {
				...event.target,
				value: typeof event.target.value === 'object' ? (event.target.value as string[]).filter(x => x !== '').join(',') : event.target.value
			}
		});
	};

	React.useEffect(() => {
		if (value && selectList) {
			setSelectItems(String(value).split(',').filter(x => x !== ''));
		}
	}, [selectList]);

    return (
		<CustomField
			select
			SelectProps={{
				multiple: true,
				renderValue: (selected) => {
					let tempList: string[] = [];
					for (let select of selected as string[]) {
						if (dictionary[select]) tempList.push(t(dictionary[select].nameKey));
					}
					return tempList.join(', ');
				},
				MenuProps: { sx: { maxHeight: '300px', maxWidth: '100%' } }
			}}
			value={selectItems}
			onChange={handleChange}
			{...textFieldProps}
		>
			{selectList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					<Checkbox checked={selectItems.indexOf(item.value) > -1} />
					<Trans i18nKey={item.nameKey}>
						{item.value}
					</Trans>
				</MenuItem>
			))}
		</CustomField>
    );
}

interface MultipleSelectChipProps {
	selectList: MenuItemType[];
	textFieldProps?: TextFieldProps;
}
export function MultipleSelectChip(props: MultipleSelectChipProps) {
	const { selectList, textFieldProps: { value, onChange, ...textFieldProps } } = props;
	const { t } = useTranslation();

	const dictionary = toRecord(selectList, "value");
	const [selectItems, setSelectItems] = React.useState<string[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectItems(typeof event.target.value === 'string' ? event.target.value.split(',').filter(x => x !== '') : event.target.value);
		onChange({
			...event, target: {
				...event.target,
				value: typeof event.target.value === 'object' ? (event.target.value as string[]).filter(x => x !== '').join(',') : event.target.value
			}
		});
	};

	React.useEffect(() => {
		if (value && selectList) {
			setSelectItems(String(value).split(',').filter(x => x !== ''));
		}
	}, [selectList]);

	return (
		<CustomField
			select
			SelectProps={{
				multiple: true,
				renderValue: (selected) => (
					<Box display="flex" flexWrap="wrap" gap={0.5}>
						{(selected as string[]).map((value) => {
							if (dictionary[value]) return <Chip key={value} label={t(dictionary[value].nameKey)} />
							else return null
                        })}
					</Box>
				),
				MenuProps: { sx: { maxHeight: '300px', maxWidth: '100%' } }
			}}
			value={selectItems}
			onChange={handleChange}
			{...textFieldProps}
		>
			{selectList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					<Checkbox checked={selectItems.indexOf(item.value) > -1} />
					<Trans i18nKey={item.nameKey}>
						{item.value}
					</Trans>
				</MenuItem>
			))}
		</CustomField>
	);
}