import { Box, Button, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQRCode from "react-qr-code";
import { Agenda } from './class';
import { useLazyGetAgendaQRCodeQuery, useLazyGetBoothQRCodeQuery, useLazyGetUserQRCodeQuery, useLazyGetVisitQRCodeQuery } from './services/qrcode';

export default function QRCode(props: QRCodeProps) {
    const { userId, boothId, agenda, visitId } = props;
    const { t } = useTranslation();
    React.useEffect(() => {
        if (userId || boothId || agenda || visitId) {
            if (userId) getUserQRCode(userId);
            if (boothId) getBoothQRCode(boothId);
            if (agenda) getAgendaQRCode({ agendaId: agenda.agendaId, seminarId: agenda.seminarId });
            if (visitId) getVisitQRCode(visitId);
        }
    }, [userId, boothId, agenda, visitId])

    const [getUserQRCode, { data: UserQRCodeData, isLoading: isUserQRLoading }] = useLazyGetUserQRCodeQuery();
    const [getBoothQRCode, { data: BoothQRCodeData, isLoading: isBoothQRLoading }] = useLazyGetBoothQRCodeQuery();
    const [getAgendaQRCode, { data: AgendaQRCodeData, isLoading: isAgendaQRCodeLoading }] = useLazyGetAgendaQRCodeQuery();
    const [getVisitQRCode, { data: VisitQRCodeData, isLoading: isVisitQRCodeLoading }] = useLazyGetVisitQRCodeQuery();

    const onImageCownload = (name: string) => {
        const svgs = Array.from(document.getElementsByClassName(name));
        svgs.forEach(
            function (svg, currentIndex, listObj) {
                const svgData = new XMLSerializer().serializeToString(svg);
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    ctx.font = "40px Calibri";
                    ctx.fillStyle = "red";
                  //  ctx.fillText("My TEXT!", 50, 300);
                    const pngFile = canvas.toDataURL("image/png");
                    const downloadLink = document.createElement("a");
                    downloadLink.download = svg.getAttribute("name");
                    downloadLink.href = `${pngFile}`;
                    downloadLink.click();
                };
                img.src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`;
            }
        );
    };

    return (
        <Box>
            <Stack direction='column' spacing={1} sx={{ maxWidth: '256px', width: "100%" }}>
                {((UserQRCodeData || BoothQRCodeData || AgendaQRCodeData || VisitQRCodeData) && !(isUserQRLoading || isBoothQRLoading || isAgendaQRCodeLoading || isVisitQRCodeLoading)) ? <>
                    <ReactQRCode
                        name={UserQRCodeData?.data?.name ?? BoothQRCodeData?.data?.name ?? AgendaQRCodeData?.data?.name ?? VisitQRCodeData?.data?.name}
                        className="downloadQRCode"
                        size={1024}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={UserQRCodeData?.data?.url ?? BoothQRCodeData?.data?.url ?? AgendaQRCodeData?.data?.url ?? VisitQRCodeData?.data?.url}
                    />
                    <Button variant='contained' onClick={() => onImageCownload("downloadQRCode")}>{t("common.button.download")}</Button>
                </> :
                    <Box sx={{ maxWidth: '256px', width: "100%", aspectRatio: '1' }}>
                        <Skeleton sx={{ width: "100%", height: '100%' }} animation="wave" variant="rectangular" />
                    </Box>
                }
            </Stack>
        </Box>
    );
}
interface QRCodeProps {
    userId?: string;
    agenda?: Agenda;
    boothId?: string;
    visitId?: string;
}