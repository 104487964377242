import * as React from 'react';
import { Box, Button, Dialog, Divider, IconButton, Paper, Stack, styled, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoothArticle } from '../class';
import { DialogContent } from '../responsive-components';
import { Close } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function BoothArticlePage(props: BoothArticlePageProps) {
    const { data, matches } = props;

    return (
        <Paper style={{ padding: matches ? '20px 40px' : '16px 10px' }}>
            {data &&
                <BoothArticleContent
                data={data}
                matches={matches}
                />
            }
        </Paper>
    )
}
interface BoothArticlePageProps {
    data: BoothArticle;
    matches: boolean;
}

export function BoothArticleDialog(props: BoothArticleDialogProps) {
    const { data, matches, open, onClose } = props;
    const { t} = useTranslation();

    function handleClose() {
        onClose();
    }

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
            open={open}
            PaperProps={{ sx: { overflow: 'initial' } }}
            scroll={'body'}
        >
             <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute', top: '0px', right: '0px',
                    backgroundColor: 'transparent', color: '#C2C2C2'
                }}
            >
                <Close fontSize='large' />
            </IconButton>
            <DialogContent style={{ minHeight: '400px', padding: matches ? '60px 120px 40px 120px' : '20px 24px' }}>
              {data &&
                    <BoothArticleContent
                        data={data}
                        matches={matches}
                    />
                }
                <Box textAlign='center'>
                    <Button sx={{ color: '#C2C2C2' }} onClick={handleClose}>
                        <HighlightOffIcon sx={{ mr: "5px", verticalAlign: 'middle' }} /><span>{t('common.button.close')}</span>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
interface BoothArticleDialogProps {
    data: BoothArticle;
    matches: boolean;
    open: boolean;
    onClose: () => void;
}

export function BoothArticleContent(props: BoothArticlePageProps) {
    const { data, matches } = props;

    const StyledTimeTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
        fontSize: matches ? '16px' : '11px',
        color: 'gray',
    }));
    const StyledTitleTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
        fontSize: matches ? '26px' : '20px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }));

    const { t, i18n } = useTranslation();

    return (<>
        <Stack spacing='10px'>
            <Box p='3px 5px' sx={{
                color: '#2573DE', border: '#2573DE 1px solid', borderRadius: '5px', width: "120px", textAlign: 'center', fontSize: '14px',
                lineHeight: '20px'
            }}>{data?.boothId ? "攤位資訊" : "活動資訊"}</Box>
            <StyledTitleTypography>
                {data.title}
            </StyledTitleTypography>
            <Stack direction='row' pb='5px' spacing={1 }>
                <StyledTimeTypography>
                    {new Date(data.createTime).toLocaleString(
                        i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </StyledTimeTypography>

                {(data.boothId != null && data.boothId != '') ?
                    <StyledTimeTypography>
                        {`【${i18n.language == 'ch' ? data.chName : data.enName}】`}
                    </StyledTimeTypography> : <StyledTimeTypography >
                        {`【${t("articleList.noBoothIdName")}】`}
                    </StyledTimeTypography>
                }
            </Stack>
            <Divider />
        </Stack>
        <div className="ck-content"
            dangerouslySetInnerHTML={{ __html: data.content }}
            style={{ paddingTop: '43px', paddingBottom: "20px" }}
        />
    </>)
}
interface BoothArticlePageProps {
    data: BoothArticle;
    matches: boolean;
}