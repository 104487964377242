import * as React from "react";
import { Avatar, Box, IconButton, Link, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BroadcastTextObject, NotAnswerTextObject, NoticeMessageContent, NoticeTextCode, QuestStatusTextObject, UpcomingEventTextObject } from "../../class";
import { Circle, Close } from "@mui/icons-material";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { useLazyGetPathQuery } from "../../services/imageFile";
import { NoticeToastContent } from "./ToastSystem";
import "./NotificationToast.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CheckIsMobile } from "../../Admin/Utils";
import { logEvent } from "../../Analytics";

interface NotificationToastProps {
    message: NoticeToastContent;
    closeToast: (id: number) => void;
    autoClose?: boolean;
    pauseOnHover?: boolean;
    onClick?: (id: number) => void;
    closeOnClick?: boolean;
    delay?: number;
}

export function NotificationToast(props: NotificationToastProps) {
    const { message, closeToast, onClick, ...other } = props;
    const { t, i18n } = useTranslation();

    const [getPath, { avatarUrl }] = useLazyGetPathQuery({
        selectFromResult: ({ data }) => ({ avatarUrl: data?.data }),
    });

    const [eventId, setEventId] = React.useState<string>();
    /*const { exchangeCard } = useGetExchangeCardQuery(exchangeCardId, {
        selectFromResult: ({ data }) => ({ exchangeCard: data?.data })
    });*/
    const [questId, setQuestId] = React.useState<string>();
    /*const { appointment } = useGetAppointmentQuery(appointmentId, {
        selectFromResult: ({ data }) => ({ appointment: data?.data })
    });*/
    React.useEffect(() => {
        if (message.textCode === NoticeTextCode.廣播通知) {
            getPath({
                userId: (message.textObject as BroadcastTextObject).senderId
            });
        }
        else if (message.textCode === NoticeTextCode.活動通知) {
            setEventId((message.textObject as UpcomingEventTextObject).agendaId);
        }
        else if (message.textCode === NoticeTextCode.活動更改通知) {
            setEventId((message.textObject as UpcomingEventTextObject).agendaId);
        }
        else if (message.textCode === NoticeTextCode.問題狀態改變通知) {
            setQuestId((message.textObject as QuestStatusTextObject).questId);
        }
    }, [message]);

    function handleClose() {
        closeToast(message.id);
    }
    function handleOnClick() {
        onClick(message.id);
    }
    function handleLinkOnClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) {
        event.stopPropagation();
        logEvent('Notification', `Toast`, `${link}`);
    }
    switch (message.textCode) {
        case NoticeTextCode.廣播通知:
            let broadcastTextObject = (message.textObject as BroadcastTextObject);
            return (
                <NotificationToastContainer avatarUrl={avatarUrl} closeToast={handleClose} onClick={handleOnClick} {...other}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.conferenceNotice')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">{broadcastTextObject.content}</Typography>
                    {broadcastTextObject.link &&
                        <Link variant="body2" color="primary.main" fontWeight="inherit" underline="none" href={broadcastTextObject.link} rel="noopener" target="_blank"
                        onClick={(event) => handleLinkOnClick(event, broadcastTextObject.link)}>
                        {t('notification.bell.link')}
                        </Link>
                    }
                </NotificationToastContainer>
            )
        case NoticeTextCode.活動通知:
            let upcomingEventTextObject = (message.textObject as UpcomingEventTextObject);
            return (
                <NotificationToastContainer avatarUrl={avatarUrl} closeToast={handleClose} onClick={handleOnClick} {...other}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.upcomingAgenda')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.startIn"
                            values={{
                                agenda: upcomingEventTextObject.name
                            }}
                        >
                            說明會場次 {upcomingEventTextObject.name} 將於 20分鐘後 開始。
                        </Trans>
                    </Typography>
                </NotificationToastContainer>
            )
        case NoticeTextCode.活動更改通知:
            let changeEventTextObject = (message.textObject as UpcomingEventTextObject);
            return (
                <NotificationToastContainer avatarUrl={avatarUrl} closeToast={handleClose} onClick={handleOnClick} {...other}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.changeNotice')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.changeTime"
                            values={{
                                agenda: changeEventTextObject.name,
                                location: changeEventTextObject.location,
                                startTime: format(new Date(changeEventTextObject.startTime), "MM/dd(eeeee) kk:mm"),
                                endTime: format(new Date(changeEventTextObject.endTime), " kk:mm")
                            }}
                        >
                            說明會場次 {changeEventTextObject.name} 改於
                            {changeEventTextObject.location} {format(new Date(changeEventTextObject.startTime), "MM/dd(eeeee) kk:mm")}{format(new Date(changeEventTextObject.endTime), " kk:mm")} 舉辦。
                        </Trans>
                    </Typography>
                </NotificationToastContainer>
            )
        case NoticeTextCode.問題狀態改變通知:
            return (
                <NotificationToastContainer avatarUrl={avatarUrl} closeToast={handleClose} onClick={handleOnClick} {...other}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.Q&A')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.reply"
                            values={{
                                name: "暴風數位股份有限公司"
                            }}
                        >
                            {"暴風數位股份有限公司"} 回應了您的提問。
                        </Trans>
                    </Typography>
                </NotificationToastContainer>
            )
        case NoticeTextCode.問答版待回覆通知:
            let notAnswerTextObject = (message.textObject as NotAnswerTextObject);
            return (
                <NotificationToastContainer avatarUrl="/images/Avatar_1.png" closeToast={handleClose} onClick={handleOnClick} {...other}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.Q&A')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.wait_to_reply"
                            values={{
                                count:notAnswerTextObject.questIds.length
                            }}
                        >
                            有 {notAnswerTextObject.questIds.length}則 待回覆問題於問答版中。
                        </Trans>
                    </Typography>
                </NotificationToastContainer>
            )
    }
}

interface NotificationToastContainerProps {
    avatarUrl?: string;
    avatarIcon?: JSX.Element;
    children?: React.ReactNode;

    closeToast: () => void;
    autoClose?: boolean;
    pauseOnHover?: boolean;
    onClick?: () => void;
    closeOnClick?: boolean;
    delay?: number;
}

function NotificationToastContainer(props: NotificationToastContainerProps) {
    const { avatarUrl, avatarIcon, children, closeToast, autoClose, pauseOnHover, onClick, closeOnClick, delay } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const [running, setRunning] = React.useState(true);
    function handlePause() {
        setRunning(false);
    }
    function handlePlay() {
        setRunning(true);
    }

    function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation()
        closeToast();
    }
    function handleOnClick() {
        if (closeOnClick)
            closeToast();
        onClick();
    }
    return (
        <Paper elevation={3} sx={{ position: "relative", width: matches ? "360px" : "550px", maxWidth: "calc(100vw - 1rem)", minHeight: "90px", padding: matches ? 2 : 1, overflow: "hidden", cursor: onClick || closeOnClick ? "pointer" : "auto" }} onClick={handleOnClick}
            onMouseOver={() => { if (pauseOnHover) handlePause(); }}
            onMouseLeave={() => { if (pauseOnHover) handlePlay(); }}
        >
            <IconButton onClick={handleClose} sx={{ position: "absolute", top: "4px", right: "4px", color: "text.secondary" }}>
                <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" color="text.secondary">
                    <FontAwesomeIcon icon={faTimes} /></Box>
            </IconButton>
            <Box display="inline-flex" pl={0.5} pr={2}>
                <Box minWidth="58px" mt={1}>
                    {avatarUrl ?
                        <Avatar src={avatarUrl} sx={{ width: "42px", height: "42px" }} /> :
                        avatarIcon ?
                            <Avatar sx={{ width: "42px", height: "42px", backgroundColor: "primary.main" }}> {avatarIcon}</Avatar> :
                            <Skeleton variant="circular" width="42px" height="42px" />
                    }
                </Box>
                <Box mt={1}>
                    {children}
                </Box>
            </Box>
            {autoClose &&
                <ProgressBar
                    delay={delay}
                    isRunning={running}
                    closeToast={closeToast}
                />
            }
        </Paper>
    )
}

interface ProgressBarProps {
    delay: number;
    isRunning: boolean;
    closeToast: () => void;
}
function ProgressBar(props: ProgressBarProps) {
    const { delay, isRunning, closeToast } = props;
    const style: React.CSSProperties = {
        animationDuration: `${delay ?? 5000}ms`,
        animationPlayState: isRunning ? 'running' : 'paused',
        background: "linear-gradient(to right, #5433ff, #20bdff, #a5fecb)"
    };
    return (
        <Box className="toast-progress-bar" style={style} onAnimationEnd={closeToast} />
    )
}