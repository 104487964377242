﻿import { HubConnection } from "@microsoft/signalr";
import { ResultType } from "../services/utils";

interface ISystemControlHub {
    connection: HubConnection;
    addHandler: {
        UpdateOnline: (fn: (statuses) => void) => void;
        OnChatDialogOpen: (fn: (chatId: string) => void) => void;
        ReceiveMessage: (fn: (message) => void) => void;
        UpdateMessage: (fn: (message) => void) => void;
        ReceiveChannelMessage: (fn: (message) => void) => void;
        UpdateChannelMessage: (fn: (message) => void) => void;
        ReceiveNoticeMessage: (fn: (message) => void) => void;
        ExchangeCardSuccess: (fn: (chatId: string) => void) => void;
        OnEnterVideoChatRoom: (fn: (chatId: string, userId: string) => void) => void;
        OnLeaveVideoChatRoom: (fn: (chatId: string, userId: string) => void) => void;
        OnReadDialog: (fn: (chatId: string, userId: string) => void) => void;
        UpdateHistory: (fn: (historyData) => void) => void;
        OnChangeAgenda: (fn: (agendaData: string) => void) => void;
        OnChangeSignInStatus: (fn: (seminarId: string, isOpen: boolean) => void) => void;
        OnChangeChannel: (fn: (channelId) => void) => void;
        OnChangeLink: (fn: (link) => void) => void;
        ReceiveGameMessage: (fn: (message) => void) => void;
    };
    removeHandler: {
        UpdateOnline: () => void;
        ReceiveMessage: () => void;
        UpdateMessage: () => void;
        ReceiveChannelMessage: () => void;
        UpdateChannelMessage: () => void;
        ReceiveNoticeMessage: () => void;
        ExchangeCardSuccess: () => void;
        OnEnterVideoChatRoom: () => void;
        OnLeaveVideoChatRoom: () => void;
        OnReadDialog: () => void;
        UpdateHistory: () => void;
        OnChangeAgenda: () => void;
        OnChangeSignInStatus: () => void;
        OnChangeChannel: () => void;
        OnChangeLink: () => void;
        ReceiveGameMessage: () => void;
    };
    //"Send" returns a promise that is resolved when the client has sent the invocation to the server, or an error occurred. The server may still be handling the invocation when the promise resolves.
    send: {
        ChangeLocation(locatedAt: LocatedAt): Promise<void>;
        ReadDialog(chatId: string): Promise<void>;
        SendMessage(message: ChatMessage): Promise<void>;
        DeleteMessage(chatId: string, messageId: string): Promise<void>;
        EnterChannel(channelId: string): Promise<void>;
        LeaveChannel(channelId: string): Promise<void>;
        SendChannelMessage(message: ChannelMessage): Promise<void>;
        PinChannelMessage(channelId: string, messageId: string): Promise<void>;
        UnpinChannelMessage(channelId: string, messageId: string): Promise<void>;
        DeleteChannelMessage(channelId: string, messageId: string): Promise<void>;
        ReadNotice(): Promise<void>;
        SendNoticeMessage(content: string, link: string): Promise<void>;
        EnterVideoChatRoom(chatId: string): Promise<void>;
        LeaveVideoChatRoom(chatId: string): Promise<void>;
        SendGameMessage(message: string): Promise<void>;
        ExitGame(): Promise<void>;
    };
    //"Invoke" returns a promise that is resolved when the server has finished invoking the method (or an error occurred). In addition, the Invoke promise can receive a result from the server method, if the server returns a result.
    invoke: {
        ChangeLocation(locatedAt: LocatedAt): Promise<void>;
        OpenChatDialog(receiverId: string): Promise<ResultType<string>>;
        GetChatId(receiverId: string): Promise<ResultType<string>>;
        SendMessage(message: ChatMessage): Promise<void>;
        SendChannelMessage(message: ChannelMessage): Promise<void>;
        SendNoticeMessage(content: string, link: string): Promise<void>;
        GetEnterVideoChatRoomIds(chatId: string): Promise<ResultType<string[]>>;
        GetEnterVideoChatRoomIdsList(chatId: string[]): Promise<ResultType<Record<string, string[]>>>;
        SendGameMessage(message: string): Promise<void>;
    };
}

export class SystemControlHub implements ISystemControlHub {
    connection: HubConnection;
    addHandler: {
        UpdateOnline: (fn: (statuses) => void) => void;
        OnChatDialogOpen: (fn: (chatId: string) => void) => void;
        ReceiveMessage: (fn: (message) => void) => void;
        UpdateMessage: (fn: (message) => void) => void;
        ReceiveChannelMessage: (fn: (message) => void) => void;
        UpdateChannelMessage: (fn: (message) => void) => void;
        ReceiveNoticeMessage: (fn: (message) => void) => void;
        ExchangeCardSuccess: (fn: (chatId: string) => void) => void;
        OnEnterVideoChatRoom: (fn: (chatId: string, userId: string) => void) => void;
        OnLeaveVideoChatRoom: (fn: (chatId: string, userId: string) => void) => void;
        OnReadDialog: (fn: (chatId: string, userId: string) => void) => void;
        UpdateHistory: (fn: (historyData) => void) => void;
        OnChangeAgenda: (fn: (agendaData: string) => void) => void;
        OnChangeSignInStatus: (fn: (seminarId: string, isOpen: boolean) => void) => void;
        OnChangeChannel: (fn: (channelId) => void) => void;
        OnChangeLink: (fn: (link) => void) => void;
        ReceiveGameMessage: (fn: (message) => void) => void;
    };
    removeHandler: {
        UpdateOnline: () => void;
        OnChatDialogOpen: () => void;
        ReceiveMessage: () => void;
        UpdateMessage: () => void;
        ReceiveChannelMessage: () => void;
        UpdateChannelMessage: () => void;
        ReceiveNoticeMessage: () => void;
        ExchangeCardSuccess: () => void;
        OnEnterVideoChatRoom: () => void;
        OnLeaveVideoChatRoom: () => void;
        OnReadDialog: () => void;
        UpdateHistory: () => void;
        OnChangeAgenda: () => void;
        OnChangeSignInStatus: () => void;
        OnChangeChannel: () => void;
        OnChangeLink: () => void;
        ReceiveGameMessage: () => void;
    };
    send: {
        ChangeLocation(locatedAt: LocatedAt): Promise<void>;
        ReadDialog(chatId: string): Promise<void>;
        SendMessage(message: ChatMessage): Promise<void>;
        DeleteMessage(chatId: string, messageId: string): Promise<void>;
        EnterChannel(channelId: string): Promise<void>;
        LeaveChannel(channelId: string): Promise<void>;
        SendChannelMessage(message: ChannelMessage): Promise<void>;
        PinChannelMessage(channelId: string, messageId: string): Promise<void>;
        UnpinChannelMessage(channelId: string, messageId: string): Promise<void>;
        DeleteChannelMessage(channelId: string, messageId: string): Promise<void>;
        ReadNotice(): Promise<void>;
        SendNoticeMessage(content: string, link: string): Promise<void>;
        EnterVideoChatRoom(chatId: string): Promise<void>;
        LeaveVideoChatRoom(chatId: string): Promise<void>;
        SendGameMessage(message: string): Promise<void>;
        ExitGame(): Promise<void>;
    };
    invoke: {
        ChangeLocation(locatedAt: LocatedAt): Promise<void>;
        OpenChatDialog(receiverId: string): Promise<ResultType<string>>;
        GetChatId(receiverId: string): Promise<ResultType<string>>;
        SendMessage(message: ChatMessage): Promise<void>;
        SendChannelMessage(message: ChannelMessage): Promise<void>;
        ClearChannel(seminarId: string): Promise<ResultType<null>>;
        SendNoticeMessage(content: string, link: string): Promise<void>;
        GetEnterVideoChatRoomIds(chatId: string): Promise<ResultType<string[]>>;
        GetEnterVideoChatRoomIdsList(chatId: string[]): Promise<ResultType<Record<string, string[]>>>;
        SendGameMessage(message: string): Promise<void>;
    };
    constructor(connection: HubConnection) {
        this.connection = connection;
        this.addHandler = {
            UpdateOnline: (fn: (statuses) => void) => this.connection.on("UpdateOnline", fn),
            OnChatDialogOpen: (fn: (chatId: string) => void) => this.connection.on("OnChatDialogOpen", fn),
            ReceiveMessage: (fn: (message) => void) => this.connection.on("ReceiveMessage", fn),
            UpdateMessage: (fn: (message) => void) => this.connection.on("UpdateMessage", fn),
            ReceiveChannelMessage: (fn: (message) => void) => this.connection.on("ReceiveChannelMessage", fn),
            UpdateChannelMessage: (fn: (message) => void) => this.connection.on("UpdateChannelMessage", fn),
            ReceiveNoticeMessage: (fn: (message) => void) => this.connection.on("ReceiveNoticeMessage", fn),
            ExchangeCardSuccess: (fn: (chatId: string) => void) => this.connection.on("ExchangeCardSuccess", fn),
            OnEnterVideoChatRoom: (fn: (chatId: string, userId: string) => void) => this.connection.on("OnEnterVideoChatRoom", fn),
            OnLeaveVideoChatRoom: (fn: (chatId: string, userId: string) => void) => this.connection.on("OnLeaveVideoChatRoom", fn),
            OnReadDialog: (fn: (chatId: string, userId: string) => void) => this.connection.on("OnReadDialog", fn),
            UpdateHistory: (fn: (message) => void) => this.connection.on("UpdateHistory", fn),
            OnChangeAgenda: (fn: (agendaData) => void) => this.connection.on("OnChangeAgenda", fn),
            OnChangeSignInStatus: (fn: (seminarId: string, isOpen: boolean) => void) => this.connection.on("OnChangeSignInStatus", fn),
            OnChangeChannel: (fn: (channelId) => void) => this.connection.on("OnChangeChannel", fn),
            OnChangeLink: (fn: (link) => void) => this.connection.on("OnChangeLink", fn),
            ReceiveGameMessage: (fn: (message) => void) => this.connection.on("ReceiveGameMessage", fn)
        };
        this.removeHandler = {
            UpdateOnline: () => this.connection.off("UpdateOnline"),
            OnChatDialogOpen: () => this.connection.off("OnChatDialogOpen"),
            ReceiveMessage: () => this.connection.off("ReceiveMessage"),
            UpdateMessage: () => this.connection.off("UpdateMessage"),
            ReceiveChannelMessage: () => this.connection.off("ReceiveChannelMessage"),
            UpdateChannelMessage: () => this.connection.off("UpdateChannelMessage"),
            ReceiveNoticeMessage: () => this.connection.off("ReceiveNoticeMessage"),
            ExchangeCardSuccess: () => this.connection.off("ExchangeCardSuccess"),
            OnEnterVideoChatRoom: () => this.connection.off("OnEnterVideoChatRoom"),
            OnLeaveVideoChatRoom: () => this.connection.off("OnLeaveVideoChatRoom"),
            OnReadDialog: () => this.connection.off("OnReadDialog"),
            UpdateHistory: () => this.connection.off("UpdateHistory"),
            OnChangeAgenda: () => this.connection.off("OnChangeAgenda"),
            OnChangeSignInStatus: () => this.connection.off("OnChangeSignInStatus"),
            OnChangeChannel: () => this.connection.off("OnChangeChannel"),
            OnChangeLink: () => this.connection.off("OnChangeLink"),
            ReceiveGameMessage: () => this.connection.off("ReceiveGameMessage")
        };
        this.send = {
            ChangeLocation: (locatedAt: LocatedAt) => this.connection.send("ChangeLocation", locatedAt),
            ReadDialog: (chatId: string) => this.connection.send("ReadDialog", chatId),
            SendMessage: (message: ChatMessage) => this.connection.send("SendMessage", message),
            DeleteMessage: (chatId: string, messageId: string) => this.connection.send("DeleteMessage", chatId, messageId),
            EnterChannel: (channelId: string) => this.connection.send("EnterChannel", channelId),
            LeaveChannel: (channelId: string) => this.connection.send("LeaveChannel", channelId),
            SendChannelMessage: (message: ChannelMessage) => this.connection.send("SendChannelMessage", message),
            PinChannelMessage: (channelId: string, messageId: string) => this.connection.send("PinChannelMessage", channelId, messageId),
            UnpinChannelMessage: (channelId: string, messageId: string) => this.connection.send("UnpinChannelMessage", channelId, messageId),
            DeleteChannelMessage: (channelId: string, messageId: string) => this.connection.send("DeleteChannelMessage", channelId, messageId),
            ReadNotice: () => this.connection.send("ReadNotice"),
            SendNoticeMessage: (content: string, link: string) => {
                let message: NoticeMessage = {
                    textCode: 0, textObject: { content: content, link: link }
                };
                return this.connection.send("SendNoticeMessage", message)
            },
            EnterVideoChatRoom: (chatId: string) => this.connection.send("EnterVideoChatRoom", chatId),
            LeaveVideoChatRoom: (chatId: string) => this.connection.send("LeaveVideoChatRoom", chatId),
            SendGameMessage: (message: string) => this.connection.send("SendGameMessage", message),
            ExitGame: () => this.connection.send("ExitGame")
        };
        this.invoke = {
            ChangeLocation: (locatedAt: LocatedAt) => this.connection.invoke("ChangeLocation", locatedAt),
            OpenChatDialog: (receiverId: string) => this.connection.invoke("OpenChatDialog", receiverId),
            GetChatId: (receiverId: string) => this.connection.invoke("GetChatId", receiverId),
            SendMessage: (message: ChatMessage) => this.connection.invoke("SendMessage", message),
            SendChannelMessage: (message: ChannelMessage) => this.connection.invoke("SendChannelMessage", message),
            ClearChannel: (seminarId: string) => this.connection.invoke("ClearChannel", seminarId),
            SendNoticeMessage: (content: string, link: string) => {
                let message: NoticeMessage = {
                    textCode: 0, textObject: { senderId: null, content: content, link: link }
                };
                return this.connection.invoke("SendNoticeMessage", message)
            },
            GetEnterVideoChatRoomIds: (chatId: string) => this.connection.invoke("GetEnterVideoChatRoomIds", chatId),
            GetEnterVideoChatRoomIdsList: (chatId: string[]) => this.connection.invoke("GetEnterVideoChatRoomIdsList", chatId),
            SendGameMessage: (message: string) => this.connection.invoke("SendGameMessage", message)
        };
    };
}

export interface LocatedAt {
    location: number;
    refId: string;
}
export interface ChatMessage {
    chatId: string;
    textCode: TextCode;
    textObject: any;
}
export interface ChannelMessage {
    channelId: string;
    textCode: number;
    textObject: any;
}
export interface NoticeMessage {
    textCode: NoticeTextCode;
    textObject: any;
}
export enum TextCode {
    系統通知 = 0,
    純文字及連結,
    互動訊息, //event狀態改變就會寄的東東
    貼圖,
    交換名片,
    預約面談,
    視訊邀請,
    來自電腦的完整檔案,
    來自電腦的縮圖版本檔案,
    來自Google雲端的完整檔案
}
export enum NoticeTextCode {
    廣播通知 = 0,
    活動通知,
    活動更改通知,
    問題狀態改變通知,
    問答版待回覆通知
}