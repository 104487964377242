import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Booth, BoothExtendField, BoothFieldName, BoothPresence, BoothQandA, BoothType, CardInfo, IInfoCard, VideoRecord, VoteRecord } from '../class'
import { UserCardInfoModel } from './user';
import { ResultType } from './utils';

export interface BoothCardInfoModel extends Booth {
    isOnline: boolean;
    votes: number;
}

export interface BoothDetailData extends Booth {
    createTime: string;
    managers: ManagerData[];
}
export interface ManagerData {
    userId: string;
    name: string;
    logo: string;
}
export interface ImportBoothModel extends Omit<Booth, "whiteboardRoomCode"> {
}
export interface RecruitData {
    dataId: string;
    boothId: string;
    content: string;
    link: string;
    isEnd: boolean;
    updateTime: string;
}
export interface ImportBoothModel {
    boothId: string;
    type: BoothType;
    boothNo: string;
    boothLocation: string;
    category: string;
    chName: string;
    enName: string;
    abbrev: string;
    logoUrl: string;
    bannerUrl: string;
    customContent: string;
    fieldList: BoothExtendField[];
}
export interface BellCall {
    boothId: string;
    receiverId: string;
    option: number;
    message: string;
}
export interface BoothWithJobOpeningListModel {
    boothId: string;
    title: string;
    isInternship: boolean;
    category: string;
    place: string;
    placeDescipt: string;
    demandNum: number;
    payType: string;
    payment: number;
    protectLabor: boolean;
    jobDescipt: string;
    qualification: string;
    plus: string;
    startWorking: string;
    hoursPerWeek: number;
    duration: string;
}
interface PosterData {
    booth: Booth;
    editTime: string;
    posterUrl: string;
};
export interface BellCallData {
    dataId: string;
    booth: Booth;
    sender: CardInfo;
    receiver: CardInfo;
    option: number;
    message: string;
    createTime: string;
}
//通用api
const baseApi = createApi({
    reducerPath: 'boothApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/booth' }),
    endpoints: (builder) => ({
        getAllBooths: builder.query<ResultType<BoothCardInfoModel[]>, void>({
            query: () => `boothList`,
        }),
        getAllBoothNames: builder.query<ResultType<Pick<Booth, 'boothId' | 'chName' | 'enName' | 'type'>[]>, void>({
            query: () => `boothList/name`,
        }),
        getTypeBooth: builder.mutation<ResultType<Booth[]>, BoothType>({
            query: (patch) => ({
                url: `type`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch,
            }),
        }),
        getBooth: builder.query<ResultType<Booth>, Pick<Booth, 'boothId'>>({
            query: ({ boothId }) => `${boothId}`,
        }),
        getBoothFieldNameList: builder.query<ResultType<BoothFieldName[]>, void>({
            query: () => `fieldname`,
        }),
        getBoothQandA: builder.query<ResultType<BoothQandA>, string>({
            query: (questId) => `question/${questId}`
        }),
        getAllBoothQandAs: builder.query<ResultType<BoothQandA[]>, string>({
            query: (boothId) => `questions/${boothId}`
        }),
        watchBoothVideo: builder.mutation<ResultType<VideoRecord>, string>({
            query: (boothId) => `WatchBoothVideo/${boothId}`
        }),
        getAllInfoCard: builder.query<ResultType<IInfoCard[]>, void>({
            query: () => `infoCard`,
        }),
        getInfoCard: builder.query<ResultType<IInfoCard[]>, string>({
            query: (boothId) => `infoCard/${boothId}`,
        }),
        getBellCalls: builder.query<ResultType<BellCallData[]>, string>({
            query: (boothId) => `serviceBell/${boothId}`,
        }),
        postBellCall: builder.mutation<ResultType<number>, BellCall>({
            query: (patch) => ({
                url: `serviceBell`,
                method: 'POST',
                body: patch,
            }),
        }),
        getBoothManagers: builder.query<ResultType<UserCardInfoModel[]>, string>({
            query: (boothId) => `getManagers/${boothId}`,
        }),
        getBoothPresenceInfos: builder.mutation<ResultType<BoothPresence[]>, {boothIds: string[]}>({
            query: (patch) => ({
                url: `getBoothPresenceInfos`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        getAllPoster: builder.query<ResultType<PosterData[]>, void>({
            query: () => `poster`,
        }),
        getVideo: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/video`,
        }),
        //TSDGF
        getGameIntro: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/gameIntro`,
        }),
        getTeamIntro: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/teamIntro`,
        }),
        getCompanyIntro: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/companyIntro`,
        }),
        getEventIntro: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/eventIntro`,
        }),
        //投票
        putVoteBooth: builder.mutation<ResultType<null>, string>({
            query: (boothId) => ({
                url: `vote`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: { boothId: boothId }
            }),
        }),
        getAllVoteGetVoteRecord: builder.query<ResultType<VoteRecord[]>, void>({
            query: () => `getAllVoteRecord`,
        }),
        getVoteRecord: builder.query<ResultType<VoteRecord>, string>({
            query: (boothId) => `getVoteRecord/${boothId}`,
        }),
    }),
});

//exhibitor或superuser或admin用api
export const boothApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getBoothList: builder.query<ResultType<BoothDetailData[]>, void>({
            query: () => `getList`,
        }),
        putBooth: builder.mutation<ResultType<null>, ImportBoothModel>({
            query: (patch) => ({
                url: ``,
                method: 'PUT',
                body: patch,
            }),
        }),
        postQandA: builder.mutation<ResultType<BoothQandA>, Pick<BoothQandA, "boothId" | "question">>({
            query: ({ ...patch }) => ({
                url: `question`,
                method: 'POST',
                body: patch,
            }),
        }),
        putQandA: builder.mutation<ResultType<BoothQandA>, Pick<BoothQandA, "dataId" | "answer">>({
            query: ({ ...patch }) => ({
                url: `question`,
                method: 'PUT',
                body: patch,
            }),
        }),
        putQandALikemark: builder.mutation<ResultType<boolean>, { userId: string, boardId: string }>({
            query: ({ userId, boardId }) => ({
                url: `likemark/${userId}/${boardId}`,
                method: 'PUT'
            }),
        }),
        postInfoCard: builder.mutation<ResultType<null>, Pick<IInfoCard, 'boothId' | 'title' | 'imgUrl' | 'content'>>({
            query: ({ ...patch }) => ({
                url: `infoCard`,
                method: 'POST',
                body: patch,
            }),
        }),
        putInfoCard: builder.mutation<ResultType<null>, Pick<IInfoCard, 'dataId' | 'title' | 'imgUrl' | 'content'>>({
            query: ({ ...patch }) => ({
                url: `infoCard`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deleteInfoCard: builder.mutation<ResultType<null>, string>({
            query: (dataId) => ({
                url: `infoCard/${dataId}`,
                method: 'DELETE'
            }),
        }),
        postNewBooths: builder.mutation<ResultType<null>, ImportBoothModel[]>({
            query: (patch) => ({
                url: `addlist`,
                method: 'POST',
                body: patch,
            }),
        }),
        updateBoothBanner: builder.mutation<ResultType<null>, { uri: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `banner/${boothId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateBoothPoster: builder.mutation<ResultType<null>, { uri: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `poster/${boothId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateBoothEdm: builder.mutation<ResultType<null>, { uri: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `edm/${boothId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        postNewBoothJobList: builder.mutation<ResultType<null>, BoothWithJobOpeningListModel[]>({
            query: (patch) => ({
                url: `jobopening/addlist`,
                method: 'POST',
                body: patch,
            }),
        }),
        updateVideo: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/video`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        //TSDGF
        updateGameIntro: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/gameIntro`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateTeamIntro: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/teamIntro`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateCompanyIntro: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/companyIntro`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateEventIntro: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/eventIntro`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
   
        
    }),
    overrideExisting: false
});

//通用api
export const { useGetAllBoothsQuery } = boothApi;
export const { useLazyGetAllBoothsQuery } = boothApi;
export const { useGetAllBoothNamesQuery } = boothApi;
export const { useLazyGetAllBoothNamesQuery } = boothApi;
export const { useGetTypeBoothMutation } = boothApi;
export const { useGetBoothQuery } = boothApi;
export const { useLazyGetBoothQuery } = boothApi;
export const { useGetBoothFieldNameListQuery } = boothApi;
export const { useLazyGetBoothFieldNameListQuery } = boothApi;
//export const { useGetRecruitsQuery } = boothApi;
export const { useGetBoothQandAQuery } = boothApi;
export const { useLazyGetBoothQandAQuery } = boothApi;
export const { useGetAllBoothQandAsQuery } = boothApi;
export const { useLazyGetAllBoothQandAsQuery } = boothApi;
export const { useWatchBoothVideoMutation } = boothApi;
export const { useGetAllInfoCardQuery } = boothApi;
export const { useLazyGetAllInfoCardQuery } = boothApi;
export const { useGetInfoCardQuery } = boothApi;
export const { useLazyGetInfoCardQuery } = boothApi;
export const { useGetBellCallsQuery } = boothApi;
export const { usePostBellCallMutation } = boothApi;
export const { useGetBoothManagersQuery } = boothApi;
export const { useLazyGetBoothManagersQuery } = boothApi;
export const { useGetBoothPresenceInfosMutation } = boothApi;
export const { useGetAllPosterQuery } = boothApi;
export const { useLazyGetAllPosterQuery } = boothApi;
export const { useGetVideoQuery } = boothApi;

export const { useGetGameIntroQuery } = boothApi;
export const { useGetTeamIntroQuery } = boothApi;
export const { useGetCompanyIntroQuery } = boothApi;
export const { useGetEventIntroQuery } = boothApi;
//投票
export const { usePutVoteBoothMutation } = boothApi;
export const { useGetAllVoteGetVoteRecordQuery } = boothApi;
export const { useGetVoteRecordQuery } = boothApi;
export const { useLazyGetVoteRecordQuery } = boothApi;

//exhibitor或superuser或admin用api
export const { useGetBoothListQuery } = boothApi;
export const { usePutBoothMutation } = boothApi;
export const { usePostQandAMutation } = boothApi;
export const { usePutQandAMutation } = boothApi;
export const { usePutQandALikemarkMutation } = boothApi;
export const { usePostInfoCardMutation } = boothApi;
export const { usePutInfoCardMutation } = boothApi;
export const { useDeleteInfoCardMutation } = boothApi;
export const { usePostNewBoothsMutation } = boothApi;
export const { useUpdateBoothBannerMutation } = boothApi;
export const { useUpdateBoothPosterMutation } = boothApi;
export const { useUpdateBoothEdmMutation } = boothApi;
export const { usePostNewBoothJobListMutation } = boothApi;
export const { useUpdateVideoMutation } = boothApi;
//TSDGF
export const { useUpdateGameIntroMutation } = boothApi;
export const { useUpdateTeamIntroMutation } = boothApi;
export const { useUpdateCompanyIntroMutation } = boothApi;
export const { useUpdateEventIntroMutation } = boothApi;