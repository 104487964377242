import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, ListItem, ListItemAvatar,  ListItemButton,  Skeleton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProgressiveImage } from '../../Posters/LazyBackground';
import { useUser } from '../../store';
import { ChannelMessageContent, PlainTextObject, UserRole } from '../class';
import { TextCode } from '../react-signalr/systemControlHub';
import Linkify from 'react-linkify';

interface ChannelChatMessageProps {
    message: ChannelMessageContent;
    onPin?: (id: string) => void;
    onDelete?: (id: string) => void;
    onClickLogo?: (userId: string) => void;
    onClickName?: (userId: string) => void;
}

export function ChannelChatMessage(props: ChannelChatMessageProps) {
    const { message, onPin, onDelete, onClickLogo, onClickName } = props;
    const { t } = useTranslation();

    function handlePin() {
        onPin(message.messageId);
    }
    function handleDelete() {
        onDelete(message.messageId);
    }

    function handleClickLogo() {
        onClickLogo(message.senderId);
    }

    function handleClickName() {
        onClickName(message.senderId);
    }

    if (message.isDeleted) {
        return (
            <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}>
                <Typography>
                    {t("onlineSeminar.delete")}
                </Typography>
            </ChannelChatMessageContent>
        )
    }
    switch (message.textCode) {
        case TextCode.純文字及連結:
            let content = (message.textObject as PlainTextObject).content;
            return (
                <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}
                    isPinned={message.isPinned} onPin={handlePin} onDelete={handleDelete} onClickLogo={handleClickLogo} onClickName={handleClickName}>
                    <Linkify componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                        <a target='_blank' style={{ color: '#007bff' }} href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}><Typography sx={{ userSelect: "text" }}>{content}</Typography></Linkify>
                </ChannelChatMessageContent>
            )
        case TextCode.貼圖:
            return (
                <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}
                    isPinned={message.isPinned} onPin={handlePin} onDelete={handleDelete} onClickLogo={handleClickLogo} onClickName={handleClickName}>
                </ChannelChatMessageContent>
            )
        case TextCode.來自電腦的完整檔案:
            return (
                <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}
                    isPinned={message.isPinned} onPin={handlePin} onDelete={handleDelete} onClickLogo={handleClickLogo} onClickName={handleClickName}>
                </ChannelChatMessageContent>
            )
        case TextCode.來自電腦的縮圖版本檔案:
            return (
                <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}
                    isPinned={message.isPinned} onPin={handlePin} onDelete={handleDelete} onClickLogo={handleClickLogo} onClickName={handleClickName}>
                </ChannelChatMessageContent>
            )
        case TextCode.來自Google雲端的完整檔案:
            return (
                <ChannelChatMessageContent name={message.senderName} avatarUrl={message.senderLogoUrl} timestamp={message.createTime}
                    isPinned={message.isPinned} onPin={handlePin} onDelete={handleDelete} onClickLogo={handleClickLogo} onClickName={handleClickName}>
                </ChannelChatMessageContent>
            )
    }
}

interface ChannelChatMessageContentProps {
    name: string;
    avatarUrl?: string;
    avatarIcon?: JSX.Element;
    timestamp: string;
    isPinned?: boolean;
    onPin?: () => void;
    onDelete?: () => void;
    onClickLogo?: () => void;
    onClickName?: () => void;
    children?: React.ReactNode;
}

function ChannelChatMessageContent(props: ChannelChatMessageContentProps) {
    const { name, avatarUrl, avatarIcon, timestamp, isPinned, onPin, onDelete, onClickLogo, onClickName, children } = props;

    const { t, i18n } = useTranslation();
    const user = useUser();
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);
    React.useEffect(() => { if (user) setIsAdmin(user.role >= UserRole.administrator); }, [user]);

    const handleMouseOver = () => { setIsHover(true); }
    const handleMouseLeave = () => { setIsHover(false); }

    const attachedColor = "#B7B7B7";
    return (
        <ListItem disablePadding>
            <ListItemButton disableRipple disableTouchRipple alignItems="flex-start" sx={{ cursor: "unset" }} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                {/*頭像*/}
                <ListItemAvatar sx={{ minWidth: "58px", paddingInline: "4px 8px", marginTop: "4px" }}>
                    {avatarUrl ?
                        <Avatar src={avatarUrl} sx={{ width: "38px", height: "38px", cursor: onClickLogo ? "pointer" : "auto" }} onClick={onClickLogo} /> :
                        avatarIcon ?
                            <Avatar sx={{ width: "38px", height: "38px", backgroundColor: "warning.light", cursor: onClickLogo ? "pointer" : "auto" }} onClick={onClickLogo}>{avatarIcon}</Avatar> :
                            <Skeleton variant="circular" width="38px" height="38px" />
                    }
                </ListItemAvatar>
                <Stack flex="1 1" sx={{ maxWidth: "calc(100% - 14px - 20px)", marginTop: "4px" }}>
                    {/*名字*/}
                    <Box display="inline-flex">
                        {name ?
                            <Typography variant="caption" sx={{ cursor: onClickName ? "pointer" : "auto" }} onClick={onClickName}>{name}</Typography> :
                            <Skeleton variant="text" width="80%" />
                        }
                        {/*時間標記*/}
                        {timestamp &&
                            <Typography variant="caption" color={attachedColor} sx={{ flex: "1 1", textAlign: "end" }}>
                                {format(new Date(timestamp), "kk:mm")}
                            </Typography>
                        }
                    </Box>
                    {/*文字內容*/}
                    <Box display="flex">
                        {children ?
                            <>{children}</>
                            :
                            <Skeleton variant="rectangular" />
                        }
                    </Box>
                    {isPinned ?
                        <Box display="flex" justifyContent="flex-end" height="14px">
                            <Typography variant="caption" color="error.light">
                                <Box component="span" display="inline-block" mr={0.5} sx={{ transform: "rotateZ(45deg)" }}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                </Box>
                                {t("onlineSeminar.pinned")}
                            </Typography>
                        </Box> :
                        isAdmin &&
                        <Box display="flex" justifyContent="flex-end" height="14px">
                            {isHover &&
                                <Button color="primary" disableRipple sx={{ p: 0, minWidth: "unset" }} onClick={onPin}>
                                <Typography variant="caption">{t("onlineSeminar.pin")}</Typography>
                                </Button>
                            }
                        </Box>
                    }
                </Stack>
            </ListItemButton>
        </ListItem>
    )
}

interface ChannelChatPinnedMessageProps {
    message: ChannelMessageContent;
    onUnpin: (id: string) => void;
}

export function ChannelChatPinnedMessage(props: ChannelChatPinnedMessageProps) {
    const { message, onUnpin } = props;
    const { t } = useTranslation();

    const user = useUser();
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);
    React.useEffect(() => { if (user) setIsAdmin(user.role >= UserRole.administrator); }, [user]);

    const handleMouseOver = () => { setIsHover(true); }
    const handleMouseLeave = () => { setIsHover(false); }

    function getMessageContent(message: ChannelMessageContent) {
        if (message == null)
            return "";

        switch (message.textCode) {
            case TextCode.純文字及連結:
                return (message.textObject as PlainTextObject).content;
            case TextCode.貼圖:
                return;
            default:
                return;
        }
    }

    function handleUnpin() {
        onUnpin(message.messageId);
    }

    return (
        <ListItem disablePadding>
            <ListItemButton disableRipple disableTouchRipple sx={{ cursor: "unset", display: "block" }} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <Stack>
                    {/*文字內容*/}
                    <Box display="flex">
                        {message ?
                            <Typography sx={{ userSelect: "all" }}>
                                <Box component="span" display="inline-block" mr={1} sx={{ transform: "rotateZ(45deg)", color: "error.light" }}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                </Box>
                                {getMessageContent(message)}
                            </Typography>
                            :
                            <><Box component="span" display="inline-block" mr={0.5} sx={{ transform: "rotateZ(45deg)", color: "error.light" }}>
                                <FontAwesomeIcon icon={faThumbtack} />
                            </Box>
                                <Skeleton variant="rectangular" /></>
                        }
                    </Box>
                    {isAdmin &&
                        <Box display="flex" justifyContent="flex-end" height="14px">
                            {isHover &&
                                <Button color="error" disableRipple sx={{ p: 0, minWidth: "unset" }} onClick={handleUnpin}>
                            <Typography variant="caption">{t("onlineSeminar.unpin")}</Typography>
                                </Button>
                            }
                        </Box>
                    }
                </Stack>
            </ListItemButton>
        </ListItem>
    )
}