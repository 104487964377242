import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Container, MenuItem, styled, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Booth, BoothPresence, BoothType, IInfoCard } from '../components/class';
import { boothTypeList } from '../components/boothType';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '../components/responsive-components';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { gameGenreList } from '../components/selectOptions';
import { BoothCard } from '../components/BoothCard/BoothCard';
import { BoothCardInfoModel, useGetAllVoteGetVoteRecordQuery } from '../components/services/booth';
import { useDispatchWithType, useUser } from '../store';
import { useChangeBookMarkMutation, useChangeInfoCardBookMarkMutation } from '../components/services/user';
import { updateUser } from '../store/rootReducer';

export default function BoothList(props: BoothListProps) {
    const { boothList, category, justDisplay } = props;
    const onlineList = props.onlineList ?? [];

    const { t, i18n } = useTranslation();
    const matches = useMediaQuery('(min-width:768px)');
  /*  const { data, isLoading } = useGetAllVoteGetVoteRecordQuery();
    React.useEffect(() => {
        console.log(data)
    }, [data]);*/
    //類型篩選
    React.useEffect(() => {
        if (category != null) {
            setSearchCategory(category)
        }
    }, [category]);
    const [searchCategory, setSearchCategory] = React.useState<number>(category ?? -1);
    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCategory(Number(event.target.value));
    };
    function categorySearch(data: Booth): boolean {
        let isMatch = false;
        if (searchCategory == -1) isMatch = (data.type === BoothType.一般攤位 || data.type === BoothType.服務台);
        else if (searchCategory == 10) isMatch = (data.type === BoothType.廣告贊助 || data.type === BoothType.服務台);
        else if (data.category&&data.category.split(',').includes(gameGenreList[searchCategory].value)) isMatch = true;
        return isMatch;
    }

    //搜尋文字
    const [searchString, setSearchString] = React.useState<string>('');
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    function textSearch(data: Booth): boolean {
        let isMatch = false;
        if (data.chName && data.chName.includes(searchString)) isMatch = true;
        if (data.enName && data.enName.toLowerCase().includes(searchString.toLowerCase())) isMatch = true;
        if (data.abbrev && data.abbrev.includes(searchString)) isMatch = true;
        if (data.boothNo && data.boothNo.includes(searchString)) isMatch = true;

        return isMatch;
    }

    //顯示數量
    const [displayMore, setDisplayMore] = React.useState<number>(3);
    function handleMoreCard() {
        setDisplayMore(displayMore + 6);
    }

    //❤️按鈕
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeBookMark] = useChangeBookMarkMutation();
    function handleClickFavorite(data: Booth) {
        changeBookMark({ boothId: data.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    return (
        <Container>
            {/*!justDisplay &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    flexDirection: matches ? 'unset' : 'column-reverse',
                }}>
                 

                    <TextField
                        variant='outlined'
                        margin="dense"
                        value={searchString}
                        onChange={handleSearchChange}
                        placeholder={t('boothList.search.placeholder')}
                        style={{
                            padding: 12,
                            width: '100%',
                            minWidth: 200,
                            maxWidth: 320,
                            //height: '100%',
                            margin: 0,
                        }}
                    />
                </div>
          */  }
            <Box display="grid" gap={{ xs: 2, md: 2 }} gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }}>
                {boothList.filter(x => categorySearch(x)).filter(x => textSearch(x)).sort((a, b) => Number(a.boothNo) - Number(b.boothNo)).sort((a, b) => b.type == BoothType.服務台 ? 1:b.votes - a.votes).map((booth, index) => (
                    <Box key={booth.boothId}>
                        <BoothCard
                            data={booth}
                            votes={booth.votes}
                            isOnline={onlineList.find(x => x.boothId === booth.boothId)?.isOnline || booth?.isOnline}
                            simpleMode
                            onClickFollow={handleClickFavorite}
                        />
                    </Box>
                ))}
            </Box>
            {/*!matches &&
                (displayMore >= boothList.length ||
                    <div style={{ display: 'flex', padding: '20px 10px', justifyContent: 'center' }}>
                        <Button
                            onClick={() => handleMoreCard()}
                            sx={{
                                textTransform: 'none',
                                width: '100%',
                                outline: 'black 2px solid',
                                '&:focus': {
                                    outline: 'black 2px solid',
                                },
                            }}
                        >
                            <Typography variant='h6'>
                                {t('boothList.more')}
                            </Typography>
                        </Button>
                    </div>
                )
           */ }
        </Container>
    )
};

interface BoothListProps {
    boothList: BoothCardInfoModel[];
    onlineList?: BoothPresence[];
    category?: number;
    justDisplay?: boolean;
}
