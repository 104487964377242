import * as React from 'react';
import { Button, Box } from '@mui/material';
import { CardInfo, UserRole, userRoleList } from '../../../components/class';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, GridColDef, GridEnrichedColDef, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { zhTWGrid } from '../../data-grid-locale';
import { format } from 'date-fns';
import { useUser } from '../../../store';
import { useGetUserListQuery, UserCardInfoModel, UserDetailData } from '../../../components/services/user';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { Article } from '@mui/icons-material';
import { UserCardDialog } from '../../../components/BussinessCard/UserCardDialog';
import { useDispatchWithType } from '../../../store';
import { setScrollTrigger } from '../../../store/rootReducer';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function UserList() {
    const user = useUser();
    const { t } = useTranslation();

    const [currentRole, setCurrentRole] = React.useState<UserRole>(UserRole.normal);
    const { data: userList, isLoading } = useGetUserListQuery(currentRole);

    function handleClickRole(clickRole: UserRole) {
        setCurrentRole(clickRole);
    }

    //開啟名片
    const [open, setOpen] = React.useState(false);
    const [currentCardData, setCurrentCardData] = React.useState<CardInfo>(null);
    function handleOpenCard(cardInfo: CardInfo) {
        setCurrentCardData(cardInfo);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };

    //載入完成滾動
    const dispatch = useDispatchWithType();
    React.useEffect(() => { dispatch(setScrollTrigger(true)) }, []);

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.userList.title')}
            subText={t('manage.userList.subTitle')}
        />
        <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={1} mb={2}>
            {userRoleList.filter(x => x.value != UserRole.anonymous).map(role => (
                <Button key={role.value}
                    variant="outlined"
                    onClick={() => handleClickRole(role.value)}
                    disabled={role.value === currentRole}
                >
                    {t(role.nameKey)}
                </Button>
            ))}
        </Box>
        <UserListInRole
            dataList={userList?.data ?? []}
            loading={isLoading}
            handleOpenCard={handleOpenCard}
            isEditable={user.role == UserRole.superuser}
        />
        <UserCardDialog
            data={{ ...currentCardData } as UserCardInfoModel}
            open={open}
            onClose={handleClose}
        />
    </>)
}

interface GridDataRow extends UserDetailData { }
interface UserListInRoleProps {
    dataList: GridDataRow[];
    handleOpenCard: (cardInfo: CardInfo) => void;
    loading?: boolean;
    isEditable?: boolean;
}

function UserListInRole(props: UserListInRoleProps) {
    const { dataList, loading, handleOpenCard, isEditable } = props;
    const { t, i18n } = useTranslation();

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'account',
            headerName: t('manage.userList.options.account'),
            flex: 1,
            minWidth: 280,
        },
        {
            field: 'name',
            headerName: t('manage.userList.options.name'),
            flex: 0.8,
            minWidth: 150,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) => (cardInfo?.name || ""),
        },
        {
            field: 'school_organization',
            headerName: t('manage.userList.options.school_organization'),
            flex: 1,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) =>
                cardInfo?.infoFields.find(x => x.fieldId === "school/organization"|| x.fieldId === "school" || x.fieldId === "organization" )?.value,
        },
        {
            field: 'department_jobTitle',
            headerName: t('manage.userList.options.department_jobTitle'),
            flex: 1,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) =>
                cardInfo?.infoFields.find(x => x.fieldId === "jobTitle"|| x.fieldId === "department"  )?.value,
        },
        {
            field: 'createTime',
            headerName: t('manage.userList.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm:ss"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: ({ row: { cardInfo } }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem icon={<Article />} onClick={() => handleOpenCard(cardInfo)} label={t("manage.userList.options.actions.info")} showInMenu />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid rows={dataList} columns={columns}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
                loading={loading}
            />
        </ThemeProvider>
    )
}