import * as React from 'react';
import { Box, CircularProgress, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BoothArticle } from '../../class'
import { useDeleteArticleMutation, useLazyGetAllArticlesQuery, useLazyGetArticlesByBoothQuery, usePostArticleMutation, usePutArticleMutation } from '../../services/article';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { BorderButton } from '../../responsive-components';
import BoothArticleEditDialog from './BoothArticleEditDialog';
import { cloneDeep } from 'lodash';
import BoothArticleTable, { BoothArticleTableData } from './BoothArticleTable';
import { BoothArticleDialog } from '../BoothArticlePage';
import { logEvent } from '../../Analytics';

export default function BoothArticleList(props: BoothArticleListProps) {
    const { isManage, boothId, canClick, canEdit, canDelete, showPin } = props;

    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));

    //初始化資料
    const [getAllArticle, { data: articles, isLoading: isLoadingArticles, isSuccess: isSuccessArticles }] = useLazyGetAllArticlesQuery();
    const [getBoothAllArticle, { data: boothArticles, isLoading: isLoadingBoothArticles, isSuccess: isSuccessBoothArticles }] = useLazyGetArticlesByBoothQuery();
    const [articleDict, setArticleDict] = React.useState<Record<string, BoothArticle>>({});
    React.useEffect(() => {
        if (boothId) {
            getBoothAllArticle(boothId);
        }
        else {
            getAllArticle();
        }
    }, [boothId])
    React.useEffect(() => {
        if (articles && articles.data) {
            //轉型成record
            let dict = articles.data.reduce((acc, item) => (acc[item["dataId"]] = item, acc), {} as Record<string, BoothArticle>);
            setArticleDict(dict);
        }
        if (boothArticles && boothArticles.data) {
            //轉型成record
            let dict = boothArticles.data.reduce((acc, item) => (acc[item["dataId"]] = item, acc), {} as Record<string, BoothArticle>);
            setArticleDict(dict);
        }
    }, [articles, boothArticles])

    //顯示視窗
    const [currentDisplayData, setCurrentDisplayData] = React.useState<BoothArticle>(null);
    const [openDisplay, setOpenDisplay] = React.useState(false);
    const handleOpenDisplay = (id?: string) => {
        if (id) {
            setCurrentDisplayData(articleDict[id]);
            logEvent('Booth', `${boothId ?? 'Admin'}: Article`, `${articleDict[id]?.title}`);
        }
        else {
            setCurrentDisplayData(null);
        }
        setOpenDisplay(true);
    };
    const handleCloseDisplay = () => {
        setOpenDisplay(false);
    };

    //新增&編輯視窗
    const [currentEditData, setCurrentEditData] = React.useState<BoothArticle>(null);
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = (id?: string) => {
        if (id) {
            setCurrentEditData(articleDict[id]);
        }
        else {
            setCurrentEditData(null);
        }
        setOpenEdit(true);
    };
    const handleCloseEdit = (value?: BoothArticle) => {
        if (value) {
            if (value.dataId != '') {
                PutBoothArticle(value);
            }
            else {
                PostBoothArticle(value);
            }
        }
        else {
            setOpenEdit(false);
        }
    };
    //新增&編輯
    const [postArticle, { isLoading: isPostArticleLoading }] = usePostArticleMutation();
    const [putArticle, { isLoading: isPutArticleLoading }] = usePutArticleMutation();
    function PostBoothArticle(value: BoothArticle) {
        postArticle(value).unwrap().then((result) => {
            if (result.isSuccess) {
                UpdateArticleList(result.data);
                setOpenEdit(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    function PutBoothArticle(value: BoothArticle) {
        putArticle(value).unwrap().then((result) => {
            if (result.isSuccess) {
                UpdateArticleList(value);
                setOpenEdit(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    function UpdateArticleList(value: BoothArticle) {
        setArticleDict(articleDict => {
            articleDict[value.dataId] = value;
            return articleDict;
        });
    }

    //刪除按鈕
    const handleClickDelete = (id: string) => {
        let article = articleDict[id];
        if (article !== null) {
            DeleteBoothArticle(article);
        }
    };
    //刪除
    const [deleteArticle, { isLoading: isDeleteArticleLoading }] = useDeleteArticleMutation();
    function DeleteBoothArticle(value: BoothArticle) {
        deleteArticle(value).unwrap().then((result) => {
            if (result.isSuccess) {
                DeleteArticleList(value);
                setOpenEdit(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    function DeleteArticleList(value: BoothArticle) {
        const filteredList: Record<string, BoothArticle> = {}
        for (let key in articleDict) {
            if (key !== value.dataId) {
                filteredList[key] = articleDict[key]
            }
        }
        setArticleDict(filteredList)
    }

    function getArticles() {
        let list: BoothArticleTableData[] = [];
        for (let key in articleDict) {
            let item = articleDict[key];
            list.push({
                id: key,
                boothId: item.boothId,
                boothChName: item.chName,
                boothEnName: item.enName,
                name: item.title,
                time: new Date(item.createTime),
                pin: item.isPin
            });
        }
        list.sort(function (a, b) {
            if (a.pin != b.pin) {
                if (a.pin) return -1;
                else return 1;
            }
            return a.time >= b.time ? -1 : 1;
        });
        return list;
    }

    if (isSuccessArticles || isSuccessBoothArticles) {
        return (<>
            {isManage &&
                <Box
                    style={{
                        overflow: 'auto',
                        padding: '12px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography fontWeight='bold' sx={{ color: (theme) => theme.palette.primary.main }}>
                        {t('boothArticle.table.title')}
                    </Typography>
                    <BorderButton onClick={() => handleOpenEdit()}>
                        <Add fontSize='large' />
                        {t('boothArticle.table.button')}
                    </BorderButton>
                </Box>
            }
            <Paper style={{ padding: tabletMatches ? '20px 40px' : '16px 10px' }}>
                <BoothArticleTable
                    isManage={isManage}
                    rows={getArticles()}
                    onOpen={(canClick !== false) && handleOpenDisplay}
                    onEdit={(isManage && canEdit !== false) && handleOpenEdit}
                    onDelete={(isManage && canDelete !== false) && handleClickDelete}
                    showPin={showPin}
                />
            </Paper>
            {isManage &&
                <BoothArticleEditDialog
                    boothId={boothId}
                    data={currentEditData}
                    open={openEdit}
                    onClose={handleCloseEdit}
                    isLoading={isPostArticleLoading || isPutArticleLoading}
                />
            }
            {(canClick !== false) &&
                <BoothArticleDialog
                    data={currentDisplayData}
                    matches={tabletMatches}
                    open={openDisplay}
                    onClose={handleCloseDisplay}
                />
            }
        </>)
    } else if (isLoadingArticles || isLoadingBoothArticles) {
        return (
            <Container>
                <Grid container justifyContent="center">
                    <CircularProgress style={{ marginTop: '2rem' }} />
                </Grid>
            </Container>
        )
    }
    else {
        return (
            <Container>
                <Grid container justifyContent="center">
                    {'Error'}
                </Grid>
            </Container>
        )
    }
}
interface BoothArticleListProps {
    boothId?: string;
    isManage?: boolean;
    canClick?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    showPin?: boolean;
}