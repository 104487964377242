import * as React from 'react';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../responsive-components';
import { Backdrop, Box, Checkbox, CircularProgress, Dialog as HintDialog, DialogActions as HintDialogActions, FormControlLabel, FormHelperText, Grid, Stack, styled, TextField as OriginField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { hideModal } from '../../store/rootReducer';
import { useDispatchWithType } from '../../store';
import { DoNotDisturbOnOutlined, RoomService } from '@mui/icons-material';
import { usePostBellCallMutation } from '../services/booth';
import { add, format, formatDistanceStrict } from 'date-fns';
import enUS from "date-fns/locale/en-US";
import zhTW from "date-fns/locale/zh-TW";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: 0,
        background: "rgba(0, 0, 0, 0.04)",
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

type formData = {
    option: optionType;
    message: string;
}
export enum optionType {
    想與企業人員文字聯絡,
    告知企業人員我在視訊聊天室,
    其他
}
export interface ServiceBellModalProps {
    boothId: string;
    receiverId?: string;
}
export function ServiceBellModal(props: ServiceBellModalProps) {
    const { boothId, receiverId } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatchWithType();

    const [open, setOpen] = React.useState(true);

    const schema = yup.object().shape({
        message: yup.string().max(50, t("dialog.exchange.error_limit"))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, control } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            option: optionType.想與企業人員文字聯絡,
            message: '',
        }
    });
    const [postBellCall, { isLoading }] = usePostBellCallMutation();
    function onSubmit(data: formData) {
        postBellCall({ boothId: boothId, receiverId: receiverId, option: data.option, message: data.message }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    setOpen(false);
                } else if (result.data != null) {
                    setOpenTooFast(true);
                    var locale = { locale: i18n.language === "ch" ? zhTW : enUS };
                    var baseDate = new Date();
                    var date = new Date(baseDate.valueOf() + result.data);
                    setLastTime(format(add(date, { minutes: -10 }), "p", locale));
                    setRemainTime(formatDistanceStrict(date, baseDate, locale));
                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
            });
    }
    //優雅的關閉
    function onClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const [openAsk, setOpenAsk] = React.useState(false);
    const handleCancel = () => {
        setOpenAsk(true);
    };

    const [openTooFast, setOpenTooFast] = React.useState(false);
    const [lastTime, setLastTime] = React.useState<string>();
    const [remainTime, setRemainTime] = React.useState<string>();
    const handleCloseTooFast = () => {
        setOpenTooFast(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleCancel} maxWidth={"sm"} fullWidth sx={{ px: 2, py: 1 }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <RoomService sx={{ color: '#FBA017', fontSize: "1.375rem", mr: 2 }} />
                        <Typography variant="h6" fontWeight="bold">{t("dialog.serviceBell.title")}</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                    <DialogContent sx={{ py: 0 }}>
                        <Stack>
                            <Typography>{important}{t("dialog.serviceBell.question")}</Typography>
                            <Controller
                                name="option"
                                control={control}
                                render={({ field }) => (
                                    <StyledToggleButtonGroup
                                        color="primary"
                                        value={field.value}
                                        exclusive
                                        onChange={(event, value) => { if (value !== null) field.onChange(value) }}
                                    >
                                        <ToggleButton value={optionType.想與企業人員文字聯絡}>
                                            {t("dialog.serviceBell.ans_contact")}
                                        </ToggleButton>
                                        <ToggleButton value={optionType.告知企業人員我在視訊聊天室}>
                                            {t("dialog.serviceBell.ans_video")}
                                        </ToggleButton>
                                        <ToggleButton value={optionType.其他}>
                                            {t("dialog.serviceBell.ans_other")}
                                        </ToggleButton>
                                    </StyledToggleButtonGroup>
                                )}
                            />
                            <FormHelperText> </FormHelperText>
                            <Typography>{t("dialog.serviceBell.message")}</Typography>
                            <TextField
                                variant="outlined"
                                inputRef={register('message').ref}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 50 }}
                                placeholder={t("dialog.serviceBell.limitNum")}
                                error={!!errors.message}
                                helperText={errors.message ? errors.message.message : ' '}
                                {...register("message")}
                                disabled={isLoading}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton type="submit" loading={isLoading}>{t('dialog.serviceBell.submit')}</ConfirmButton>
                    </DialogActions>
                </Box>
                <HintDialog open={openAsk}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {t('dialog.serviceBell.discardEdit')}
                        </span>
                    </div>
                    <HintDialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpenAsk(false); onClose(); }}>{t('dialog.serviceBell.yes')}</Button>
                        <Button onClick={() => { setOpenAsk(false); }}>{t('dialog.serviceBell.no')}</Button>
                    </HintDialogActions>
                </HintDialog>
                <Dialog open={openTooFast} onClose={handleCloseTooFast} PaperProps={{ sx: { borderRadius: "15px" } }}>
                    <Stack alignItems="center" spacing={1} sx={{ p: "20px 24px" }}>
                        <Typography variant="h2" color="error.light">
                            <DoNotDisturbOnOutlined sx={{ color: 'inherit', fontSize: "inherit" }} />
                        </Typography>
                        <Typography>
                            <Trans i18nKey="dialog.serviceBell.lastTime" values={{ lastTime: lastTime }} components={[<b></b>]} />
                        </Typography>
                        <Typography>
                            <Trans i18nKey="dialog.serviceBell.countdown" values={{ remainTime: remainTime }} components={[<b></b>]} />
                        </Typography>
                    </Stack>
                    <DialogActions sx={{ pt: 0 }} />
                </Dialog>
            </Dialog>
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}