import { Close, Event, Favorite, FavoriteBorderRounded, Help, List, People, RoomService, SportsEsports, Videocam } from '@mui/icons-material';
import { AppBar, Backdrop, Box, ButtonProps, Container, IconButton, Stack, styled, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { CheckIsMobile } from '../components/Admin/Utils';
import { logEvent } from '../components/Analytics';
import { Button, Dialog, DialogContent } from '../components/responsive-components';
import { useChangeBookMarkMutation } from '../components/services/user';
import { useDispatchWithType, useUser } from '../store';
import { updateUser } from "../store/rootReducer";
import BoardCardTable from './BoardCard/BoardCardTable';
import BoothAppointment from './BoothAppointment';
import BoothOnlineUserList from './BoothOnlineUserList';

interface BoothAction {
    name: string;
    icon: React.ReactNode;
    function: Function;
    buttonProps?: ButtonProps;
}

export default function BoothActions(props: BoothActionsProps) {
    const { boothId, boothNo, handleOpenMedia, handleOpenAppointment, handleRingBell, showOnCall } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const history = useHistory();

    //彈出視窗
    const [display, setDisplay] = React.useState<React.ReactNode>(null);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (node?: React.ReactNode) => {
        setDisplay(node);
        setOpen(true);
    };
    const handleClose = () => {
        setDisplay(null);
        setOpen(false);
    };

    //收藏
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeBookMark] = useChangeBookMarkMutation();
    const handleClickFavorite = () => {
        changeBookMark({ boothId: boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };
    //功能列表
    const actions: BoothAction[] = [
        {
            name: t('booth.button.game'),
            icon: <SportsEsports sx={{ color: (theme) => theme.palette.background.light }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                history.push(`./Game?shop=${boothNo.split("、")[0]}`);

            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.video'),
            icon: <Videocam sx={{ color: (theme) => theme.palette.background.light }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleOpenMedia();
                logEvent('Booth', `${boothId}: SidebarButton`, `MediaRoom`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
       /* {
            name: t('booth.title.appointment'),
            icon: <Event sx={{ color: (theme) => theme.palette.background.light }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: handleOpenAppointment,
        },*/
        {
            name: t('booth.title.online'),
            icon: <People sx={{ color: (theme) => theme.palette.background.light }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleClickOpen(<BoothOnlineUserList boothId={boothId} onClose={handleClose} />);
                logEvent('Booth', `${boothId}: SidebarButton`, `OnlineUser`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.board'),
            icon: <Help sx={{ color: (theme) => theme.palette.background.light }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleClickOpen(<BoardCardTable boothId={boothId} />);
                logEvent('Booth', `${boothId}: SidebarButton`, `BoardCardTable`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.bell'),
            icon: <RoomService sx={{ color: '#FBA017' }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleRingBell();
                logEvent('ServiceBell', `Booth`, `${boothId}`);
                logEvent('Booth', `${boothId}: SidebarButton`, `ServiceBell`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.button.favorite'),
            icon: user?.saveBoothIds.includes(boothId) ?
                <Favorite style={{ color: '#fa6980', fontSize:'1.37rem' }} /> :
                <FavoriteBorderRounded style={{ color: '#545569', fontSize: '1.37rem' }} />,
            function: () => {
                handleClickFavorite();
                logEvent('favorite', `Booth`, `${boothId}`);
                logEvent('Booth', `${boothId}: SidebarButton`, `favorite`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        }
    ];

    return (<>
        {isMobile ?
            <BoothActionSpeedDial actions={actions} boothId={boothId} /> :
            <BoothActionBar actions={actions.filter(x => x.name !=  t('booth.button.favorite'),)} showOnCall={showOnCall} />
        }
        <BoothActionDialog
            open={open}
            onClose={handleClose}
        >
            {display}
        </BoothActionDialog>
    </>)
};
export interface BoothActionsProps {
    boothId: string;
    boothNo: string;
    handleOpenMedia: Function;
    handleOpenAppointment: Function;
    handleRingBell: Function;
    showOnCall?: boolean;
}

const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    borderColor: "white"
    //border: `2px solid ${theme.palette.grey[500]}`,
}));
function BoothActionBar(props: BoothActionBarProps) {
    const { actions, showOnCall } = props;
    const { t } = useTranslation();

    return (
        <Box position="relative">
            <Stack direction='row' justifyContent='space-between'>
                {actions.filter(x => x.name == t('booth.button.game')).map((action) => (
                    <Box key={action.name}>
                        <ActionButton startIcon={action.icon} onClick={() => { action.function(); }} {...action.buttonProps}>
                            <Typography variant="h5" component="p" fontWeight="bold">
                                {action.name}
                            </Typography>
                        </ActionButton>
                    </Box>
                ))}
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                //style={{ position: 'absolute', zIndex: 5, top: '500px', right: 100 }}
                >
                    {actions.filter(x => x.name != t('booth.button.game')).map((action) => (
                        <Box key={action.name}>
                            <ActionButton startIcon={action.icon} onClick={() => { action.function(); }} {...action.buttonProps}>
                                <Typography variant="h5" component="p" fontWeight="bold">
                                    {action.name}
                                </Typography>
                            </ActionButton>
                        </Box>
                    ))}
                </Stack>
            </Stack>
            {showOnCall &&
                <Box position="absolute" right={0} top="-16px" bgcolor="red" color="white" borderRadius={1.5} px={1} py={0.25}>
                    <Typography variant='body2'>{t("booth.title.onCall")}</Typography>
                </Box>
            }
        </Box>
    )
};
interface BoothActionBarProps {
    actions: BoothAction[];
    showOnCall?: boolean;
}

function BoothActionSpeedDial(props: BoothActionSpeedDialProps) {
    const { actions, boothId } = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let hash = useLocation().hash;
    const { t } = useTranslation();

    return (<>
        <AppBar position='relative' sx={{ bgcolor: '#fff' }} >
            <Toolbar
                sx={{
                    justifyContent: 'center',
                    "& button:first-child": {
                        position: "absolute",
                        left: "16px",
                        border: "1px solid #0060CE"
                    },
                    "& button:last-child": {
                        position: "absolute", right: "16px"
                    }
                }} >
                {actions.map((action) => (
                    <IconButton
                        color="inherit"
                        aria-label={action.name}
                        onClick={() => { action.function() }}
                        sx={{
                            padding: "6px 11px",
                            borderRadius: "10px",
                            filter: "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.161))",
                            border: "1px solid #D6D6D6", margin: '0px 2px',
                            backgroundColor: action.name == t('booth.title.video') && hash === "#media" ? '#0060CE' : '#fff',
                            "& svg": { color: action.name == t('booth.title.video') && hash == "#media" ? '#fff' : "0096FA" },
                            "&:hover": { backgroundColor:action.name == t('booth.title.video') && hash === "#media" ? '#0060CE' : '#fff' },
                        }}
                    >
                        {action.icon}
                    </IconButton>
                ))}
            </Toolbar>
        </AppBar>
        <Backdrop open={open} style={{ zIndex: 500 }} />
    </>)
};
interface BoothActionSpeedDialProps {
    actions: BoothAction[];
    boothId: string;
}

function BoothActionDialog(props: BoothActionDialogProps) {
    const { onClose, open, children } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            //fullScreen={!matches}
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
            open={open}
            PaperProps={{ sx: { overflow: 'inherit', height: '100%' } }}
        >
            <Button
                variant='contained'
                onClick={handleClose}
                sx={{
                    position: 'absolute', right: -28, top: -28, padding: '2px 2px', minWidth: 'unset',
                    color: 'inherit',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#f1f1f1',
                    },
                }}
            >
                <Close />
            </Button>
            {children}
        </Dialog>
    );
}
interface BoothActionDialogProps {
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
}