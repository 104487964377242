import { Box, Skeleton } from "@mui/material";
import * as React from 'react';

export const useProgressiveImage = (src: string | undefined) => {
    const [sourceLoaded, setSourceLoaded] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (src) {
            const img = new Image();
            img.src = src;
            img.onload = () => setSourceLoaded(src);
        }
    }, [src])

    return sourceLoaded
}

export const LazyImg = (props: React.ImgHTMLAttributes<HTMLImageElement> & React.Attributes) => {
    const { key, src, width, height, ...other } = props;
    const loaded = useProgressiveImage(src);

    if (loaded)
        return (
            <Box key={key} width={width} height={height}>
                <img src={loaded} width="100%" {...other} />
            </Box>
        );
    else
        return <Skeleton key={key} variant="rectangular" width={width} height={height} />;
}