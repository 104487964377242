import { Box, Button, IconButton, Paper, Skeleton, styled, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { useGetVideoQuery, useUpdateVideoMutation } from '../../components/services/booth';
import { Edit } from '@mui/icons-material';
import ReactPlayer from 'react-player';

const prefix = "manage.boothTool.";
export function VideoManage(props: { boothId: string }) {
    const { boothId } = props;
    const { t } = useTranslation();

    const { data: video, isLoading, refetch } = useGetVideoQuery(boothId, { refetchOnMountOrArgChange: true });
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<string>();
    React.useEffect(() => {
        if (video && video.data) {
            setEditValue(video.data);
        }
    }, [video])
    function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setEditValue(event.target.value);
    }

    const [update] = useUpdateVideoMutation();
    const [loading, setLoading] = React.useState<boolean>(false);
    async function handleSave() {
        try {
            setLoading(true);
            setIsEdit(false);
            var result = await update({ boothId: boothId, value: editValue });
            refetch();
        }
        catch {
            console.error("發生錯誤");
        }
        finally {
            setLoading(false);
        }
    }
    function handleDiscard() {
        setEditValue(video?.data ?? "");
        setIsEdit(false);
    }

    const width: number = 720, height: number = 405;
    return (<>
        <SubTitle
            icon='circle'
            text={t(prefix + "promotionalVideo")}
        />
        {isLoading || loading ? <>
            <Skeleton variant="rectangular" animation="wave" width={width} height={height} />
        </> : isEdit ? <>
            <TextField
                size="small"
                label={t(prefix + "video.link")}
                fullWidth
                value={editValue}
                onChange={handleChange}
            />
            <Box display="flex" gap={1} mt={1.5}>
                <Button variant="contained" onClick={handleSave}>{t(prefix + "button.save")}</Button>
                <Button onClick={handleDiscard}>{t(prefix + "button.discard")}</Button>
            </Box>
        </> : <>
            <IconButton onClick={() => setIsEdit(true)}><Edit /></IconButton>
            {(!editValue || editValue === "") ?
                <Box bgcolor="grey.200" width={width} height={height} display="flex" justifyContent="center" alignItems="center">
                    {t(prefix + "video.empty")}
                </Box>
                : ReactPlayer.canPlay(editValue) ?
                    <ReactPlayer
                        url={editValue}
                        width={width}
                        height={height}
                        controls
                    />
                    : <Box bgcolor="grey.200" width={width} height={height} display="flex" justifyContent="center" alignItems="center">
                        {t(prefix + "video.playError")}
                    </Box>
            }
        </>}
    </>)
};