import ReactGA from 'react-ga4'
import { gaTrackingId } from "../config";

export const initGA = () => {
    //每個專案都記得要改 measurement id
    ReactGA.initialize(gaTrackingId);
    /*ReactGA.initialize([
        {
            trackingId: "G-HQVLQ8GC5R",
            gaOptions: { testMode: true }, // optional
            gtagOptions: { }, // optional
        }
    ]);*/
}

export const setUserIdGA = (userId: string) => {
    ReactGA.set({ userId: userId });
}

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

export const logEvent = (category = '', action = '', label = '') => {
    ReactGA.event({ category: category, action: action, label: label });
}

export const logException = (description = '', fatal = false) => {
    if (description) {
        //ReactGA.exception({ description, fatal });
    }
}