import * as React from 'react';
import Game from './Game';
import joystick from './images/joystick.png';
import keyC from './images/KeyBoardC.png';
import keyE from './images/keyE.png';
import keyMove from './images/keyMove.png';
import keyEnter from './images/keyEnter.png';
import { useUser } from '../store';
import { CheckIsMobile } from '../components/Admin/Utils';
import { Box, Container, Stack, Toolbar, Typography } from '@mui/material';
import { SubTitle } from '../components/responsive-components';
import { useTranslation } from 'react-i18next';
import { imageFolder } from '../config';

const Map = imageFolder + "Map.png";

export default function GameDisplay() {
    const user = useUser();
    const { t } = useTranslation();

    const keyItems = [{
        src: CheckIsMobile() ? joystick : keyMove,
        text: "移動",
        imgWidth: CheckIsMobile() ? 100 : 140,
        textWidth: 4 * 16 * 1.5 //字數 * 單一em寬 * ?em
    }, {
        src: keyE,
        text: "互動",
        imgWidth: 60,
        textWidth: 2 * 16 * 1.5
    }, {
        src: keyC,
        text: "聊天室",
        imgWidth: 60,
        textWidth: 3 * 16 * 1.5
    }, {
        src: keyEnter,
        text: "聊天",
        imgWidth: 100,
        textWidth: 2 * 16 * 1.5
    }];
    const maxImgWidth = Math.max(...keyItems.map(i => i.imgWidth));
    const maxTextWidth = Math.max(...keyItems.map(i => i.textWidth));
   
    return (
        <>
            {user && <>
                <Game />
                <Box p={2 }>
                    <Typography variant="body1" color="gray" textAlign="center" >音效來源： TAM Music Factory（https://www.tam-music.com/）</Typography>
                    {/*<Typography variant="body1" color="gray" textAlign="center" > Otologic（https://otologic.jp/）</Typography>
                    <Typography variant="body1" color="gray" textAlign="center" > Presence of Music（http://www.presence-of-music.com/）</Typography>*/}
                </Box>
            </>
            }
            {/*<Container style={{ marginTop: '22px' }}>
                <SubTitle icon='circle' text={t('game.map')} />
                <img src={Map}
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '100%',
                    }}
                />
                <Toolbar />
            </Container>*/}
        </>
    )
}