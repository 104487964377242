import * as React from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Link, MenuItem, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { UserRole } from '../../../components/class';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PlayCircleFilled } from '@mui/icons-material';
import { read, utils, writeFile } from 'xlsx';
import { useGetAllBoothNamesQuery } from '../../../components/services/booth';
import { ImportUserModel, usePostNewUsersMutation } from '../../../components/services/user';
import { important, TextField } from '../../../components/responsive-components';
import cryptoRandomString from 'crypto-random-string';

interface editState {
    AcctRandomLength: number;
    AcctPrefix: string;
    AcctSuffix: string;
    PwRandomLength: number;
    PwPrefix: string;
    PwSuffix: string;
    level: number;
    boothId: string;
    generateNum: number;
}
interface ImportData {
    account: string;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
    boothName: string;
    isStudent: boolean;
    isAdmin: boolean;
}
interface ExportData extends ImportData { };

export default function NewUserList(props) {
    const { boothList = [] } = useGetAllBoothNamesQuery(null, {
        selectFromResult: ({ data }) => ({ boothList: data?.data })
    });
    const [fileAccept, setFileAccept] = React.useState('.csv');
    const handleFileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileAccept((event.target as HTMLInputElement).value);
        setUploadErrorMessage(null);
    };

    const [randomCharset_Acct, setRandomCharset_Acct] = React.useState('alphanumeric');
    const [randomCharset_Pw, setRandomCharset_Pw] = React.useState('alphanumeric');
    const handleAccountCharsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRandomCharset_Acct((event.target as HTMLInputElement).value);
    };
    const handlePasswordCharsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRandomCharset_Pw((event.target as HTMLInputElement).value);
    };
    const schema = yup.object().shape({
        AcctRandomLength: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
        AcctPrefix: yup.string().when(['AcctRandomLength', 'AcctSuffix'], {
            is: (AcctRandomLength, AcctSuffix) => (randomCharset_Acct === "" || AcctRandomLength === 0) && !AcctSuffix,
            then: yup.string().required('At least one 使用者帳號 field is required.'),
        }),
        AcctSuffix: yup.string().when(['AcctRandomLength', 'AcctPrefix'], {
            is: (AcctRandomLength, AcctPrefix) => (randomCharset_Acct === "" || AcctRandomLength === 0) && !AcctPrefix,
            then: yup.string().required('At least one 使用者帳號 field is required.'),
        }),
        PwRandomLength: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
        PwPrefix: yup.string().when(['PwRandomLength', 'PwSuffix'], {
            is: (PwRandomLength, PwSuffix) => (randomCharset_Pw === "" || PwRandomLength === 0) && !PwSuffix,
            then: yup.string().required('At least one 密碼 field is required.'),
        }),
        PwSuffix: yup.string().when(['PwRandomLength', 'PwPrefix'], {
            is: (PwRandomLength, PwPrefix) => (randomCharset_Pw === "" || PwRandomLength === 0) && !PwPrefix,
            then: yup.string().required('At least one 密碼 field is required.'),
        }),
        level: yup.number(),
        boothId: yup.string(),
        generateNum: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
    }, [['AcctPrefix', 'AcctSuffix'], ['AcctRandomLength', 'AcctSuffix'], ['AcctRandomLength', 'AcctPrefix'], ['PwPrefix', 'PwSuffix'], ['PwRandomLength', 'PwSuffix'], ['PwRandomLength', 'PwPrefix']]);
    const { register, handleSubmit, formState: { errors }, watch } = useForm<editState>({
        mode: "onBlur", resolver: yupResolver(schema), defaultValues: {
            AcctRandomLength: 6,
            AcctPrefix: "",
            AcctSuffix: "",
            PwRandomLength: 8,
            PwPrefix: "",
            PwSuffix: "",
            level: UserRole.normal,
            boothId: "",
            generateNum: 0
        }
    });
    const boothId = watch("boothId");

    const [excelData, setExcelData] = React.useState<ImportData[]>([]);
    const [uploadErrorMessage, setUploadErrorMessage] = React.useState<string>();
    const onImport = (file) => {
        setUploadErrorMessage(null);
        //讀檔
        if (fileAccept === ".csv") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                setExcelData([]);
                setUploadErrorMessage("上傳失敗");
            })
        } else if (fileAccept === ".xlsx, .xls") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                setExcelData([]);
                setUploadErrorMessage("上傳失敗");
            })
        }
    }

    const [postNewUsers] = usePostNewUsersMutation();
    const onSubmit = async (data: editState) => {
        setLoading(true);
        if (excelData.length == 0 && fileAccept != "other") {
            setUploadErrorMessage("尚未上傳檔案");
            setLoading(false);
            return;
        }
        let newUsers: ImportUserModel[] = [];
        if (excelData.length == 0 || fileAccept == "other") {
            for (let i = 0; i < data.generateNum; i++) {
                let newUser: ImportUserModel = {
                    account: data.AcctPrefix +
                        (randomCharset_Acct === "" ? "" :
                            cryptoRandomString({
                                length: data.AcctRandomLength,
                                type: randomCharset_Acct as 'alphanumeric' | 'numeric' | 'hex'
                            })
                        ) +
                        data.AcctSuffix,
                    password: data.PwPrefix +
                        (randomCharset_Pw === "" ? "" :
                            cryptoRandomString({
                                length: data.PwRandomLength,
                                type: randomCharset_Pw as 'alphanumeric' | 'numeric' | 'hex'
                            })
                        ) +
                        data.AcctSuffix,
                    firstName: null,
                    lastName: null,
                    email: null,
                    boothId: (data.boothId != "") ? data.boothId : null,
                    isStudent: false,
                    isAdmin: data.level >= UserRole.administrator ? true : false
                };
                newUsers.push(newUser);
            }
            await postNewUsers(newUsers);
        }
        else {
            for (let i = 0; i < excelData.length; i++) {
                let boothId = null;
                if (excelData[i].boothName && excelData[i].boothName.trim() != "") {
                    let booth = boothList.find(x => x.chName == excelData[i].boothName);
                    if (booth) {
                        boothId = booth.boothId;
                    }
                    else {
                        console.error(`第${i}筆：新增 ${excelData[i].account} 失敗`);
                        continue;
                    }
                }
                const newUser: ImportUserModel = {
                    account: (excelData[i].account == null || excelData[i].account == "") ?
                        data.AcctPrefix +
                        (randomCharset_Acct === "" ? "" :
                            cryptoRandomString({
                                length: data.AcctRandomLength,
                                type: randomCharset_Acct as 'alphanumeric' | 'numeric' | 'hex'
                            })
                        ) +
                        data.AcctSuffix
                        : excelData[i].account,
                    password: (excelData[i].password == null || excelData[i].password == "") ?
                        data.PwPrefix +
                        (randomCharset_Pw === "" ? "" :
                            cryptoRandomString({
                                length: data.PwRandomLength,
                                type: randomCharset_Pw as 'alphanumeric' | 'numeric' | 'hex'
                            })) +
                        data.AcctSuffix
                        : excelData[i].password,
                    firstName: excelData[i].firstName === "" ? null : excelData[i].firstName,
                    lastName: excelData[i].lastName === "" ? null : excelData[i].lastName,
                    email: excelData[i].email === "" ? null : excelData[i].email,
                    boothId: boothId === "" ? null : boothId,
                    isStudent: excelData[i].isStudent,
                    isAdmin: excelData[i].isAdmin
                };
                newUsers.push(newUser);
            }
            await postNewUsers(newUsers);
        }
        setGenerateList(newUsers);
        setLoading(false);
    };
    const [generateList, setGenerateList] = React.useState<ImportUserModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    const onExport = () => {
        let exportData: ExportData[] = [];
        for (let user of generateList) {
            let booth = boothList.find(x => x.boothId == user.boothId);
            if (booth) {
                exportData.push({
                    account: user.account,
                    password: user.password,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    boothName: booth.chName,
                    isStudent: user.isStudent,
                    isAdmin: user.isAdmin
                });
            }
        }
        Export(exportData);
    }
    return (
        <div>
            <Typography variant='h6' style={{
                padding: 10,
                borderRadius: "6px 6px 0px 0px",
                fontWeight: 'bold',
                color: 'white',
                background: 'linear-gradient(to right, #368AFC, #7DDCFF)',
            }}>
                建立新使用者
            </Typography>
            <Typography ml={3}>
                使用匯入功能時，請先確保header欄位名稱包含以下：
            </Typography>
            <Typography ml={3} fontWeight="bold">
                account, password, firstName, lastName, email, boothName, isStudent, isAdmin
            </Typography>
            <Link ml={3} href="./files/Addnewaccount.xlsx" underline="hover" target="_blank">下載：範例樣本</Link>
            <Typography ml={3} pt={2} color="error.light">
                重要：新增使用者時，帳號、密碼為必填欄位；如匯入時沒有填入，將使用以下亂數設定自動填入<br/>
                P.S. 這裡只能生成非學生名片的使用者，無法新增學生使用者
            </Typography>
            <Box component="form" sx={{ '& .MuiTextField-root': { minWidth: 320, width: '50%' } }} onSubmit={handleSubmit(onSubmit)}>
                <Stack alignItems="start" spacing={1} p={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">上傳檔案格式</FormLabel>
                        <RadioGroup row value={fileAccept} onChange={handleFileTypeChange}>
                            <FormControlLabel value=".csv" control={<Radio />} label=".csv" />
                            <FormControlLabel value=".xlsx, .xls" control={<Radio />} label=".xlsx, .xls" />
                            <FormControlLabel value="other" control={<Radio />} label="不使用匯入功能" />

                        </RadioGroup>
                    </FormControl>
                    {fileAccept != "other" && <>
                        <input type='file' accept={fileAccept} onChange={onImport} />
                        <Typography color="error.light">{uploadErrorMessage ? uploadErrorMessage : " "}</Typography>
                        <br /></>
                    }
                    {fileAccept == "other" && <>
                        <Typography variant='subtitle1'>
                            使用者帳號
                        </Typography>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">亂數組成字元</FormLabel>
                            <RadioGroup row value={randomCharset_Acct} onChange={handleAccountCharsetChange}>
                                <FormControlLabel value="alphanumeric" control={<Radio />} label="[0-9 a-z A-Z]" />
                                <FormControlLabel value="numeric" control={<Radio />} label="[0-9]" />
                                <FormControlLabel value="hex" control={<Radio />} label="[0-9 a-f]" />
                                {/*<FormControlLabel value="" control={<Radio />} label="不使用亂數" />*/}
                            </RadioGroup>
                        </FormControl>
                        <Stack direction="row" spacing={1} sx={{ '& .MuiTextField-root': { minWidth: 200, width: '100%' } }}>
                            <Box>
                                <Typography variant='subtitle1'>亂數長度</Typography>
                                <TextField
                                    inputRef={register('AcctRandomLength').ref}
                                    error={!!errors.AcctRandomLength}
                                    helperText={errors.AcctRandomLength ? errors.AcctRandomLength.message : ' '}
                                    {...register("AcctRandomLength")}
                                    disabled={randomCharset_Acct === ""}
                                />
                            </Box>
                            <Box>
                                <Typography variant='subtitle1'>前綴</Typography>
                                <TextField
                                    inputRef={register('AcctPrefix').ref}
                                    error={!!errors.AcctPrefix}
                                    helperText={errors.AcctPrefix ? errors.AcctPrefix.message : ' '}
                                    {...register("AcctPrefix")}
                                />
                            </Box>
                            <Box>
                                <Typography variant='subtitle1'>後綴</Typography>
                                <TextField
                                    inputRef={register('AcctSuffix').ref}
                                    error={!!errors.AcctSuffix}
                                    helperText={errors.AcctSuffix ? errors.AcctSuffix.message : ' '}
                                    {...register("AcctSuffix")}
                                />
                            </Box>
                        </Stack></>
                    }
                    <Typography variant='subtitle1'>
                        密碼
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">亂數組成字元</FormLabel>
                        <RadioGroup row value={randomCharset_Pw} onChange={handlePasswordCharsetChange}>
                            <FormControlLabel value="alphanumeric" control={<Radio />} label="[0-9 a-z A-Z]" />
                            <FormControlLabel value="numeric" control={<Radio />} label="[0-9]" />
                            <FormControlLabel value="hex" control={<Radio />} label="[0-9 a-f]" />
                            <FormControlLabel value="" control={<Radio />} label="不使用亂數(前綴、後綴至少要填一欄)" />
                        </RadioGroup>
                    </FormControl>
                    <Stack direction="row" spacing={1} sx={{ '& .MuiTextField-root': { minWidth: 200, width: '100%' } }}>
                        <Box>
                            <Typography variant='subtitle1'>亂數長度</Typography>
                            <TextField
                                inputRef={register('PwRandomLength').ref}
                                error={!!errors.PwRandomLength}
                                helperText={errors.PwRandomLength ? errors.PwRandomLength.message : ' '}
                                {...register("PwRandomLength")}
                                disabled={randomCharset_Pw === ""}
                            />
                        </Box>
                        <Box>
                            <Typography variant='subtitle1'>前綴</Typography>
                            <TextField
                                inputRef={register('PwPrefix').ref}
                                error={!!errors.PwPrefix}
                                helperText={errors.PwPrefix ? errors.PwPrefix.message : ' '}
                                {...register("PwPrefix")}
                            />
                        </Box>
                        <Box>
                            <Typography variant='subtitle1'>後綴</Typography>
                            <TextField
                                inputRef={register('PwSuffix').ref}
                                error={!!errors.PwSuffix}
                                helperText={errors.PwSuffix ? errors.PwSuffix.message : ' '}
                                {...register("PwSuffix")}
                            />
                        </Box>
                    </Stack>
                    {fileAccept == "other" &&
                        <>
                            <Typography variant='subtitle1'>
                                權限等級
                            </Typography>
                            <TextField
                                select
                                variant="outlined"
                                inputRef={register('level').ref}
                                defaultValue={UserRole.normal}
                                {...register("level")}
                            >
                                <MenuItem value={UserRole.normal}>一般使用者</MenuItem>
                                <MenuItem value={UserRole.administrator}>管理員</MenuItem>
                                {/*<MenuItem value={UserRole.superuser}>超級管理員</MenuItem>*/}
                            </TextField>
                            <Typography variant='subtitle1'>
                                所屬攤位
                            </Typography>
                            <TextField
                                select
                                variant="outlined"
                                inputRef={register('boothId').ref}
                                value={boothId}
                                {...register("boothId")}
                                SelectProps={{
                                    displayEmpty: true,
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: "400px" }
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    {'無'}
                                </MenuItem>
                                {boothList.map((option) => (
                                    <MenuItem key={option.boothId} value={option.boothId}>
                                        {option.chName}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography variant='subtitle1'>
                                生成人數
                            </Typography>
                            <TextField
                                variant="outlined"
                                inputRef={register('generateNum').ref}
                                error={!!errors.generateNum}
                                helperText={errors.generateNum ? errors.generateNum.message : ' '}
                                {...register("generateNum")}
                            />
                        </>
                    }
                    <br />
                    <div className="d-flex justify-content-center w-100">
                        <LoadingButton size='large' color="primary" variant="contained" type="submit"
                            loading={loading} loadingPosition="start" startIcon={<PlayCircleFilled />}>
                            生成
                        </LoadingButton>
                    </div>
                    {generateList.map((data) => (
                        <div key={data.account}>
                            <Typography paragraph>
                                {`帳號：${data.account} 密碼：${data.password}`}
                            </Typography>
                        </div>
                    ))}
                    {generateList.length > 0 &&
                        <Button size='large' color="primary" variant="contained" onClick={() => onExport()}>
                            匯出Excel檔
                        </Button>
                    }
                </Stack>
            </Box>
        </div>
    )
};

const onImportExcel = file => new Promise<ImportData[]>(function (resolve, reject) {
    // 獲取上傳的檔案物件
    const { files } = file.target;
    // 通過FileReader物件讀取檔案
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            // 以二進位制流方式讀取得到整份excel表格物件
            const workbook = read(result, { type: 'binary' });
            // 儲存獲取到的資料
            let data = [];
            // 遍歷每張工作表進行讀取（這裡預設只讀取第一張表）
            for (const sheet in workbook.Sheets) {
                // esline-disable-next-line
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法將 excel 轉成 json 資料
                    data = data.concat(utils.sheet_to_json(workbook.Sheets[sheet], { defval: '' }));
                    break; // 如果只取第一張表，就取消註釋這行
                }
            }
            // 最終獲取到並且格式化後的 json 資料
            resolve(data);
        } catch (e) {
            // 這裡可以丟擲檔案型別錯誤不正確的相關提示
            reject('檔案型別不正確！');
        }
    };
    // 以二進位制方式開啟檔案
    fileReader.readAsBinaryString(files[0]);
})

function Export(data: ExportData[]) {
    const workbook = utils.book_new();
    const sheet = utils.json_to_sheet(data);

    //appendSheet
    const name = 'sheet_name_1';
    utils.book_append_sheet(workbook, sheet, name)

    //writeFile
    writeFile(workbook, `Export_NewUser_${Date.now()}.xlsx`);
}