import { FormHelperText, MenuItem, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomField } from '.';
import { toRecord } from './elementFactory';

export interface MenuItemType {
	nameKey: string,
	value: string
}
interface SelectProps {
	selectList: MenuItemType[];
	textFieldProps?: TextFieldProps;
	otherFieldValue?: string; //連動自行輸入框的項目值，預設為「其他﹞
	otherFieldProps?: TextFieldProps;
}
export function Select(props: SelectProps) {
	const { selectList, textFieldProps: { value, onChange, error, helperText, ...textFieldProps }, otherFieldValue, otherFieldProps } = props;
	const { t } = useTranslation();

	const other = otherFieldValue ?? "其他";
	const dictionary = toRecord(selectList, "value");
	const [selectItem, setSelectItem] = React.useState<string>("");
	const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectItem(event.target.value as string);
		onChange(event);
	};

	const [editValue, setEditValue] = React.useState<string>("");
	const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditValue(event.target.value as string);
		onChange(event);
	};

	React.useEffect(() => {
		if (value && selectList) {
			let text = String(value);
			if (dictionary[text]) setSelectItem(text);
			else {
				setSelectItem(other);
				setEditValue(text);
			}
		}
	}, [selectList]);

	return (<>
		<CustomField
			select
			SelectProps={{
				renderValue: (selected) => t(dictionary[selected as string].nameKey),
				MenuProps: { sx: { maxHeight: '300px', maxWidth: '100%' } }
			}}
			value={selectItem}
			onChange={handleSelectChange}
			error={error}
			{...textFieldProps}
		>
			{selectList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					<Trans i18nKey={item.nameKey}>
						{item.value}
					</Trans>
				</MenuItem>
			))}
		</CustomField>
		{selectItem === other &&
			<CustomField
				value={editValue}
				onChange={handleTextChange}
				{...otherFieldProps}
				error={error}
			/>
		}
		<FormHelperText
			error={error}
			variant={textFieldProps.variant}
			margin={textFieldProps.margin === "dense" ? "dense" : null}
		>
			{helperText}
		</FormHelperText>
	</>);
}