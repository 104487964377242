import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BoothManageInvitation } from '../components/class';

export default function ExpiredPage(props: ExpiredPageProps) {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div>
            <Stack width={280} spacing={3} justifyContent="stretch" alignItems="stretch">
                <div>
                    <Typography align='center'>
                        {t("invite.haveExpired")}
                    </Typography>
                </div>
                <Button variant='contained' color='primary' onClick={() => history.push("/")}>{t("invite.button.ignore")}</Button>
            </Stack>
        </div >
    );
}
interface ExpiredPageProps {
    code: string;
    data: BoothManageInvitation;
}