import * as React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { Booth, BoothType } from '../../../components/class';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PlayCircleFilled } from '@mui/icons-material';
import { read, utils, writeFile } from 'xlsx';
import { ImportBoothModel, usePostNewBoothsMutation } from '../../../components/services/booth';
import cryptoRandomString from 'crypto-random-string';

interface editState {
    IdRandomLength: number;
    IdPrefix: string;
    IdSuffix: string;
}
interface ImportData {
    boothNo: string;
    category: string;
    chName: string;
    abbrev: string;
    companyNum: string;
    intro: string;
    address: string;
    phone: string;
    website: string;
}
export default function NewBoothList(props) {
    const [fileAccept, setFileAccept] = React.useState('.csv');
    const handleFileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileAccept((event.target as HTMLInputElement).value);
    };

    const [randomCharset_Id, setRandomCharset_Id] = React.useState('alphanumeric');
    const handleIdCharsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRandomCharset_Id((event.target as HTMLInputElement).value);
    };
    const schema = yup.object().shape({
        IdRandomLength: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
        PwRandomLength: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
        generateNum: yup.number().typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<editState>({
        mode: "onBlur", resolver: yupResolver(schema), defaultValues: {
            IdRandomLength: 6,
            IdPrefix: "Booth-",
            IdSuffix: ""
        }
    });
    const [excelData, setExcelData] = React.useState<ImportData[]>([]);
    const onImport = (file) => {
        //讀檔
        if (fileAccept === ".csv") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        } else if (fileAccept === ".xlsx, .xls") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        }
    }

    const [postNewBooths, { isSuccess }] = usePostNewBoothsMutation();
    const onSubmit = async (data: editState) => {
        setLoading(true);
        let newBooths: ImportBoothModel[] = [];

        for (let i = 0; i < excelData.length; i++) {
            let newBooth: ImportBoothModel = {
                boothId: data.IdPrefix +
                    (randomCharset_Id === "" ? "" :
                    cryptoRandomString({
                        length: data.IdRandomLength,
                        type: randomCharset_Id as 'alphanumeric' | 'numeric' | 'hex'
                    })) +
                    data.IdSuffix,
                type: value,
                abbrev: excelData[i].abbrev.toString(),
                boothLocation: '',
                boothNo: excelData[i].boothNo.toString(),
                category: excelData[i].category,
                intro: excelData[i].intro.toString(),
                chName: excelData[i].chName.toString(),
                enName: '',
                logoUrl: '',
                bannerUrl: '',
                customContent: '',
                fieldList: [
                    { fieldId: 'intro', value: excelData[i].intro.toString() },
                   /* { fieldId: 'address', value: excelData[i].address.toString() },
                    { fieldId: 'unifiedBusinessNo', value: excelData[i].companyNum.toString() },
                    { fieldId: 'establishment', value: '' },
                    { fieldId: 'numOfEmployees', value: '' },
                    { fieldId: 'phone', value: excelData[i].phone.toString() },
                    { fieldId: 'website', value: excelData[i].website.toString() },
                    { fieldId: 'video', value: '' },*/
                ]
            };
            newBooths.push(newBooth);
        }
        await postNewBooths(newBooths);

        setGenerateList(newBooths);
        setLoading(false);
    };
    const [generateList, setGenerateList] = React.useState<ImportBoothModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    const [value, setValue] = React.useState(BoothType.一般攤位);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number((event.target as HTMLInputElement).value));
    };

    return (
        <div>
            <Typography variant='h6' style={{
                padding: 10,
                borderRadius: "6px 6px 0px 0px",
                fontWeight: 'bold',
                color: 'white',
                background: 'linear-gradient(to right, #368AFC, #7DDCFF)',
            }}>
                建立新攤位
            </Typography>
            <Typography ml={3}>
                使用匯入功能時，請先確保header欄位名稱包含以下：
            </Typography>
            <Typography ml={3} fontWeight="bold">
                boothNo, category, chName, abbrev, intro
            </Typography>
            <Box component="form" sx={{ '& .MuiTextField-root': { minWidth: 320, width: '50%' } }} onSubmit={handleSubmit(onSubmit)}>
                <Stack alignItems="start" spacing={1} p={3}>
                    <FormControl>
                        <FormLabel>攤位類型</FormLabel>
                        <RadioGroup
                            row
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value={BoothType.一般攤位.toString()} control={<Radio />} label="一般攤位" />
                            <FormControlLabel value={BoothType.廣告贊助.toString()} control={<Radio />} label="廣告贊助" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">上傳檔案格式</FormLabel>
                        <RadioGroup row value={fileAccept} onChange={handleFileTypeChange}>
                            <FormControlLabel value=".csv" control={<Radio />} label=".csv" />
                            <FormControlLabel value=".xlsx, .xls" control={<Radio />} label=".xlsx, .xls" />
                        </RadioGroup>
                    </FormControl>
                    {fileAccept != "other" &&
                        <input type='file' accept={fileAccept} onChange={onImport} />
                    }
                    <Toolbar variant="dense" />
                    <Typography variant='subtitle1'>
                        帳號ID
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">亂數組成字元</FormLabel>
                        <RadioGroup row value={randomCharset_Id} onChange={handleIdCharsetChange}>
                            <FormControlLabel value="alphanumeric" control={<Radio />} label="[0-9 a-z A-Z]" />
                            <FormControlLabel value="numeric" control={<Radio />} label="[0-9]" />
                            <FormControlLabel value="hex" control={<Radio />} label="[0-9 a-f]" />
                            <FormControlLabel value="" control={<Radio />} label="不使用亂數" />
                        </RadioGroup>
                    </FormControl>
                    <Stack direction="row" spacing={1}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">亂數長度</FormLabel>
                            <OutlinedInput
                                inputRef={register('IdRandomLength').ref}
                                error={!!errors.IdRandomLength}
                                {...register("IdRandomLength")}
                                disabled={randomCharset_Id === ""}
                            />
                            <FormHelperText>{errors.IdRandomLength ? errors.IdRandomLength.message : ' '}</FormHelperText>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">前綴</FormLabel>
                            <OutlinedInput
                                inputRef={register('IdPrefix').ref}
                                error={!!errors.IdPrefix}
                                {...register("IdPrefix")}
                            />
                            <FormHelperText>{errors.IdPrefix ? errors.IdPrefix.message : ' '}</FormHelperText>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">後綴</FormLabel>
                            <OutlinedInput
                                inputRef={register('IdSuffix').ref}
                                error={!!errors.IdSuffix}
                                {...register("IdSuffix")}
                            />
                            <FormHelperText>{errors.IdSuffix ? errors.IdSuffix.message : ' '}</FormHelperText>
                        </FormControl>
                    </Stack>
                    <Toolbar />

                    <div className="d-flex justify-content-center w-100">
                        <LoadingButton size='large' color="primary" variant="contained" type="submit"
                            loading={loading} loadingPosition="start" startIcon={<PlayCircleFilled />}>
                            生成
                        </LoadingButton>
                    </div>
                    {generateList.map((data) => (
                        <div key={data.boothId}>
                            <Typography paragraph>
                                {`名稱：${data.chName} 統編：${data.fieldList.find(x => x.fieldId === 'unifiedBusinessNo')?.value}`}
                            </Typography>
                        </div>
                    ))}
                </Stack>
            </Box>
        </div>
    )
};

const onImportExcel = file => new Promise<ImportData[]>(function (resolve, reject) {
    // 獲取上傳的檔案物件
    const { files } = file.target;
    // 通過FileReader物件讀取檔案
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            // 以二進位制流方式讀取得到整份excel表格物件
            const workbook = read(result, { type: 'binary' });
            // 儲存獲取到的資料
            let data = [];
            // 遍歷每張工作表進行讀取（這裡預設只讀取第一張表）
            for (const sheet in workbook.Sheets) {
                // esline-disable-next-line
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法將 excel 轉成 json 資料
                    data = data.concat(utils.sheet_to_json(workbook.Sheets[sheet], { defval: '' }));
                    break; // 如果只取第一張表，就取消註釋這行
                }
            }
            // 最終獲取到並且格式化後的 json 資料
            resolve(data);
        } catch (e) {
            // 這裡可以丟擲檔案型別錯誤不正確的相關提示
            reject('檔案型別不正確！');
        }
    };
    // 以二進位制方式開啟檔案
    fileReader.readAsBinaryString(files[0]);
})