import * as React from 'react';
import BoothList from './BoothList';
import { Backdrop, Box, Button, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { useLazyGetAllBoothsQuery } from '../components/services/booth';
import { useSystemConnection } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { gameGenreList } from '../components/selectOptions';
import { useTranslation } from 'react-i18next';
import { SubTitle } from '../components/responsive-components';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const categoryList: { nameKey: string; value: number }[] = [
    { nameKey: "boothList.type.game", value: -1 },
    { nameKey: "boothList.type.sponsor", value: gameGenreList.length },
    ...gameGenreList.map((item, index) => ({ nameKey: item.nameKey, value: index })),
];
export default function BoothListPage() {
    let query = useQuery();
    const location = useLocation()
    const theme = useTheme();
    const { t } = useTranslation();
    const history= useHistory();
    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 3, refId: "boothList" });
        }
    }, [systemConnection]);

    //取得攤位及在線資料
    const [getAllBooths, { boothList = [], isBoothListLoading }] = useLazyGetAllBoothsQuery({
        selectFromResult: ({ data, isLoading }) => ({ boothList: data?.data ?? [], isBoothListLoading: isLoading })
    });
    React.useEffect(() => { getAllBooths() }, []);

    const [filterNum, setFilterNum] = React.useState<number>(-1);
    React.useEffect(() => {
        if (query.get('type'))
            setFilterNum(Number(query.get('type')));
        else
            setFilterNum(-1);
    }, [location])

    const handleChangeType = (type: number) => {
        if (filterNum !== type) {
            setFilterNum(type);
            history.push("/BoothList?type="+type)
        }
    }

    return (<>

        <Container >
            <Container>
            <SubTitle icon='circle'
                    text={t('booth.title.gameIntro')} style={{ paddingRight: '0px', paddingBottom: '0px', justifyContent:'center'  }} />
            <Box display="flex" flexWrap="wrap" gap={1} mb={{ xs: 2, md: 5 }} pt={3}>
                {categoryList.map((item) => (
                    <Button key={item.value} onClick={() => handleChangeType(item.value)} sx={{
                        "borderRadius": "5px", "background": "#fff",
                        "&:hover": {
                            backgroundColor: { xs: "#2573DE", md:"rgba(37, 115, 222, 0.04)" }},
                        ...item.value === filterNum ?
                            { color: theme.palette.background.default, backgroundColor: "#2573DE", fontWeight: "bold" } : null
                    }}
                    >
                        <Typography>
                            {t(item.nameKey)}
                        </Typography>
                    </Button>
                ))}
            </Box>
            </Container>
            <BoothList
                boothList={boothList}
                category={filterNum}
            />
            <br />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isBoothListLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    </>)
}