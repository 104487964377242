import { Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { PageLocation, useGetOnlineListMutation, UserPresence } from '../components/services/system';
import { useGetUserWithListMutation, UserCardInfoModel } from '../components/services/user';
import { People } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { CardInfo } from '../components/class';
import { BussinessCard } from '../components/BussinessCard/BussinessCardInfo';
import { UserCardDialog } from '../components/BussinessCard/UserCardDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

export default function BoothOnlineUserList(props: BoothOnlineUserListProps) {
    const { boothId, onClose } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    //取得網頁在線清單
    const [getWebOnlineList, { isSuccess, isError }] = useGetOnlineListMutation();
    const [getWebUserList] = useGetUserWithListMutation();
    const [onlineUserIds, setOnlineUserIds] = React.useState<string[]>([]);
    const [webUserListData, setWebUserListData] = React.useState([]);

    React.useEffect(() => {
        refreshOnlineList();
    }, []);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    async function refreshOnlineList() {
        setRefreshable(false);
        setLoading(true);
        try {
            let result = await getWebOnlineList({ location: PageLocation.列表總覽, refId: boothId }).unwrap();
            let list = result.data.length > 0 ? result.data.filter(onlyUnique) : [];
            setOnlineUserIds(list);

            if (list.length > 0) {
                let userListResult = await getWebUserList(list).unwrap();
                if (userListResult.isSuccess) setWebUserListData(userListResult.data);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
            if (isFirst) {
                setIsFirst(false);
                setRefreshable(true);
            }
            else {
                setTimeout(() => {
                    setRefreshable(true);
                }, 1000);
            }

        }
    }

    const [isFirst, setIsFirst] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [refreshable, setRefreshable] = React.useState<boolean>(false);
    function handleClickRefresh() {
        refreshOnlineList();
    }

    //顯示視窗
    const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data: CardInfo) => {
        setCurrentData({ ...data, isOnline: true } as UserCardInfoModel);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <DialogTitle>
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" pt="5px">
                    <Box component="span" color="secondary.main" mr={1}>
                        <People fontSize="large" color="inherit" viewBox="0 2 24 24" />
                    </Box>
                    {t('booth.onlineList.title')}
                </Typography>

                <IconButton color="primary" size="small" onClick={handleClickRefresh} disabled={!refreshable}>
                    {loading ?
                        <FontAwesomeIcon icon={faSpinner} pulse />
                        :
                        <FontAwesomeIcon icon={faSyncAlt} />
                    }
                </IconButton>
            </Box>
        </DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
            <Box minHeight="200px" pt={2}>
                {isError ?
                    <Typography>{t('booth.onlineList.error')}</Typography>
                    : isSuccess && onlineUserIds.length > 0 ?
                        <Box display="grid" gridTemplateColumns={{ xs: "minmax(auto, 200px)", sm: "repeat(2, minmax(200px, auto))", md: "repeat(4, minmax(auto, 200px))", lg: "repeat(5, 200px)" }} gap={2}>
                            {webUserListData.map((user) => (
                                <BussinessCard
                                    key={user.userId}
                                    data={user}
                                    isOnline
                                    simpleMode
                                    withBorder
                                    onClickShowMore={handleOpen}
                                />
                            ))}
                        </Box>
                        : isSuccess &&
                        <Typography>{t('booth.onlineList.none')}</Typography>
                }
            </Box>
        </DialogContent>
        <Box p="10px 22px">
            <Typography>
                <Trans
                    i18nKey="booth.onlineList.webNum"
                    values={{ totalNum: onlineUserIds.length }}
                    components={[<span style={{ color: theme.palette.primary.main }}></span>]}
                />
            </Typography>
        </Box>
        <UserCardDialog
            data={currentData}
            open={open}
            onClose={handleClose}
            onClickExchange={onClose}
            onClickSendMessage={onClose}
            onClickReport={onClose}
        />
    </>)
}
interface BoothOnlineUserListProps {
    boothId: string;
    onClose?: () => void;
}

/*export default function BoothOnlineUserList(props: BoothOnlineUserListProps) {
    const { boothId, onClose } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    //取得網頁在線清單
    const [getWebOnlineList, { data: webOnlineListData, isSuccess: isWebOnlineSuccess, isLoading: isWebOnlineLoading }] = useGetOnlineListMutation();
    const [getWebUserList, { data: webUserListData, isSuccess: isWebUserSuccess, isLoading: isWebUserLoading }] = useGetUserWithListMutation();
    const [webUserList, setWebUserList] = React.useState<CardInfo[]>([]);
    const [webOnlineList, setWebOnlineList] = React.useState<UserPresence[]>([]);
    React.useEffect(() => {
        getWebOnlineList({ location: PageLocation.列表總覽, refId: boothId })
    }, []);
    React.useEffect(() => {
        if (isWebOnlineSuccess) {
            if (webOnlineListData.data.length > 0) {
                getWebUserList(webOnlineListData.data);

                let tempArray: UserPresence[] = [];
                for (let temp of webOnlineListData.data) {
                    tempArray.push({ userId: temp, isOnline: true });
                }
                setWebOnlineList(tempArray);
            }
            else {
                setWebUserList([]);
                setWebOnlineList([]);
            }
        }
    }, [webOnlineListData])
    React.useEffect(() => {
        if (isWebUserSuccess) {
            if (webUserListData.data.length > 0) {
                setWebUserList(cloneDeep(webUserListData.data));
            }
            else {
                setWebUserList([]);
            }
        }
    }, [webUserListData])

    //取得遊戲在線清單
    const [getGameOnlineList, { data: gameOnlineListData, isSuccess: isGameOnlineSuccess, isLoading: isGameOnlineLoading }] = useGetOnlineListMutation();
    const [getGameUserList, { data: gameUserListData, isSuccess: isGameUserSuccess, isLoading: isGameUserLoading }] = useGetUserWithListMutation();
    const [gameUserList, setGameUserList] = React.useState<CardInfo[]>([]);
    const [gameOnlineList, setGameOnlineList] = React.useState<UserPresence[]>([]);
    React.useEffect(() => {
        getGameOnlineList({ location: PageLocation.遊戲逛展, refId: boothId })
    }, [])
    React.useEffect(() => {
        if (isGameOnlineSuccess) {
            if (gameOnlineListData.data.length > 0) {
                getGameUserList(gameOnlineListData.data);

                let tempArray: UserPresence[] = [];
                for (let temp of gameOnlineListData.data) {
                    tempArray.push({ userId: temp, isOnline: true });
                }
                setGameOnlineList(tempArray);
            }
            else {
                setGameUserList([]);
                setGameOnlineList([]);
            }
        }
    }, [gameOnlineListData])
    React.useEffect(() => {
        if (isGameUserSuccess) {
            if (gameUserListData.data.length > 0) {
                setGameUserList(cloneDeep(gameUserListData.data));
            }
            else {
                setGameUserList([]);
            }
        }
    }, [gameUserListData])

    function refreshList() {
        getWebOnlineList({ location: PageLocation.列表總覽, refId: boothId })
        getGameOnlineList({ location: PageLocation.遊戲逛展, refId: boothId })
    }

    return (<>
        <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
                display: 'flex', flexWrap: 'wrap', alignItems: 'center'
            }}>
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                    <People color='primary' fontSize='large' style={{ marginRight: '12px' }} />
                    <Typography variant='h6' fontWeight='bold'>
                        {t('booth.onlineList.title')}
                    </Typography>

                    <IconButton
                        onClick={refreshList}
                        disabled={(isWebOnlineLoading || isWebUserLoading || isGameOnlineLoading || isGameUserLoading)}
                    //style={{ marginLeft: 'auto' }}
                    >
                        <Refresh fontSize='medium' />
                    </IconButton>
                </Box>

                <Typography sx={{ marginRight: '12px' }}>
                    <Trans i18nKey="booth.onlineList.webNum"
                        values={{
                            totalNum: webOnlineList.length
                        }}
                    >
                        網頁在線 <span style={{ color: theme.palette.primary.main }}>{{ totalNum: webOnlineList.length }}</span> 人
                    </Trans>
                </Typography>

                <Typography sx={{ marginRight: '12px' }}>
                    <Trans i18nKey="booth.onlineList.gameNum"
                        values={{
                            totalNum: gameOnlineList.length
                        }}
                    >
                        遊戲在線 <span style={{ color: theme.palette.primary.main }}>{{ totalNum: webOnlineList.length }}</span> 人
                    </Trans>
                </Typography>
            </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                margin: '12px 0px'
            }}>
                <Typography fontWeight='bold'>
                    {t('booth.onlineList.web')}
                </Typography>
                <Divider style={{ flexGrow: 1, marginLeft: '10px', borderColor: 'initial' }} />
            </Box>
            <Box style={{ minHeight: '200px' }}>
                {webOnlineList.length > 0 ?
                    <UserList
                        userList={webUserList}
                        onlineList={webOnlineList}
                        onClose={onClose}
                    /> :
                    <Typography style={{ paddingLeft: '32px' }}>{t('booth.onlineList.none')}</Typography>
                }
            </Box>

            <Box style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                margin: '12px 0px'
            }}>
                <Typography fontWeight='bold'>
                    {t('booth.onlineList.game')}
                </Typography>
                <Divider style={{ flexGrow: 1, marginLeft: '10px', borderColor: 'initial' }} />
            </Box>
            <Box style={{ minHeight: '200px' }}>
                {gameOnlineList.length > 0 ?
                    <UserList
                        userList={gameUserList}
                        onlineList={gameOnlineList}
                        onClose={onClose}
                    /> :
                    <Typography style={{ paddingLeft: '32px' }}>{t('booth.onlineList.none')}</Typography>
                }
            </Box>
        </DialogContent>
        <DialogActions></DialogActions>
    </>)
}
interface BoothOnlineUserListProps {
    boothId: string;
    onClose?: () => void;
}*/