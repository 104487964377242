import * as React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const TO_RADIANS = Math.PI / 180;

export interface CropperProps {
    imgSrc?: File & { preview: string; };
    aspect?: number;
    imageSmoothingQuality?: ImageSmoothingQuality;
    onConfirm?: (blob: Blob) => void;
    onCancel?: () => void;
    title?: string;
}

export default function Cropper(props: CropperProps) {
    const { imgSrc, aspect: aspectProp, imageSmoothingQuality, onConfirm, onCancel, title } = props;

    //state
    const imgRef = React.useRef<HTMLImageElement>(null);
    const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
    const [dataUrl, setDataUrl] = React.useState<string>();
    const [crop, setCrop] = React.useState<Crop>();
    const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
    const [scale, setScale] = React.useState<number>(1);
    const [rotate, setRotate] = React.useState<number>(0);
    const [aspect, setAspect] = React.useState<number | undefined>(aspectProp);

    //init
    React.useEffect(() => {
        if (imgSrc) {
            setDataUrl(imgSrc.preview);
            console.log("crop接收到檔案")
        }
        else {
            setTimeout(() => {
                setDataUrl("");
            }, 300);
        }
        }, [imgSrc]);
    function onImageLoad(event: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = event.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
        else {
            setCrop({
                unit: '%',
                x: 10,
                y: 10,
                width: 80,
                height: 80,
            });
        }
    }

    //action
    async function handleConfirm() {
        if (onConfirm)
            onConfirm(await getCroppedImg());
    }

    function handleRestart() {
        if (aspect && imgRef.current) {
            const { width, height } = imgRef.current;
            setCrop(centerAspectCrop(width, height, aspect));
        }
        else {
            setCrop({
                unit: '%',
                x: 10,
                y: 10,
                width: 80,
                height: 80,
            });
        }
    }

    function handleClose() {
        if (onCancel) onCancel();
    }

    async function getCroppedImg(): Promise<Blob> {
        let canvas = previewCanvasRef.current;
        let image = imgRef.current;
        let crop = completedCrop;
        if (!canvas) {
            canvas = document.createElement("canvas");
        }
        if (!image) {
            throw new Error('No image');
        }
        if (!crop) {
            return new Promise((resolve, reject) => {
                resolve(imgSrc as Blob);
            });
        }

        const ctx = canvas?.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

        ctx.scale(pixelRatio, pixelRatio);

        ctx.imageSmoothingQuality = imageSmoothingQuality ?? "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        );

        ctx.restore();
        
        return new Promise((resolve, reject) => {
            canvas?.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                }
            }, 'image/jpeg', 1);
        });
    }

    return (
        <Dialog open={!!imgSrc}>
            <DialogTitle sx={{ p: 0.5 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ flexShrink: 0 }}><Close /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ p: "0 !important", background: "#808080", display: "flex", justifyContent: "center" }}>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    keepSelection
                    ruleOfThirds
                    style={{ background: "#fff" }}
                >
                    {dataUrl &&
                        <img
                            ref={imgRef}
                            src={dataUrl}
                            style={{
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                                maxHeight: "calc(100vh - 64px - 72px - 54px)",
                                minHeight: "100px",
                                objectFit: "contain"
                            }}
                            onLoad={onImageLoad}
                        />
                    }
                </ReactCrop>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    //variant="light"
                    onClick={handleRestart}
                    sx={{ minWidth: 150 }}
                >
                    重新裁切
                </Button>
                <Button
                    //variant="main"
                    onClick={handleConfirm}
                    sx={{ minWidth: 150, ml: "auto" }}
                >
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}