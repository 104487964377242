import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore } from "redux";
import { Provider, useSelector } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
//import { createBrowserHistory } from 'history';
//import configureStore from './store/configureStore';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import './custom.css'
import { store } from './store';

export default function Index() {

    return (
        <>
            <title>TSDGF2023</title>
            <App />
        </>
    );
}

ReactDOM.render(
    <Provider store={store}>
    <BrowserRouter>
        <Index />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

unregisterServiceWorker();
