import * as React from 'react';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LowerTextField, SubmitButton, TextButton, UpperTextField } from '../Login/LoginWindow';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BoothManageInvitation } from '../components/class';
import { logoPath } from '../config';

type formData = {
    password: string;
}
export default function RegisterPage(props: RegisterPageProps) {
    const { code, data, handleSubmitRegister } = props;

    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        password: yup.string().required(t('register.password.error1')).min(6, t('register.password.error2')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { password: "" }
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    function onRegisterSubmit(input: formData) {
        if (!data || !code) {
            setErrorMessage("Error");
            return;
        }

        setIsLoading(true);

        setErrorMessage("");

        handleSubmitRegister(input.password).then(result => {
            if (result) {
                setErrorMessage(result);
            }
        }).catch((error) => {
            setErrorMessage("Unconnect");
        });

        setIsLoading(false);
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div>
            <Stack width={280} justifyContent="stretch" alignItems="stretch">
                <div className="d-flex align center w-100">
                    <img src={logoPath} style={{ height: '36px', maxWidth: '100%' }} />
                    <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                </div>

                <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                    <Typography variant="h5">
                        {t('register.subtitle1')}
                    </Typography>
                    <Typography variant="h5">
                        {t('register.subtitle2')}
                    </Typography>
                    <Typography variant="h5">
                        {t('register.subtitle3')}
                    </Typography>
                </div>

                <Stack component="form" onSubmit={handleSubmit(onRegisterSubmit)}>
                    <UpperTextField
                        value={data?.toEmail}
                        disabled
                        variant="outlined"
                        size='small'
                        placeholder={t('register.account.placeholder')}
                        FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                    />
                    <LowerTextField
                        variant="outlined"
                        size='small'
                        type={'password'}
                        placeholder={t('register.password.placeholder')}

                        inputRef={register('password').ref}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ' '}
                        {...register("password")}

                        FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                        disabled={isLoading}
                    />
                    <div className="d-flex center" style={{ height: 30 }}>
                        <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                    </div>
                    <SubmitButton type="submit" variant="contained" loading={isLoading}>
                        {t('register.button')}
                    </SubmitButton>
                </Stack>
                <Typography variant='body2' align='center'>
                    {t('register.desciption')}
                    <a className="link" href='https://docs.google.com/document/d/1bY284ltQIg_nrmmnzhvuZz6K4EiS1aqCDmQi6CdjcrU/edit?usp=sharing' target='_blank'>
                        {t('register.privacy_policy')}
                    </a>
                </Typography>

                <div style={{ height: 12 }} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='body2'>Click to</Typography>
                    {i18n.language == 'ch' ?
                        <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                        <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                    }
                </div>
            </Stack>
        </div>
    );
}
interface RegisterPageProps {
    code: string;
    data: BoothManageInvitation;
    handleSubmitRegister: (password: string) => Promise<string>;
}