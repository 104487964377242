import { Box, Container, Divider, Paper, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import BoothArticleTable, { BoothArticleTableData } from '../components/Article/manage/BoothArticleTable';
import { BoothArticleDialog } from '../components/Article/BoothArticlePage';
import { useLazyGetAllArticlesQuery } from '../components/services/article';
import { BoothArticle } from '../components/class';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSystemConnection } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { toRecord } from '../components/Admin/Utils';
import { SubTitle } from '../components/responsive-components';
import ArticleListArticleTable from '../components/Article/ArticleListArticleTable';

export default function ArticleListPage() {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 1, refId: null });
        }
    }, [systemConnection]);

    //初始化資料
    const [getAllArticle, { data: articles }] = useLazyGetAllArticlesQuery();
    const [articleDict, setArticleDict] = React.useState<Record<string, BoothArticle>>({});
    React.useEffect(() => {
        getAllArticle();
    }, [])
    React.useEffect(() => {
        if (articles && articles.data) {
            //轉型成record
            let dict = articles.data.reduce((acc, item) => (acc[item["dataId"]] = item, acc), {} as Record<string, BoothArticle>);
            setArticleDict(dict);
        }
    }, [articles])

    //顯示視窗
    const [currentDisplayData, setCurrentDisplayData] = React.useState<BoothArticle>(null);
    const [openDisplay, setOpenDisplay] = React.useState(false);
    const handleOpenDisplay = (id?: string) => {
        if (id) {
            setCurrentDisplayData(articleDict[id]);
        }
        else {
            setCurrentDisplayData(null);
        }
        setOpenDisplay(true);
    };
    const handleCloseDisplay = () => {
        setOpenDisplay(false);
    };

    return (
        <div>
            <Container sx={{paddingBottom:"2rem"}} >
                <SubTitle icon='circle' text={t('articleList.pinMessage')} />
                <Paper sx={{ padding: { xs: "16px 10px", sm: "40px 56px" } }}>
                    <ArticleListArticleTable
                        rows={articles?.data.filter(x => x.isPin == true).map((item) => ({
                            id: item.dataId,
                            boothId: item.boothId,
                            boothChName: item.chName,
                            boothEnName: item.enName,
                            name: item.title,
                            time: new Date(item.createTime),
                            pin: item.isPin
                        } as BoothArticleTableData)) ?? []}
                        onOpen={handleOpenDisplay}
                        showPin={true}
                    />
                </Paper>
                <SubTitle icon='circle' text={t('articleList.newMessage')} />
                <Paper sx={{ padding: { xs: "16px 10px", sm: "20px 40px" } }}>
                    <ArticleListArticleTable
                        rows={articles?.data.filter(x => x.isPin!=true).map((item) => ({
                            id: item.dataId,
                            boothId: item.boothId,
                            boothChName: item.chName,
                            boothEnName: item.enName,
                            name: item.title,
                            time: new Date(item.createTime),
                            pin: item.isPin
                        } as BoothArticleTableData)) ?? []}
                        onOpen={handleOpenDisplay}
                        showPin={true}
                    />
                </Paper>
            </Container>
            <BoothArticleDialog
                data={currentDisplayData}
                matches={tabletMatches}
                open={openDisplay}
                onClose={handleCloseDisplay}
            />
        </div>
    )
}