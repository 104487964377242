//Google 登入
export const googleClientId = "692396982093-4a8pu880ge8cunqc8i3p66sd60d5h2l2.apps.googleusercontent.com";

//jitsi server domain
export const mediaDomain = 'shuttle.tempestdigi.com';

//jitsi live streaming server domain
export const liveDomain = "shuttle.tempestdigi.com";

//Google 分析
export const gaTrackingId = "G-TZVG3YNMM1";

//圖
export const imageFolder = "images/";
export const logoPath = imageFolder + "Logo.png";
export const defaultAvatar = imageFolder + "Avatar_1.png"

//手冊
export const manualFolder = "manuals/";