import * as React from 'react';
import { Divider, Fade, IconButton, InputAdornment, Paper, Popper, Stack, styled, TextField, Typography } from '@mui/material';
import { useHistory, useLocation } from "react-router-dom";
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { NoOutlineButton } from '../components/responsive-components/Button';
import GoogleLoginManager from '../components/GoogleLoginManager';
import { useDispatchWithType } from '../store';
import { profileObj, useGuestLoginMutation, useLoginMutation } from '../components/services/login';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';
import { GoogleLoginResponse } from 'react-google-login';
import { loginUser } from '../store/rootReducer';
import {useLoginWithGoogleMutation } from '../components/services/login';
import { googleClientId, logoPath } from '../config';

export const PaperWrap = styled(Paper)({
    width: '360px',
    maxWidth: '100%',
    maxHeight: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    '&:after': {
        content: '""',
        backgroundColor: '#213089',
        width: '100%',
        height: 10,
        position: 'absolute',
        top: 0
    }
});

export const PopperPaper = styled(Paper)({
    background: "#efefff",
    padding: "0.5rem"
});

export const TextButton = styled(NoOutlineButton)({
    textTransform: 'unset',
    color: '#687dfc',
    minWidth: 'unset'
});

export const SubmitButton = styled(LoadingButton)({
    color: '#ffffff',
    background: '#107AE5',
    borderRadius: 8,
    marginBottom: '10px',
    padding: '10px',
    border: 0,
    fontSize: '1rem',
    '&:hover': {
        background: '#586ad5',
    },
    '&.Mui-disabled	': {
        color: '#E3E3E3',
        background: '#A4B1FF',
    }
});

export const GeneralTextField = styled(TextField)({
    color: '#a2a2a2',
    borderRadius: 25,
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-root': {
        padding: 5,
        borderRadius: '8px',
        '& fieldset': {
            borderColor: '#b0b0b0',
            top: 0,
        },
        '&:hover fieldset': {
            borderColor: '#606060',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#000000',
        },
        '& fieldset legend': {
            display: 'none',
        },
    },
});

export const UpperTextField = styled(GeneralTextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px 8px 0px 0px',
    },
});

export const LowerTextField = styled(GeneralTextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '0px 0px 8px 8px',
    },
});

type formData = {
    account: string;
    password: string;
}
export default function LoginWindow() {
    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);

    const [login, { isLoading }] = useLoginMutation();
    const [loginWithGoogle, { isLoading: isGoogleLoading }] = useLoginWithGoogleMutation();
    const [guestLogin] = useGuestLoginMutation();
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        account: yup.string().required(t('login.account.error')),
        password: yup.string().required(t('login.password.error')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { account: "", password: "" }
    });

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopper = Boolean(anchorEl);
    const [showPassword, setShowPassword] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    function handleRegister() {
        history.push("/Register");
    }
    function handleForgotPassword() {
        history.push("/ForgotPassword");
    }

    function onLoginSubmit(data: { account: string, password: string }) {
        setErrorMessage("");
        login(data).unwrap()
            .then(result => {
                handleLoginIsSuccess(result) 
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    function handleLoginIsSuccess(result) {
        if (result.isSuccess) {
            dispatch(loginUser(result.data));
if (query.get('redirect')) {
                history.push(decodeURIComponent(query.get('redirect')));
            }
            else { history.push('/'); }
        }
        else {
            setErrorMessage(result.message);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowHelp = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    //Google登入
    function handleGoogleLoginSuccess(res) {
        setErrorMessage("");
        const googleData: profileObj = { tokenId: res.tokenId, ...res.profileObj };
        loginWithGoogle(res).unwrap()
            .then(result => {
                handleLoginIsSuccess(result)            
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }
    function handleGoogleLoginFailure() {

    }

    function handleGuestLogin() {
        history.push('/');
        /*guestLogin().unwrap().then(result => {
            if (result.isSuccess) {
                dispatch(loginUser(result.data));
                history.push('/');
            }
        });*/
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="root bg-lavender">
            <PaperWrap className="py-5" elevation={3}>
                <Stack width={280} justifyContent="stretch" alignItems="stretch">
                    <div className="d-flex align center w-100">
                        <img src={logoPath} style={{ height: '36px', maxWidth: '100%' }} />
                        <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                    </div>

                    <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {t('login.subtitle1')}
                        </Typography>
                        <Typography variant="h5">
                            {t('login.subtitle2')}
                        </Typography>
                        <Typography variant="h5">
                            {t('login.subtitle3')}
                        </Typography>
                    </div>

                    <Stack component="form" sx={{ minWidth: 200 }} onSubmit={handleSubmit(onLoginSubmit)}>
                        <UpperTextField
                            variant="outlined"
                            size='small'
                            placeholder={t('login.account.placeholder')}
                            inputRef={register('account').ref}
                            error={!!errors.account}
                            helperText={errors.account ? errors.account.message : ' '}
                            {...register("account")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" className="ml-1">
                                        <IconButton onClick={handleClickShowHelp} >
                                            <HelpOutline />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <Popper open={openPopper} anchorEl={anchorEl} placement="top-end" transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <PopperPaper elevation={3}>
                                        <Typography>{t('login.account.popper1')}</Typography>
                                        <Typography>{t('login.account.popper2')}</Typography>
                                    </PopperPaper>
                                </Fade>
                            )}
                        </Popper>
                        <LowerTextField
                            variant="outlined"
                            size='small'
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('login.password.placeholder')}
                            inputRef={register('password').ref}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ' '}
                            {...register("password")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" className="ml-1">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <div className="d-flex center" style={{ height: 30 }}>
                            <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                        </div>
                        <SubmitButton type="submit" variant="contained" loading={isLoading}>
                            {t('login.button')}
                        </SubmitButton>
                    </Stack>
                    
                    <div className="d-flex center">
                        <Typography variant='body2'>{t('login.question')}</Typography>
                        <TextButton onClick={handleRegister}>{t('login.signup')}</TextButton>
                    </div>
                    <div className="d-flex center">
                        <TextButton onClick={handleForgotPassword}>{t('login.forgotPassword')}</TextButton>
                    </div>
                    <div className="d-flex center">
                        <TextButton onClick={handleGuestLogin}>{t('login.guestLogin')}</TextButton>
                    </div>
                    <Divider sx={{ my:2 }}/>
                    <Stack alignItems="stretch" >
                        <Typography variant="h5" align="center">
                            {t('login.otherLogin')}
                        </Typography>
                        <GoogleLoginManager
                            clientId={googleClientId}
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            width={ 280 }
                        />
                        <Typography variant="body2" align="center" color='#cccccc'>
                            {t('iOS系統建議使用safari')}
                        </Typography>

                    </Stack>
                    <div style={{ height: 12 }} />
                    <div className="d-flex center">
                        <Typography variant='body2'>Click to</Typography>
                        {i18n.language == 'ch' ?
                            <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                            <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                        }
                    </div>
                </Stack>
            </PaperWrap>
        </div>
    );
}