import * as React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import './MediaSection.css';
import { CircularProgress } from '@mui/material';
//import { clearInterval } from 'timers';
import { logEvent } from '../Analytics';
import { useUser } from '../../store';
import { useTranslation } from 'react-i18next';
import { mediaDomain as domain } from '../../config';

export interface MediaSectionProps {
    roomId: string;
    roomName: string;
    token?: string;
    password?: string;
    onExit?: Function;
    varient?: MediaVarient;
    limited?: number;
    domain?: string;
    containerStyle?: React.CSSProperties;
}
export type MediaVarient = "allowVideo" | "allowLiveStream" | "private";
export function MediaSection(props: MediaSectionProps) {
    const user = useUser();
    const { t, i18n } = useTranslation();

    const { roomId, roomName, token, password, onExit, varient, limited, ...other } = props;
    const [initialize, setInit] = React.useState(false);
    const [allowEnter, setAllowEnter] = React.useState(true);
    const [roomFull, setRoomFull] = React.useState(false);
    const [waiting, setWaiting] = React.useState(false);
    const [passwordRequied, setPasswordRequied] = React.useState(false);
    const [input, setInput] = React.useState(false);
    const [end, setEnd] = React.useState(false);
    const [onCall, setOnCall] = React.useState(false)

    React.useEffect(() => {
        if (user && user.role) {
            setInit(true);
        }
    }, [user]);

    React.useLayoutEffect(() => {
        GetParticipantCount(roomId, other.domain).then((num) => {
            if ((token == null || token == "") && num == 0)
                setAllowEnter(false);
            else {
                setAllowEnter(true);
            }
            if (limited && num >= limited)
                setRoomFull(true);
            else {
                setRoomFull(false);
            }
        });
    }, [token])

    function handleJitsiIFrameRef(iframeRef: HTMLDivElement) {
        iframeRef.style.width = "100%";
        iframeRef.style.height = '100%';
        iframeRef.style.display = 'flex';
        iframeRef.style.alignItems = 'center';
        iframeRef.style.justifyContent = 'center';
    }

    function handleAPI(api) {
        api.addEventListener('videoConferenceJoined', () => {
            api.executeCommand('subject', roomName);
            if (user.logoUrl.indexOf('http://') === 0 || user.logoUrl.indexOf('https://') === 0) {
                api.executeCommand('avatarUrl', user.logoUrl);
            }
            else {
                api.executeCommand('avatarUrl', window.location.origin + user.logoUrl);
            }
        });

        api.addEventListener('readyToClose', () => { onMeetingEnd(api) });
        api.addEventListener('passwordRequired', () => {
            setPasswordRequied(true);
            setOnCall(false);
        });
        api.addEventListener('participantRoleChanged', (event) => {
            if (event.role == "moderator") {
                api.executeCommand('overwriteConfig',
                    {
                        toolbarButtons: [
                            'camera',
                            'chat',
                            'closedcaptions',
                            'desktop',
                            'download',
                            'etherpad',
                            'feedback',
                            'filmstrip',
                            'fullscreen',
                            'hangup',
                            'help',
                            (varient == 'private' && "invite"),
                            'livestreaming',
                            'microphone',
                            'mute-everyone',
                            'mute-video-everyone',
                            'participants-pane',
                            'profile',
                            'raisehand',
                            'recording',
                            'security',
                            'select-background',
                            'settings',
                            'shareaudio',
                            'sharedvideo',
                            'shortcuts',
                            'stats',
                            'tileview',
                            'toggle-camera',
                            'videoquality',
                            '__end'],
                        liveStreamingEnabled: (varient == "allowLiveStream") ? true : false, fileRecordingsEnabled: false
                    }
                );
            }
        });
    }

    function handleAPIWithPassword(api) {
        let count = 0;
        api.addEventListener('videoConferenceJoined', () => {
            api.executeCommand('subject', roomName);
            if (user.logoUrl.indexOf('http://') === 0 || user.logoUrl.indexOf('https://') === 0) {
                api.executeCommand('avatarUrl', user.logoUrl);
            }
            else {
                api.executeCommand('avatarUrl', window.location.origin + user.logoUrl);
            }
        });
        api.addEventListener('readyToClose', onMeetingEnd);
        api.addEventListener('passwordRequired', () => {
            if (count < 1) {
                api.executeCommand('password', input);
                count++;
            }
            else {
                setOnCall(false);
            }
        });
        api.addEventListener('participantRoleChanged', (event) => {
            if (event.role == "moderator") {
                api.executeCommand('overwriteConfig',
                    {
                        toolbarButtons: [
                            'camera',
                            'chat',
                            'closedcaptions',
                            'desktop',
                            'download',
                            'etherpad',
                            'feedback',
                            'filmstrip',
                            'fullscreen',
                            'hangup',
                            'help',
                            (varient == 'private' && "invite"),
                            'livestreaming',
                            'microphone',
                            'mute-everyone',
                            'mute-video-everyone',
                            'participants-pane',
                            'profile',
                            'raisehand',
                            'recording',
                            'security',
                            'select-background',
                            'settings',
                            'shareaudio',
                            'sharedvideo',
                            'shortcuts',
                            'stats',
                            'tileview',
                            'toggle-camera',
                            'videoquality',
                            '__end'],
                        liveStreamingEnabled: (varient == "allowLiveStream") ? true : false, fileRecordingsEnabled: false
                    }
                );
            }
        });
    }

    function handleInputChanged(e) {
        setInput(e.target.value);
    }

    // 進入房間
    function EnterRoom() {
        logEvent('MediaRoom', `EnterMediaRoom`, `${roomId}`);
        setEnd(false);
        setOnCall(true);
    }

    function EnterRoomWithPassword() {
        setOnCall(true);
    }

    function onMeetingEnd(api) {
        setOnCall(false);
        setEnd(true);
        if (api) {
            api.removeEventListener('videoConferenceJoined');
            api.removeEventListener('readyToClose');
            api.removeEventListener('passwordRequired');
            api.removeEventListener('participantRoleChanged');
        }
    }

    const interfaceConfig = [
        { TOOLBAR_BUTTONS: ["microphone", "chat", "hangup", "raisehand", "fullscreen", "participants-pane"] },
        { TOOLBAR_BUTTONS: ["camera", "microphone", "chat", "hangup", "raisehand", "fullscreen", "participants-pane"] }
    ];
    if (!initialize)
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <span className="mediaText center">{t('media.loading')}</span>
            </div>
        )

    else if (waiting) {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <div className="backdrop">
                    <CircularProgress />
                </div>
            </div>
        )
    }
    else if (!allowEnter) {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <span className="mediaText center">{t('media.closed')}</span>
            </div>
        )
    }
    else if (roomFull) {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <span className="mediaText center">{t('media.full')}</span>
            </div>
        )
    }
    else if (end) {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <div className="vertical center">
                    <p className="mediaText">{t('media.leave')}</p>
                    <button className="mediaButton secondary" onClick={EnterRoom}>{t('media.return')}</button>
                </div>
            </div>
        )
    }
    else if (passwordRequied && onCall) {
        return (
            <div className="mediaSection rounded" style={other.containerStyle}>
                <JitsiMeeting
                    domain={other.domain ?? domain}
                    roomName={roomId}
                    jwt={token}
                    configOverwrite={{
                        liveStreamingEnabled: false,
                        fileRecordingsEnabled: false,
                        startWithAudioMuted: true,
                        startWithVideoMuted: true
                    }}
                    interfaceConfigOverwrite={{
                        ...interfaceConfig[1],
                        DEFAULT_REMOTE_DISPLAY_NAME: '未命名',
                    }}
                    userInfo={{
                        displayName: user.name,
                        email: user.email
                    }}
                    spinner={CircularProgress}
                    onApiReady={handleAPIWithPassword}
                    getIFrameRef={handleJitsiIFrameRef}
                />
            </div>
        )
    }
    else if (passwordRequied) {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <div className="vertical center">
                    <p className="mediaText">{t('media.password')}</p>
                    <input type={password} onChange={handleInputChanged} style={{ marginBottom: '2rem' }} />
                    <button className="mediaButton" onClick={EnterRoomWithPassword}>{t('media.enter')}</button>
                </div>
            </div>
        )
    }
    else if (onCall) {
        return (
            <div className="mediaSection rounded" style={other.containerStyle}>
                <JitsiMeeting
                    domain={other.domain ?? domain}
                    roomName={roomId}
                    jwt={token}
                    configOverwrite={{
                        liveStreamingEnabled: false,
                        fileRecordingsEnabled: false,
                        startWithAudioMuted: true,
                        startWithVideoMuted: true
                    }}
                    interfaceConfigOverwrite={{
                        ...interfaceConfig[1],
                        DEFAULT_REMOTE_DISPLAY_NAME: '未命名',
                    }}
                    userInfo={{
                        displayName: user.name,
                        email: user.email
                    }}
                    spinner={CircularProgress}
                    onApiReady={handleAPI}
                    getIFrameRef={handleJitsiIFrameRef}
                />
            </div>
        )
    }
    else {
        return (
            <div className="mediaSection outline" style={other.containerStyle}>
                <div className="vertical center">
                    <p className="mediaText">{t('media.ready')}</p>
                    <button className="mediaButton" onClick={EnterRoom}>{t('media.ready_enter')}</button>
                </div>
            </div>
        )
    }
}

/*function generateJwt(domain: string, user: User) {
    const sign = require('jwt-encode');
    const secret = 'Xga27hmMKZWyrQRy';
    const data = {
        context: {
            user: {
                avatar: user.userData.logo,
                name: user.userData.lastname + user.userData.firstname,
                email: user.userData.email,
                id: user.userId,
                moderator: isModerator(user)
            }
        },
        aud: "jitsi",
        iss: "shuttle_app_id",
        sub: domain,
        room: "*",
    };
    return sign(data, secret);
}

function isModerator(user: User) {
    return false;
}*/

/*function useInterval(callback, delay) {
    const savedCallback = React.useRef(null);

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}*/

export function GetParticipantCount(roomId: string, dom?: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
        fetch(`https://${dom ?? domain}/room-size?room=${roomId.toLowerCase()}`)
            .then(res => {
                if (res.ok)
                    res.json().then((result) => { resolve(result.participants) });
                else if (res.status == 404) resolve(0);
                else reject();
            })
    })
}