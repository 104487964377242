import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import StartAnnounce from '../components/StartAnnounce/StartAnnounce';
import { useGetAnnouncesQuery } from '../components/services/system';
import { logEvent } from '../components/Analytics';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
export interface HomeStepperProps {
    open: boolean;
    onClose?: () => void;
}

export interface HomeStepperContent {
    message: string;
    link: string;
}

export default function HomeStepper(props: HomeStepperProps) {
    const { open, onClose } = props;
    const { t } = useTranslation();

    const { contents = [], isLoading } = useGetAnnouncesQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => ({ contents: data?.data, isLoading }),
        refetchOnMountOrArgChange: true
    });

    const [page, setPage] = React.useState<number>(0);
    const Content = () => {
        if (contents.length != 0) {
            return (
                <StartAnnounce
                    data={contents[page]}
                />
            )
        }
        else {
            return (
                <div>Empty</div>
            )
        }
    };

    const ChangePage = (index: number) => {
        logEvent(`Home`, `HomeStepper`, `ChangePage`);
        setPage(index);
    }

    const style = {
        border: 'none',
        color:'#2573DE',
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: "50px",
        marginBottom: '10px',
        height: "max-content",
        "&:hover": {
            border: 'none',
            }
        }

    return (
        <Dialog open={open && contents && contents.length > 0} onClose={onClose} fullWidth={true} maxWidth="sm"
            sx={{
                background: 'none', boxShadow: 'none',
                "& .MuiDialog-container>.MuiPaper-root": {
                    "border": "1px solid #707070",
                    borderRadius: '15px',
                    padding: '30px 40px'
                }
            }} >
            <Button
                onClick={onClose}
                sx={{
                position: "absolute",
                top: '10px',
                    right: '10px',
                    color:'#D8D8D8'
                }}
            >
                <CloseIcon fontSize='large' />
            </Button>
            <DialogContent sx={{ backgroundColor: "background.default" ,padding:0,marginBottom:'10px'}}>
                {!isLoading ?
                    <Content /> :
                    <CircularProgress />
                }
            </DialogContent>
            <DialogActions>
                <Stack spacing='16px' direction='row' flexWrap='wrap' justifyContent='flex-end'>
                {page > 0 &&
                        <Button variant="outlined" onClick={() => ChangePage(page - 1)} sx={style}><KeyboardArrowLeftIcon />{t('home.stepper.last')}</Button>
                }
                {page < contents.length - 1 &&
                        <Button variant="outlined" onClick={() => ChangePage(page + 1)} sx={style}>{t('home.stepper.next')}<KeyboardArrowRightIcon /></Button>
                }
                    <Button variant="outlined" onClick={onClose} sx={style}>{t('home.stepper.skip')}<KeyboardDoubleArrowRightIcon/></Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}