import { Box, Button, IconButton, Paper, Skeleton, styled, Typography } from '@mui/material';
import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { useGetCompanyIntroQuery, useGetEventIntroQuery, useGetGameIntroQuery, useGetTeamIntroQuery, useUpdateCompanyIntroMutation, useUpdateEventIntroMutation, useUpdateGameIntroMutation, useUpdateTeamIntroMutation } from '../../components/services/booth';
import { Edit } from '@mui/icons-material';
import CKEditor from '../../components/Editor/CKEditor';

const prefix = "manage.boothTool.";
const WrapperPaper = styled(Paper)(({ theme }) => ({
    "&.darkMode": {
        background: theme.palette.background.default,
        color: theme.palette.text.white,
        "& .rdw-editor-toolbar": {
            color: theme.palette.text.primary
        },
        "& .rdw-editor-main": {
            background: theme.palette.background.default,
            color: theme.palette.text.white,
            "& .rdw-image-alignment-option": {
                color: theme.palette.text.primary,
            },
            "& .rdw-image-alignment-option-popup": {
                border: 0,
                background: "transparent"
            }
        }
    },
    "& .CKEditor-root": {
        paddingInline: theme.spacing(1.5)
    },
    "& a.rdw-dropdown-selectedtext, & a.rdw-dropdown-selectedtext:hover": {
        color: "inherit",
        textDecoration: "none"
    }
}));
export function GameIntroManage(props: { boothId: string }) {
    const { boothId } = props;
    const { t } = useTranslation();

    const { data: gameIntro, isLoading, refetch } = useGetGameIntroQuery(boothId, { refetchOnMountOrArgChange: true });
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<string>();
    React.useEffect(() => {
        if (gameIntro && gameIntro.data) {
            setEditValue(gameIntro.data);
        }
    }, [gameIntro])

    const [update] = useUpdateGameIntroMutation();
    const [loading, setLoading] = React.useState<boolean>(false);
    async function handleSave() {
        try {
            setLoading(true);
            setIsEdit(false);
            var result = await update({ boothId: boothId, value: editValue });
            refetch();
        }
        catch {
            console.error("發生錯誤");
        }
        finally {
            setLoading(false);
        }
    }
    function handleDiscard() {
        setEditValue(gameIntro?.data ?? "");
        setIsEdit(false);
    }

    const Paper = WrapperPaper;
    return (<>
        <SubTitle
            icon='circle'
            text={t(prefix + "gameIntro")}
        />
        {isLoading || loading ? <>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h4"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Skeleton variant="rectangular" animation="wave" height="150px" />
        </> : isEdit ? <>
                <Paper variant="outlined" className="darkMode">
                
                <CKEditor
                        data={editValue}
                        onChange={setEditValue}
                />
            </Paper>
            <Box display="flex" gap={1} mt={1.5}>
                <Button variant="contained" onClick={handleSave}>{t(prefix + "button.save")}</Button>
                <Button onClick={handleDiscard}>{t(prefix + "button.discard")}</Button>
            </Box>
        </> : <>
            <IconButton onClick={() => setIsEdit(true)}><Edit /></IconButton>
            <Paper variant="outlined" className="darkMode">
                        {editValue && <div className="ck-content"
                            dangerouslySetInnerHTML={{ __html: editValue }}
                        />}
            </Paper>
        </>}
    </>)
};

export function TeamIntroManage(props: { boothId: string }) {
    const { boothId } = props;
    const { t } = useTranslation();

    const { data: teamIntro, isLoading, refetch } = useGetTeamIntroQuery(boothId, { refetchOnMountOrArgChange: true });
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<string>();
    React.useEffect(() => {
        if (teamIntro && teamIntro.data) {
            setEditValue(teamIntro.data);
        }
    }, [teamIntro])

    const [update] = useUpdateTeamIntroMutation();
    const [loading, setLoading] = React.useState<boolean>(false);
    async function handleSave() {
        try {
            setLoading(true);
            setIsEdit(false);
            var result = await update({ boothId: boothId, value: editValue });
            refetch();
        }
        catch {
            console.error("發生錯誤");
        }
        finally {
            setLoading(false);
        }
    }
    function handleDiscard() {
        setEditValue(teamIntro?.data ?? "");
        setIsEdit(false);
    }

    const Paper = WrapperPaper;
    return (<>
        <SubTitle
            icon='circle'
            text={t(prefix + "teamIntro")}
        />
        {isLoading || loading ? <>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h4"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Skeleton variant="rectangular" animation="wave" height="150px" />
        </> : isEdit ? <>
            <Paper variant="outlined" className="darkMode">
                <CKEditor
                    data={editValue}
                        onChange={setEditValue}
                />
            </Paper>
            <Box display="flex" gap={1} mt={1.5}>
                <Button variant="contained" onClick={handleSave}>{t(prefix + "button.save")}</Button>
                <Button onClick={handleDiscard}>{t(prefix + "button.discard")}</Button>
            </Box>
        </> : <>
            <IconButton onClick={() => setIsEdit(true)}><Edit /></IconButton>
            <Paper variant="outlined" className="darkMode">
                        {editValue && <div className="ck-content"
                            dangerouslySetInnerHTML={{ __html: editValue }}
                        />}
            </Paper>
        </>}
    </>)
};

export function CompanyIntroManage(props: { boothId: string }) {
    const { boothId } = props;
    const { t } = useTranslation();

    const { data: companyIntro, isLoading, refetch } = useGetCompanyIntroQuery(boothId, { refetchOnMountOrArgChange: true });
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<string>();
    React.useEffect(() => {
        if (companyIntro && companyIntro.data) {
            setEditValue(companyIntro.data);
        }
    }, [companyIntro])

    const [update] = useUpdateCompanyIntroMutation();
    const [loading, setLoading] = React.useState<boolean>(false);
    async function handleSave() {
        try {
            setLoading(true);
            setIsEdit(false);
            var result = await update({ boothId: boothId, value: editValue });
            refetch();
        }
        catch {
            console.error("發生錯誤");
        }
        finally {
            setLoading(false);
        }
    }
    function handleDiscard() {
        setEditValue(companyIntro?.data ?? "");
        setIsEdit(false);
    }

    const Paper = WrapperPaper;
    return (<>
        <SubTitle
            icon='circle'
            text={t(prefix + "companyIntro")}
        />
        {isLoading || loading ? <>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h4"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Skeleton variant="rectangular" animation="wave" height="150px" />
        </> : isEdit ? <>
            <Paper variant="outlined" className="darkMode">
                <CKEditor
                    data={editValue}
                        onChange={setEditValue}
                />
            </Paper>
            <Box display="flex" gap={1} mt={1.5}>
                <Button variant="contained" onClick={handleSave}>{t(prefix + "button.save")}</Button>
                <Button onClick={handleDiscard}>{t(prefix + "button.discard")}</Button>
            </Box>
        </> : <>
            <IconButton onClick={() => setIsEdit(true)}><Edit /></IconButton>
            <Paper variant="outlined" className="darkMode">
                        {editValue && <div className="ck-content"
                            dangerouslySetInnerHTML={{ __html: editValue }}
                        />}
            </Paper>
        </>}
    </>)
};

export function EventIntroManage(props: { boothId: string }) {
    const { boothId } = props;
    const { t } = useTranslation();

    const { data: eventIntro, isLoading, refetch } = useGetEventIntroQuery(boothId, { refetchOnMountOrArgChange: true });
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<string>();
    React.useEffect(() => {
        if (eventIntro && eventIntro.data) {
            setEditValue(eventIntro.data);
        }
    }, [eventIntro])

    const [update] = useUpdateEventIntroMutation();
    const [loading, setLoading] = React.useState<boolean>(false);
    async function handleSave() {
        try {
            setLoading(true);
            setIsEdit(false);
            var result = await update({ boothId: boothId, value: editValue });
            refetch();
        }
        catch {
            console.error("發生錯誤");
        }
        finally {
            setLoading(false);
        }
    }
    function handleDiscard() {
        setEditValue(eventIntro?.data ?? "");
        setIsEdit(false);
    }

    const Paper = WrapperPaper;
    return (<>
        <SubTitle
            icon='circle'
            text={t(prefix + "eventIntro")}
        />
        {isLoading || loading ? <>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h4"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Typography variant="h3"><Skeleton variant="text" animation="wave" /></Typography>
            <Typography variant="h2"><Skeleton variant="text" animation="wave" width="80%" /></Typography>
            <Skeleton variant="rectangular" animation="wave" height="150px" />
        </> : isEdit ? <>
            <Paper variant="outlined" className="darkMode">
                <CKEditor
                    data={editValue}
                    onChange={setEditValue}
                />
            </Paper>
            <Box display="flex" gap={1} mt={1.5}>
                <Button variant="contained" onClick={handleSave}>{t(prefix + "button.save")}</Button>
                <Button onClick={handleDiscard}>{t(prefix + "button.discard")}</Button>
            </Box>
        </> : <>
            <IconButton onClick={() => setIsEdit(true)}><Edit /></IconButton>
            <Paper variant="outlined" className="darkMode">
                        {editValue && <div className="ck-content"
                            dangerouslySetInnerHTML={{ __html: editValue }}
                        />}
            </Paper>
        </>}
    </>)
};