
export enum UserRole {
    anonymous = 0,
    normal,
    administrator,
    superuser,
}
export const userRoleList = [
    { value: UserRole.anonymous, nameKey: 'user.role.anonymous' },
    { value: UserRole.normal, nameKey: 'user.role.normal' },
    { value: UserRole.administrator, nameKey: 'user.role.administrator' },
    { value: UserRole.superuser, nameKey: 'user.role.superuser' },
];

export interface User extends CardInfo {
    role: UserRole;
    saveCardIds: string[];
    saveBoothIds: string[];
    saveInfoCardIds: string[];
}
export interface CardInfo {
    userId: string;
    type: CardType;
    name: string;
    alias: string;
    logoUrl: string;
    phone: string;
    phonePrivacy: FieldPrivacy;
    email: string;
    emailPrivacy: FieldPrivacy;
    infoFields: UserExtendField[];
    boothIds: string[];
}

export enum CardType {
    unknown = 0,
    student,
    nonStudent,
    admin
}
export const cardTypeList = [
    { value: CardType.unknown, nameKey: 'user.cardType.unknown' },
    { value: CardType.student, nameKey: 'user.cardType.student' },
    { value: CardType.nonStudent, nameKey: 'user.cardType.nonStudent' }
]

export interface UserExtendField {
    fieldId: string;
    value: string;
    privacy: FieldPrivacy;
}

export interface UserFieldName {
    fieldId: string;
    fieldName: string[];
    fieldType: ItemType;
    cardTypeList: CardType[];
    maxStringLength: number;
    privacy: FieldPrivacy;
}
export enum FieldPrivacy {
    公開 = 0, //包括 0123
    不公開給訪客, //包括 123
    已交換過名片, //包括 23 與 交換過名片之1
    隱藏
}
export const fieldPrivacyList = [
    { value: FieldPrivacy.公開, nameKey: 'user.editDialog.fieldPrivacy.public', fullNameKey: 'user.editDialog.fieldPrivacy.full_public' },
    { value: FieldPrivacy.不公開給訪客, nameKey: 'user.editDialog.fieldPrivacy.private_to_visitors', fullNameKey: 'user.editDialog.fieldPrivacy.full_private_to_visitors' },
    //{ value: FieldPrivacy.不公開給一般使用者, nameKey: 'user.editDialog.fieldPrivacy.private_to_normals', fullNameKey: 'user.editDialog.fieldPrivacy.full_private_to_normals' },
    //{ value: FieldPrivacy.不公開給攤位人員, nameKey: 'user.editDialog.fieldPrivacy.private_to_exhibitors', fullNameKey: 'user.editDialog.fieldPrivacy.full_private_to_exhibitors' },
    { value: FieldPrivacy.已交換過名片, nameKey: 'user.editDialog.fieldPrivacy.justexchange', fullNameKey: 'user.editDialog.fieldPrivacy.full_justexchange' },
    { value: FieldPrivacy.隱藏, nameKey: 'user.editDialog.fieldPrivacy.private', fullNameKey: 'user.editDialog.fieldPrivacy.full_private' }
]

export interface UserPresence {
    userId: string;
    isOnline: boolean;
}

export const departmentList = [
    { value: "資工系", nameKey: '資工系' },
    { value: "中文系", nameKey: '中文系' },
]
export const gradeList = [
    { value: "一年級", nameKey: '一年級' },
    { value: "二年級", nameKey: '二年級' },
]

export interface Booth {
    boothId: string;
    type: BoothType;
    boothNo: string;
    boothLocation: string;
    category: string;
    intro: string;
    chName: string;
    enName: string;
    abbrev: string;
    bannerUrl: string;
    logoUrl: string;
    customContent: string;
    fieldList: BoothExtendField[];
    whiteboardRoomCode: string;
}

export enum BoothType {
    一般攤位 = 0,
    廣告贊助,
    服務台
}

export interface ImageUrl {
    url: string;
    updateTime: string;
}
export interface BoothExtendField {
    fieldId: string;
    value: string;
}

export interface BoothFieldName {
    fieldId: string;
    fieldName: string[];
    fieldType: ItemType;
    boothTypeList: BoothType[];
    maxStringLength: number;
}
export enum ItemType {
    String = 0,
    Int,
    Bool
}

export interface BoothPresence {
    boothId: string;
    isOnline: boolean;
}

export interface IInfoCard {
    dataId: string;
    boothId: string;
    boothChName: string;
    boothEnName: string;
    boothAbbrev: string;
    boothLogoUrl: string;
    boothBannerUrl: string;
    title: string;
    imgUrl: string;
    content: string;
    createTime: string;
}

export interface BoothQandA {
    dataId: string;
    boothId: string;
    questioner: string;
    question: string;
    likers: string[];
    answerer: string;
    answer: string;
    askTime: string;
    ansTime: string;
    updateTime: string;
}

export interface DialogControl {
    readableDialogs: string[];
    unreadableDialogs: string[];
}

export interface DialogContent {
    chatId: string;
    title: string;
    participants: Participant[]
    messages: MessageContent[]
}
export interface Participant {
    info: CardInfo;
    hasBusinessCard: boolean;
    isOnline: boolean;
    lastSeenTime: string;
}
export interface MessageContent {
    chatId: string;
    messageId: string;
    senderId: string;
    textCode: TextCode;
    textObject: AutoTextObject | PlainTextObject | InteractionTextObject | StickerTextObject | ExchangeCardTextObject | AppointmentTextObject | VideoChatTextObject | FileTextObject | ThumbnailTextObject;
    isDeleted: boolean;
    hasBeenSeen: boolean;
    createTime: string;
}
export interface TextObject { }
export interface AutoTextObject extends TextObject {
    appointmentId: string;
}
export interface PlainTextObject extends TextObject {
    content: string;
}
export interface InteractionTextObject extends TextObject {
    interactionType: InteractionType;
    message: string;
}
export interface StickerTextObject extends TextObject {
    stickerId: string
}
export interface ExchangeCardTextObject extends TextObject {
    actionId: string
}
export interface AppointmentTextObject extends TextObject {
    actionId: string
}
export interface VideoChatTextObject extends TextObject {
    videoChatId: string
}
export interface FileTextObject extends TextObject {
    fileUrl: string
}
export interface ThumbnailTextObject extends TextObject {
    imageUrl: string
}
export enum TextCode {
    系統通知 = 0,
    純文字及連結,
    互動訊息, //event狀態改變就會寄的東東
    貼圖,
    交換名片,
    預約面談,
    視訊邀請,
    來自電腦的完整檔案,
    來自電腦的縮圖版本檔案,
    來自Google雲端的完整檔案
}
export enum InteractionType {
    接受交換名片 = 0,
    拒絕交換名片,
    接受預約面談,
    拒絕預約面談,
}

export interface ChannelMessageContent {
    channelId: string;
    messageId: string;
    senderId: string;
    senderLogoUrl: string;
    senderName: string;
    textCode: TextCode;
    textObject: PlainTextObject | StickerTextObject | FileTextObject | ThumbnailTextObject;
    isPinned: boolean;
    isDeleted: boolean;
    createTime: string;
}

export interface NoticeMessageContent {
    textCode: NoticeTextCode;
    textObject: BroadcastTextObject | UpcomingEventTextObject | QuestStatusTextObject | NotAnswerTextObject;
    hasBeenSeen: boolean;
    createTime: string;
}
export interface NoticeTextObject { }
export interface BroadcastTextObject extends NoticeTextObject {
    senderId: string
    content: string
    link: string
}
export interface UpcomingEventTextObject extends NoticeTextObject {
    agendaId: string
    name: string
    startTime: string
    endTime: string
    location: string
}
export interface QuestStatusTextObject extends NoticeTextObject {
    questId: string
    status: QuestStatus
}
export interface NotAnswerTextObject extends NoticeTextObject {
    questIds: string[] //紀錄未回覆的QA Ids
}
export enum NoticeTextCode {
    廣播通知 = 0,
    活動通知,
    活動更改通知,
    問題狀態改變通知,
    問答版待回覆通知
}
export enum QuestStatus {
    被回覆 = 0,
    已刪除,
}

export interface IRoom {
    roomId: string
    roomName: string
    whiteboardRoomCode: string
    allowAnonymous: boolean
    owner: string[]
}

export interface Poster {
    booth: Booth
    editTime: Date
    posterUrl: string
}
export interface UserOnlineTime {
    userId: string
    timeList: OnlineTime[]
}
export interface OnlineTime {
    boothChName: string;
    boothEnName: string;
    onlineTime: number;
}

export interface Announce {
    dataId: string;
    content: string;
    link: string;
    isEnd: boolean;
    //updateTime: string;
}

export interface SignIn {
    date: string;
    userSigns: UserSign[];
}
export interface UserSign {
    userId: string;
    time: string;
}

//攤位影片
export interface VideoRecord {
    sessionId: string;
    recordList: string[];
    updateTime: string;
}
export interface BoothVideo {
    boothId: string;
    link: string;
    updateTime: string;
}

//攤位文章
export interface BoothArticle {
    dataId: string;
    boothId: string;
    chName: string;
    enName: string;
    posterId: string;
    editorId: string[];
    title: string;
    content: string;
    isPin: boolean;
    createTime: string;
}

//說明會
export interface Seminar {
    seminarId: string;
    chTitle: string;
    enTitle: string;
    chAbbrev: string;
    enAbbrev: string;
    agendas: Agenda[];
    youtubeLink: string;
    channelId: string;
}
export interface Agenda {
    seminarId: string;
    agendaId: string;
    title: string;
    boothId: string;
    startTime: string;
    endTime: string;
    regiStartTime: string;
    regiEndTime: string;
    speakerInfo: string;
    eventContent: string;
    registrationLink: string;
    boothName: string;
    boothChName: string;
    boothEnName: string;
}
export interface SeminarSignIn {
    dataId: string;
    seminarId: string;
    agendaId: string;
    startTime: string;
    endTime: string;
}

//問卷
export interface Question {
    dataId: string;
    posterId: string;
    title: string;
    link: string;
    createTime: string;
}

export interface Report {
    reportType: number;
    description: string;
    jsonData: string;
}

//邀請
export interface BoothManagement {
    user: CardInfo;
    booth: Booth;
    inviter: CardInfo;
    joinDate: string;
    leaveDate?: string;
}
export interface BoothManageInvitation {
    inviter: CardInfo;
    toEmail: string;
    booth: Booth;
    inviteTime: string;
    expireTime: string;
    isAccept: boolean;
}
export interface VoteRecord {
    boothId: string;
    recordList: Ballot[];
    updateTime: string;
}
export interface Ballot {
    ballotId: string;
    userId: string;
    updateTime: string;
}