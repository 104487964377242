import { Close, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Dialog, DialogContent, IconButton, useTheme } from "@mui/material";
import * as React from "react";
import { IInfoCard } from "../class";
import { InfoCard as BoothInfoCard, InfoCardProps } from "./InfoCard";

interface InfoCardDialogProps {
	data: IInfoCard;
	open: boolean;
	onClose: () => void;
	handleChangePrev?: () => void;
	handleChangeNext?: () => void;
	infoCardProps?: InfoCardProps<IInfoCard>;
}
export function InfoCardDialog(props: InfoCardDialogProps) {
	const { data, open, onClose, handleChangePrev, handleChangeNext, infoCardProps } = props;

	function handleClose() {
		onClose();
	}

	return (
		<Dialog open={open} onClose={handleClose}
			fullWidth maxWidth="md"
			PaperProps={{
				sx: {
					overflow: "initial",
				}
			}}
			scroll="paper"
		>
			{handleChangePrev &&
				<Box position="absolute" top="50%" left="-3rem" color="white">
					<IconButton color="inherit" onClick={handleChangePrev}>
						<KeyboardArrowLeft />
					</IconButton>
				</Box>
			}
			{handleChangeNext &&
				<Box position="absolute" top="50%" right="-3rem" color="white">
					<IconButton color="inherit" onClick={handleChangeNext} >
						<KeyboardArrowRight />
					</IconButton>
				</Box>
			}
			<IconButton onClick={handleClose}
				style={{
					position: 'absolute', top: '0px', right: '-54px',
					backgroundColor: 'transparent', color: 'white'
				}}
			>
				<Close fontSize='large' />
			</IconButton>

			<DialogContent style={{ padding: "0" }}>
				{data &&
					<BoothInfoCard
						data={data}
						disableFollow
						{...infoCardProps}
					/>
				}
			</DialogContent>
		</Dialog>
	)
}