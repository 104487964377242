import * as React from 'react';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { userRoleList } from '../../components/class';
import { format } from 'date-fns';
import { zhTWGrid } from '../data-grid-locale';
import { useGetDateInSignQuery, useLazyGetSignInListQuery, UserSignData } from '../../components/services/system';
import { SubTitle } from '../../components/responsive-components/Typography';
import enUS from 'date-fns/locale/en-US';
import zhTW from 'date-fns/locale/zh-TW';

const roles: string[] = userRoleList.map(x=>x.nameKey);
export default function DailyCheckInList() {
    const { t } = useTranslation();

    const { data: dateList, isLoading, isSuccess } = useGetDateInSignQuery();
    const [getSignInList, { data: signInData, isLoading: isSigninDateLoading }] = useLazyGetSignInListQuery();

    const [currentDate, setCurrentDate] = React.useState<string>();
    React.useEffect(() => {
        if (isSuccess && dateList.isSuccess) {
            let latest = [...dateList.data].pop();
            let formatDate = format(new Date(latest), "yyyy-MM-dd", { locale: zhTW });
            getSignInList(formatDate);
            setCurrentDate(latest);
        }
    }, [dateList])

    function handleClickDate(clickDate: string) {
        let date = format(new Date(clickDate), "yyyy-MM-dd", { locale: zhTW });
        getSignInList(date);
        setCurrentDate(clickDate);
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.analysis.checkIn')}
            subText={t('manage.dailyCheckIn.subTitle')}
        />
        <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={1} mb={2}>
            {dateList?.data.map((date) => (
                <Button
                    key={date}
                    variant="outlined"
                    onClick={() => handleClickDate(date)}
                    disabled={date === currentDate}
                >
                    {format(new Date(date), "yyyy/MM/dd", { locale: zhTW })}
                </Button>
            ))}
        </Box>
        <UserSignTable dataList={signInData?.data ?? []} loading={isSigninDateLoading} />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress />
        </Backdrop>
    </>)
};

interface GridDataRow extends UserSignData {}
function UserSignTable(props: { dataList: GridDataRow[], loading:boolean }) {
    const { dataList, loading } = props;
    const { t, i18n } = useTranslation();

    function formati18n(date: Date, language) {
        if (language == "ch") {
            const convertedDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Taipei" }));
            if (convertedDate.getHours() === 0)
                return format(date, "午夜 hh:mm");
            else if (convertedDate.getHours() === 12)
                return format(date, "中午 hh:mm");
            return format(date, "a hh:mm", { locale: zhTW });
        }
        return format(date, "p", { locale: enUS });
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t("manage.dailyCheckIn.options.name"),
            flex: 0.8,
            minWidth: 150,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) => cardInfo?.name,
            /*renderCell: ({ row: { cardInfo } }: GridRenderCellParams<string, GridDataRow>) => (
                <a onClick={() => handleOpenCard(cardInfo)}
                    style={{ textDecoration: "underline", cursor: "pointer", lineHeight: "1.43" }}>
                    {cardInfo.name}
                </a>
            )*/
        },
        {
            field: 'role',
            headerName: t("manage.dailyCheckIn.options.role"),
            width: 120,
            type: 'singleSelect',
            valueOptions: roles.map(x => `${t(x)}`),
            valueGetter: ({ row: { level } }: GridValueGetterParams<string, GridDataRow>) => `${t(roles[Number(level)]) || ''}`,
        },
        {
            field: 'school_or_organization',
            headerName: t("manage.dailyCheckIn.options.school_or_organization"),
            flex: 1,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) =>
                cardInfo?.infoFields.find(x => x.fieldId === "school" || x.fieldId === "organization")?.value,
        },
        {
            field: 'department_or_jobTitle',
            headerName: t("manage.dailyCheckIn.options.department_or_jobTitle"),
            flex: 1,
            valueGetter: ({ row: { cardInfo } }: GridValueGetterParams<string, GridDataRow>) =>
                cardInfo?.infoFields.find(x => x.fieldId === "department" || x.fieldId === "jobTitle")?.value,
        },
        {
            field: 'time',
            headerName: t("manage.dailyCheckIn.options.time"),
            type: 'dateTime',
            width: 120,
            valueGetter: ({ value }: GridValueGetterParams<string>) => formati18n(new Date(value), i18n.language)
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}