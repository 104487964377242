import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Publish } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem, Paper, styled, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { important, TextField, Upload } from "../../components/responsive-components";
import { useUploadInfoCardMutation } from "../../components/services/file";
import { useUser } from "../../store";
import TextEditor from "../Editor/CKEditor";
import { useGetAllBoothNamesQuery, usePostInfoCardMutation, usePutInfoCardMutation } from "../services/booth";

type formData = {
    dataId: string;
    boothId: string;
    title: string;
    imgUrl: string;
    content: string;
}

export interface InfoEditDialogProps {
    open: boolean;
    boothId?: string;
    initData?: formData;
    onClose?: () => void;
    onSave?: () => void;
}
export function InfoEditDialog(props: InfoEditDialogProps) {
    const { open, boothId, initData, onClose, onSave } = props;
    const { t, i18n } = useTranslation();

    const { boothList = [], isSuccess } = useGetAllBoothNamesQuery(null, {
        selectFromResult: ({ data, isSuccess }) => ({ boothList: data?.data ?? [], isSuccess }),
        refetchOnMountOrArgChange: true
    });

    const schema = yup.object().shape({
        boothId: yup.string().trim().required(t('infoCard.dialog.error.required')),
        title: yup.string().trim().required(t('infoCard.dialog.error.required')),
        imgUrl: yup.string().required(t('infoCard.dialog.error.required')),
        content: yup.string().required(t('infoCard.dialog.error.required')),
    });
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema),
        defaultValues: initData ?? {
            dataId: "", boothId: boothId ?? "", title: "", imgUrl: "",
            content: "<p>請在此輸入</p>"
        }
    });
    React.useEffect(() => {
        reset(initData ?? {
            dataId: "", boothId: boothId ?? "", title: "", imgUrl: "",
            content: "<p>請在此輸入</p>"
        });
    }, [initData, open])

    const boothIdValue = watch("boothId");

    const imageUrlValue = watch("imgUrl");
    const handleImageUrlChange = (url: string) => setValue("imgUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    const contentValue = watch("content");
    const handleContentChange = (content: string) => setValue("content", content);

    //圖檔暫存
    const [imgFile, setImgFile] = React.useState<File>();
    const handleChangeFile = (file: File) => { setImgFile(file); handleImageUrlChange("new file");}

    async function onSubmit(data: formData) {
        setIsLoading(true);
        try {
            if (imgFile != null) {
                let uri = await uploadImage();
                handleImageUrlChange(uri);
                await updateData({ ...data, imgUrl: uri });
            }
            else await updateData(data);

            if (onSave) onSave();

        } catch (error) {
            let message = 'Unknown Error'
            if (error instanceof Error) message = error.message
            setErrorMessage(message);
            setOpenError(true);

            console.error(message);
        } finally {
            setIsLoading(false);
        }
    }

    //圖片上傳
    const [uploadImg] = useUploadInfoCardMutation();
    const userId = useUser().userId;
    async function uploadImage() {
        try {
            const formData = new FormData();
            formData.append("file", imgFile);
            let result = await uploadImg({ boothId: boothIdValue, formData }).unwrap();
            if (!result.isSuccess)
                throw new Error(result.message);
            return result.data.url;
        }
        catch {
            throw new Error("上傳圖片失敗");
        }
    }

    //新增或編輯資料
    const [addCard] = usePostInfoCardMutation();
    const [editCard] = usePutInfoCardMutation();
    async function updateData(data: formData) {
        try {
            let result = null;
            if (!!initData)
                result = await editCard({ ...data }).unwrap();
            else result = await addCard({ ...data }).unwrap();
            if (!result.isSuccess)
                throw new Error(result.message);
        }
        catch {
            throw new Error("上傳資料失敗");
        }
    }

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openAsk, setOpenAsk] = React.useState<boolean>(false);
    const [openError, setOpenError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const handleCancel = () => {
        setOpenAsk(true);
    };

    const handleClose = () => {
        if (onClose) onClose();
    }
    return (<>
        <Dialog open={open} onClose={handleCancel} maxWidth={"md"} fullWidth disableEnforceFocus>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                            {!!initData ? t('infoCard.dialog.editTitle') : t('infoCard.dialog.addTitle')}
                        </Typography>
                        <Button onClick={handleCancel} endIcon={<Close />} sx={{ flexShrink: 0 }}>{t('common.button.close')}</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {!initData && !boothId && <>
                        <Typography variant="subtitle1">{t("infoCard.dialog.question.booth")}{important}</Typography>
                        <TextField
                            hiddenLabel
                            variant="outlined"
                            select
                            fullWidth
                            value={boothIdValue}
                            error={!!errors.boothId}
                            helperText={errors.boothId ? errors.boothId.message : ' '}
                            {...register("boothId")}
                            disabled={isLoading}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    PaperProps: {
                                        style: { maxHeight: "400px" }
                                    }
                                }
                            }}
                        >
                            <MenuItem disabled value="">
                                <em>{t('infoCard.dialog.placeholder.booth')}</em>
                            </MenuItem>
                            {boothList?.map((option) => (
                                <MenuItem key={option.boothId} value={option.boothId}>
                                    {i18n.language == "ch" ? option.chName : option.enName}
                                </MenuItem>
                            ))}
                            {!isSuccess &&
                                <MenuItem value={null}>
                                    {t('infoCard.dialog.error.connectionFailed')}
                                </MenuItem>
                            }
                        </TextField>
                    </>}
                    <Typography variant="subtitle1">{t("infoCard.dialog.question.title")}{important}</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('title').ref}
                        inputProps={{ maxLength: 50 }}
                        placeholder={t("infoCard.dialog.placeholder.title")}
                        error={!!errors.title}
                        helperText={errors.title ? errors.title.message : ' '}
                        {...register("title")}
                        disabled={isLoading}
                    />
                    <Typography variant="subtitle1">{t("infoCard.dialog.question.image")}{important}</Typography>
                    <Box width="100%" maxWidth="320px">
                        <Box position="relative" width="100%" paddingTop={(9 / 16 * 100) + "%"} mb={6}>
                            <Box position="absolute" top={0} right={0} bottom={0} left={0}>
                                <Upload
                                    value={imageUrlValue} onChange={handleChangeFile}
                                    width="100%" height="100%"
                                    containerStyle={{ width: "100%", height: "100%", border: "1px solid rgba(0, 0, 0, 0.23)" }}
                                    imgStyle={{ objectFit: "contain" }}
                                >
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "4px",
                                            color: "darkgray",
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                        }}>
                                        <Publish />
                                        {t('common.hint.imageUpload.dropzone')}
                                    </div>
                                </Upload>
                            </Box>
                        </Box>
                    </Box>
                    <FormHelperText color="error.main">{errors.imgUrl ? errors.imgUrl.message : ' '}</FormHelperText>

                    <Typography variant="subtitle1">{t("infoCard.dialog.question.content")}{important}</Typography>
                    <TextEditor data={contentValue} onChange={handleContentChange} />
                    <FormHelperText color="error.main">{errors.content ? errors.content.message : ' '}</FormHelperText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                        {t("common.button.submit")}
                    </LoadingButton>
                </DialogActions>
            </Box>
            {/*關閉前詢問*/}
            <Dialog open={openAsk}>
                <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <span style={{ fontWeight: 'bold' }}>
                        {t('common.hint.notSave')}
                    </span>
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus onClick={() => { setOpenAsk(false); handleClose(); }}>{t('common.button.yes')}</Button>
                    <Button onClick={() => { setOpenAsk(false); }}>{t('common.button.no')}</Button>
                </DialogActions>
            </Dialog>
            {/*報錯*/}
            <Dialog open={openError} onClose={() => setOpenError(false)}>
                <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <Typography color="error.main">
                        {errorMessage}
                    </Typography>
                </div>
            </Dialog>
        </Dialog>
        {isLoading &&
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </>);
}