import * as React from 'react';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../responsive-components';
import { Backdrop, Box, Checkbox, CircularProgress, Dialog as HintDialog, DialogActions as HintDialogActions, FormControlLabel, FormHelperText, Grid, MenuItem, Stack, styled, TextField as OriginField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { hideModal } from '../../store/rootReducer';
import { useDispatchWithType } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { useChangeAppointmentStatusMutation } from '../services/chat';

type formData = {
    option: string;
    other: string;
}
export enum optionType {
    時間上不太合適,
    預約資訊不太清楚,
    預約細節需要修正,
    我想請其他人另外與你進行預約,
    其他
}
export interface AppointmentDeclineModalProps {
    chatId: string;
    actionId: string;

    //Controlled Props
    open?: boolean;
    onClose?: () => void;
    onFinish?: () => void;
}
export function AppointmentDeclineModal(props: AppointmentDeclineModalProps) {
    const { chatId, actionId, open: openProp, onClose: onCloseProp, onFinish } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatchWithType();

    const { current: isControlled } = React.useRef(openProp != null);
    const [open, setOpen] = React.useState(true);

    const schema = yup.object().shape({
        option: yup.string().trim().required(t('dialog.appointmentDecline.error_required')),
        other: yup.string().nullable().when("option", {
            is: optionType[optionType.其他],
            then: yup.string().trim().required(t('dialog.appointmentDecline.error_required')).max(300, t("dialog.appointmentDecline.error_limit"))
        })
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, control } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema)
    });
    const [updateAppointment, { isLoading }] = useChangeAppointmentStatusMutation();
    function onSubmit(data: formData) {
        updateAppointment({ chatId: chatId, actionId: actionId, isAccept: false, option: data.option, other: data.other }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    onClose();
                    if (isControlled) onFinish();
                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
            });
    }
    //優雅的關閉
    function onClose() {
        if (isControlled) onCloseProp();
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const [openAsk, setOpenAsk] = React.useState(false);
    const handleCancel = () => {
        setOpenAsk(true);
    };

    return (
        <>
            <Dialog open={isControlled ? openProp : open} onClose={handleCancel} maxWidth={"sm"} fullWidth sx={{ px: 2, py: 1 }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main', mr: 2 }}>
                            <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box>
                        <Typography variant="h6" fontWeight="bold">{t("dialog.appointmentDecline.title")}</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" /*onSubmit={handleOnSubmit(onSubmit)}*/>
                    <DialogContent sx={{ py: 0 }}>
                        <Stack spacing={1}>
                            <Typography>{important}{t("dialog.appointmentDecline.question")}</Typography>
                            <Controller
                                name="option"
                                control={control}
                                render={({ field }) => (<>
                                    <TextField
                                        select
                                        //size='small'
                                        variant='outlined'
                                        value={field.value ?? ""}
                                        error={!!errors.option}
                                        //helperText={errors.option?.message ?? ' '}
                                        {...register("option")}
                                        disabled={isLoading}
                                        SelectProps={{
                                            displayEmpty: true
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{t('dialog.appointmentDecline.option.empty')}</em>
                                        </MenuItem>
                                        <MenuItem value={optionType[optionType.時間上不太合適]}>
                                            {t("dialog.appointmentDecline.option.0")}
                                        </MenuItem>
                                        <MenuItem value={optionType[optionType.預約資訊不太清楚]}>
                                            {t("dialog.appointmentDecline.option.1")}
                                        </MenuItem>
                                        <MenuItem value={optionType[optionType.預約細節需要修正]}>
                                            {t("dialog.appointmentDecline.option.2")}
                                        </MenuItem>
                                        <MenuItem value={optionType[optionType.我想請其他人另外與你進行預約]}>
                                            {t("dialog.appointmentDecline.option.3")}
                                        </MenuItem>
                                        <MenuItem value={optionType[optionType.其他]}>
                                            {t("dialog.appointmentDecline.option.4")}
                                        </MenuItem>
                                    </TextField>
                                    {field.value === optionType[optionType.其他] &&
                                        <TextField
                                            size='small'
                                            variant="outlined"
                                            inputRef={register('other').ref}
                                            rows={4}
                                            placeholder={t("dialog.appointmentDecline.placeholder_other")}
                                            error={!!errors.other}
                                            helperText={errors.other ? errors.other.message : ' '}
                                            {...register("other")}
                                            disabled={isLoading}
                                        />
                                    }
                                </>)}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton /*type="submit"*/ loading={isLoading} onClick={handleOnSubmit(onSubmit)}>{t('dialog.appointmentDecline.submit')}</ConfirmButton>
                    </DialogActions>
                </Box>
                <HintDialog open={openAsk}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {t('dialog.appointmentDecline.discardEdit')}
                        </span>
                    </div>
                    <HintDialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpenAsk(false); onClose(); }}>{t('dialog.appointmentDecline.yes')}</Button>
                        <Button onClick={() => { setOpenAsk(false); }}>{t('dialog.appointmentDecline.no')}</Button>
                    </HintDialogActions>
                </HintDialog>
            </Dialog>
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}