import { Edit, Publish } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import * as React from "react";
import { Trans } from "react-i18next";
import Cropper from "../Cropper";
import { LazyImg } from "../LazyImg";


export function CropAndUpload(props: { imageUrl: string, onChange: (file: File) => void, width?: number, height?: number, transKey?:string}) {
    const {  imageUrl, onChange, width, height, transKey } = props;
    const defaultValue=200;
    const [cropedImg, setCropedImg] = React.useState<string>();
    const [cropFile, setCropFile] = React.useState<File & { preview: string; }>();


    async function handleImageCrop(file: Blob) {
        setCropFile(undefined);
        if (file) {
            const reader = new FileReader();
            reader.onload = function () {
                setCropedImg(reader.result?.toString());
                onChange(new File([file], "name.png"));

            }
            reader.readAsDataURL(file);
        }
    }
    const handleImageSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
           // setCropFile(undefined);
        let file = event.target.files?.item(0);
        if (file) {
            let reader = new FileReader();
            reader.onload = function () {
                setCropFile({ ...file as File, preview: reader.result?.toString() as string });
                console.log({ ...file as File, preview: reader.result?.toString() as string })
            }
            reader.readAsDataURL(file);
            //清空值 讓同張圖能夠再次觸發crop
            event.target.value = "";
        }
    }

    return <>
        <Button component="label" sx={{ width: 'fit-content',/* margin: 'auto', */position: "relative", d: 'flex', flex: 'column', mt: '2px' }}>
            <input hidden accept="image/*" type="file" onChange={handleImageSelected} />
            {imageUrl && <Box sx={{
                position: 'absolute', top: 0, right: 0, background: "rgba(255,255,255,0.8)", zIndex: 10,
                borderRadius: '50%', padding: '12px', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);'
            }}>
                <Edit fontSize='large' />
            </Box>}
            {imageUrl ?
                <LazyImg width={width ? width + "px" : defaultValue} height={height ? height +"px": defaultValue} src={cropedImg??imageUrl} style={{ objectFit: "contain", margin: "auto" }} />
                : <>
                    {
                        <div className="d-flex justify-content-center align-items-center"
                            style={{
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px",
                                color: "darkgray",
                                cursor: "pointer",
                                width: width ? width+"px": defaultValue,
                                height: height ? height +"px": defaultValue,
                                maxWidth:"100%"
                            }}>
                            <Publish />
                            <Trans i18nKey={transKey??'booth.editDialog.upload'} />
                        </div>
                    }
                </>
            }
        </Button>
        <Cropper
            imgSrc={cropFile}
            aspect={width/height}
            onConfirm={handleImageCrop}
            onCancel={() => setCropFile(undefined)}
        />
    </>
}