import * as React from "react";
import { useTranslation } from "react-i18next";
import { Booth } from "../components/class";
import { MediaSection, Whiteboard } from "../components/MediaSection";
import { useGetBoothRoomTokenQuery } from "../components/services/room";

interface MediaProps {
    booth: Booth;
}
export const Media = (props: MediaProps) => {
    const { booth } = props;
    const { t, i18n } = useTranslation();
    const { token } = useGetBoothRoomTokenQuery(booth.boothId, {
        selectFromResult: ({ data }) => ({ token: data?.data })
    });
    
    return (
        <div style={{ position: 'relative', height: "700px", maxHeight: "calc(100vh - 64px - 2rem)", marginBlock: "1rem" }}>
            <Whiteboard room={booth.whiteboardRoomCode} />
            <MediaSection roomId={booth.boothId} roomName={booth.chName} token={token} limited={10} />
            <div style={{ float: 'right' }}>{t('media.hint')}</div>
        </div>
    )
}