import { Logout } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { BottomNavigation, BottomNavigationAction, Box, Divider, Paper, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Booth, UserRole } from '../components/class';
import { useDispatchWithType, useSystemConnection, useUser } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { deleteAllCookie } from '../cookie-utils';
import { useLogoutMutation } from '../components/services/login';
import { logoutUser } from '../store/rootReducer';
import ManageSideBar from './ManageSideBar';
import { useGetManageBoothListQuery } from '../components/services/user';
import { useHistory } from 'react-router-dom';
import { ManagePageView, sideBarItems, viewItems } from './sideBar';
import ManageBoardPanel from './ManageBoardPanel';

export default function ManageBoardIndex() {
    const user = useUser();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 6, refId: null });
        }
    }, [systemConnection]);

    const [miniPageList, setMiniPageList] = React.useState<IManageSideBarItem[]>([]);
    const [allowSideBarList, setAllowSideBarList] = React.useState<IManageSideBarItem[]>([]);
    const [pageView, setPageView] = React.useState<ManagePageView>(viewItems);
    
    function isPass(page: IAllow) {
        if (page.allowAnonymous || user?.role > UserRole.anonymous) {
            if (!page.managerOnly || user?.boothIds.length > 0) {
                if (!page.excludeManager || user?.boothIds.length === 0) {
                    if (!page.managerOrAdminOnly || user?.boothIds.length > 0 || user?.role >= UserRole.administrator) {
                        if (!page.adminOnly || user?.role >= UserRole.administrator) {
                            if (!page.superuserOnly || user?.role >= UserRole.superuser)
                                return true;
                        }
                    }
                }
            }
        }
        return false;
    }
    function checkPageList(pageList: IManageSideBarItem[]) {
        let pages: IManageSideBarItem[] = [];
        pageList.forEach(page => {
            if (isPass(page)) {
                if (page.pageList) page.pageList = page.pageList.filter(isPass);
                pages.push(page);
            }
        });
        return pages;
    }
    function checkForMiniPage(pageList: IManageSideBarItem[]) {
        let pages: IManageSideBarItem[] = [];
        pageList.forEach(page => {
            if (page.showOnMobile) pages.push(page);
            if (page.pageList) pages.push(...checkForMiniPage(page.pageList));
        });
        return pages;
    }
    function checkView(viewList: ManagePageView) {
        Object.keys(viewList).forEach(code => {
            viewList[code].list = [...viewList[code].list].filter(isPass);
        });
        return viewList;
    }
    React.useLayoutEffect(() => {
        if (user) {
            let allowItems = checkPageList(sideBarItems);
            //setAllowSideBarList(allowItems);
            setAllowSideBarList(() => {
                //如果已有攤位資料的話，一同初始化攤位資料
                let list = [...allowItems];
                let index = list.findIndex(x => x.name === 'manage.title.booth.booth');
                if (index > -1) {
                    list[index].pageList = boothItems.map((booth) => ({
                        name: booth.chName ?? booth.boothId,
                        value: '6',
                        managerOnly: true,
                        query: `?boothId=${booth.boothId}`,
                        enName: booth.enName ?? booth.boothId,
                        noTranslate: true
                    }));
                }
                return list;
            });
            setMiniPageList(checkForMiniPage(allowItems));
            setPageView(checkView(viewItems));
        }
    }, [user]);

    //初始化攤位列表
    //多紀錄起來攤位列表的值以防初始化完後台前就初始化完攤位列表
    const [boothItems, setBoothItems] = React.useState<Booth[]>([]);
    const { data: boothDataList, isFetching, refetch } = useGetManageBoothListQuery();
    React.useLayoutEffect(() => {
        if (user) {
            if (!isFetching)
                refetch();
        }
    }, [user])
    React.useEffect(() => {
        if (boothDataList) {
            setBoothItems(boothDataList.data);
        }
    }, [boothDataList]);
    React.useEffect(() => {
        setAllowSideBarList((sideBarItems) => {
            let list = [...sideBarItems];
            let index = allowSideBarList.findIndex(x => x.name === 'manage.title.booth.booth');
            if (index > -1) {
                list[index].pageList = boothItems.map((booth) => ({
                    name: booth.chName ?? booth.boothId,
                    value: '6',
                    managerOnly: true,
                    query: `?boothId=${booth.boothId}`,
                    enName: booth.enName ?? booth.boothId,
                    noTranslate: true
                }));
            }
            return list;
        });
    }, [boothItems]);

    return (
        <Box display="flex" style={{height: "calc(100vh - 64px)"}}>
            {!isTablet ?
                <TabsBoard
                    allowSideBarList={allowSideBarList}
                    pageView={pageView}
                    defaultExpanded={["manage.title.profile.profile", "manage.title.profile.general"]}
                /> :
                <MiniBoard
                    allowSideBarList={miniPageList}
                    pageView={pageView}
                />
            }
        </Box>
    )
}

export interface IManageSideBarItem extends IAllow{
    name: string; //顯示文字，不一定district，基本上都是放translation的key，除了攤位名稱的部分
    value?: string; //對應頁面值
    className?: string; //使用scroll跳轉的對應className
    icon?: JSX.Element; //顯示圖示
    pageList?: IManageSideBarItem[]; //子頁面
    query?: string; //附加的query值
    showOnMobile?: boolean; //預設為false，是否在mini page上顯示
    enName?: string; //用來放攤位英文名
    noTranslate?: boolean; //是否不要翻譯
}
export interface ManagePageItem extends IAllow {
    className: string,
    content: JSX.Element,
}
export interface IAllow {
    allowAnonymous?: boolean; //預設為false
    managerOnly?: boolean; //預設為false，是否只准許有攤位管理權限的人閱覽
    excludeManager?: boolean; //預設為false，是否限制有攤位管理權限的人閱覽
    adminOnly?: boolean; //預設為false，是否只准許管理員以上權限閱覽
    superuserOnly?: boolean; //預設為false，是否只准許超級使用者以上權限閱覽
    managerOrAdminOnly?: boolean; //預設為false，是否准許符合 1.有攤位管理權限 2.管理員以上權限 任一種人閱覽
}

function TabsBoard(props: TabsBoardProps) {
    const { allowSideBarList, pageView, defaultExpanded } = props;

    const history = useHistory();
    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState('0');
    async function handleChange(newValue: string, query?: string) {
        await setValue(newValue);
        if (query) history.push({ search: query }); else history.push({ search: '' });
        return;
    };

    const width: number = 256;
    return (
        <TabContext value={value}>
            <Box display="flex" flexDirection="column" width={width} sx={{ "position": "sticky", "top": "64px",height:"calc(100vh - 64px)" }}>
                <ManageSideBar
                    allowSideBarList={allowSideBarList}
                    handleChange={handleChange}
                    currentPage={value}
                    defaultExpanded={defaultExpanded}
                />
            </Box>
            <Divider orientation="vertical" flexItem />
            <main id='backStagePanel' style={{ maxWidth: `calc(100% - ${width + 1}px)`, flex: 1, overflowY: "auto" }}>
                {pageView && Object.keys(pageView).map((code, index) => (
                    <ManageBoardPanel
                        key={"panel-" + code}
                        value={code}
                        view={pageView[code]}
                        sx={{ width: `calc(100vw - ${width + 1 + 17}px)` }}
                    />
                ))}
            </main>
        </TabContext>
    )
};
interface TabsBoardProps {
    allowSideBarList: IManageSideBarItem[];
    pageView: ManagePageView;
    defaultExpanded?: string[];
}

function MiniBoard(props: MiniBoardProps) {
    const { allowSideBarList, pageView } = props;
    const { t } = useTranslation();

    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState('0');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    //登出
    const dispatch = useDispatchWithType();
    const [logout] = useLogoutMutation();
    const handleClickLogout = () => {
        logout().unwrap().then(result => {
            if (result.isSuccess) {
                dispatch(logoutUser());
                deleteAllCookie();
                window.location.href = '/Login';
            }
        }).catch((error) => {
            console.log("Logout Failed!");
            console.log(error);
        });
    };

    return (
        <TabContext value={value}>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }} elevation={3}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    {allowSideBarList.map((page, index) => (
                        <BottomNavigationAction
                            key={`${index}:${page.name}`}
                            label={t(page.name)}
                            icon={page.icon}
                            value={page.value}
                            className="d-flex flex-column p-0"
                        />
                    ))}
                    <BottomNavigationAction
                        icon={<Logout />}
                        label={t('manage.title.logout')}
                        onClick={handleClickLogout}
                    />
                </BottomNavigation>
            </Paper>
            <main style={{ maxWidth: '100%', flexGrow: 1 }}>
                {pageView && Object.keys(pageView).map((code) => (
                    <ManageBoardPanel
                        key={"minipanel-" + code}
                        value={code}
                        view={pageView[code]}
                        sx={{ paddingBlockEnd: `${/*toolbar*/56 + /*custom*/8}px` }}
                    />
                ))}
            </main>
        </TabContext>
    )
};
interface MiniBoardProps {
    allowSideBarList: IManageSideBarItem[];
    pageView: ManagePageView;
}