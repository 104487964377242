import * as React from 'react';
import { Agenda, Booth, Seminar } from '../../components/class';
import { useTranslation } from 'react-i18next';
import { ResultType } from '../../components/services/utils';
import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Stack, styled, TextFieldProps, Typography } from '@mui/material';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { CountDownTimer } from '../../components/Admin/Utils';
import { ConfirmButton, important, TextField } from '../../components/responsive-components';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { useGetAllBoothNamesQuery } from '../../components/services/booth';
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { enUS, zhTW } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isToday } from 'date-fns';
import { AgendaModel } from '../../components/services/seminar';

export function AgendaEditDialog(props: AgendaEditDialogProps) {
    const { currentDate, open, defaultValue, seminars, currentSeminar, handleSubmit, handleClose } = props;

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="body"
            maxWidth="md" fullWidth
        >
            <AgendaEdit
                currentDate={currentDate}
                defaultValue={defaultValue}
                seminars={seminars}
                currentSeminar={currentSeminar}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />
        </Dialog>
    </>)
}
interface AgendaEditDialogProps {
    currentDate: Date;
    open: boolean;
    defaultValue: Agenda;
    seminars: Seminar[];
    currentSeminar: number;
    handleSubmit: (value: AgendaModel, seminarId: string) => Promise<ResultType<Agenda>>;
    handleClose: () => void;
}

const DateTimeTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        paddingTop: '12px',
    },
}));

interface formData {
    seminarId: string;
    agendaId: string;
    title: string;
    boothId: string;
    date: Date;
    startTime: Date;
    endTime: Date;
    regiStartTime: Date;
    regiEndTime: Date;
    speakerInfo: string;
    eventContent: string;
    registrationLink: string;
}
export function AgendaEdit(props: AgendaEditProps) {
    const { currentDate, defaultValue, seminars, currentSeminar, handleSubmit, handleClose } = props;
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const { boothList = [], isSuccess } = useGetAllBoothNamesQuery(null, {
        selectFromResult: ({ data, isSuccess }) => ({ boothList: data?.data ?? [], isSuccess }),
        refetchOnMountOrArgChange: true
    });

    const schema = yup.object().shape({
        seminarId: yup.string().trim().required(t('agenda.form.error_required')),
        boothId: yup.string().trim().required(t('agenda.form.error_required')),
        title: yup.string().trim().required(t('agenda.form.error_required')).max(50, t('agenda.form.error_maxStringLength')),
        date: yup.date().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid')),
        startTime: yup.date().nullable().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid')),
        endTime: yup.date().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid')).when(
            "startTime", (startTime: Date, schema) => schema.isValidSync(startTime) ? schema.test("is-greater", t('agenda.form.error_out_of_range'), (value, context) => {
                let date = format(new Date(), "yyyy/MM/dd");
                return new Date(date + " " + format(context.parent.startTime, "HH:mm")) < new Date(date + " " + format(value, "HH:mm"));
            }) : schema),
        regiStartTime: yup.date().nullable().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid')),
        regiEndTime: yup.date().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid')).when(
            "regiStartTime", (regiStartTime: Date, schema) => schema.isValidSync(regiStartTime) ? schema.min(regiStartTime, t('agenda.form.error_out_of_range')) : schema),
        speakerInfo: yup.string().trim(),
        eventContent: yup.string().trim(),
        registrationLink: yup.string().trim().url(t('agenda.form.error_url'))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, control } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: {
            seminarId: seminars[currentSeminar]?.seminarId ?? seminars[0]?.seminarId ?? "",
            agendaId: defaultValue?.agendaId ?? "",
            title: defaultValue?.title ?? "",
            boothId: defaultValue?.boothId ?? "",
            date: defaultValue ? new Date(new Date(defaultValue?.startTime).toDateString()) : new Date(),
            startTime: defaultValue ? new Date(defaultValue?.startTime) : new Date(),
            endTime: defaultValue ? new Date(defaultValue?.endTime) : new Date(),
            regiStartTime: defaultValue ? new Date(defaultValue?.regiStartTime) : new Date(),
            regiEndTime: defaultValue ? new Date(defaultValue?.regiEndTime) : new Date(),
            speakerInfo: defaultValue?.speakerInfo,
            eventContent: defaultValue?.eventContent,
            registrationLink: defaultValue?.registrationLink,
        }
    });

    const seminarIdValue = watch("seminarId");
    const boothIdValue = watch("boothId");

    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    async function onSubmit(data: formData) {
        setIsLoading(true);
        let date = format(data.date, "yyyy/MM/dd");
        let startTime = new Date(date + " " + format(data.startTime, "HH:mm")).toISOString();
        let endTime = new Date(date + " " + format(data.endTime, "HH:mm")).toISOString();
        let regiStartTime = data.regiStartTime.toISOString();
        let regiEndTime = data.regiEndTime.toISOString();
        let temp: AgendaModel = {
            ...data,
            timeSlot: { startTime: startTime, endTime: endTime },
            registration: { startTime: regiStartTime, endTime: regiEndTime }
        }
        await handleSubmit(temp, seminarIdValue).then((result) => {
            if (result.isSuccess) {
                CountDownTimer(2, handleClose);
                setSuccess(true);
            } else {
                setError(true);
            }
        }).catch((error) => {
            console.log(error);
            setError(true);
            setErrorMessage(error);
        });
        setIsLoading(false);
    }

    return (
        <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
            <DialogTitle>
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" fontWeight="bold">
                        {!!defaultValue.seminarId ? t('agenda.form.editTitle') : t('agenda.form.addTitle')}
                    </Typography>
                    <Button onClick={() => handleClose()}>{t('agenda.form.back')}</Button>
                </Box>
            </DialogTitle>
            <DialogContent style={{ flex: 1, overflow: 'auto' }}>
                <Stack spacing={1}>
                    <TextField
                        select
                        variant='standard'
                        placeholder={t('agenda.form.seminarId.placeholder')}
                        value={seminarIdValue}
                        error={!!errors.seminarId}
                        helperText={errors.seminarId?.message ?? ' '}
                        {...register("seminarId")}
                        disabled={isLoading || success}
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: "400px" }
                                }
                            }
                        }}
                    >
                        {seminars.map((option) => (
                            <MenuItem key={option.seminarId} value={option.seminarId}>
                                {i18n.language == "ch" ? option.chTitle : option.enTitle}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box id='boothId-field'>
                        <Typography>
                            {important}
                            {t('agenda.form.boothId.title')}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                select
                                variant='standard'
                                value={boothIdValue}
                                error={!!errors.boothId}
                                helperText={errors.boothId?.message ?? ' '}
                                {...register("boothId")}
                                fullWidth
                                disabled={isLoading || success}
                                SelectProps={{
                                    displayEmpty: true,
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: "400px" }
                                        }
                                    }
                                }}
                            >
                                <MenuItem disabled value="">
                                    <em>{t('agenda.form.boothId.placeholder')}</em>
                                </MenuItem>
                                {boothList.length > 0 && boothList.map((option) => (
                                    <MenuItem key={option.boothId} value={option.boothId}>
                                        {i18n.language == "ch" ? option.chName : option.enName}
                                    </MenuItem>
                                ))}
                                {!isSuccess &&
                                    <MenuItem value={null}>
                                        {t('出錯囉! 請重新整理再試試')}
                                    </MenuItem>
                                }
                            </TextField>
                        </div>
                    </Box>

                    <Box id='title-field'>
                        <Typography>
                            {important}
                            {t('agenda.form.title.title')}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                variant='standard'
                                placeholder={t('agenda.form.title.placeholder')}
                                error={!!errors.title}
                                helperText={errors.title?.message ?? ' '}
                                {...register("title")}
                                disabled={isLoading || success}
                                fullWidth
                            />
                        </div>
                    </Box>

                    <Box display='flex' flexWrap='wrap'>
                        <Box id='eventDate-field' style={{ marginRight: '12px' }}>
                            <Typography>
                                {important}
                                {t('agenda.form.eventDate.title')}
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                inputFormat="yyyy/MM/dd"
                                                mask="____/__/__"
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    error={!!errors.date}
                                                    helperText={errors.date ? errors.date.message : ' '}
                                                />}
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                </LocalizationProvider >
                            </div>
                        </Box>
                        <Box display='flex'>
                            <Box id='startTime-field'>
                                <Typography>
                                    {important}
                                    {t('agenda.form.startTime.title')}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                        <Controller
                                            name="startTime"
                                            control={control}
                                            render={({ field }) => (
                                                <TimePicker
                                                    ampm={false}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        error={!!errors.startTime}
                                                        helperText={errors.startTime ? errors.startTime.message : ' '}
                                                    />}
                                                    value={field.value}
                                                    onChange={(time) => field.onChange(time)}
                                                    disabled={isLoading}
                                                    DialogProps={{
                                                        PaperProps: {
                                                            sx: { '& *:focus': { outline: 'none' } },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider >
                                </div>
                            </Box>
                            <Divider flexItem variant='middle' style={{ width: '36px', alignSelf: 'center' }} />
                            <Box id='endTime-field'>
                                <div style={{ minHeight: '24px' }} />
                                <div style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                        <Controller
                                            name="endTime"
                                            control={control}
                                            render={({ field }) => (
                                                <TimePicker
                                                    ampm={false}
                                                    minTime={watch("startTime")}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        error={!!errors.endTime}
                                                        helperText={errors.endTime ? errors.endTime.message : ' '}
                                                    />}
                                                    value={field.value}
                                                    onChange={(time) => field.onChange(time)}
                                                    disabled={isLoading}
                                                    DialogProps={{
                                                        PaperProps: {
                                                            sx: { '& *:focus': { outline: 'none' } },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider >
                                </div>
                            </Box>
                        </Box>
                    </Box>

                    <Box display='flex'>
                        <Box id='regiStartTime-field'>
                            <Typography>
                                {important}
                                {t('agenda.form.regiStartTime.title')}
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                    <Controller
                                        name="regiStartTime"
                                        control={control}
                                        render={({ field }) => (
                                            <DateTimePicker
                                                inputFormat="yyyy/MM/dd HH:mm"
                                                mask="____/__/__ __:__"
                                                ampm={false}
                                                maxDateTime={watch("regiEndTime")}
                                                renderInput={(params) => <DateTimeTextField
                                                    {...params}
                                                    error={!!errors.regiStartTime}
                                                    helperText={errors.regiStartTime ? errors.regiStartTime.message : ' '}
                                                />}
                                                value={field.value}
                                                onChange={(time) => field.onChange(time)}
                                                disabled={isLoading}
                                                DialogProps={{
                                                    PaperProps: {
                                                        sx: { '& *:focus': { outline: 'none' } },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider >
                            </div>
                        </Box>
                        <Divider flexItem variant='middle' style={{ width: '36px', alignSelf: 'center' }} />
                        <Box id='regiEndTime-field'>
                            <div style={{ minHeight: '24px' }} />
                            <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                    <Controller
                                        name="regiEndTime"
                                        control={control}
                                        render={({ field }) => (
                                            <DateTimePicker
                                                inputFormat="yyyy/MM/dd HH:mm"
                                                mask="____/__/__ __:__"
                                                ampm={false}
                                                minDateTime={watch("regiStartTime")}
                                                renderInput={(params) => <DateTimeTextField
                                                    {...params}
                                                    error={!!errors.regiEndTime}
                                                    helperText={errors.regiEndTime ? errors.regiEndTime.message : ' '}
                                                />}
                                                value={field.value}
                                                onChange={(time) => field.onChange(time)}
                                                disabled={isLoading}
                                                DialogProps={{
                                                    PaperProps: {
                                                        sx: { '& *:focus': { outline: 'none' } },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider >
                            </div>
                        </Box>
                    </Box>

                    <Box id='speakerInfo-field'>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ marginRight: '14px' }} display='flex' alignItems='center'>
                                {t('agenda.form.speakerInfo.title')}
                            </Typography>
                        </div>
                        <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            placeholder={t('agenda.form.speakerInfo.placeholder')}
                            error={!!errors.speakerInfo}
                            helperText={errors.speakerInfo?.message ?? ' '}
                            {...register("speakerInfo")}
                            disabled={isLoading || success}
                            fullWidth
                        />
                    </Box>

                    <Box id='eventContent-field'>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ marginRight: '14px' }} display='flex' alignItems='center'>
                                {t('agenda.form.eventContent.title')}
                            </Typography>
                        </div>
                        <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            placeholder={t('agenda.form.eventContent.placeholder')}
                            error={!!errors.eventContent}
                            helperText={errors.eventContent?.message ?? ' '}
                            {...register("eventContent")}
                            disabled={isLoading || success}
                            fullWidth
                        />
                    </Box>

                    {/*     <Box id='registrationLink-field'>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ marginRight: '14px' }} display='flex' alignItems='center'>
                                {t('agenda.form.registrationLink.title')}
                            </Typography>
                        </div>
                        <TextField
                            variant='outlined'
                            placeholder={t('agenda.form.registrationLink.placeholder')}
                            error={!!errors.registrationLink}
                            helperText={errors.registrationLink?.message ?? ' '}
                            {...register("registrationLink")}
                            disabled={isLoading || success}
                            fullWidth
                        />
                    </Box>*/}
                </Stack>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <ConfirmButton type='submit' loading={isLoading} disabled={success} >
                    {success ?
                        <Typography color='green'>
                            {t('agenda.form.success')}
                        </Typography> :
                        <Typography>
                            {t('agenda.form.submit')}
                        </Typography>
                    }
                </ConfirmButton>
                {error &&
                    <Typography color='error'>
                        {errorMessage !== "" ? errorMessage : t('agenda.form.error')}
                    </Typography>
                }
            </DialogActions>
        </Box>
    )
}
interface AgendaEditProps {
    currentDate: Date;
    defaultValue: Agenda;
    seminars: Seminar[];
    currentSeminar: number;
    handleSubmit: (value: AgendaModel, seminarId: string) => Promise<ResultType<Agenda>>;
    handleClose: () => void;
    maxDate?: Date;
    minDate?: Date;
}