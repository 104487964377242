import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Agenda, Booth,User, UserRole} from '../class';
import { TimeSlot } from './seminar';
import { ResultType } from './utils';



//QRCode掃描紀錄
export interface QRCodeHistory {
    user: User;
    userRecords: UserRecord[];
    boothRecords: BoothRecord[];
}
//使用者QRCode被掃描紀錄
export interface UserRecordList {
    user: User;
    userRecords: UserRecord[];
}
//攤位QRCode被掃描紀錄
export interface BoothRecordList {
    booth: Booth;
    userRecords: UserRecord[];
}

export interface UserRecord {
    user: User;
    time: string;
}
export interface BoothRecord {
    booth: Booth;
    time: string;
}
export interface QRCode {
    name: string;
    url: string;
}

//報到紀錄
export class SignInActivity {
    id: string;
    title: string;
    qrCodeUrl: string;
    timeSlot: TimeSlot;
    userSigns: UserSign[];
    createTime: string;
    updateTime: string;
}
export class UserSign {
    id: string;
    level: UserRole;
    name: string;
    email: string;
    college: string;
    department: string;
    time: string;
}

class AttendModel {
    seminarId: string
    hash: string
}

class AttendVisitModel {
    visitId: string
    hash: string
}

export class SignInAgenda {
    agenda: Agenda;
    userSigns: UserSign[];
}
export class MySignInAgenda {
    agenda: Agenda;
    userSign: UserSign;
}

export class UpdateSignInActivityModel {
    id: string;
    title: string;
    timeSlot: TimeSlot;
}
export class AllBoothQRCodeHistoryList {
    booth: Booth;
    scanedNumber: number
}

//超級攤位QRCode被掃描紀錄
export interface UserRecordWithBooth {
    user: User;
    booth: Booth;
    time: string;
}

export const baseApi = createApi({
    reducerPath: 'qrcodeApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/qrcode' }),
    endpoints: (builder) => ({
        getQRCodeHistory: builder.query<ResultType<QRCodeHistory>, string>({
            query: (userId) => ({
                url: `${userId}`
            }),
        }),
        getBoothQRCodeHistory: builder.query<ResultType<BoothRecordList>, string>({
            query: (boothId) => ({
                url: `booth/${boothId}`
            }),
        }),
        getUserQRCodeHistory: builder.query<ResultType<UserRecordList>, string>({
            query: (userId) => ({
                url: `user/${userId}`
            }),
        }),
        getBoothQRCode: builder.query<ResultType<QRCode>, string>({
            query: (boothId) => ({
                url: `generate/booth/${boothId}`
            }),
        }),
        getUserQRCode: builder.query<ResultType<QRCode>, string>({
            query: (userId) => ({
                url: `generate/user/${userId}`
            }),
        }),
        verifyBoothQRCode: builder.mutation<ResultType<void>, { boothId: string, code: string }>({
            query: ({ boothId, code }) => ({
                url: `verify/booth/${boothId}`,
                method: 'POST',
                body: { code: code },
            }),
        }),
        verifyUserQRCode: builder.mutation<ResultType<void>, { userId: string, code: string }>({
            query: ({ userId, code }) => ({
                url: `verify/user/${userId}`,
                method: 'POST',
                body: { code: code },
            }),
        }),
        //說明會報到相關
        getMyAgendaSignInHistory: builder.query<ResultType<MySignInAgenda[]>, void>({
            query: () => ({
                url: `agenda`
            }),
        }),
        attendQRCode: builder.mutation<ResultType<{ title: string }>, AttendModel>({
            query: ({ ...patch }) => ({
                url: `attend`,
                method: 'POST',
                body: patch
            }),
        })
    })
});

//exhibitor或superuser或admin用api
export const qrcodeApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
       getAllQRCodeHistory: builder.query<ResultType<QRCodeHistory[]>, void>({
            query: () => ``,
        }),
        getAllBoothQRCodeHistory: builder.query<ResultType<AllBoothQRCodeHistoryList[]>, void>({
            query: () => `allbooth`,
        }),
        getAllBoothQRCode: builder.query<ResultType<QRCode[]>, void>({
            query: () => `generate/booth`,
        }),
        getAllAgendaSignInHistory: builder.query<ResultType<SignInAgenda[]>, void>({
            query: () => ({
                url: `agendaList`
            }),
        }),
        getAgendaQRCode: builder.query<ResultType<QRCode>, { seminarId: string, agendaId: string }>({
            query: ({ seminarId, agendaId }) => ({
                url: `generate/agenda/${seminarId}/${agendaId}`
            }),
        }),
        getVisitQRCode: builder.query<ResultType<QRCode>, string>({
            query: ( visitId ) => ({
                url: `generate/visit/${visitId}`
            }),
        }),
        getEveryBoothQRCodeHistory: builder.query<ResultType<UserRecordWithBooth[]>, void>({
            query: () => ({
                url: `allbooth/bigbigapi`
            }),
        }),
        /*getAllSignInActivity: builder.query<ResultType<SignInActivity[]>, void>({
            query: () => ({
                url: `signIn`
            }),
        }),
        addSignInActivity: builder.mutation<ResultType<SignInActivity>, UpdateSignInActivityModel>({
            query: ({ ...patch }) => ({
                url: `signIn`,
                method: 'POST',
                body: patch
            }),
        }),
        editSignInActivity: builder.mutation<ResultType<SignInActivity>, UpdateSignInActivityModel>({
            query: ({ ...patch }) => ({
                url: `signIn`,
                method: 'PATCH',
                body: patch
            }),
        }),*/
    }),
    overrideExisting: false
});

export const { useGetQRCodeHistoryQuery } = qrcodeApi;
export const { useLazyGetQRCodeHistoryQuery } = qrcodeApi;
export const { useGetBoothQRCodeHistoryQuery } = qrcodeApi;
export const { useLazyGetBoothQRCodeHistoryQuery } = qrcodeApi;
export const { useGetUserQRCodeHistoryQuery } = qrcodeApi;
export const { useGetBoothQRCodeQuery } = qrcodeApi;
export const { useLazyGetBoothQRCodeQuery } = qrcodeApi;
export const { useGetUserQRCodeQuery } = qrcodeApi
export const { useLazyGetUserQRCodeQuery } = qrcodeApi;
export const { useVerifyBoothQRCodeMutation } = qrcodeApi;
export const { useVerifyUserQRCodeMutation } = qrcodeApi;
export const { useGetMyAgendaSignInHistoryQuery } = qrcodeApi
export const { useAttendQRCodeMutation } = qrcodeApi;

//exhibitor或superuser或admin用api
export const { useLazyGetAgendaQRCodeQuery } = qrcodeApi;
export const { useLazyGetVisitQRCodeQuery } = qrcodeApi;
export const { useGetAllQRCodeHistoryQuery } = qrcodeApi;
export const { useGetAllBoothQRCodeHistoryQuery } = qrcodeApi;
export const { useGetAllBoothQRCodeQuery } = qrcodeApi;
export const { useGetAllAgendaSignInHistoryQuery } = qrcodeApi;
export const { useGetEveryBoothQRCodeHistoryQuery } = qrcodeApi;