import * as React from 'react';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { useGetAllInfoCardQuery } from '../../../components/services/booth';
import { IInfoCard } from '../../../components/class';
import { Box, Button, Paper, styled, Tooltip, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import { InfoEditDialog } from '../../../components/InfoCard/InfoEditDialog';
import InfoDeleteDialog from '../../../components/InfoCard/InfoDeleteDialog';
import format from 'date-fns/format';
import { zhTWGrid } from '../../data-grid-locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function InfoCardList() {
    const { t } = useTranslation();

    //獲取資訊小卡資料
    const { data: infoCards, isLoading, refetch } = useGetAllInfoCardQuery(null, { refetchOnMountOrArgChange: true });

    //控制編輯&新增彈框
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openInsert, setOpenInsert] = React.useState<boolean>(false);
    const [editInfo, setEditInfo] = React.useState<IInfoCard>();
    function handleOpenEdit(info: IInfoCard) {
        setEditInfo(info);
        setOpenEdit(true);
    }
    function handleOpenInsert() {
        setOpenInsert(true);
    }

    const handleSave = () => {
        setOpenEdit(false);
        setOpenInsert(false);
        refetch();
    };

    //控制刪除&還原彈框
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [openRestore, setOpenRestore] = React.useState<boolean>(false);
    const [targetInfo, setTargetInfo] = React.useState<IInfoCard>();
    function handleOpenDelete(info: IInfoCard) {
        setTargetInfo(info);
        setOpenDelete(true);
    };
    const handleDelete = () => {
        setOpenDelete(false);
        let info = targetInfo;
        setTargetInfo(null);
        refetch();
    };
    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.infoCardList.title')}
        />
        <Box mb={2} >
            <Button color="secondary" onClick={handleOpenInsert} startIcon={<Add />}>{t("manage.infoCardList.addButton")}</Button>
        </Box>
        <Paper variant="outlined">
            <InfoCardDataList
                dataList={infoCards?.data.map(item => ({ id: item.dataId, ...item } as GridDataRow)) ?? []}
                loading={isLoading}
                handleOpenEdit={handleOpenEdit}
                handleOpenDelete={handleOpenDelete}
            />
        </Paper>
        <EditDialog open={openEdit} initData={editInfo} onClose={() => setOpenEdit(false)} onSave={handleSave} />
        <InsertDialog open={openInsert} onClose={() => setOpenInsert(false)} onSave={handleSave} />
        <DeleteDialog open={openDelete} data={targetInfo} onClose={() => { setOpenDelete(false); setTargetInfo(null); }} onDelete={handleDelete} />
    </>)
};
const EditDialog = styled(InfoEditDialog)({});
const InsertDialog = styled(InfoEditDialog)({});
const DeleteDialog = styled(InfoDeleteDialog)({});

interface GridDataRow extends IInfoCard {
    id: string;
}
interface InfoCardDataListProps {
    dataList: GridDataRow[];
    loading?: boolean;
    handleOpenEdit: (info: IInfoCard) => void;
    handleOpenDelete: (info: IInfoCard) => void;
}
function InfoCardDataList(props: InfoCardDataListProps) {
    const { dataList, loading, handleOpenEdit, handleOpenDelete } = props;
    const { t, i18n } = useTranslation();

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'id',
            headerName: t('manage.infoCardList.options.id'),
            filterable: false,
            width: 70,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },
        {
            field: 'imgUrl',
            headerName: t('manage.infoCardList.options.image'),
            width: 100,
            renderCell: ({ value }: GridRenderCellParams<string>) => {
                if (value && value != "")
                    return <img width="88.8px" height="50px" src={value} style={{ objectFit: "contain" }} />
                else
                    return i18n.language === "ch" ? "無" : "empty"
            },
        },
        {
            field: 'title',
            headerName: t('manage.infoCardList.options.title'),
            flex: 1,
            minWidth: 150,
            //valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'createTime',
            headerName: t('manage.infoCardList.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm:ss"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={t("manage.infoCardList.options.actions.edit")}>
                            <Edit />
                        </Tooltip>
                    }
                    onClick={() => handleOpenEdit(row)}
                    label={t("manage.infoCardList.options.actions.edit")}
                />,
                <GridActionsCellItem
                    icon={<Delete color="error" />}
                    onClick={() => handleOpenDelete(row)}
                    label={
                        <Typography color="error.main">
                            {t("manage.infoCardList.options.actions.delete")}
                        </Typography> as any
                    }
                    showInMenu
                />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid rows={dataList} columns={columns}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
                loading={loading}
            />
        </ThemeProvider >
    )
}