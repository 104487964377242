import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faClock, faEllipsisH, faMinus, faStamp, faTasks, faTimes, faUserPlus, faVideo } from '@fortawesome/free-solid-svg-icons'
import { Message, Mood, RoomService, Send } from "@mui/icons-material";
import { Avatar, Badge, Box, Button as MuiButton, ButtonProps, Collapse, Fab, IconButton, InputAdornment, InputBase, List, ListItem, Paper, Skeleton, Slide, Stack, styled, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { DialogState } from '../ChatSystem';
import { useUser } from '../../../store';
import { CardType, DialogContent, MessageContent, Participant } from '../../class';
import { BussinessCard } from '../../BussinessCard/BussinessCardInfo';
import UserOnlineBadge from '../../responsive-components/UserOnlineBadge';
import { ChatMessage } from './ChatMessage';
import { differenceInDays, format, formatRelative, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import zhTW from 'date-fns/locale/zh-TW';
import enUS from 'date-fns/locale/en-US';
import { CheckIsMobile } from '../../Admin/Utils';

const formatRelativeLocale = {
    lastWeek: "'上'eee HH:mm",
    yesterday: "'昨天' HH:mm",
    today: "'今天' HH:mm",
    tomorrow: "'明天' HH:mm",
    nextWeek: "'下'eee HH:mm",
    other: 'yyyy/MM/dd ccc HH:mm',
};
export function relativeDate(date, baseDate, language) {
    let options = { locale: enUS };

    if (language == "ch") {
        options = {
            locale: {
                ...zhTW,
                formatRelative: token => formatRelativeLocale[token]
            }
        };
    }
    return formatRelative(date, baseDate, options);
}

interface ChatDialogProps {
    chatData: DialogContent;
    chatState: DialogState; //目前沒用到，但應該會放像是開啟的視訊房間資料?
    onMinimize: (id: string) => void;
    onClose: (id: string) => void;

    //輸入框
    onSend: (id: string, text: string) => void;
    shouldFocus: boolean;

    //互動功能區
    onClickVideoChat: (id: string) => void;
    onClickAppointment: (receiverId: string) => void;
    onClickExchange: (receiverId: string) => void;
    onClickStamp: (id: string) => void;

    disableVideoChat?: boolean;
    disableAppointment?: boolean;
    disableExchange?: boolean;
    disableStamp?: boolean;

    //其他
    onDeleteMessage: (id: string, messageId: string) => void;
    onRingBell: (boothId: string, receiverId: string) => void;
    onActive: (id: string) => void;

    //手機版
    mobile?: boolean;
    draggableRef?: React.Ref<HTMLDivElement>;
    onBack?: (id: string) => void;
}
export type MessageData = MessageContent & { pileUp?: pileUpProps, fromMe: boolean, hasBeenRead: boolean | number };
export interface pileUpProps {
    head: boolean;
    tail: boolean;
}
export type TimeData = { timestamp: string };
export enum HintType { 無 = 0, 企業離線, 對方於視訊中 }

export function ChatDialog(props: ChatDialogProps) {
    const { chatData, chatState, onMinimize, onClose, onSend, shouldFocus, onClickVideoChat, onClickAppointment, onClickExchange, onClickStamp, disableVideoChat, disableAppointment, disableExchange, disableStamp, onDeleteMessage, onRingBell, onActive, mobile, draggableRef, onBack } = props;
    const user = useUser();
    const { t, i18n } = useTranslation();
    const [showPensonalInfo, setShowPensonalInfo] = React.useState(false);
    const [hintType, setHintType] = React.useState<HintType>(HintType.無);
    const [userDatas, setUserDatas] = React.useState<Participant[]>();
    const [messages, setMessages] = React.useState<(MessageData | TimeData)[]>([]);

    React.useLayoutEffect(() => {
        if (user && chatData) {
            let userDatas = chatData ? chatData.participants.filter(x => x.info.userId !== user.userId) : null;
            setUserDatas(userDatas);
            if (userDatas) {
                setMessages(() => {
                    let list: (MessageData | TimeData)[] = [];
                    chatData.messages.forEach((item, index, array) => {
                        let isPileUp: boolean = false, isHead: boolean = false, isTail: boolean = false, isEarliestOnTheDay: boolean = false;
                        //比對前一個
                        if (index !== 0) {
                            if (!item.isDeleted) {
                                if (item.senderId === array[index - 1].senderId && !array[index - 1].isDeleted) {
                                    isPileUp = true;
                                }
                                else {
                                    isHead = true;
                                }
                            }
                            if (!isSameDay(new Date(item.createTime), new Date(array[index - 1].createTime))) {
                                isHead = true;
                                isEarliestOnTheDay = true;
                            }
                        } else { isHead = true; isEarliestOnTheDay = true; }
                        //比對後一個
                        if (index !== array.length - 1) {
                            if (!item.isDeleted) {
                                if (item.senderId === array[index + 1].senderId && !array[index + 1].isDeleted) {
                                    isPileUp = true;
                                }
                                else {
                                    isTail = true;
                                }
                            }
                            if (!isSameDay(new Date(item.createTime), new Date(array[index + 1].createTime))) {
                                isTail = true;
                            }
                        } else { isTail = true; }
                        if (isEarliestOnTheDay) {
                            list.push({ timestamp: relativeDate(new Date(item.createTime), new Date(), i18n.language) });
                        }
                        //是否已讀
                        let hasBeenRead = userDatas.length == 1 ? userDatas[0].lastSeenTime >= item.createTime : userDatas.filter(x => x.lastSeenTime >= item.createTime).length;
                        if (isPileUp) {
                            list.push(Object.assign({}, item, { pileUp: { head: isHead, tail: isTail }, fromMe: item.senderId === user.userId, hasBeenRead: hasBeenRead }));
                        } else {
                            list.push(Object.assign({}, item, { pileUp: null, fromMe: item.senderId === user.userId, hasBeenRead: hasBeenRead }));
                        }
                    });
                    return list;
                });
            }
        }
    }, [user, chatData]);
    const dialogRef = React.useRef<HTMLElement>();
    const [active, setActive] = React.useState(false);
    React.useLayoutEffect(() => {
        function handleClickInsideOrOutside(event) {
            //Outside
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setActive(false);
            }
            //Inside
            else {
                setActive(true);
            }
        }
        document.addEventListener("mousedown", handleClickInsideOrOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickInsideOrOutside);
        };
    }, [dialogRef]);

    React.useEffect(() => { if (active && chatData) onActive(chatData.chatId) }, [active]);
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (inputRef.current && shouldFocus) {
            inputRef.current.focus();
        }
    }, [shouldFocus, inputRef])

    const msgContainerRef = React.useRef<HTMLDivElement>();
    const [msgLength, setMsgLength] = React.useState<number>(null);
    const [overflow, setOverflow] = React.useState<boolean>(false); //用來鎖定手機滾動
    React.useEffect(() => {
        if (messages.length > 0) {
            //第一次開啟
            if (msgLength == null) {
                setMsgLength(messages.length);
                setActive(true);
                scrollToBottom(msgContainerRef);
            }
            //有新訊息
            else if (msgLength < messages.length) {
                //收到自己傳送的新訊息，就滾到最下面
                setMsgLength(messages.length);
                if ((messages[messages.length - 1] as MessageData).senderId === user.userId) {
                    scrollToBottom(msgContainerRef);
                }
                //收到其他人傳送的新訊息
                else {
                    //如果active，提醒有新訊息(文字)
                    if (active) {

                    }
                    //否則，提醒有新訊息(動態)
                    else {
                        scrollToBottom(msgContainerRef);
                    }
                }
            }

            //手機版
            //檢查是否有overflow
            if (msgContainerRef.current)
                setOverflow(msgContainerRef.current.offsetHeight < msgContainerRef.current.scrollHeight);
        }
    }, [messages]);
    const scrollToBottom = (ref) => {
        //給些時間等api抓完
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollTop = ref.current.scrollHeight;
            }
        }, 200);
    };

    const handleMore = () => {
        setShowPensonalInfo((prev) => !prev);
    };
    const handleMinimize = () => {
        if (chatData)
            onMinimize(chatData.chatId);
    };
    const handleClose = () => {
        if (chatData)
            onClose(chatData.chatId);
    };

    const handleBack = () => {
        if (chatData)
            onBack(chatData.chatId);
    };

    const [editValue, setEditValue] = React.useState<string>("");
    //文字訊息編輯
    const handleEditText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(event.target.value);
    };

    //文字訊息送出
    const handleSubmit = () => {
        //過濾&裁減空訊息
        const submitMessage: string = editValue.trim();
        if (submitMessage.length < 1) {
            setEditValue("");
            return;
        }

        if (chatData) {
            onSend(chatData.chatId, submitMessage);
            setEditValue("");
        }
    };

    //互動按鈕
    const handleClickVideoChat = () => {
        if (chatData)
            onClickVideoChat(chatData.chatId);
    };
    const handleClickAppointment = () => {
        if (userDatas && userDatas.length == 1)
            onClickAppointment(userDatas[0].info.userId);
    };
    const handleClickExchange = () => {
        if (userDatas && userDatas.length == 1)
            onClickExchange(userDatas[0].info.userId);
    };
    const handleClickStamp = () => {
        if (chatData)
            onClickStamp(chatData.chatId);
    };

    //提示欄
    const Button = CardDialogHintButton;
    React.useEffect(() => {
        if (userDatas && userDatas.length == 1) {
            if (userDatas[0].info.boothIds.length > 0 && userDatas[0].info.type == CardType.nonStudent && !userDatas[0].isOnline)
                setHintType(HintType.企業離線);
            else if (chatState && chatState.VideoChatingIds.length > 0 && chatState.VideoChatingIds.findIndex(x => x != user.userId) > -1)
                setHintType(HintType.對方於視訊中);
            else
                setHintType(HintType.無);
        }
    }, [chatState, userDatas]);
    const handleClickServiceBell = () => {
        if (userDatas)
            console.log("還沒修好")
            //看要怎救他，他是陣列了
            //onRingBell(userDatas[0].info.boothId, userDatas[0].info.userId);
    };

    return (
        <Box ref={dialogRef} position="relative" width={mobile ? "100%" : 350} height={mobile ? "100%" : 455} ml={mobile ? 0 : 2} sx={{ "& *": { touchAction: "none" }}}>
            <Paper elevation={12} sx={{ width: "100%", height: "100%", borderRadius: "10px 10px 0 0" }}>
                <Stack height="100%">
                    { /*聊天室header*/}
                    <Box ref={draggableRef} display="flex" alignItems="center" p={1} sx={{
                        background: (theme) => theme.palette.background.paper,
                        boxShadow: "rgb(0 0 0 / 16%) 0px 2px 9px", borderRadius: "10px 10px 0 0",
                        position: "relative", height: "56px",
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}>
                        {mobile &&
                            <Box display="flex" flex="0 0">
                            <Tooltip title={t('chat.dialog.back')}>
                                    <IconButton onClick={handleBack}>
                                        <Box display="flex" width="20px" height="20px" justifyContent="center" alignItems="center" className={showPensonalInfo ? "rotate" : ""} sx={{ color: userDatas ? 'primary.main' : '#c1c1c1' }}>
                                            <FontAwesomeIcon icon={faArrowLeft} transform="shrink-3" />
                                        </Box>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                        <Box display="flex" flex="0 0" ml={0.5} mr={1.5}>
                            {userDatas ? <>
                                {userDatas.length == 1 ?
                                    <UserOnlineBadge isOnline={userDatas[0].isOnline}>
                                        <Avatar src={userDatas[0].info.logoUrl} />
                                    </UserOnlineBadge>
                                    :
                                    <Box>
                                        <Avatar src={userDatas[0].info.logoUrl} />
                                        <Avatar src={userDatas[1].info.logoUrl} />
                                    </Box>
                                }</>
                                : <Skeleton variant="circular" width="40px" height="40px" />
                            }
                        </Box>
                        <Stack flex="1 1" sx={{ overflow: "hidden" }}>
                            {userDatas ? <>
                                {userDatas.length == 1 ?
                                    <>
                                        <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {!userDatas[0].info.alias || userDatas[0].info.alias.length === 0 ? userDatas[0].info.name : userDatas[0].info.alias}
                                        </Typography>
                                        <Typography variant="caption" noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", lineHeight: "1" }}>
                                            {userDatas[0].info.type === CardType.student && <>
                                                {userDatas[0].info.infoFields.find(x => x.fieldId === 'school')?.value}
                                                {userDatas[0].info.infoFields.find(x => x.fieldId === 'department')?.value}
                                            </>}
                                            {userDatas[0].info.type === CardType.nonStudent && <>
                                                {userDatas[0].info.infoFields.find(x => x.fieldId === 'companyName')?.value}
                                                {userDatas[0].info.infoFields.find(x => x.fieldId === 'jobTitle')?.value}
                                            </>}
                                            {/*(userDatas[0].info.userData as StudentData).school}{(userDatas[0].info.userData as StudentData).department}
                                            {(userDatas[0].info.userData as NonStudentData).companyName}{(userDatas[0].info.userData as NonStudentData).jobTitle*/}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {chatData.title}
                                        </Typography>
                                        <Typography variant="caption" noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        </Typography>
                                    </>
                                }</>
                                : <><Skeleton height={10} width="90%" />
                                    <Skeleton height={10} width="40%" /></>
                            }
                        </Stack>
                        <Box display="flex" flex="0 0">
                            <Tooltip title={t('chat.dialog.information')}>
                                <IconButton onClick={handleMore}>
                                    <Box display="flex" width="20px" height="20px" justifyContent="center" alignItems="center" className={showPensonalInfo ? "rotate" : ""} sx={{
                                        color: userDatas ? 'primary.main' : '#c1c1c1',
                                        transition: (theme) => theme.transitions.create("all"),
                                        "&.rotate": { transform: "rotateZ(90deg)" }
                                    }}>
                                        <FontAwesomeIcon icon={faEllipsisH} transform="shrink-3" />
                                    </Box>
                                </IconButton>
                            </Tooltip>
                            {!mobile &&
                                <Tooltip title={t('chat.dialog.tooltip_minimize')}>
                                    <IconButton onClick={handleMinimize}>
                                        <Box display="flex" width="20px" height="20px" justifyContent="center" alignItems="center" sx={{ color: userDatas ? 'primary.main' : '#c1c1c1' }}>
                                            <FontAwesomeIcon icon={faMinus} transform="shrink-4" /></Box></IconButton>
                                </Tooltip>
                            }
                            <Tooltip title={t('chat.dialog.tooltip_close')}>
                                <IconButton onClick={handleClose}>
                                    <Box display="flex" width="20px" height="20px" justifyContent="center" alignItems="center" sx={{ color: userDatas ? 'primary.main' : '#c1c1c1' }}>
                                        <FontAwesomeIcon icon={faTimes} transform="shrink-3" /></Box></IconButton>
                            </Tooltip>
                        </Box>
                        {mobile &&
                            <Box position="absolute" width="100%" top="6px" left={0} display="flex" justifyContent="center">
                                <Box width="32px" height="4px" bgcolor="grey.400" border="0px solid" borderRadius="10px" />
                            </Box>
                        }
                    </Box>
                    { /*提示欄顯示區*/}
                    <Box position="relative" sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}>
                        <Box sx={{
                            position: "absolute", left: 0, right: 0, maxHeight: "50px",
                            transition: (theme) => theme.transitions.create("all"),
                            transform: hintType === HintType.無 ? "translateY(-50px)" : "translateY(0px)",
                            backdropFilter: "blur(4px) brightness(50%)", px: 1.5, py: 1,
                            display: "flex", justifyContent: "space-around", alignItems: "center"
                        }}>
                            {/*hintType === HintType.企業離線 && <>
                                <Typography variant="body2" color="white">
                                    {t('chat.dialog.offline_message')}
                                </Typography>
                                <Button variant="contained" sx={{
                                    color: "text.primary", background: "white", flexShrink: 0,
                                    alignItems: "center",
                                    "&:hover": { background: "#E0E0E0" }
                                }} onClick={handleClickServiceBell}>
                                    <RoomService sx={{ color: '#FBA017', fontSize: "1rem", mr: 0.5 }} />
                                    {t('chat.dialog.serviceBell')}
                                </Button></>
                            */}
                            {hintType === HintType.對方於視訊中 && <>
                                <Typography variant="body2" color="white">
                                    {t('chat.dialog.video_open')}
                                </Typography>
                                <Button variant="contained" color="primary" onClick={handleClickVideoChat}>
                                    {t('chat.dialog.video_enter')}
                                </Button></>
                            }
                        </Box>
                    </Box>
                    { /*名片顯示區*/}
                    <Box position="relative" sx={{ "& .MuiTypography-root": { wordBreak: "break-word" } }}>
                        <Collapse in={showPensonalInfo} sx={{ position: "absolute", left: 0, right: 0 }}>
                            <Box sx={{
                                boxShadow: "rgb(0 0 0 / 16%) 0px 2px 9px", background: (theme) => theme.palette.background.paper,
                                overflow: "hidden auto", maxHeight: "200px",
                                position: "relative", zIndex: (theme) => theme.zIndex.appBar + 1
                            }}>
                                {userDatas && userDatas.length == 1 &&
                                    <BussinessCard data={userDatas[0].info} fixed disableExchange disableSendMessage />
                                }
                                {userDatas && userDatas.length > 1 &&
                                    <>
                                        <Avatar sx={{ width: "80px", height: "80px", m: 2 }} />
                                        <Stack mt={2}>
                                            <Typography fontWeight="bold">大中天</Typography>
                                            <Typography>學校學校</Typography>
                                            <Typography>反正就是排版</Typography>
                                            <Typography>反正就是排版2</Typography>
                                            <Typography>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</Typography>
                                        </Stack>
                                    </>
                                }
                            </Box>
                        </Collapse>
                    </Box>
                    { /*訊息顯示區*/}
                    <Box ref={msgContainerRef} flex="1 1" sx={{ "& .MuiTypography-root": { wordBreak: "break-word", whiteSpace: "pre-wrap" }, overflowY: "auto", overscrollBehavior: "none", "&, & *": { touchAction: overflow ? "pan-y" : "none" } }}>
                        <List>
                            {messages.map((message) => {
                                //時間標記
                                if ((message as TimeData).timestamp)
                                    return (<ListItem key={(message as TimeData).timestamp} disablePadding sx={{ justifyContent: "center" }}>
                                        <Typography variant="caption" color="rgba(148, 205, 245, 0.69)" >{(message as TimeData).timestamp}</Typography></ListItem>)
                                //一般訊息
                                else
                                    return (<ChatMessage key={(message as MessageData).messageId} message={(message as MessageData)} userDatas={userDatas.map(x => x.info)} onDelete={onDeleteMessage} />)
                            })}
                        </List>
                    </Box>
                    { /*輸入區*/}
                    <Box display="flex" alignItems="flex-end" px={1} py={0.5}>
                        {!disableVideoChat &&
                            <Tooltip title={t('chat.dialog.tooltip_video_send')}>
                                <IconButton size="small" onClick={handleClickVideoChat}>
                                    <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: userDatas ? 'primary.main' : '#c1c1c1' }}>
                                        <FontAwesomeIcon icon={faVideo} color="inherit" fixedWidth /></Box></IconButton>
                            </Tooltip>
                        }
                        {userDatas && userDatas.length == 1 && <>
                            {!disableAppointment &&
                                <Tooltip title={t('chat.dialog.tooltip_appointment')}>
                                    <IconButton size="small" onClick={handleClickAppointment}>
                                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main' }}>
                                            <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box></IconButton>
                                </Tooltip>
                            }
                            {!disableExchange &&
                                <Tooltip title={userDatas[0].hasBusinessCard ? t('chat.dialog.tooltip_has_exchanged') : t('chat.dialog.tooltip_exchange')}><span>
                                    <IconButton size="small" onClick={handleClickExchange} disabled={userDatas[0].hasBusinessCard}>
                                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: userDatas[0].hasBusinessCard ? '#c1c1c1' : 'primary.main' }}>
                                            <FontAwesomeIcon icon={faUserPlus} color="inherit" fixedWidth /></Box></IconButton></span>
                                </Tooltip>
                            }
                            {!disableStamp &&
                                <Tooltip title={t('chat.dialog.tooltip_stamp')}>
                                    <IconButton size="small" onClick={handleClickStamp}>
                                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center">
                                            <FontAwesomeIcon icon={faStamp} fixedWidth /></Box></IconButton>
                                </Tooltip>
                            }
                        </>}
                        <ChatDialogInput editable={!!userDatas} editValue={editValue} onEditValueChange={handleEditText} onSend={handleSubmit} inputRef={inputRef} />
                    </Box>
                </Stack>
            </Paper>
        </Box>
    )
}

interface ChatDialogInputProps {
    editable: boolean;
    editValue: string;
    onEditValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSend: () => void;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    sx?: SxProps<Theme>;
}
export function ChatDialogInput(props: ChatDialogInputProps) {
    const { editable, editValue, onEditValueChange, onSend, inputRef, sx } = props;
    const { t, i18n } = useTranslation();

    const isMobile: boolean = CheckIsMobile();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key == 'Enter' && event.keyCode == 13 &&  !event.shiftKey) {
            //手機板Enter為換行
            if (isMobile) {
                return;
            }

            event.preventDefault();
            onSend();
        }
    }
    const handleOpenStickerSelector = () => {
        inputRef.current.focus();
    }
    return (
        <InputBase
            inputRef={inputRef}
            multiline
            maxRows={4}
            value={editValue ?? ""}
            onChange={onEditValueChange}
            onKeyDown={handleKeyDown}
            sx={sx ?? { width: "100%", borderRadius: "20px", background: "#EFEFEF", paddingLeft: 2 }}
            autoFocus
            placeholder={isMobile ? '' : t('chat.dialog.text_placeholder')}
            disabled={!editable}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton color="primary" size="small" onClick={editValue.length > 0 ? onSend : handleOpenStickerSelector} disabled={!editable}>
                        <Send />{/*editValue.length > 0 ? <Send /> : <Mood />*/}
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}

const CardDialogHintButton: (props: ButtonProps) => JSX.Element = styled(MuiButton)({
    padding: '1px 10px',
    minWidth: "40px",
    fontWeight: "bold"
});