import * as React from 'react';
import { AccountCircle, AdminPanelSettings, AnalyticsOutlined, Assignment, Ballot, Groups, History as HistoryIcon, Key, Logout, Settings, Storefront } from '@mui/icons-material';
import { ManagePageItem, IManageSideBarItem } from "./ManageBoardIndex";
import UserProfile from './UserProfile/General/UserProfile';
import UserSaveCards from './UserProfile/General/UserSaveCards';
import UserFavorite from './UserProfile/General/UserFavorite';
import Appointment from './UserProfile/History/Appointment';
import CallBell from './UserProfile/History/CallBell';
import UserPassword from './Account/UserPassword';
import UserSignInMethod from './Account/UserSignInMethod';
import BoothInfoOverview from './Booth/BoothInfoOverview';
import DailyCheckInList from './DataViews/DailyCheckInList';
import UserList from './Manages/User/UserList';
import NewUserList from './Manages/User/NewUserList';
import BoothList from './Manages/Booth/BoothList';
import NewBoothList from './Manages/Booth/NewBoothList';
import UsersLevelManage from './Manages/Booth/UsersLevelManage';
import SeminarManage from './Manages/Stage/SeminarManage';
import StageProgram from './Manages/Stage/StageProgram';
import SignInList from './Manages/Stage/SignInList';
import ArticleListManage from './Manages/News/ArticleListManage';
import ArticleList from './Manages/News/ArticleList';
import PublishNotify from './Manages/Notice/PublishNotify';
import AnnouceEdit from './Manages/Notice/AnnouceEdit';
import ReportList from './Manages/Other/ReportList';
import AppointmentList from './Manages/Other/AppointmentList';
import QuestionList from '../components/Questionnaire/QuestionList';
import InfoCardList from './Manages/InfoCard/InfoCardList';
import UserNumberPlate from './Workshop/UserNumberPlate';
import { AllSignInHistoryList } from '../components/QrcodeTable/AllSignInHistoryList';

export type viewCode = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16'|'19'|'22';
export interface pageView {
    titleKey: string;
    list: ManagePageItem[]
}
export type ManagePageView = Record<viewCode, pageView>;

//後台導覽欄顯示設定
export const sideBarItems: IManageSideBarItem[] = [
    {
        name: 'manage.title.profile.profile',
        className: 'manage.title.profile.profile',
        value: '0',
        icon: <AccountCircle />,
        showOnMobile: true,
        pageList: [
            {
                name: 'manage.title.profile.general',
                className: 'manage.title.profile.general',
                pageList: [
                    {
                        name: 'manage.title.profile.myCard',
                        className: 'manage.title.profile.myCard',
                        value: '0',
                    },
                    {
                        name: 'manage.title.profile.saveCards',
                        className: 'manage.title.profile.saveCards',
                        value: '0',
                    },
                    {
                        name: 'manage.title.profile.favorite',
                        className: 'manage.title.profile.favorite',
                        value: '0',
                    },
                ]
            },
            {
                name: 'manage.title.profile.history',
                className: 'manage.title.profile.history',
                value: '1',
                icon: <HistoryIcon />,
                showOnMobile: true,
                pageList: [
                    {
                        name: 'manage.title.profile.appointment',
                        className: 'manage.title.profile.appointment',
                        value: '1',
                    },
                    {
                        name: 'manage.title.profile.bell',
                        className: 'manage.title.profile.bell',
                        value: '1',
                    }
                ]
            },
            /*{
                name: 'manage.title.profile.booth',
                className: 'manage.title.profile.booth',
                value: '2',
            }*/
        ]
    },
    {
        name: 'manage.title.account.account',
        className: 'manage.title.account.account',
        icon: <AdminPanelSettings />,
        pageList: [
            {
                name: 'manage.title.account.setting',
                className: 'manage.title.account.setting',
                value: '3',
                pageList: [
                    {
                        name: 'manage.title.account.password',
                        className: 'manage.title.account.password',
                        value: '3',
                    },
                    {
                        name: 'manage.title.account.signInMethod',
                        className: 'manage.title.account.signInMethod',
                        value: '3',
                    },
                ]
            },
            /*{
                name: 'manage.title.account.loginHistory',
                className: 'manage.title.account.loginHistory',
                value: '4',
            }*/
        ]
    },
    {
        name: 'manage.title.booth.booth',
        className: 'manage.title.booth.booth',
        managerOnly: true,
        icon: <Storefront />,
        //value: '6',
        pageList: []
    },
    {
        name: 'manage.title.analysis.analysis',
        className: 'manage.title.analysis.analysis',
        adminOnly: true,
        icon: <AnalyticsOutlined />,
        pageList: [
            {
                name: 'manage.title.analysis.checkIn',
                className: 'manage.title.analysis.checkIn',
                value: '7',
            },
            {
                name: 'manage.title.qrcodeHistory.signInHistory',
                className: 'manage.title.qrcodeHistory.signInHistory',
                value: '22',
            },
            /*{
                name: 'manage.title.analysis.accessHistory',
                className: 'manage.title.analysis.accessHistory',
                value: '7',
            },*/
        ]
    },
    {
        name: 'manage.title.manage.manage',
        className: 'manage.title.manage.manage',
        managerOrAdminOnly: true,
        icon: <Assignment />,
        pageList: [
            {
                name: 'manage.title.manage.user',
                className: 'manage.title.manage.user',
                value: '8',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.userList',
                        className: 'manage.title.manage.userList',
                        value: '8',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.newUser',
                        className: 'manage.title.manage.newUser',
                        value: '8',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.userLevel',
                        className: 'manage.title.manage.userLevel',
                        value: '8',
                        superuserOnly: true,
                    },
                ]
            },
            {
                name: 'manage.title.manage.booth',
                className: 'manage.title.manage.booth',
                value: '9',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.boothList',
                        className: 'manage.title.manage.boothList',
                        value: '9',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.newBooth',
                        className: 'manage.title.manage.newBooth',
                        value: '9',
                        adminOnly: true,
                    },
                    /*{
                        name: 'manage.title.manage.boothType',
                        className: 'manage.title.manage.boothType',
                        value: '9',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.invitation',
                        className: 'manage.title.manage.invitation',
                        value: '9',
                        adminOnly: true,
                    },*/
                ]
            },
         /*   {
                name: 'manage.title.manage.infoCard',
                className: 'manage.title.manage.infoCard',
                value: '10',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.infoCardList',
                        className: 'manage.title.manage.infoCardList',
                        value: '10',
                        adminOnly: true,
                    }
                ]
            },*/
            {
                name: 'manage.title.manage.stage',
                className: 'manage.title.manage.stage',
                value: '11',
                managerOrAdminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.stageList',
                        className: 'manage.title.manage.stageList',
                        value: '11',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.programSchedule',
                        className: 'manage.title.manage.programSchedule',
                        value: '11',
                        managerOrAdminOnly: true,
                    },
                    /*{
                        name: 'manage.title.manage.checkIn',
                        className: 'manage.title.manage.checkIn',
                        value: '11',
                        adminOnly: true,
                    },*/
                ]
            },
            {
                name: 'manage.title.manage.news',
                className: 'manage.title.manage.news',
                value: '12',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.newsList',
                        className: 'manage.title.manage.newsList',
                        value: '12',
                        adminOnly: true,
                    },
                    /*{
                        name: 'manage.title.manage.newArticle',
                        className: 'manage.title.manage.newArticle',
                        value: '12',
                        adminOnly: true,
                    },*/
                ]
            },
            {
                name: 'manage.title.manage.notice',
                className: 'manage.title.manage.notice',
                value: '13',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.annoucement',
                        className: 'manage.title.manage.annoucement',
                        value: '13',
                        adminOnly: true,
                    },
                    {
                        name: 'manage.title.manage.newNotification',
                        className: 'manage.title.manage.newNotification',
                        value: '13',
                        adminOnly: true,
                    },
                ]
            },
            {
                name: 'manage.title.manage.other',
                className: 'manage.title.manage.other',
                value: '14',
                adminOnly: true,
                pageList: [
                    {
                        name: 'manage.title.manage.reportList',
                        className: 'manage.title.manage.reportList',
                        value: '14',
                        adminOnly: true,
                    },
                    /*{
                        name: 'manage.title.manage.feedbackList',
                        className: 'manage.title.manage.feedbackList',
                        value: '14',
                        adminOnly: true,
                    },*/
                ]
            },
        ]
    },
    /*工作坊 */
    {
        name: 'manage.title.workshop.workshop',
        className: 'manage.title.workshop.workshop',
        icon: <Groups />,
        pageList: [
            {
                name: 'manage.title.workshop.numberPlate',
                className: 'manage.title.workshop.workshop',
                value: '19',
                icon: <Groups />,
                showOnMobile: true,
            },
        ]
    },
    /*{
        name: 'manage.title.myPoints.myPoints',
        className: 'manage.title.analysis.myPoints',
        pageList: [
            {
                name: 'manage.title.myPoints.pointList',
                className: 'manage.title.analysis.pointList',
                value: '15',
            },
            {
                name: 'manage.title.myPoints.redeem',
                className: 'manage.title.myPoints.redeem',
                value: '15',
            },
        ]
    },*/
    /*{
        name: 'manage.title.loyaltySystem.loyaltySystem',
        className: 'manage.title.loyaltySystem.loyaltySystem',
        adminOnly: true,
        pageList: [
            {
                name: 'manage.title.loyaltySystem.pointList',
                className: 'manage.title.loyaltySystem.pointList',
                value: '16',
            },
            {
                name: 'manage.title.loyaltySystem.redeem',
                className: 'manage.title.loyaltySystem.redeem',
                value: '16',
            },
        ]
    },*/
    /*{
        name: 'manage.title.game.game',
        className: 'manage.title.game.game',
        value: '5',
    },*/
];

//後台檢視畫面顯示設定
export const viewItems: ManagePageView = {
    0: {
        titleKey: "manage.title.profile.profile",
        list: [
            { className: 'manage.title.profile.myCard', content: <UserProfile /> },
            { className: 'manage.title.profile.saveCards', content: <UserSaveCards /> },
            { className: 'manage.title.profile.favorite', content: <UserFavorite /> },
        ]
    },
    1: {
        titleKey: "manage.title.profile.history",
        list: [
            { className: 'manage.title.profile.appointment', content: <Appointment /> },
            { className: 'manage.title.profile.bell', content: <CallBell /> },
        ]
    },
    2: {
        titleKey: "manage.title.profile.booth",
        list: [
            { className: '', content: <></> },
        ]
    },
    3: {
        titleKey: "manage.title.account.setting",
        list: [
            { className: 'manage.title.account.password', content: <UserPassword /> },
            { className: 'manage.title.account.signInMethod', content: <UserSignInMethod /> },
        ]
    },
    4: {
        titleKey: "manage.title.account.loginHistory",
        list: [
            { className: '', content: <></> },
        ]
    },
    5: {
        titleKey: "manage.page.game.game",
        list: [
            { className: '', content: <></> },
        ]
    },
    6: {
        titleKey: "manage.title.booth.booth",
        list: [
            { className: '', content: <BoothInfoOverview /> },
        ]
    },
    7: {
        titleKey: "manage.title.analysis.analysis",
        list: [
            { className: 'manage.title.analysis.checkIn', content: <DailyCheckInList />, adminOnly: true },
        ]
    },
    8: {
        titleKey: "manage.title.manage.userManage",
        list: [
            { className: 'manage.title.manage.userList', content: <UserList />, adminOnly: true },
            { className: 'manage.title.manage.newUser', content: <NewUserList />, adminOnly: true },
            //{ className: 'manage.page.manageTool.appointmentList', content: <AppointmentList />, adminOnly: true }, 
            { className: 'manage.title.manage.userLevel', content: <UsersLevelManage />, superuserOnly: true },
        ]
    },
    9: {
        titleKey: "manage.title.manage.boothManage",
        list: [
            { className: 'manage.title.manage.boothList', content: <BoothList />, adminOnly: true },
            { className: 'manage.title.manage.newBooth', content: <NewBoothList />, adminOnly: true },
        ]
    },
    10: {
        titleKey: "manage.title.manage.infoCardManage",
        list: [
            { className: 'manage.title.manage.infoCardList', content: <InfoCardList /> },
        ]
    },
    11: {
        titleKey: "manage.title.manage.stageManage",
        list: [
            { className: 'manage.title.manage.stageList', content: <SeminarManage />, adminOnly: true },
            { className: 'manage.title.manage.programSchedule', content: <StageProgram />, managerOrAdminOnly: true },
            //{ className: 'manage.title.manage.checkIn', content: <SignInList />, adminOnly: true },
        ]
    },
    12: {
        titleKey: "manage.title.manage.news",
        list: [
            //{ className: 'manage.title.manage.newsList', content: <ArticleList />, adminOnly: true },
            { className: 'manage.title.manage.newsList', content: <ArticleListManage />, adminOnly: true },
        ]
    },
    13: {
        titleKey: "manage.title.manage.notice",
        list: [
            { className: 'manage.title.manage.annoucement', content: <AnnouceEdit />, adminOnly: true },
            { className: 'manage.title.manage.newNotification', content: <PublishNotify />, adminOnly: true },
        ]
    },
    14: {
        titleKey: "manage.title.manage.other",
        list: [
            { className: 'manage.title.manage.reportList', content: <ReportList />, adminOnly: true },
        ]
    },
    15: {
        titleKey: "manage.title.myPoints.myPoints",
        list: [
            { className: '', content: <></> },
        ]
    },
    16: {
        titleKey: "manage.title.loyaltySystem.loyaltySystem",
        list: [
            { className: '', content: <></> },
        ]
    },
    19: {
        titleKey: "manage.title.workshop.workshop",
        list: [
            { className: 'manage.title.workshop.workshop', content: <UserNumberPlate /> },
        ]
    },
    22: {
        titleKey: "manage.title.analysis.analysis",
        list: [{ className: 'manage.title.qrcodeHistory.signInHistory', content: <AllSignInHistoryList />, adminOnly: true },
        ]
    },
}