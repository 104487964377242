import * as React from 'react';
import { Box, Tooltip } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Help, ThumbUpOutlined, Report as ReportIcon, ReportGmailerrorred } from '@mui/icons-material';
import { BoothQandA, Report } from '../../components/class';
import BoardCardDialog from './BoardCardDialog';
import { useTranslation } from 'react-i18next';
import { useDispatchWithType, useUser } from '../../store';
import { showModal } from '../../store/rootReducer';

const ansContent: React.CSSProperties = {
    width: '100%',
    backgroundColor: '#ffffff',
    padding: '1.5rem 2.25rem',
};
const contentText: React.CSSProperties = {
    overflow: 'auto',
    minHeight: 100,
    maxHeight: 300
};
const questionText: React.CSSProperties = {
    fontWeight: 'bold',
    color: '#000000',
    //maxWidth: 550,
};
const replyText: React.CSSProperties = {
    color: '#000000',
    //maxWidth: 550,
};
const subText: React.CSSProperties = {
    color: '#8e9c9f',
    //maxWidth: 550,
};

export default function BoardCard(props: boardProps) {
    const { t, i18n } = useTranslation();

    const { data, isManage, handleReply, handleLikeMarkClick } = props;
    const user = useUser();

    //按讚
    const [likeMark, setLikeMark] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (user) {
            setLikeMark(data.likers.includes(user.userId));
        }
    }, [user, data.likers.length])
    function handleClickLikeMark() {
        handleLikeMarkClick(data.dataId, likeMark);
    }

    //回覆視窗
    const [openReply, setOpenReply] = React.useState(false);
    const handleClickOpenReply = () => {
        setOpenReply(true);
    };
    const handleCloseReply = () => {
        setOpenReply(false);
    };

    //檢舉視窗
    const handleClickOpenReport = () => {
        //setMoreAnchorEl(null);
        SendReport();
    };
    //送去Redux回報
    const dispatch = useDispatchWithType();
    function SendReport() {
        dispatch(showModal({
            modalType: "REPORT", modalProps: {
                jsonData: JSON.stringify(data)
            }
        }));
    }
    /*
    //功能列表
    const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMoreAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setMoreAnchorEl(null);
    };
    */

    return (
        <Box sx={{ position: 'relative', boxShadow: "0px 3px 6px rgba(0, 0, 0, 16%)", borderRadius: "10px" }}>
            <Box px={4.5} py={3} bgcolor={(theme) => theme.palette.grey[100]} sx={{ borderRadius: "10px 10px 0 0" }}>
                <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]} paddingRight="40px" sx={{ borderRadius: "10px" }}>
                    {new Date(data.askTime).toLocaleString(
                        i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'long',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    })}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                    {`${t('boardCard.card.question')}：`}
                </Typography>
                <Box minHeight="100px" maxHeight="300px" overflow="auto">
                    <Typography variant="subtitle1" fontWeight="bold">
                        {data.question}
                    </Typography>
                </Box>
            </Box>
            {data.answer &&
                <Box px={4.5} py={3} bgcolor="white" sx={{ borderRadius: "0 0 10px 10px" }}>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                        {new Date(data.ansTime).toLocaleString(
                            i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            weekday: 'long',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                        })}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                    >
                        {`${t('boardCard.card.answer')}：`}
                    </Typography>
                    <Box minHeight="100px" maxHeight="300px" overflow="auto">
                        <Typography variant="subtitle1">
                            {data.answer}
                        </Typography>
                    </Box>
                </Box>
            }

            <div style={{
                position: 'absolute',
                right: 10,
                bottom: 10,

                display: 'flex',
                alignItems: 'flex-end',
            }}>
                {(!data.answer && isManage) &&
                    <Button
                        startIcon={<Help />}
                        variant='contained'
                        sx={{ '&:focus': { outline: 'none' } }}
                        onClick={() => handleClickOpenReply()}
                    >
                        <Typography
                            variant="subtitle1"
                            fontWeight='bold'
                            style={{ borderRadius: '8px' }}
                        >
                            {t('boardCard.card.reply')}
                        </Typography>
                    </Button>
                }
            </div>

            <div style={{
                position: 'absolute',
                left: 2,
                top: 2,
            }}>
                <Tooltip title={t('boardCard.card.report')} arrow>
                    <IconButton onClick={handleClickOpenReport}>
                        <ReportGmailerrorred fontSize='small' />
                    </IconButton>
                </Tooltip>
            </div>

            <div style={{
                position: 'absolute',
                right: 8,
                top: 8,

                display: 'flex',
                alignItems: 'flex-end',
            }}>
                <Typography
                    align='center'
                    variant="subtitle2"
                    fontWeight="bold"
                    gutterBottom
                >
                    {data.likers.length}
                </Typography>
                <IconButton
                    onClick={() => handleClickLikeMark()}
                    sx={{
                        color: (theme) => likeMark ? theme.palette.primary.main : 'inherit',
                        '&:focus': { outline: 'none' },
                    }}
                >
                    <ThumbUpOutlined />
                </IconButton>
            </div>

            <BoardCardDialog
                boardCardData={data}
                open={openReply}
                handleSubmit={handleReply}
                handleClose={handleCloseReply}
            />

            {/*檢舉功能
                <IconButton
                    onClick={handleMoreClick}
                    sx={{
                        '&:focus': {
                            outline: 'none',
                        },
                        color: 'black',
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={moreAnchorEl}
                    keepMounted
                    open={Boolean(moreAnchorEl)}
                    onClose={handleMoreClose}
                >
                    <MenuItem onClick={handleClickOpenReport}>
                        <ReportIcon fontSize='small' />
                        <Typography
                            align='left'
                            variant="subtitle1"
                        >
                            {t('boardCard.card.report')}
                        </Typography>
                    </MenuItem>
                </Menu>
                */}
        </Box>
    )
};

interface boardProps {
    index: number;
    isManage: boolean;
    data: BoothQandA;
    handleReply: (value: Pick<BoothQandA, "dataId" | "answer">) => Promise<boolean>;
    handleLikeMarkClick: (boardId: string, isLike: boolean) => Promise<boolean>;
}