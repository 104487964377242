import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Close, Favorite, FavoriteBorderRounded, KeyboardArrowLeft, KeyboardArrowRight, People, Videocam } from "@mui/icons-material";
import { alpha, Backdrop, Box, Button, ButtonBase, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, Paper, PaperProps, Stack, styled, Tab, Toolbar, ToolbarProps, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Booth, BoothType, BoothArticle, IInfoCard, Seminar } from "../components/class";
import { Media } from "./BoothMedia";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import ErrorPage from "../components/ErrorPage/408Page";
import BoothIntro from "./BoothIntro";
import BoothTimer from "./BoothTimer";
import BoothAppointment from "./BoothAppointment";
import { useDispatchWithType, useSystemConnection, useUser } from "../store";
import { showModal, updateUser } from "../store/rootReducer";
import { useChangeBookMarkMutation, useChangeInfoCardBookMarkMutation, UserCardInfoModel } from "../components/services/user";
import SideBar, { idData } from "../components/SideBar";
import BoothActionBar, { BoothActionsProps } from "./BoothActionBar";
import { useGetVoteRecordQuery, useLazyGetBoothManagersQuery, useLazyGetBoothQuery, useLazyGetInfoCardQuery, usePutVoteBoothMutation } from "../components/services/booth";
import { SubTitle } from "../components/responsive-components/Typography";
import { SystemControlHub } from "../components/react-signalr/systemControlHub";
import {Link,  scroller } from 'react-scroll'
import { useLazyGetArticlesByBoothQuery } from "../components/services/article";
import { GetParticipantCount } from "../components/MediaSection";
import { useLazyGetSeminarsByBoothQuery } from "../components/services/seminar";
import AgendaCard from "./Seminar/AgendaCard";
import { BussinessCard } from "../components/BussinessCard/BussinessCardInfo";
import { UserCardDialog } from "../components/BussinessCard/UserCardDialog";
import ReactPlayer from "react-player";
import BoothArticleTable, { BoothArticleTableData } from "../components/Article/manage/BoothArticleTable";
import { BoothArticleDialog } from "../components/Article/BoothArticlePage";
import { BoothCard } from "../components/BoothCard/BoothCard";
import { InfoCard as BoothInfoCard } from "../components/InfoCard/InfoCard";
import { InfoCardDialog } from "../components/InfoCard/InfoCardDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import Marquee from "react-fast-marquee";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
interface BoothPageItem {
    className: string;
    content: (props: { booth: Booth, children?: JSX.Element }) => JSX.Element;

}

interface BoothSideBarItem {
    name: string;
    className: string;

}
const prefix = "booth.title.";
const sideBarItems: BoothSideBarItem[] = [
    { name: prefix + "info", className: prefix + "info"},
    { name: prefix + "boothMembers", className: prefix + "boothMembers"},
    { name: prefix + "companyIntro", className: prefix + "companyIntro" },
    { name: prefix + "gameIntro", className: prefix + "gameIntro" },
    { name: prefix + "teamIntro", className: prefix + "teamIntro" },
    { name: prefix + "edm", className: prefix + "edm" },
    { name: prefix + "eventIntro", className: prefix + "eventIntro" },
    { name: prefix + "film", className: prefix + "film" },
    { name: prefix + "article", className: prefix + "article" },
    { name: prefix + "infoCards", className: prefix + "infoCards" },
    { name: prefix + "agenda", className: prefix + "agenda" },
    { name: prefix + "recruit", className: prefix + "recruit" }
];

const pageItemList: BoothPageItem[] = [
    { className: prefix + "info", content: InfoSection },
    { className: prefix + "boothMembers", content: InheritSection },
    { className: prefix + "companyIntro", content: CompanyIntroSection },
    { className: prefix + "gameIntro", content: GameIntroSection },
    { className: prefix + "teamIntro", content: InheritSection },
    { className: prefix + "edm", content: EdmSection },
    { className: prefix + "eventIntro", content: EventIntroSection },
    { className: prefix + "film", content: FilmSection },
    { className: prefix + "article", content: InheritSection },
    { className: prefix + "infoCards", content: InheritSection },
    { className: prefix + "agenda", content: InheritSection },
    { className: prefix + "recruit", content: InheritSection },
];
//客製化攤位顯示內容
const boothTypeContent = {
    0: ["info", "boothMembers", "gameIntro", "teamIntro", "edm", "film", "article"/*, "agenda"*/],
    1: ["info", "boothMembers", "companyIntro", "film", "article"],
    2: ["info", "boothMembers", "eventIntro"/*, "film"*/, "article",/* "infoCards"*//*, "agenda"*/]
}
function noVideo(x: BoothSideBarItem | BoothPageItem, booth: Booth) {
    return x.className == prefix + "film" ? booth?.fieldList.find(y => y.fieldId == "video")?.value != "" && booth?.fieldList.find(y => y.fieldId == "video")?.value != undefined : true
}
function noInfoCard(x: BoothSideBarItem | BoothPageItem, infoCards: IInfoCard[]) {
    return x.className == prefix + "infoCards" ? infoCards.length > 0 : true
}

const sideBarWidth: number = 155;
const toobarHeight: number = 94;
const navHeight: number = 64;
const mobileNavHeight: number = 56;




export function strLength(str: string) {
    return str.replace(/[^\x00-\xff]/g, "xx").length;
}

export default function BoothIndex() {
    const user = useUser();
    const dispatch = useDispatchWithType();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    let query = useQuery();
    const location = useLocation()
    let hash = useLocation().hash;
    const history = useHistory();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 3, refId: boothId });
        }
    }, [systemConnection]);

    const [boothId, setBoothId] = React.useState<string>();
    React.useEffect(() => {
        setBoothId(query.get('id'));

        //換連線位置
        if (hubRef.current)
            hubRef.current.send.ChangeLocation({ location: 3, refId: query.get('id') });
    }, [location]);

    const [getBooth, { booth, isError }] = useLazyGetBoothQuery({
        selectFromResult: ({ data, isError }) => ({ booth: data?.data, isError: isError })
    });

    const [getBoothManagers, { managers = [] }] = useLazyGetBoothManagersQuery({
        selectFromResult: ({ data }) => ({ managers: data?.data })
    });

    const [getInfoCard, { infoCards = [] }] = useLazyGetInfoCardQuery({
        selectFromResult: ({ data }) => ({ infoCards: data?.data ?? [] })
    });

    const [getBoothAllArticle, { articles = [] }] = useLazyGetArticlesByBoothQuery({
        selectFromResult: ({ data }) => ({ articles: data?.data ?? [] })
    });

    const [getSeminars, { seminars = [] }] = useLazyGetSeminarsByBoothQuery({
        selectFromResult: ({ data }) => ({ seminars: data?.data ?? [] })
    });

    React.useEffect(() => {
        if (boothId) {
            getBooth({ boothId: boothId });
            getBoothManagers(boothId);
            getInfoCard(boothId);
            getBoothAllArticle(boothId);
            getSeminars(boothId);
            GetParticipantCount(boothId).then((num) => {
                if (num == 0)
                    setShowOnCall(false);
                else {
                    setShowOnCall(true);
                }
            });
        }
    }, [boothId]);

    //攤位視訊開放顯示
    const [showOnCall, setShowOnCall] = React.useState<boolean>();

    //攤位視訊控制
    const handleOpenMedia = () => {
        if (hash !== "#media") {
            history.push({
                ...history.location, hash: "#media"
            });
        }
        scroller.scrollTo('media', {
            smooth: true,
            offset: -(isMobile ? mobileNavHeight : (navHeight + toobarHeight))
        });
        /*else {

        }
        if (systemConnection)
            systemConnection.send('UpdateUserVideoRecord', boothId);*/
    }
    const handleCloseMedia = () => {
        if (hash === "#media") {
            history.push({
                ...history.location, hash: null
            });
        }
    }

    //預約面談
    const [openAppointment, setOpenAppointment] = React.useState(false);
    const handleOpenAppointment = () => {
        setOpenAppointment(true);
    }
    const handleCloseAppointment = () => {
        setOpenAppointment(false);
    }
    //服務鈴
    const handleRingBell = () => {
        dispatch(showModal({ modalType: "SERVICE_BELL", modalProps: { boothId: boothId } }));
    }

    //控制頁面切換時的位置
    React.useEffect(() => {
        if (booth) {
            if (hash)
                setTimeout(() => {
                    scroller.scrollTo(hash.split('#')[1], {
                        smooth: true,
                        offset: -(isMobile ? mobileNavHeight : (navHeight + toobarHeight))
                    });
                }, 100);
        }
    }, [booth])

    const pathName = useLocation().pathname;
    const search = useLocation().search;
    const path = pathName.toLowerCase();
    const loginUrl = '/Login' + (pathName ? "?redirect=" + pathName + search : "");


    //客製化職缺列表
    const recruitBoothList = ["Booth-aZ4Wlxy"/*網銀*/, "Booth-pZdHzzi"/*微晶*/];

    if (booth) {
        return (
            <>
                <Box
                    paddingLeft={isMobile ? "" : `${sideBarWidth}px`}
                    paddingRight={isMobile ? "" : `${sideBarWidth - 20}px`}
                    paddingTop={isMobile ? '0' : (toobarHeight) + 'px'}
                >
                    <main id="boothPanel" style={{ "maxWidth": "1200px", "margin": "0 auto" }}>
                        {pageItemList.filter(x => (recruitBoothList.includes(booth.boothId) ? [...boothTypeContent[booth.type], "recruit"] : boothTypeContent[booth.type])
                            .map(y => prefix + y).includes(x.className)).filter(x => noVideo(x,booth)).filter(x=>noInfoCard(x,infoCards)).map((item) => (
                            <Box key={item.className} className={item.className} style={{ minHeight: 100 }}>
                                {item.className == prefix + "boothMembers" ? <item.content booth={booth} >
                                    <ContactSection managers={managers} actionBarProps={{
                                        boothNo: booth.boothNo,
                                        boothId: boothId,
                                        handleOpenMedia: handleOpenMedia,
                                        handleOpenAppointment: handleOpenAppointment,
                                        handleRingBell: handleRingBell,
                                        showOnCall: showOnCall
                                    }} />
                                </item.content> :
                                    item.className == prefix + "article" ?
                                        <item.content booth={booth} >
                                            <ArticleSection articles={articles} />
                                        </item.content> :
                                        item.className == prefix + "agenda" ?
                                            <item.content booth={booth} >
                                                <SeminarSection seminars={seminars} />
                                            </item.content> :
                                                item.className == prefix + "recruit" ?
                                            <item.content booth={booth} >
                                                        <徵才資訊 boothId={booth.boothId} />
                                            </item.content> :
                                            <item.content booth={booth} />}
                                <Toolbar />
                            </Box>
                        ))}
                    </main>
                    <BoothTimer boothId={boothId} />
                    { /*預約面談*/}
                    {user&&<BoothAppointment boothId={boothId} managers={managers?.filter(x => x.userId != user?.userId)}
                        open={openAppointment} onClose={handleCloseAppointment} />}

                    {hash === "#media" && user &&
                        <Container className={"media"}>
                            <Box pb={2} color="text.white" >
                                <SubTitle icon={<Videocam color='primary' fontSize='large' />}
                                    text={t('booth.title.video')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
                                <Media booth={booth} />

                                {/*關閉視訊*/}
                                <Button variant="outlined" onClick={handleCloseMedia}>
                                    {t("booth.button.closeMedia")}
                                </Button>
                            </Box>
                        </Container>
                    }
                </Box>
                {!isMobile && <Box position="fixed" top="0" bottom="0" left="0px" paddingLeft='45px' paddingTop={(navHeight + toobarHeight) + 'px'} width={sideBarWidth}>
                    <BoothSideBar sideBarList={sideBarItems.filter(x => (recruitBoothList.includes(booth.boothId) ? [...boothTypeContent[booth.type], "recruit"] : boothTypeContent[booth.type])
                        .map(y => prefix + y).includes(x.className)).filter(x=>noVideo(x,booth)).filter(x=>noInfoCard(x,infoCards))} />
                </Box>}
                <Box position="fixed" top={isMobile ? "auto" : "0"} bottom="0" left="0px" paddingTop={isMobile ? 0 : navHeight + 'px'} width='100%' height='max-content' bgcolor='#fff' zIndex="999">
                    <Stack p={isMobile ? '0' : '23px 55px'}>
                        {user ?  <BoothActionBar {...{
                            boothNo: booth.boothNo,
                            boothId: boothId,
                            handleOpenMedia: handleOpenMedia,
                            handleOpenAppointment: handleOpenAppointment,
                            handleRingBell: handleRingBell,
                            showOnCall: showOnCall
                        }} /> :
                            <Box>攤位互動功能請先<RouterLink to={loginUrl}>登入</RouterLink></Box>}
                    </Stack>
                </Box>


                {/*詳細資料*/}
                {/*<DraftSection data={booth} />*/}

                {/*資訊小卡*/}
                {/*infoCards?.length > 0 && <>
                <StyledToolbar />
                <InfoCardsSection infoCards={infoCards} />
            </>*/}
            </>)
    } else if (isError) {
        return <ErrorPage />;
    } else {
        return (
            <Backdrop open sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}


export function IntroSection(props: IntroSectionProps) {
    const { data } = props;
    const { t } = useTranslation();

    const dispatch = useDispatchWithType();
    const user = useUser();

    const [changeBookMark] = useChangeBookMarkMutation();
    const handleClickFavorite = () => {
        changeBookMark({ boothId: data.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    const history = useHistory();
    function handleClickGoToGame(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        history.push(`./Game?shop=${data.boothNo.split("、")[0]}`);
    }

    let videoLink = data?.fieldList?.find(x => x.fieldId === 'video')?.value ?? "";
    const [openVideo, setOpenVideo] = React.useState<boolean>(false);
    const handleOpen = () => setOpenVideo(true), handleClose = () => setOpenVideo(false);

    return (
        <Box py={2}>
            <Container>
                <BoothCard
                    data={data}
                    onClickFollow={handleClickFavorite}
                />
                <Box display="inline-flex" gap={2} pt={2}>
                    <Button variant="outlined" href={`/Game?shop=${data.boothNo.split("、")[0]}`} onClick={handleClickGoToGame}>
                        <Typography>
                            {t("booth.button.game")}
                        </Typography>
                    </Button>
                    {videoLink != "" &&
                        <Button variant="outlined" onClick={handleOpen}>
                            <Typography>
                                {t("booth.button.video")}
                            </Typography>
                        </Button>
                    }
                </Box>
                <Dialog open={openVideo} onClose={handleClose} fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" fontWeight="bold">
                                {t("booth.button.video")}
                            </Typography>
                            <IconButton onClick={handleClose} sx={{ mt: -1, mr: -1 }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <ReactPlayer
                            url={videoLink}
                            width="100%"
                            controls
                        />
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            </Container>
        </Box>
    )
}
interface IntroSectionProps {
    data: Booth
}
function ContactSection(props: ContactSectionProps) {
    const { managers, actionBarProps } = props;
    const { t, i18n } = useTranslation();
    const user = useUser();

    //顯示視窗
    const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data: UserCardInfoModel) => {
        setCurrentData(data);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    function handleDisplayChange(changeIndex: number) {
        let currentIndex = managers.findIndex(x => x.userId === currentData.userId);
        if (managers.length > 0) {
            if (currentIndex + changeIndex < 0) {
                setCurrentData(managers[managers.length - 1]);
            }
            else if (managers.length > currentIndex + changeIndex) {
                setCurrentData(managers[currentIndex + changeIndex]);
            }
            else {
                setCurrentData(managers[0]);
            }
        }
    }
    const pathName = useLocation().pathname;
    const search = useLocation().search;
    const path = pathName.toLowerCase();
    const loginUrl = '/Login' + (pathName ? "?redirect=" + pathName + search : "");

    return (
        <Container>
            <Box display="grid" mt={3} minHeight="270px">
                <Box>
                    <Box>
                        <SubTitle icon='circle'
                            text={t('booth.title.contact')}
                            subText={t('booth.subtitle.contact')}
                        />
                    </Box>
                    {

                     user?<Box sx={contentStyle} pb={2} display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}>
                        {managers?.map((user) => (
                            <Box key={user.userId}>
                                <BussinessCard
                                    data={user}
                                    isOnline={user.isOnline}
                                    simpleMode
                                    withBorder
                                    onClickShowMore={handleOpen}
                                />
                            </Box>
                        ))}
                        </Box> :
                            <Box sx={contentStyle} pb={2}  >
                                請先<RouterLink to={loginUrl}>登入</RouterLink>
                        </Box>
                    }
                </Box>
          
            </Box>
            <UserCardDialog
                data={currentData}
                open={open}
                onClose={handleClose}
                handleChangePrev={() => handleDisplayChange(-1)}
                handleChangeNext={() => handleDisplayChange(1)}
            />
        </Container>
    )
}
interface ContactSectionProps {
    managers: UserCardInfoModel[];
    actionBarProps: BoothActionsProps;
}


function DraftSection(props: DraftSectionProps) {
    const { data } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    let contents = [
        { fieldId: "gameIntro", nameKey: "booth.title.gameIntro", type: BoothType.一般攤位 },
        { fieldId: "teamIntro", nameKey: "booth.title.teamIntro", type: BoothType.一般攤位 },
        { fieldId: "companyIntro", nameKey: "booth.title.companyIntro", type: BoothType.廣告贊助 },
        { fieldId: "eventIntro", nameKey: "booth.title.eventIntro", type: BoothType.服務台 }
    ];
    contents = contents.filter(x => x.type === data?.type);

    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState(contents[0]?.fieldId);
    function handleChange(event: React.SyntheticEvent, newValue: string) {
        setValue(newValue);
    };

    if (contents?.length > 0)
        return (
            <Container>
                <Box bgcolor="background.darkPaper" color="text.white" mt={6}>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{
                            style: {
                                display: "none",
                            }
                        }} style={{ transform: `translateY(-${theme.spacing(1.5)})` }}>
                            {contents.map((item, index) => (
                                <TabButton key={index.toString()}
                                    icon={<Typography variant="h6">{t(item.nameKey)}</Typography>}
                                    value={item.fieldId}
                                    disableRipple
                                />
                            ))}
                        </TabList>
                        {contents.map((item, index) => (
                            <TabPanel key={index.toString()} value={item.fieldId} sx={{ pt: 0, px: 2 }}>
                                <div className="ck-content"
                                    dangerouslySetInnerHTML={{ __html: data.fieldList?.find(x => x.fieldId === item.fieldId)?.value }}
                                />
                            </TabPanel >
                        ))}
                    </TabContext>
                </Box>
            </Container>
        )
    else return null;
}
interface DraftSectionProps {
    data: Booth
}
const TabButton = styled(Tab)(({ theme }) => ({
    transform: "skewX(-20deg)",
    backgroundColor: theme.palette.background.default,
    borderRadius: 0,
    minHeight: "46px",
    height: "46px",
    minWidth: "150px",
    "& > p, & > h6, & > h5": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `12px 9px ${theme.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(10.2px, 8.4px)",
        backgroundColor: theme.palette.background.default,
        boxShadow: `6.8px 5.6px ${theme.palette.primary.main}`,
    },
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));


function ArticleSection(props: ArticleSectionProps) {
    const { articles } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));

    //顯示視窗
    const [currentDisplayData, setCurrentDisplayData] = React.useState<BoothArticle>(null);
    const [openDisplay, setOpenDisplay] = React.useState(false);
    const handleOpenDisplay = (id?: string) => {
        if (id) {
            setCurrentDisplayData(articles.find(x => x.dataId === id));
        }
        else {
            setCurrentDisplayData(null);
        }
        setOpenDisplay(true);
    };
    const handleCloseDisplay = () => {
        setOpenDisplay(false);
    };
    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='circle' text={t('booth.title.article')} />
            </Box>
            <Paper sx={{ padding: { xs: "16px 10px", sm: "20px 40px" } }}>
                <BoothArticleTable
                    rows={articles?.map((item) => ({
                        id: item.dataId,
                        boothId: item.boothId,
                        boothChName: item.chName,
                        boothEnName: item.enName,
                        name: item.title,
                        time: new Date(item.createTime),
                        pin: item.isPin
                    } as BoothArticleTableData)) ?? []}
                    onOpen={handleOpenDisplay}
                    showPin={true}
                    paginationClassName="pagination"
                />
            </Paper>
            <BoothArticleDialog
                data={currentDisplayData}
                matches={tabletMatches}
                open={openDisplay}
                onClose={handleCloseDisplay}
            />
        </Container>
    )
}
interface ArticleSectionProps {
    articles: BoothArticle[];
}

function SeminarSection(props: SeminarsSectionProps) {
    const { seminars } = props;
    const { t } = useTranslation();
    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='circle' text={t('booth.title.agenda')} />
            </Box>
            <Stack spacing={1} sx={contentStyle}>
                {seminars.length > 0 ? seminars.map((seminar) => (
                    seminar.agendas.map((agenda) => (
                        <AgendaCard
                            seminarId={seminar.seminarId}
                            seminarChName={seminar.chTitle}
                            seminarEnName={seminar.enTitle}
                            data={agenda}
                            key={agenda.agendaId}
                            showRegister
                            showDate
                        />
                    )))) :
                    <Box >暫無議程。</Box>
                }
            </Stack>
        </Container>
    )
}
interface SeminarsSectionProps {
    seminars: Seminar[];
}



interface BoothSideBarProps {
    sideBarList: BoothSideBarItem[];
}
function BoothSideBar(props: BoothSideBarProps) {
    const { sideBarList } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const scrollProps = {
        //containerId: "root",
        spy: true,
       // smooth: true,
        delay:0,
       // duration: 500,
        offset: -(isMobile ? navHeight:(navHeight + toobarHeight))
    };
    return (
        <Box paddingTop='49px' sx={{
            height: "100%",
            overflow: 'hidden',
            "&:hover": {
                overflowY: 'auto'
            },
            "&::-webkit-scrollbar": {
                width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 5,
                background: theme.palette.grey[400],
            },
            "& a": {
                textDecoration: 'none',
                mt: 0.5, mb: 1, pl: 1.25, pr: 1,
                borderLeft: "1px solid transparent",
                "& span": {
                fontWeight:'400',
                    },
                '&:hover': {
                    borderLeft: `3px solid ${theme.palette.grey[400]}`,
                    '& > span': {
                        color: theme.palette.grey[500],
                    }
                },
            },
            "& a.active": {
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                borderLeft: `3px solid ${alpha(theme.palette.primary.main, 1)}`,
                "& span": {
                    fontWeight: '500',
                },
                '&:hover': {
                    borderLeft: `3px solid ${theme.palette.primary.main}`,
                    '& > span': {
                        color: theme.palette.primary.main,
                    }
                },
            }
        }}>
            <List>
                {sideBarList.map(item => (
                    <ListItem disablePadding key={item.name} sx={{ cursor:"pointer"} }>
                        <Link to={item.name} {...scrollProps } >
                            <Typography component="span" variant="subtitle2">{t(item.name)}</Typography>
                        </Link>        
                    </ListItem>
                ))}
            </List>
        </Box >
    )
}

interface SectionProps {
    booth: Booth;
    children?: JSX.Element;
}



function InfoSection(props: SectionProps) {
    const { booth } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const infoItems = [
        {
            key: 'category',
            content: <><LocationSearchingIcon /><Typography marginLeft='13px'>{booth.category}</Typography></>,
            type: [0, 1]
        },
        {
            key: 'teamName',
            content: <><PersonIcon /><Typography marginLeft='13px'>{booth.fieldList.find(x => x.fieldId == (i18n.language == 'ch' ? 'chTeamName' : 'enTeamName'))?.value}</Typography></>,
            type: [0]
        },
        {
            key: 'email',
            content: <><MailOutlineIcon /><Typography marginLeft='13px'>{booth.fieldList.find(x => x.fieldId == "email")?.value}</Typography></>,
            type: [1]
        },
        {
            key: 'address',
            content: <><LocationOnIcon /><Typography marginLeft='13px'>{booth.fieldList.find(x => x.fieldId == "address")?.value}</Typography></>,
            type: [1]
        },
        {
            key: 'phone',
            content: <><LocalPhoneIcon /><Typography marginLeft='13px'>{booth.fieldList.find(x => x.fieldId == "phone")?.value}</Typography></>,
            type: [1]
        },
        {
            key: 'fb',
            content: <a href={booth.fieldList.find(x => x.fieldId == "fb")?.value} target='_blank'><FacebookIcon /></a>,
            type: [2]
        },
        {
            key: 'ig',
            content: <a href={booth.fieldList.find(x => x.fieldId == "ig")?.value} target='_blank'><InstagramIcon /></a>,
            type: [2]
        },
        {
            key: 'link',
            content: <><LanguageIcon /><Box marginLeft='13px'></Box><a href={booth.fieldList.find(x => x.fieldId == "link")?.value} target='_blank' style={{ "maxWidth": "100%", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{booth.fieldList.find(x => x.fieldId == "link")?.value}</a></>,
            type: [0]
        },
        {
            key: 'website',
            content: <><LanguageIcon /><Box marginLeft='13px'></Box><a href={booth.fieldList.find(x => x.fieldId == "website")?.value} target='_blank' style={{ "maxWidth": "100%", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{booth.fieldList.find(x => x.fieldId == "website")?.value}</a></>,
            type: [1]
        }
    ]
    const [changeBookMark] = useChangeBookMarkMutation();
    const { data: votesData } = useGetVoteRecordQuery(booth.boothId);
    const dispatch = useDispatchWithType();
    const user = useUser();
    const handleClickFavorite = () => {
        changeBookMark({ boothId: booth.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };
    const favoriteStyle = {
        position: 'absolute',
        top: '0',
        right: '0',
        fontSize: '24px',
        "padding": "6px 10px",
        "background": "#F5F5F5",
        "borderRadius": "50px",
        transation: '.1s',
        "&:hover": { background: "transparent" },
        "&:hover .fadeout": { opacity: '0' },
        "& .fadein": { opacity: '0', transation: '.1s', },
        "&:hover .fadein": { opacity: '1' },
    }
    //投票功能
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [putVoteBooth] = usePutVoteBoothMutation();
    async function handleClickVote() {
        if (booth?.boothId) {
            let res = await putVoteBooth(booth.boothId).unwrap();
            setOpen(true)
            setMessage(res.message)

        }
        else console.log(`boothId is null`);
    }


    return (
        <Box bgcolor='background.default'>
            <Box height={!isMobile ? "370px" : "30vh"}>
                <img src={!booth || booth?.bannerUrl == null || booth?.bannerUrl == "" ? "/images/nobanner.png" : booth?.bannerUrl} width="100%" height="100%" style={{ objectFit: "contain" }} />
            </Box>
            <Box p='12px 16px'>
                {/*跑馬燈*/}
                {booth.fieldList.find(x => x.fieldId == 'newsTicker') && <Stack className='newsTickerContainer' p="9px 30px" direction='row' spacing='10px' bgcolor='primary.light' m='0px auto 18px' borderRadius='5px'>
                    <img src='images/speakerphone.svg' />
                    <Marquee gradient={false} speed={70}>{[...new Array(10)].map((x, index) => <Box marginRight={10} key={index}>{booth.fieldList.find(x => x.fieldId == 'newsTicker')?.value}</Box>)}  </Marquee>
                </Stack>}
                {/*攤位資訊*/}
                <Box className='boothTitle' position='relative'>
                    {/*收藏按鈕*/}
                    {!isMobile && user && <IconButton onClick={handleClickFavorite} sx={favoriteStyle}  >
                        {user?.saveBoothIds.includes(booth.boothId) ?
                            <Favorite style={{ color: '#fa6980' }} /> :
                            <>
                                <FavoriteBorderRounded style={{ color: '#A9A9A9' }} className='fadeout' />
                                <Favorite style={{ color: '#fa6980' }} className='fadein' sx={{ position: 'absolute', "color": "rgb(250, 105, 128)", "left": "10px", "top": "6px", "opacity": "0", "transition": ".3s" }} />
                            </>
                        }<Box component='span' fontSize='16px' marginLeft='5px' color='#A9A9A9'>{t("booth.button.favorite") + t("home.button.booths")}</Box>
                    </IconButton>}
                    <Box>
                        <Box className='boothLogo' width='150px' height='150px' display='inline-block' borderRadius='5px' marginRight='12px' overflow='hidden' >
                            <img src={booth?.logoUrl == null || booth?.logoUrl == "" ? "/images/nologo.png" : booth?.logoUrl} width="100%" height="100%" style={{ objectFit: "cover" }} />
                        </Box>
                        <Box display='inline-block' sx={{ verticalAlign: 'top' }}>
                            <Stack direction='row' spacing={1}>
                                <Box>
                                    <img src={'images/house.svg'} width="15px" style={{ objectFit: "cover", "verticalAlign": "baseline", "marginRight": "6px" }} />
                                    <span>{t('booth.editDialog.boothNo')}：{booth.boothNo}</span>
                                </Box>
                                {<Box>
                                    <LocationOnIcon style={{ "verticalAlign": "sub", color: '#FF4747' }} />
                                    <span>{t('booth.editDialog.boothLocation')}：{booth.boothLocation}</span>
                                </Box>}
                            </Stack>
                            <Tooltip title={strLength(booth.chName) > 20 && !isMobile ? booth.chName : ""}>
                                <Typography variant='h4' fontWeight='700' sx={{ "maxWidth": { xs: "100%", md: "40vw", lg: "50vw", xl: "900px" }, "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": { xs: "wrap", md: "nowrap" } }}>{booth.chName}</Typography>
                            </Tooltip>
                            <Tooltip title={strLength(booth.enName) > 80 && !isMobile ? booth.enName : ""}>
                                <Typography sx={{ "maxWidth": { xs: "100%", md: "40vw", lg: "50vw", xl: "900px" }, "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": { xs: "wrap", md: "nowrap" } }}>{booth.enName}</Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Grid container spacing={2} paddingLeft={isMobile ? "0px" : (150 + 12) + 'px'} maxWidth={(827 + 12 + 150) + 'px'} marginTop={isMobile ? "0px" : "-50px"} sx={{ "zIndex": "9", "position": "relative" } }>
                    <Grid item xs={12} md={6} paddingRight={isMobile ? '0px' : '16px'}>
                        <Stack direction='column' spacing='9px'>
                            {infoItems.filter(x => x.type.includes(booth.type)).map(x => <Stack key={x.key} direction='row'>{x.content}</Stack>)}
                            {booth?.type == BoothType.一般攤位 ? <Box> 得票數：{votesData?.data?.recordList?.length ?? 0} &nbsp;
                                {user && <IconButton onClick={handleClickVote} sx={{ padding: '0' }}>
                                    <HowToVoteIcon style={{ color: '#fa6980' }} />
                                </IconButton>}
                            </Box> : <br />}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} borderLeft={isMobile ? "" : "1px solid #D8D8D8"} pb={2}>
                        {booth.intro}
                    </Grid>
                </Grid>
                <Dialog open={open}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {message}
                        </span>
                    </div>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpen(false); }}>{t('onlineSeminar.ok')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>)
}

const contentStyle = {
    "borderRadius": "15px", "padding": { xs: "15px 30px", md: "30px 60px" }, "background": "#fff", "marginTop": "20px" }

function InheritSection(props: SectionProps) {
    return <>{props?.children}</>
}
function CompanyIntroSection(props: SectionProps) {
    const { booth } = props;
    const { t } = useTranslation();

    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.companyIntro')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle }>
        <div className="ck-content"
        dangerouslySetInnerHTML={{ __html: booth.fieldList?.find(x => x.fieldId === "companyIntro")?.value }}
    />
        </Box>
    </Container>
}
function GameIntroSection(props: SectionProps) {
    const { booth } = props;
    const { t } = useTranslation();

    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.gameIntro')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle}>
            {booth?.fieldList?.find(x => x.fieldId === "gamesIntro")?.value }
            {/* <div className="ck-content"
        dangerouslySetInnerHTML={{ __html: booth.fieldList?.find(x => x.fieldId === "gameIntro")?.value }}
    />*/}
        </Box>
    </Container>
}
function EdmSection(props: SectionProps) {
    const { booth } = props;
    const { t } = useTranslation();

    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.edm')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle}>
            <img src={booth?.fieldList?.find(x => x.fieldId === "edm")?.value} style={{ maxWidth:"100%" }} />
            
            {/* <div className="ck-content"
        dangerouslySetInnerHTML={{ __html: booth.fieldList?.find(x => x.fieldId === "gameIntro")?.value }}
    />*/}
        </Box>
    </Container>
}
function TeamIntroSection(props: SectionProps) {
    const { t } = useTranslation();

    const { booth } = props;
    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.teamIntro')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle}>
            {booth?.fieldList?.find(x => x.fieldId === "groupIntro")?.value}
            { /*
        <div className="ck-content"
        dangerouslySetInnerHTML={{ __html: booth.fieldList?.find(x => x.fieldId === "teamIntro")?.value }}
    />*/}
        </Box>
    </Container>
}
function EventIntroSection(props: SectionProps) {
    const { t } = useTranslation();

    const { booth } = props;
    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.eventIntro')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle }>
        <div className="ck-content"
            dangerouslySetInnerHTML={{ __html: booth.fieldList?.find(x => x.fieldId === "eventIntro")?.value }}
    />
        </Box>
    </Container>
}

function FilmSection(props: SectionProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { booth } = props;
    return <Container>
        <SubTitle icon='circle'
            text={t('booth.title.film')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        <Box sx={contentStyle }>
        <ReactPlayer
            url={booth?.fieldList.find(x => x.fieldId === "video")?.value}
            width="100%"
            height={isMobile ? '350px' : '500px'}
            controls
        />
        </Box>
    </Container>
}


function InfoCardsSection(props: InfoCardsSectionProps) {
    const { infoCards } = props;

    const theme = useTheme();
    const { t } = useTranslation();

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState<number>(2);

    //responsive pageSize
    React.useLayoutEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < theme.breakpoints.values.md
                ? setPageSize(2) : setPageSize(6);
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    let showList = infoCards?.slice((page - 1) * pageSize, page * pageSize);
    let totalCount = infoCards?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize) || 1;
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleNext = () => setPage(page => page + 1);
    const handlePrevious = () => setPage(page => page - 1);

    //❤️按鈕
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeInfoCardBookMark] = useChangeInfoCardBookMarkMutation();
    function handleClickFavorite(data: IInfoCard) {
        changeInfoCardBookMark({ dataId: data.dataId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveInfoCardIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    //彈窗檢視
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentData, setCurrentData] = React.useState<IInfoCard>();
    function handleClick(data: IInfoCard) {
        setCurrentData(data);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    function handleDisplayChange(changeIndex: number) {
        let currentIndex = infoCards.findIndex(x => x.dataId === currentData.dataId);
        if (infoCards.length > 0) {
            if (currentIndex + changeIndex < 0) {
                setCurrentData(infoCards[totalCount - 1]);
            }
            else if (infoCards.length > currentIndex + changeIndex) {
                setCurrentData(infoCards[currentIndex + changeIndex]);
            }
            else {
                setCurrentData(infoCards[0]);
            }
        }
    }

    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='square' text={t('booth.title.infoCards')} />
            </Box>
            <Box display="flex" alignItems="center">
                <Typography>
                    <Trans i18nKey="infoCardList.totalNum" values={{ totalNum: totalCount }} />
                </Typography>

                <Paper style={{
                    margin: '12px 2px 12px auto',
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    <IconButton onClick={handlePrevious} disabled={!hasPrevious}>
                        <KeyboardArrowLeft />
                    </IconButton>
                    <Divider variant="middle" flexItem orientation="vertical" />
                    <Box style={{ padding: '6px 14px' }}>
                        <Typography variant="body2">
                            <span>{currentPage}</span>
                            {` / ${totalPages}`}
                        </Typography>
                    </Box>
                    <Divider variant="middle" flexItem orientation="vertical" />
                    <IconButton onClick={handleNext} disabled={!hasNext}>
                        <KeyboardArrowRight />
                    </IconButton>
                </Paper>
            </Box>
            <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}>
                {showList.map((card, index) => (
                    <BoothInfoCard
                        key={card.dataId}
                        data={card}
                        simpleMode
                        onClick={handleClick}
                        onClickFollow={handleClickFavorite}
                        disableShowBoothOnSimpleMode
                    />
                ))}
            </Box>

            <InfoCardDialog
                data={currentData}
                open={open}
                onClose={handleClose}
                handleChangePrev={() => handleDisplayChange(-1)}
                handleChangeNext={() => handleDisplayChange(1)}
            />
        </Container>
    )
}
interface InfoCardsSectionProps {
    infoCards: IInfoCard[];
}

function 徵才資訊(props: { boothId: string }) {
    const { t } = useTranslation();
    type byListType = { nameKey: string; img: string; href?: string; };
    const LinkCard = styled(({ nameKey, img, href, ...paperProps }: PaperProps & byListType) =>
        <Paper sx={{ flex: { xs: "1 1 100%", md: "0 1 25%" }, "alignItems": "center", "display": "flex", minHeight: "123px" }} {...paperProps} >
            <ButtonBase href={href} target="_blank" sx={{ position: "relative", display: "block", width: "100%" }}>
                <Stack flexDirection={{ xs: "row", sm: "column" }} alignItems="center" p={1.5}>
                    {img != "" && <Box width={{ xs: "100px", sm: "unset" }} mx={1} my={0.5} >
                        <img src={img} width="100%" height="100%" style={{ objectFit: "contain", maxHeight: "60px" }} />
                    </Box>}
                    <Typography textAlign={{ xs: "left", sm: "center" }} fontFamily="'Noto Serif TC'" mx={1} my={0.5}>
                        {nameKey}
                    </Typography>
                </Stack>
            </ButtonBase>
        </Paper>
    )(({ theme }) => ({ "& a:hover": { color: theme.palette.text.secondary, textDecoration: "none" } }));

    const 網銀徵才 = [{
        nameKey: "【軟體開發處】網頁後端工程師(台中大里)",
        img: "images/home/網銀國際.png",
        href: "https://www.104.com.tw/job/7qcu7?jobsource=cs_2018indexpoc"
    },
    {
        nameKey: "【軟體開發處】網頁後端工程師 ",
        img: "images/home/網銀國際.png",
        href: "https://www.104.com.tw/job/6pcc0?jobsource=cs_2018indexpoc"
    },
    {
        nameKey: "【軟體開發處】網頁後端工程師(V) ",
        img: "images/home/網銀國際.png",
        href: "https://www.104.com.tw/job/6ntqb?jobsource=cs_2018indexpoc"
    },
    {
        nameKey: "【軟體開發處】Unity資深遊戲開發工程師",
        img: "images/home/網銀國際.png",
        href: "https://www.104.com.tw/job/6vt01?jobsource=cs_2018indexpoc"
    },
    {
        nameKey: "【軟體開發處】Unity遊戲開發工程師(新鮮人可，須附作品集)",
        img: "images/home/網銀國際.png",
        href: "https://www.104.com.tw/job/6g2t9?jobsource=cs_2018indexpoc"
        }];

    const 微晶徵才 = [{
        nameKey: "Unity程式設計師",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "Unreal Engine程式設計師",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "2D美術設計師",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "3D美術設計師",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "3D動畫師",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "遊戲企劃",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
        }];

    const 微晶學生實習 = [{
        nameKey: "遊戲企劃實習生",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "遊戲QA實習生",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "程式實習生",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "2D美術實習生",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    },
    {
        nameKey: "程式實習生",
        img: "images/home/winking corp logo_01_R.png",
        href: ""
    }];
    return <>
        <SubTitle icon='circle'
            text={t('booth.title.recruit')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
        {props.boothId == "Booth-aZ4Wlxy" && <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
            {網銀徵才.map((element) => <LinkCard key={element.nameKey} {...element} />)}
        </Box>}
        {props.boothId == "Booth-pZdHzzi" && <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
            {微晶徵才.map((element) => <LinkCard key={element.nameKey} {...element} />)}
        </Box>}
        {props.boothId == "Booth-pZdHzzi" && <>
        <SubTitle icon='circle'
            text={"學生實習"} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
            <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }} gap={1} py={2}>
                {微晶學生實習.map((element) => <LinkCard key={element.nameKey} {...element} />)}
            </Box>
        </>}
    </>
}