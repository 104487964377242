import * as React from 'react';
import { Box, ButtonBase, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Footer } from './Footer';
import { NavBar } from './NavBar';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram, Language } from '@mui/icons-material';
import { logoPath } from '../../config';
import ServerChecker from '../ServerChecker';



export type LayoutVarient = "login" | "noFooter";
export const Layout = (props: LayoutProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const ShuttleIcon = "images/footer_Shuttle_logo.png";
    const TempestdigiLogo = "images/footer_Tempest_logo.png";
    const [withNavBar, setWithNavBar] = React.useState(true);
    const [withContainer, setWithContainer] = React.useState(true);
    const [withFooter, setWithFooter] = React.useState(true);
    React.useEffect(() => {
        if (props.varient == "login") {
            setWithNavBar(false);
            setWithContainer(false);
            setWithFooter(false);
        }
        else if (props.varient == "noFooter") {
            setWithNavBar(true);
            setWithContainer(false);
            setWithFooter(false);
        }
        else {
            setWithNavBar(true);
            setWithContainer(true);
            setWithFooter(true);
        }
    }, [props.varient])

    const clearCssStyle: React.CSSProperties = {
        width: 'unset',
        height: 'unset',
        maxWidth: 'unset',
        padding: 'unset',
        margin: 'unset',
    }
    const backgroundFixed = {
        backgroundAttachment: 'fixed',
        backgroundImage: theme.palette.background.fixed
    }

    const Logo = () => {
        return (
            <img src={logoPath} style={{ maxHeight: '55px', maxWidth: '100%', height: "100%" }} />
        )
    }
    const LogoText = () => {
        const { t } = useTranslation();
        return (
            <Typography sx={{ color: "background.black", "paddingRight": isMobile ? "40px":"5px"  , "paddingLeft": isMobile ? "5px" : "10px", "maxWidth": isMobile ? "inherit" : "120px", "overflow": "hidden", "textOverflow": "ellipsis" }}>{t('navbar.title')}</Typography>
        )
    }
    return (
        <>
            {withNavBar &&
                <NavBar logo={<Logo />} name={<LogoText />} />
            }
            <ServerChecker />
            <div style={withContainer ? { minHeight: window.innerHeight - 64 - 168, ...backgroundFixed } : { ...clearCssStyle, ...backgroundFixed }}  >
                {props.children}
            </div>

            {withFooter &&
                <Footer position="relative" /*mt={{ xs: 2, sm: 4 }}*/ bgcolor="appBar.light" color="#fff" textAlign='center' sx={{padding:'30px 30px 56px 30px'} }>  
                    <Box display="flex" flexWrap="wrap" justifyContent={{ xs: "center", md: "space-between" }} pt={2} pb={3} style={{ wordBreak: "keep-all" }}>
                        <Box>
                            <Box display="flex" flexWrap="wrap" alignItems="center" color="#09e9cd">
                                <Box maxWidth="100%" mb={1}>
                                    <img src={ShuttleIcon} width="120" />
                                </Box>
                                <Typography fontWeight="bold" ml={1} lineHeight="2.4">線上展會系統</Typography>
                                <Box width="20px" height="100%" />
                                <ButtonBase href='https://tempestdigi.com/' target='_blank'>
                                    <img src={TempestdigiLogo} height={"60"} />
                                </ButtonBase>
                            </Box>

                            <Box color="#09e9cd">                   
                                    <IconButton color="inherit" sx={{ ml: -1 }} href="https://www.facebook.com/TempestDigitalTW/" target='_blank'><Facebook /></IconButton>
                                <span>copyright © <a style={{ color: "inherit" }} href="https://tempestdigi.com/" target='_blank'>2023 暴風數位股份有限公司</a> All right reserved.</span>
                         
                            </Box>
                        </Box>
                        <Box color="secondary.light" pt={1} >
                            <IconButton color="inherit" sx={{ ml: -1, "&:hover": { color: "secondary.main" } }} href="https://www.facebook.com/TSGDF/" target='_blank'><Facebook /></IconButton>
                            <IconButton color="inherit" sx={{ ml: -1, "&:hover": { color: "secondary.main" } }} href="https://www.instagram.com/explore/tags/%E8%87%BA%E7%81%A3%E5%AD%B8%E7%94%9F%E9%81%8A%E6%88%B2%E9%96%8B%E7%99%BC%E8%80%85%E8%AB%96%E5%A3%87/" target='_blank'><Instagram /></IconButton>
                        </Box>
                    </Box>
                </Footer>
            }
        </>
    );
};
export interface LayoutProps {
    varient?: LayoutVarient;
    children: React.ReactElement
}

