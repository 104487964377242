import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BoothManageInvitation, User } from '../class';
import { ResultType } from './utils';

export enum AccountLoginType {
    normal = 0,
    google,
}
export enum InviteState {
    error = 0,
    success,
    expired,
    retype,
    owned
}

export const baseApi = createApi({
    reducerPath: 'inviteApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/invite' }),
    endpoints: (builder) => ({
        getInvite: builder.query<ResultType<BoothManageInvitation>, string>({
            query: (inviteCode) => ({
                url: `id/${inviteCode}`
            }),
        }),
        getUserAllInvites: builder.query<ResultType<BoothManageInvitation[]>, void>({
            query: () => ``,
        }),
        checkInviteIfNewAccount: builder.query<ResultType<AccountLoginType[]>, string>({
            query: (inviteCode) => ({
                url: `newaccount/${inviteCode}`
            }),
        }),
        inviteStateCheck: builder.query<ResultType<InviteState>, string>({
            query: (inviteCode) => ({
                url: `state/${inviteCode}`
            }),
        }),
        registerWithInvite: builder.mutation<ResultType<User>, { inviteCode: string, password: string }>({
            query: ({ ...patch }) => ({
                url: `register`,
                method: 'POST',
                body: patch,
            }),
        }),
        verifyInvite: builder.mutation<ResultType<InviteState>, { inviteCode: string, verify: boolean }>({
            query: ({ ...patch }) => ({
                url: `verify`,
                method: 'PUT',
                body: patch,
            }),
        })
    })
});

//exhibitor或superuser或admin用api
export const inviteApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getBoothAllInvites: builder.query<ResultType<BoothManageInvitation[]>, string>({
            query: (boothId) => `booth/${boothId}`,
        }),
        newBoothInvite: builder.mutation<ResultType<BoothManageInvitation>, { toEmail: string, boothId: string }>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'POST',
                body: patch,
            }),
        }),
        cancelBoothInvite: builder.mutation<ResultType<null>, { toEmail: string }>({
            query: ({ ...patch }) => ({
                url: `cancel`,
                method: 'PUT',
                body: patch,
            }),
        }),
    }),
    overrideExisting: false
});

export const { useGetInviteQuery } = inviteApi;
export const { useGetUserAllInvitesQuery } = inviteApi;
export const { useLazyCheckInviteIfNewAccountQuery } = inviteApi;
export const { useLazyInviteStateCheckQuery } = inviteApi;
export const { useRegisterWithInviteMutation } = inviteApi;
export const { useVerifyInviteMutation } = inviteApi;

//exhibitor或superuser或admin用api
export const { useGetBoothAllInvitesQuery } = inviteApi;
export const { useNewBoothInviteMutation } = inviteApi;
export const { useCancelBoothInviteMutation } = inviteApi;
