import * as React from "react";
import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, styled, Toolbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import 青年局Logo from "./images/04 青年事務局LOGO1.png";
import { SubTitle } from "../components/responsive-components/Typography";

export default function Venue() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (<>

        <Toolbar sx={{ justifyContent: "center" }}>
            <SubTitle icon='circle' text={t("event.venue")} />
        </Toolbar>
        <Container>
            <Box bgcolor={theme.palette.background.default} p={3}>
                <Stack spacing={3}>
                    <Box>
                        <SubTitle icon='circle' text={"1F"} />
                        <img src={"./images/event/青年局1F.png"} width="100%" style={{ objectFit: "contain" }} />
                    </Box>
                    <Box>
                        <SubTitle icon='circle' text={"2F"} />
                        <img src={"./images/event/青年局2F.png"} width="100%" style={{ objectFit: "contain" }} />
                    </Box><Box>
                        <SubTitle icon='circle' text={"3F"} />
                        <img src={"./images/event/青年局3F.png"} width="100%" style={{ objectFit: "contain" }} />
                    </Box>
                </Stack>
            </Box>
        </Container>
        <br />
    </>)
}
