import * as React from 'react';
import { useEffect, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useDispatchWithType, useSystemConnection, useUser } from '../store';
import { Box, CircularProgress, CircularProgressProps, Fade, Typography, useMediaQuery, useTheme } from '@mui/material';
import { logEvent } from '../components/Analytics';
import { CheckIsMobile } from '../components/Admin/Utils';
import { showModal } from '../store/rootReducer';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';

const gameWidth: number = 960;
const gameHeight: number = 600;
const gameMinWidth: number = 300;
const gameMinHeight: number = 300;

const version = 6;
export default function Game() {
    const user = useUser();
    const dispatch = useDispatchWithType();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    const { unityProvider, addEventListener, sendMessage, removeEventListener, loadingProgression, isLoaded, UNSAFE__unityInstance } = useUnityContext({
        loaderUrl: `/Unity/Build/WebGL.loader.js?version=${version}`,
        dataUrl: `/Unity/Build/WebGL.data.br?version=${version}`,
        frameworkUrl: `/Unity/Build/WebGL.framework.js.br?version=${version}`,
        codeUrl: `/Unity/Build/WebGL.wasm.br?version=${version}`,
        streamingAssetsUrl: "StreamingAssets"
    });

    useEffect(() => {
        //連上遊戲
        ConnectEvent();

        addEventListener("canvas", function (canvas) {
            canvas.width = gameWidth;
            canvas.height = gameHeight;
            //canvas.width = window.innerWidth;
            //canvas.height = window.innerHeight;
        });
        addEventListener("StartChatTo", function (userId: string) {
            hubRef.current?.invoke.OpenChatDialog(userId);
            logEvent('Chat', `Send from Game`, `${user.userId}`);
        });
        addEventListener("SendExchangeTo", function (userId: string) {
            dispatch(showModal({ modalType: "EXCHANGE_CARD", modalProps: { receiverId: userId } }));
            logEvent('ExchangeCard', `Send from Game`, `${user.userId}`);
        });
        addEventListener("StartEmbedPic", function (url: string) {
            dispatch(showModal({ modalType: "SHOW_PICTURE", modalProps: { url: url } }));
            logEvent('ExchangeCard', `Send from Game`, `${user.userId}`);
        });
        addEventListener("StartEmbedVideo", function (url: string) {
            dispatch(showModal({ modalType: "SHOW_VIDEO", modalProps: { url: url, forceUntilEnd: true } }));
            logEvent('ExchangeCard', `Send from Game`, `${user.userId}`);
        });
        addEventListener("UnityToReact", function (msg) {
            var obj = JSON.parse(msg);
            if (obj.sendType == "other") {
                hubRef.current?.send.SendGameMessage(msg);
            }
        });
        return (() => {
            //斷開遊戲連線
            console.log('Game: return');
            DisconnectEvent();
            console.log('Game: start shut down game');

            removeEventListener("canvas", () => { });
            removeEventListener("StartChatTo", () => { });
            removeEventListener("SendExchangeTo", () => { });
            removeEventListener("StartEmbedPic", () => { });
            removeEventListener("StartEmbedVideo", () => { });
            removeEventListener("UnityToReact", () => { });

            if (hubRef.current)
                hubRef.current.send.ExitGame();

            window.location.reload();
        })
    }, []);

    useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            if (isLoaded && UNSAFE__unityInstance) {
                //收到新訊息，傳到遊戲
                hubRef.current.addHandler.ReceiveGameMessage((message) => {
                    sendMessage("[SINGLETON] AppBridge", "ReactToUnity", message);
                });
            }
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.ReceiveGameMessage();
            }
        })
    }, [systemConnection, isLoaded, UNSAFE__unityInstance]);

    function ConnectEvent() {
        console.log("connect start");
        console.log(UNSAFE__unityInstance);
        var obj: MsgModle = {
            type: "Connected", userId: user.userId, payload: {}
        };
        sendMessage("[SINGLETON] AppBridge", "ReactToUnity", JSON.stringify(obj));
        console.log("connect end");
    }
    function DisconnectEvent() {
        var obj: MsgModle = {
            type: "Disconnected", userId: user.userId, payload: {}
        };
        sendMessage("[SINGLETON] AppBridge", "ReactToUnity", JSON.stringify(obj));
    }

    useEffect(() => {
        const handler = function (e) {
            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",
               /* "Numpad2", "Numpad4", "Numpad8", "Numpad6"*/].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        }
        window.addEventListener("keydown", handler, false);
        return () => {
            window.removeEventListener("keydown", handler, false);
        }
    }, []);

    function vh(v) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }
    function vw(v) {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (v * w) / 100;
    }

    if (CheckIsMobile()) {
        return (
            <>
                <Fade in={loadingProgression < 1}>
                    <Box position='absolute' width='100%'>
                        <Box sx={{ //這邊樣式比例要跟unity一樣才行
                            // aspectRatio: `calc(${gameWidth} / ${gameHeight})`,
                            margin: 'auto',
                            height: 'calc(100vh - 58px - 64px)',
                            width: '100vw',
                            padding: '12px 12px 0'
                        }}>
                            <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' sx={{
                                backgroundColor: (theme) => theme.palette.primary.light
                            }}>
                                <CircularProgressWithLabel value={loadingProgression * 100} />
                            </Box>
                        </Box>
                    </Box>
                </Fade>
                <Unity
                    unityProvider={unityProvider}
                    matchWebGLToCanvasSize={true}
                    devicePixelRatio={2}
                    tabIndex={-1}
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 'calc(100vh - 58px - 64px)',
                        width: '100vw',
                        padding: '12px 12px 0',
                        /*
                        width: '90vw',
                        height: '80vh',
                        minWidth: gameMinWidth,
                        minHeight: gameMinHeight,
                        */
                    }}
                />
            </>
        );
    } else {
        return (
            <div style={{ position: 'relative' }}>
                <Fade in={loadingProgression < 1}>
                    <Box position='absolute' width='100%'>
                        <Box sx={{ //這邊樣式比例要跟unity一樣才行
                            //aspectRatio: `calc(${gameWidth} / ${gameHeight})`,
                            margin: 'auto',
                            height: '62.5vw',
                            width: "calc(90vh * 19 / 9)",
                            maxWidth: '100%',
                            maxHeight: '90vh'
                        }}>
                            <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' sx={{
                                backgroundColor: (theme) => theme.palette.primary.light
                            }}>
                                <CircularProgressWithLabel value={loadingProgression * 100} />
                            </Box>
                        </Box>
                    </Box>
                </Fade>
                <Unity
                    unityProvider={unityProvider}
                    matchWebGLToCanvasSize={true}
                    devicePixelRatio={2}
                    tabIndex={-1}
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '62.5vw',
                        width: "calc(90vh * 19 / 9)",
                        maxWidth: '100%',
                        maxHeight: '90vh',
                        /*
                        width: '90vw',
                        height: '80vh',
                        minWidth: gameMinWidth,
                        minHeight: gameMinHeight,
                        */
                    }}
                />
            </div>
        );
    }
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex" style={{ width: 'fit-content' }}>
            <CircularProgress variant="determinate" size='10rem' {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface MsgModle {
    type: string,
    userId: string,
    payload: any
}

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
