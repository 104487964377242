import * as React from 'react';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { ConfirmButton, Dialog, DialogActions, DialogContent, important, TextField, Upload } from '../components/responsive-components';
import { Edit, Publish } from '@mui/icons-material';
import { Booth, BoothType, ItemType } from '../components/class';
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from 'react-hook-form';
import { Button, Box, Stack, styled, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { boothCustomFieldList, uniqueBoothFieldList } from '../components/CustomField';
import { ErrorMessage } from '@hookform/error-message';
import { useGetBoothFieldNameListQuery } from '../components/services/booth';
import { useUploadBannerMutation, useUploadBoothLogoMutation, useUploadEDMMutation } from '../components/services/file';
import TextEditor from '../components/Editor/CKEditor';
import { checkBoothFieldList, CustomBoothFieldProps, useBoothExtendField } from '../components/Manage/CustomField/elementFactory';
import { MultipleSelect, MultipleSelectChip, Select } from '../components/Manage/CustomField';
import { gameEngineList, gameGenreList, platformList } from '../components/selectOptions';
import UploadPoster from '../ManageBoard/Booth/UploadPoster';
import { LazyImg } from '../components/LazyImg';
import { CropAndUpload } from '../components/responsive-components/CropAndUpload';

export interface EditDialogProps {
    data: Booth;
    onClose: (result?: Booth) => void;
    isLoading?: boolean;
}

const spaceValue = '18px';
const phoneRegExp = /^$|^(((\+\d{3})|(\(\+\d{3}\))) ?)?((\d{1,4})|(\(\d{2,4}\)))[- ]?\d{1,4}[- ]?\d{2,4}( ?#(\d+)){0,2}$/i;
const dateRegExp = /^$|^(1\d{3}|20\d{2})\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[0-1])$/i;

const emailRegExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const StyledTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}));
const StyledTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    height: '35px',
}));

export default function EditIntroDialog(props: EditDialogProps & Omit<MuiDialogProps, keyof EditDialogProps>) {
    const { data, onClose, isLoading, ...other } = props;
    const { t, i18n } = useTranslation();

    const isNormalBooth = (data.type === BoothType.一般攤位 || data.type === BoothType.廣告贊助);
    //欄位名稱
    const { data: boothFieldNameList } = useGetBoothFieldNameListQuery();
    const { register, watch, formState: { errors }, handleSubmit, setValue, reset, control } = useForm({
        defaultValues: checkBoothFieldList(data, boothFieldNameList.data), mode: 'onChange'
    });
    React.useEffect(() => {
        reset(checkBoothFieldList(data, boothFieldNameList.data));
    }, [data])


    //客製化欄位
    const upperField = ["chTeamName", "enTeamName", "phone", "email", "fb", "ig"];
    const [extendFieldList, setExtendFieldList] = React.useState<CustomBoothFieldProps[]>([]);
    const [extendFieldList2, setExtendFieldList2] = React.useState<CustomBoothFieldProps[]>([]);
    React.useEffect(() => {
        if (boothFieldNameList) {
            setExtendFieldList(() => {
                return boothFieldNameList.data.filter(x => upperField.includes(x.fieldId)).map((fieldInfo) => (
                    {
                        ...fieldInfo,
                        fieldStyle: "separate",
                        textFieldProps: {
                            required: fieldInfo.fieldId === "chTeamName",
                            variant: "outlined",
                            fullWidth: true
                        },
                        registerOptions: fieldInfo.fieldId === "phone" ? { pattern: { value: phoneRegExp, message: t("booth.editDialog.error_isPhone") } } :
                            fieldInfo.fieldId === "email" ? { pattern: { value: emailRegExp, message: t("booth.editDialog.error_isEmail") } } :
                                (fieldInfo.fieldId === "fb" || fieldInfo.fieldId === "ig") ? { pattern: { value: urlRegExp, message: t("booth.editDialog.error_isUrl") } } :
                                    null
                    } as CustomBoothFieldProps
                ))
            });
            setExtendFieldList2(() => {
                return boothFieldNameList.data.filter(x => !upperField.includes(x.fieldId)).map((fieldInfo) => {
                    if (fieldInfo.fieldId === "playerNum")
                        return {
                            ...fieldInfo,
                            textFieldProps: {
                                required: true,
                                variant: "outlined",
                                fullWidth: true
                            }
                        } as CustomBoothFieldProps;
                    else if (fieldInfo.fieldId === "groupIntro" || fieldInfo.fieldId === "gamesIntro")
                        return {
                            ...fieldInfo,
                            textFieldProps: {
                                required: true,
                                variant: "outlined",
                                fullWidth: true
                            }
                        } as CustomBoothFieldProps;
                    else if (fieldInfo.fieldId === "platform")
                        return {
                            ...fieldInfo,
                            overrideComponent: ({ field, fieldState }) => (
                                <MultipleSelect
                                    selectList={platformList}
                                    textFieldProps={{
                                        required: true,
                                        variant: "outlined",
                                        size: "small",
                                        label: (i18n.language === 'en' ? fieldInfo.fieldName[1] : fieldInfo.fieldName[0]) || "",
                                        error: !!fieldState.error,
                                        helperText: fieldState.error ? fieldState.error.message : ' ',
                                        ...field
                                    }}
                                />
                            )
                        } as CustomBoothFieldProps;
                    else if (fieldInfo.fieldId === "gameEngine")
                        return {
                            ...fieldInfo,
                            overrideComponent: ({ field, fieldState }) => (
                                <Select
                                    selectList={gameEngineList}
                                    textFieldProps={{
                                        required: true,
                                        variant: "outlined",
                                        size: "small",
                                        label: (i18n.language === 'en' ? fieldInfo.fieldName[1] : fieldInfo.fieldName[0]) || "",
                                        error: !!fieldState.error,
                                        helperText: fieldState.error ? fieldState.error.message : ' ',
                                        ...field
                                    }}
                                    otherFieldProps={{
                                        variant: "outlined",
                                        size: "small",
                                        hiddenLabel: true,
                                        placeholder: t("booth.editDialog.placeholder_other"),
                                    }}
                                />
                            )
                        } as CustomBoothFieldProps;
                    else return {
                        ...fieldInfo,
                        textFieldProps: {
                            required: false,
                            variant: "outlined",
                            fullWidth: true
                        },
                        registerOptions: (fieldInfo.fieldId === "link" || fieldInfo.fieldId === "website") ? { pattern: { value: urlRegExp, message: t("booth.editDialog.error_isUrl") } } : null
                    } as CustomBoothFieldProps;
                })
            });
        }
    }, [boothFieldNameList]);

    const content = useBoothExtendField({ data: data, fieldList: extendFieldList, register, errors, control });
    const content2 = useBoothExtendField({ data: data, fieldList: extendFieldList2, register, errors, control });


    //進行logo圖片上傳
    const [uploadBoothLogo, { isLoading: isUploading }] = useUploadBoothLogoMutation();

    const [logoFile, setLogoFile] = React.useState<File>();
    const handleLogoUrlChange = (url: string) => setValue("logoUrl", url);

    async function onSubmit(data) {
        let temp = data;
        if (logoFile != null) {
            let formData = new FormData();
            formData.append("file", logoFile);
            await uploadBoothLogo({ boothId: data.boothId, formData }).unwrap().then(result => {
                if (result.isSuccess) {
                    let uri = result.data.url;
                    handleLogoUrlChange(uri);
                    temp = { ...temp, logoUrl: uri };
                }
            });
        }
        if (bannerFile != null) {
            let formData = new FormData();
            formData.append("file", bannerFile);
            await uploadBoothBanner({ boothId: data.boothId, formData }).unwrap().then(result => {
                if (result.isSuccess) {
                    let uri = result.data.url;
                    handleBannerUrlChange(uri);
                    temp = { ...temp, bannerUrl: uri };
                }
            });
        }
        if (edmFile != null) {
            let formData = new FormData();
            formData.append("file", edmFile);
            await uploadBoothEdm({ boothId: data.boothId, formData }).unwrap().then(result => {
                if (result.isSuccess) {
                    let uri = result.data.url;
                    handleEdmUrlChange(uri);
                    temp.fieldList.edm.value=uri ;
                }
            });
        }
        onClose(temp);
    }

    //進行banner圖片上傳
    const [uploadBoothBanner, { isLoading: isBannerUploading }] = useUploadBannerMutation();
    const bannerUrlValue = watch("bannerUrl");
    const handleBannerUrlChange = (url: string) => setValue("bannerUrl", url);
    const [bannerFile, setBannerFile] = React.useState<File>();
    
    //進行edm圖片上傳
    const [uploadBoothEdm, { isLoading: isEdmUploading }] = useUploadEDMMutation();
    const edmUrlValue = watch(`fieldList.edm.value`);
    const handleEdmUrlChange = (url: string) => setValue(`fieldList.edm.value`, url);
    const [edmFile, setEdmFile] = React.useState<File>();


    function boothFieldIdToName(fieldId: string) {
        let fieldName = '';
        if (boothFieldNameList) {
            let boothFieldName = boothFieldNameList.data.find(x => x.fieldId === fieldId);
            if (boothFieldName) {
                if (i18n.language === 'en') {
                    if (boothFieldName.fieldName[1])
                        fieldName = boothFieldName.fieldName[1];
                }
                else {
                    if (boothFieldName.fieldName[0])
                        fieldName = boothFieldName.fieldName[0];
                }
            }
        }
        return fieldName;
    }
    //圖片調版
    const [width, setWidth] = React.useState(420);
    const [height, setHeight] = React.useState(420);
    const divRef = React.createRef<HTMLDivElement>(); //控制寬度的外層
    /* React.useEffect(() => {
         const resizeObserver = new ResizeObserver(entries => {
             setWidth(entries[0].target.clientWidth);
             setHeight(entries[0].target.clientWidth / 3);
         });
         if (divRef.current) {
             resizeObserver.observe(divRef.current);
             setWidth(divRef.current.clientWidth);
             setHeight(divRef.current.clientWidth / 3);
         }
         return (() => {
             resizeObserver.disconnect();
         })
     }, [other.open, divRef.current]);*/
    return (
        <Dialog {...other} disableEnforceFocus>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box className="d-flex flex-row flex-wrap justify-content-end" paddingRight='12px'>
                        <Button onClick={() => onClose()}>{t('booth.editDialog.discard')}</Button>
                    </Box>
                    <Box className="d-flex flex-row flex-wrap">
                        <Box className="d-flex flex-row flex-wrap">
                            <div ref={divRef} className="d-flex flex-column w-100 mt-2 mb-2">
                                <StyledTypography>
                                    {important}<Trans i18nKey={'booth.editDialog.bannerUrl'} />
                                </StyledTypography>
                                <CropAndUpload imageUrl={bannerUrlValue} onChange={setBannerFile} width={1050} height={350} transKey="booth.editDialog.bannerUrl"/>
                                <Typography variant='subtitle2'>
                                    {t('manage.boothEdit.imageSize_1')}
                                    <span style={{ color: 'red' }}>1:3，400 x 1200px。</span>                      
                                </Typography>
                            </div>
                            <Box flex='1 0 40%' className="d-flex flex-row flex-wrap align-content-start"
                                padding='12px'>
                                {other.open &&
                                    <div ref={divRef} className="d-flex flex-column w-100 mt-2 mb-2">
                                        <StyledTypography>
                                            {important}<Trans i18nKey={'booth.editDialog.logoUrl'} />
                                        </StyledTypography>
                                        <CropAndUpload imageUrl={watch("logoUrl")} onChange={setLogoFile} width={420} height={420} transKey="booth.editDialog.logoUrl"/>                         
                                        <Typography variant='subtitle2'>
                                            {t('manage.boothEdit.imageSize_1')}
                                            <span style={{ color: 'red' }}>1:1 150 x 150px 請勿將LOGO貼邊上傳，以免圖檔裁切</span>
                                        </Typography>
                                    </div>
                                }
                                <Box flex={1} /*flex='0 0 35%'*/ className="d-flex flex-column mt-2"
                                    marginRight={spaceValue}>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.boothNo'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        disabled
                                        inputProps={{ maxLength: 3001 }}
                                        error={!!errors.boothNo}
                                        helperText={errors.boothNo ? errors.boothNo.message : ' '}
                                        {...register("boothNo")}
                                    />
                                </Box>
                            </Box>

                            <Box flex='1 0 40%' padding='12px'
                                minWidth='300px' className="d-flex flex-row mt-2 flex-wrap align-content-start">
                                <Box flex='1 0 50%' className="d-flex flex-column mt-2">
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.chName'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        inputProps={{ maxLength: 40 }}
                                        error={!!errors.chName}
                                        {...register("chName", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'chName'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                                { /*        <Box flex='0 0 50%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue}>
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.abbrev'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        error={!!errors.abbrev}
                                        inputProps={{ maxLength: 40 }}
                                        {...register("abbrev", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'abbrev'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>*/}
                                <Box flex='1 0 100%' className="d-flex flex-column mt-2">
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.enName'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        error={!!errors.enName}
                                        inputProps={{ maxLength: 80 }}
                                        {...register("enName", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'enName'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                                {/*遊戲類型*/}
                                {data.type == BoothType.一般攤位 && <>
                                    <Box flex='1 0 100%' className="d-flex flex-column mt-2">

                                        <Typography fontWeight="bold">
                                            <Trans i18nKey={'booth.editDialog.gameGenre'} />{important}
                                        </Typography>
                                        <Controller
                                            name="category"
                                            control={control}
                                            rules={{
                                                required: `${t('booth.editDialog.error_required')}`
                                            }}
                                            render={({ field, fieldState }) => (
                                                <MultipleSelectChip
                                                    selectList={gameGenreList}
                                                    textFieldProps={{
                                                        variant: "outlined",
                                                        size: "small",
                                                        hiddenLabel: true,
                                                        error: !!fieldState.error,
                                                        helperText: fieldState.error ? fieldState.error.message : ' ',
                                                        ...field
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>

                                </>}
                                {/*產業類別*/}
                                {data.type == BoothType.廣告贊助 && <>
                                    <Box flex='1 0 100%' className="d-flex flex-column mt-2">

                                        <Typography fontWeight="bold">
                                            <Trans i18nKey={'booth.editDialog.category'} />{important}
                                        </Typography>
                                        <TextField
                                            variant='outlined'
                                            size="small"
                                            error={!!errors.enName}
                                            helperText={errors.enName ? errors.enName.message : ' '}
                                            inputProps={{ maxLength: 80 }}
                                            {...register("category", {
                                                required: `${t('booth.editDialog.error_required')}`
                                            })}
                                        />
                                    </Box>
                                </>}
                                <Stack direction='column' width='100%'>
                                    {content}
                                </Stack>

                            </Box>
                        </Box>
                        <Box flex='1 0 100%' className="d-flex flex-column mt-2">
                            <Typography fontWeight="bold">
                                <Trans i18nKey={'booth.editDialog.intro'} />{important}
                            </Typography>
                            <TextField
                                variant='outlined'
                                size="small"
                                error={!!errors.intro}
                                helperText={errors.intro ? errors.intro.message : ' '}
                                inputProps={{ maxLength: 120 }}
                                {...register("intro", {
                                    required: `${t('booth.editDialog.error_required')}`
                                })}
                            />
                        </Box>
                        <Box className="d-flex flex-row flex-wrap" style={{ flex: 1 }}>
                            {/*!isNormalBooth &&
                                <Box flex='1 0' className="d-flex flex-column mt-2" padding='12px'>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.customContent'} />
                                    </StyledTypography>
                                    <TextEditor
                                        data={watchCustomContent}
                                        onChange={handleCustomContentChange}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'customContent'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                           */ }
                            {data.fieldList.map((boothField, index) => {
                                let field = boothCustomFieldList.find(x => x.id === boothField.fieldId);
                                if (field && !uniqueBoothFieldList.includes(boothField.fieldId))
                                    return (
                                        <Box flex='1 0 100%' className="d-flex flex-column mt-2" padding='12px' key={field.id}>
                                            <StyledTypography>
                                                {boothFieldIdToName(field.id)}
                                            </StyledTypography>
                                            {field.editComponent({
                                                displayTitle: boothFieldIdToName(field.id),
                                                value: watch(`fieldList.${index}.value`),
                                                required: field.id == "groupIntro" ? true : field.id == "gamesIntro" ? true : false,
                                                onChange: (event) => setValue(`fieldList.${index}.value`, event.target.value),
                                                errorMessage: errors.fieldList && errors.fieldList[index]?.value?.message,
                                                inputProps: {                                                
                                                    maxLength: field.id == "groupIntro" ? 192 : field.id =="gamesIntro"?240: 3001,
                                                    ...register(`fieldList.${index}.value`, field.validation)
                                                },
                                            })}
                                        </Box>
                                    )
                                else
                                    return (
                                        <div key={boothField.fieldId} />
                                    )
                            })}
                        </Box>
                        <Box>

                        </Box>
                        <Stack direction='column' width='100%'>
                            {content2}
                        </Stack>
                    </Box>
                    {data.type == BoothType.一般攤位&& <>
                        <StyledTypography>
                                    {important}<Trans i18nKey={'booth.editDialog.edmUrl'} />
                                </StyledTypography>
                                <Upload
                            value={edmUrlValue} onChange={(file) => { setEdmFile(file) }}
                                    width={'100%'} height={'370px'}
                                >
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "4px",
                                            color: "darkgray",
                                            cursor: "pointer",
                                            width: `${width}px`,
                                            height: `${height}px`,
                                        }}>
                                        <Publish />
                                        <Trans i18nKey={'booth.editDialog.upload'} />
                                    </div>
                        </Upload>
                        <Typography variant='subtitle2'>
                            {t('manage.boothEdit.imageSize_1')}
                            <span style={{ color: 'red' }}>16:9 720 x 405px</span>
                        </Typography>
                    </>
                    }
                </DialogContent>
                <DialogActions>
                    <ConfirmButton type="submit" disabled={isUploading || isLoading || isBannerUploading || isEdmUploading}>
                        <Trans i18nKey={'booth.editDialog.saveButton'} />
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
