import { Email, Facebook, Favorite, FavoriteBorderRounded, Instagram, Language, Phone, Place } from "@mui/icons-material";
import { Badge, BadgeProps, Box, Button, ButtonBase, IconButton, Link, Skeleton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { BoothType } from "../../../components/class";
import { gameGenreDictionary, platformDictionary } from "../../../components/selectOptions";
import { useProgressiveImage } from "../../../Posters/LazyBackground";
import { Booth, boothCard, theme2022 } from "./BoothList2022";

type cardOption = { nameKey: string; styleKey: string; };
const cardStyleList: cardOption[] = [
	{ nameKey: "boothCard.serviceDesk", styleKey: "serviceDesk" },
	{ nameKey: "boothCard.sponsor", styleKey: "sponsor" },
	{ nameKey: "boothCard.exhibitorD1", styleKey: "exhibitorD1" },
	{ nameKey: "boothCard.exhibitorD2", styleKey: "exhibitorD2" },
	{ nameKey: "boothCard.other", styleKey: "other" },
];

export interface BoothCardProps<T extends Booth> {
	data: T;
	cardStyle?: number; //決定小卡模式的樣式
	isOnline?: boolean;
	simpleMode?: boolean; //是否為小卡模式
	darkMode?: boolean;
	editable?: boolean;
	fixed?: boolean; //固定顯示方式，不做簡介rwd
	className?: string;

	onClickEdit?: (data: T) => void; //非小卡模式時，放入編輯按鈕並觸發此函式
	onClickFollow?: (data: T) => void;
	onClickShowMore?: (event: React.MouseEvent<HTMLElement>, data: T) => void;

	disableFollow?: boolean; //不顯示「❤️」按鈕
	disableShowMore?: boolean; //小卡模式時，不顯示「更多」按鈕
}
export function BoothCard2022<T extends Booth>(props: BoothCardProps<T>) {
	const { data, cardStyle, isOnline, simpleMode, darkMode, editable, fixed, className, onClickEdit, onClickFollow, onClickShowMore, disableFollow, disableShowMore } = props;
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	//初始化
	const [cardData, setCardData] = React.useState<cardOption>();
	React.useEffect(() => {
		if (cardStyle)
			setCardData(cardStyleList[cardStyle]);
		else if (data) {
			if (data.type === BoothType.服務台) {
				setCardData(cardStyleList[0]);
			}
			else if (data.type === BoothType.廣告贊助) {
				setCardData(cardStyleList[1]);
			}
			else if (data.exhibitionDate.includes("D1")) {
				setCardData(cardStyleList[2]);
			}
			else if (data.exhibitionDate.includes("D2")) {
				setCardData(cardStyleList[3]);
			}
			else setCardData(cardStyleList[4]);
		}
	}, [data]);

	//按鈕功能
	function handleClickEdit() {
		if (data?.boothId) {
			if (onClickEdit) onClickEdit(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickFollow() {
		if (data?.boothId) {
			if (onClickFollow) onClickFollow(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickShowMore(event: React.MouseEvent<HTMLElement>) {
		if (data?.boothId) {
			if (onClickShowMore) onClickShowMore(event, data);
		}
		else console.log(`boothId is null`);
	}

	//客製化欄位
	/*const boothFieldNameList = useBoothFieldNameList();
	let fieldList = boothFieldNameList;
	if (data) fieldList = fieldList.filter(x => x.boothTypeList.includes(data.type));
	//fieldList = fieldList.filter(x => !config.editFieldExcluded.user.includes(x.fieldId)); //應該都會顯示吧? 如有屏蔽需求，可以去翻 Manage/CustomField

	let index = 0;
	if (i18n.language === 'en') index = 1;
	//let somethingToSay = fieldList?.find(x => x.fieldId === 'somethingToSay')?.fieldName[index];*/

	const logo = useProgressiveImage(data?.logoUrl);
	const [logoSize, setLogoSize] = React.useState<{ width: number; height: number }>({ width: 450, height: 150 });
	const container = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			let width = entries[0].target.clientWidth;
			if (width <= 900)
				setLogoSize({ width: 450, height: 150 });
			else
				setLogoSize({ width: 480, height: 160 });
		});
		resizeObserver.observe(container.current)
		return (() => {
			resizeObserver.disconnect();
		})
	}, []);

	return (
		<Box bgcolor={(!simpleMode && darkMode) ? '#00003E' : theme2022.palette.background.paper}
			border={simpleMode && `5px solid ${cardData ? boothCard[cardData.styleKey] : theme2022.palette.background.paper}`}
			borderRadius={simpleMode && 3}
			color={!simpleMode && darkMode && theme2022.palette.text.white}
			className={className}
		>
			{/*編輯 or ❤️*/}
			<Box display="flex" justifyContent={!simpleMode ? "flex-end" : "space-between"} alignItems="flex-start" height={simpleMode ? "16px" : "30px"}
				pl={simpleMode && 2}
			>
				{simpleMode && <Box color={theme2022.palette.background.paper}>{cardData && t(cardData.nameKey)}</Box>}
				{editable && data  ?
					<Button variant="text" onClick={handleClickEdit}>{t('booth.button.edit')}</Button>
					: !disableFollow && data  ?
						<Box bgcolor={(!simpleMode && darkMode) ? '#00003E' : theme2022.palette.background.paper} zIndex={1} borderRadius="50%">
							<IconButton color="secondary" onClick={handleClickFollow}>
								{
								//	<Favorite /> 
								}
							</IconButton></Box>
						: null
				}
			</Box>
			<Box ref={container} display="grid" gridTemplateColumns={(!fixed && (isMobile || simpleMode)) ? "1fr" : `${logoSize.width}px 1fr`} gap={2} px={2}>
				{/*LOGO*/}
				<Box >
					<BoothCardBadge isOnline={isOnline} ripple>
						<ButtonBase sx={{ width: "100%", "&:hover": { color: theme.palette.text.secondary } }} {...(simpleMode ? { onClick: handleClickShowMore, href: `/Booth?id=${data?.boothId}` } : { disabled: true })}>
							<Box position="relative" width={"100%"/*isMobile ? "100%" : logoSize.width*/} maxWidth="100%" paddingTop={(logoSize.height / logoSize.width * 100) + "%"}>
								<Box position="absolute" top={0} right={0} bottom={0} left={0} borderRadius={3}>
									{data && data.logoUrl && data.logoUrl !== "" && logo ?
										<img src={logo} width="100%" height="100%" style={{ objectFit: "cover" }} />
										: data && (data.logoUrl === "" || data.logoUrl === null) ?
											<Box bgcolor={darkMode ? "background.dark" : "grey.200"} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" border={darkMode && `2px solid ${theme2022.palette.primary.main}`}>
												<Typography color={darkMode && "primary.main"}>NO LOGO</Typography>
											</Box>
											: <Skeleton variant="rectangular" width="100%" height="100%" />
									}
								</Box>
							</Box>
						</ButtonBase>
					</BoothCardBadge>
				</Box>
				{/*簡介*/}
				<Box>
					{/*名字 and 攤位位置*/}
					{simpleMode ? <>
						{data ? <>
							<Typography variant="h6" fontWeight="bold" noWrap>{data.chName || " "}</Typography>
							<Typography>{data.enName || " "}</Typography>
							<Box height="10px" />

							<Typography>
								<Box component="span" color="primary.main" fontWeight="bold" mr={1}>
									攤位編號：
								</Box>
								{data.boothNo}
							</Typography>
							<Typography fontWeight="bold">
								<Box component="span" color="primary.main" mr={1}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{getBoothLocation(data.exhibitionDate, data.boothNo)}
							</Typography>
						</> : <>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
						</>}</> : <>
						{data ? <>
							<Typography variant="subtitle1" mb={0.5}>
								<Box component="span" color="primary.main" fontWeight="bold" mr={1}>
									攤位編號：
								</Box>
								{data.boothNo}
								<Box display={{ xs: "block", md: "none" }} width="100%" />
								<Box component="span" color="primary.main" fontWeight="bold" ml={{ md: 3 }} mr={0.5}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{getBoothLocation(data.exhibitionDate, data.boothNo)}
							</Typography>
							<Typography variant="h5" fontWeight="bold">{data.chName || " "}</Typography>
							<Typography variant="h6">{data.enName || ""}</Typography>
						</> : <>
							<Typography variant="h5"><Skeleton variant="text" /></Typography>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
						</>}
					</>}

					<Box height="10px" />
					{/*其他攤位資料*/}
					{simpleMode ? <>
						{data ? <>
							{data.type === BoothType.一般攤位 ? <>
								<Typography variant="body2">
									<Box component="span" fontWeight="bold" mr={1}>
										所屬團隊：
									</Box>
									{data.fieldList?.find(x => x.fieldId === 'chTeamName')?.value}
									{data.fieldList?.find(x => x.fieldId === 'chTeamName')?.value && data.fieldList?.find(x => x.fieldId === 'enTeamName')?.value && " | "}
									{data.fieldList?.find(x => x.fieldId === 'enTeamName')?.value}
								</Typography>
								<Typography variant="body2">
									<Box component="span" fontWeight="bold" mr={1}>
										遊戲類型：
									</Box>
									{data.category.split(",").filter(x => x).map(x => t(gameGenreDictionary[x].nameKey)).join(i18n.language === "ch" ? "、" : ", ")}
								</Typography>
							</> : data.type === BoothType.廣告贊助 ? <>
								<Typography variant="body2">
									<Box component="span" fontWeight="bold" mr={1}>
										產業類別：
									</Box>
									{data.category}
								</Typography>
								<Typography variant="body2">
									<Box component="span" fontWeight="bold" mr={1}>
										攤位類型：
									</Box>
									{t("boothList.type.sponsor")}
								</Typography>
							</> : data.type === BoothType.服務台 ? <>
								<Box display="flex" gap={1}>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'fb')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme2022.palette.secondary.main } }}
									>
										<Facebook />
									</Link>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'ig')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme2022.palette.secondary.main } }}
									>
										<Instagram />
									</Link>
								</Box>
							</> : null}
						</> : <>
							<Typography variant="subtitle1" gutterBottom><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1" gutterBottom><Skeleton variant="text" /></Typography>
						</>}
					</> : <>
						{data ? <>
							{data.type === BoothType.一般攤位 ? <>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										所屬團隊：
									</Box>
									{data.fieldList?.find(x => x.fieldId === 'chTeamName')?.value}
									{data.fieldList?.find(x => x.fieldId === 'chTeamName')?.value && data.fieldList?.find(x => x.fieldId === 'enTeamName')?.value && " | "}
									{data.fieldList?.find(x => x.fieldId === 'enTeamName')?.value}
								</Typography>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										遊玩人數：
									</Box>
									{data.fieldList?.find(x => x.fieldId === 'playerNum')?.value}
								</Typography>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										遊戲類型：
									</Box>
									{data.category.split(",").filter(x => x).map(x => t(gameGenreDictionary[x].nameKey)).join(i18n.language === "ch" ? "、" : ", ")}
								</Typography>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										平台裝置：
									</Box>
									{data.fieldList?.find(x => x.fieldId === 'platform')?.value.split(",").filter(x => x != "").map(x => t(platformDictionary[x].nameKey)).join(i18n.language === "ch" ? "、" : ", ")}
								</Typography>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										開發引擎：
									</Box>
									{data.fieldList?.find(x => x.fieldId === 'gameEngine')?.value}
								</Typography>
								{data.fieldList?.find(x => x.fieldId === 'link')?.value &&
									<Typography fontWeight="bold" variant="body1" mt={0.5}>
										<Box component="span" color={darkMode ? "secondary.main" : "primary.main"} mr={1}>
											<Language fontSize='small' color="inherit" />
										</Box>
										<Link color="#6ACCFF"
											href={data.fieldList?.find(x => x.fieldId === 'link')?.value} target="_blank"
										>
											{data.fieldList?.find(x => x.fieldId === 'link')?.value}
										</Link>
									</Typography>
								}
							</> : data.type === BoothType.廣告贊助 ? <>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										產業類別：
									</Box>
									{data.category}
								</Typography>
								<Typography variant="body1">
									<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
										攤位類型：
									</Box>
									{t("boothList.type.sponsor")}
								</Typography>
								{data.fieldList?.find(x => x.fieldId === 'phone')?.value &&
									<Typography variant="body1" mt={0.5}>
										<Box component="span" color={darkMode ? "secondary.main" : "primary.main"} mr={1}>
											<Phone fontSize='small' color="inherit" />
										</Box>
										{data.fieldList?.find(x => x.fieldId === 'phone')?.value}
									</Typography>
								}
								{data.fieldList?.find(x => x.fieldId === 'address')?.value &&
									<Typography variant="body1" mt={0.5}>
										<Box component="span" color={darkMode ? "secondary.main" : "primary.main"} mr={1}>
											<Place fontSize='small' color="inherit" />
										</Box>
										{data.fieldList?.find(x => x.fieldId === 'address')?.value}
									</Typography>
								}
								{data.fieldList?.find(x => x.fieldId === 'email')?.value &&
									<Typography fontWeight={500} variant="body1" mt={0.5}>
										<Box component="span" color={darkMode ? "secondary.main" : "primary.main"} mr={1}>
											<Email fontSize='small' color="inherit" />
										</Box>
										<Link color="#6ACCFF"
											href={"mailto:" + data.fieldList?.find(x => x.fieldId === 'email')?.value} target="_blank"
										>
											{data.fieldList?.find(x => x.fieldId === 'email')?.value}
										</Link>
									</Typography>
								}
								{data.fieldList?.find(x => x.fieldId === 'website')?.value &&
									<Typography fontWeight={500} variant="body1" mt={0.5}>
										<Box component="span" color={darkMode ? "secondary.main" : "primary.main"} mr={1}>
											<Language fontSize='small' color="inherit" />
										</Box>
										<Link color="#6ACCFF"
											href={data.fieldList?.find(x => x.fieldId === 'website')?.value} target="_blank"
										>
											{data.fieldList?.find(x => x.fieldId === 'website')?.value}
										</Link>
									</Typography>
								}
							</> : data.type === BoothType.服務台 ? <>
								<Box display="flex" gap={1}>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'fb')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme2022.palette.secondary.main } }}
									>
										<Facebook />
									</Link>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'ig')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme2022.palette.secondary.main } }}
									>
										<Instagram />
									</Link>
								</Box>
							</> : null}
						</> : <>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
						</>}
					</>}
				</Box>
			</Box>
			{/*更多按鈕*/}
			{simpleMode && !disableShowMore &&
				<Box px={2} display="flex" justifyContent="flex-end" color="primary.main" height="16px">
					<Box alignSelf="flex-end" mb={1}>
						<Link
							href={`/Booth?id=${data?.boothId}`} underline="hover" onClick={handleClickShowMore}
							sx={{ cursor: "pointer", "&:hover": { color: theme2022.palette.secondary.main } }}
						>
							{`${t('user.card.more')} >>`}
						</Link>
					</Box>
				</Box>
			}
		</Box>
	);
}
function getBoothLocation(exhibitionDate: string[], boothNo: string) {
	if (boothNo === "D2002") //微軟
		return "DAY2 Mentor產學推廣展";
	if (exhibitionDate.includes("D1")) {
		if (exhibitionDate.includes("D2")) {
			return "DAY1、DAY2 Mentor產學推廣展"
		}
		else return "DAY1 Idea創意啟航展"
	}
	else if (exhibitionDate.includes("D2")) {
		return "DAY2 Landing夢想著陸展"
	}
	else if (exhibitionDate.includes("D0")) {
		return "純線上展"
	}
	else return null;
}

const BoothCardBadge = styled(({ isOnline, ripple, ...otherProps }: BoothCardBadgeProps) =>
	<Badge
		variant="dot"
		invisible={isOnline === null || isOnline === undefined}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		{...otherProps}
	/>
)(({ theme, isOnline, ripple }) => ({
	width: "100%",
	'& .MuiBadge-badge': {
		width: "20px",
		height: "20px",
		border: `3px solid ${theme2022.palette.background.paper}`,
		borderRadius: "50%",
		backgroundColor: isOnline ? theme2022.palette.success.light : theme.palette.grey[400],
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: (isOnline === true && ripple === true) && 'ripple 1.2s infinite ease-in-out',
			border: (isOnline === true && ripple === true) && `1px solid ${theme2022.palette.success.light}`,
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.6)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(1.8)',
			opacity: 0,
		},
	}
}));
interface BoothCardBadgeProps extends BadgeProps {
	isOnline?: boolean;
	ripple?: boolean;
}