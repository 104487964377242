import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import * as React from 'react';

export default function GoogleLoginManager(props: GoogleLoginManagerProps) {
    const { clientId, onSuccess, onError, width } = props;

    const handleLoginSuccess = (response) => {
        if (onSuccess) onSuccess(response);
    }

    const handleLoginFailure = () => {
        if (onError) onError();
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={(response) => handleLoginSuccess(response)}
                onError={() => handleLoginFailure()}
                logo_alignment="center"
                width={width}
            />
        </GoogleOAuthProvider>
    )
}
interface GoogleLoginManagerProps {
    clientId: string;
    onSuccess?: (response: CredentialResponse) => void;
    onError?: () => void;
    width?: number;
}

interface CredentialResponse {
    /** This field is the returned ID token */
    credential?: string;
    /** This field sets how the credential is selected */
    select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'brn_add_session' | 'btn_confirm_add_session';
    clientId?: string;
}