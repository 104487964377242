import * as React from 'react';
import { Agenda, Seminar } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../components/responsive-components';
import { Box, ButtonBase, ButtonProps, Card, CardContent, Divider, Fade, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Article, CircleOutlined, Close, Redeem } from '@mui/icons-material';
import { CountDownTimer } from '../../components/Admin/Utils';

const StyledButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    minWidth: '96px',
    color: '#FFFFFF',
    backgroundColor: '#12C735',
    '&:hover': {
        color: '#E6E6E6',
        backgroundColor: '#0EA02A',
    },
    '&:active': {
        backgroundColor: '#11DB38',
    },
}));
export default function AgendaCard(props: AgendaCardProps) {
    const { isManage, seminarId, seminarChName, seminarEnName, data, showDate, onEdit, onQrcode,onDelete, showRegister } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

    function handleClickLink(event: React.MouseEvent<HTMLElement>, link: string) {
        event.preventDefault();
        window.open(link, '_blank')
    }

    return (
        <Card style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column', minWidth: '80px', maxHeight: '200px' }}>
                <Typography fontWeight='bold' variant={isMobile ? 'subtitle2' : 'subtitle1'}>
                    {new Date(data.startTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
                <Divider flexItem orientation='vertical' variant='middle' style={{ flex: 1, margin: 'auto' }} />
                <Typography fontWeight='bold' variant={isMobile ? 'subtitle2' : 'subtitle1'}>
                    {new Date(data.endTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
            </CardContent>
            <Divider flexItem orientation='vertical' />
            <CardContent style={{ flex: 1, position: 'relative' }}>
                <Typography fontWeight='bold' style={{ fontSize: isMobile ? '12px' : '14px', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                    {showDate &&
                        <span style={{ marginRight: '12px' }}>
                            {new Date(data.startTime).toLocaleString(
                                i18n.language == "ch" ? "zh-TW" : 'en-US',
                                { year: "numeric", month: '2-digit', day: '2-digit', weekday: 'narrow' }
                            )}
                        </span>
                    }
                    <span style={{ color: '#91CFFF' }}>
                        {i18n.language == "ch" ? seminarChName : seminarEnName}
                    </span>
                </Typography>
                <ButtonBase href={`/Booth?id=${data.boothId}`} onClick={(e) => handleClickLink(e, `/Booth?id=${data.boothId}`)}>
                    <Typography fontWeight='bold' style={{ fontSize: isMobile ? '12px' : '16px' }}>
                        {i18n.language == "ch" ? data.boothChName : data.boothEnName}
                    </Typography>
                </ButtonBase>
                <Typography fontWeight='bold' style={{ fontSize: isMobile ? '16px' : '22px' }}>
                    {data.title}
                </Typography>
                <Typography style={{ marginRight: 'auto', paddingRight: '12px', fontSize: isMobile ? '12px' : '14px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {`${t('agenda.card.regiTime')}：`}
                    </span>
                    <Trans i18nKey="agenda.card.regiStartEnd"
                        values={{
                            startTime: new Date(data.regiStartTime).toLocaleString(
                                i18n.language == "ch" ? "zh-TW" : 'en-US',
                                { year: "numeric", month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit' }
                            ),
                            endTime: new Date(data.regiEndTime).toLocaleString(
                                i18n.language == "ch" ? "zh-TW" : 'en-US',
                                { year: "numeric", month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit' }
                            )
                        }}
                    />
                </Typography>
                <Box style={{ display: 'flex', flexWrap: isMobile ? 'wrap' : 'nowrap', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '12px', whiteSpace: "pre-wrap" }}>
                    {((data.speakerInfo && data.speakerInfo != '') || (data.eventContent && data.eventContent != '')) &&
                        <Box sx={{ marginRight: "auto" }}>
                            {(data.speakerInfo && data.speakerInfo != '') &&
                                <Box id='speakerInfo' sx={{ display: 'flex' }}>
                                    <Redeem sx={{ color: '#FF51FF', marginRight: '6px' }} />
                                    <Box>
                                        <Typography fontWeight='bold' style={{ fontSize: isMobile ? '11px' : '14px' }}>
                                            {t('agenda.card.speakerInfo')}
                                        </Typography>
                                        <Typography style={{ fontSize: isMobile ? '11px' : '14px' }}>
                                            {data.speakerInfo}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                            {(data.eventContent && data.eventContent != '') &&
                                <Box id='eventContent' sx={{ display: 'flex', marginTop: '12px' }}>
                                    <Article sx={{ color: '#1763FB', marginRight: '6px' }} />
                                    <Box>
                                        <Typography fontWeight='bold' style={{ fontSize: isMobile ? '11px' : '14px' }}>
                                            {t('agenda.card.eventContent')}
                                        </Typography>
                                        <Typography style={{ fontSize: isMobile ? '11px' : '14px' }}>
                                            {data.eventContent}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                    {/*(!isManage && showRegister) &&
                        <StyledButton
                            variant='contained'
                            href={data.registrationLink}
                            onClick={(e) => handleClickLink(e, data.registrationLink)}
                            disabled={
                                (new Date(data.regiEndTime).getTime() < new Date().getTime() || new Date().getTime() < new Date(data.regiStartTime).getTime()) ||
                                (!data.registrationLink || data.registrationLink === '')
                            }
                        >
                            <Typography variant={isMobile ? 'subtitle2' : "h6"} fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
                                {(!data.registrationLink || data.registrationLink === '') ? t('agenda.card.registrationNone') :
                                    (new Date(data.regiEndTime).getTime() < new Date().getTime() || new Date().getTime() < new Date(data.regiStartTime).getTime()) ? t('agenda.card.registrationEnd') :
                                        t('agenda.card.registrationLink')
                                }
                            </Typography>
                        </StyledButton>
                   */ }
                </Box>
                {isManage &&
                    <Box style={{
                        position: 'absolute', right: 0, top: 0, padding: '8px 16px',
                        display: 'flex', flexDirection: 'column'
                    }}>
                        {onQrcode &&
                            <Button onClick={() => onQrcode(data)}>
                                {t('agenda.card.qrCode')}
                            </Button>
                        }
                        {onEdit &&
                            <Button onClick={() => onEdit(data)}>
                                {t('agenda.card.edit')}
                            </Button>
                        }
                        {onDelete &&
                            <Button
                                onClick={() => onDelete(data, seminarId)}
                                sx={{
                                    color: 'gray',
                                    ':hover': {
                                        backgroundColor: '#70707010',
                                    }
                                }}
                            >
                                {t('agenda.card.delete')}
                            </Button>
                        }
                    </Box>
                }
            </CardContent>
            <RegisterResultWindow
                isOpen={isOpen}
                isSuccess={isSuccess}
            />
        </Card>
    )
}
interface AgendaCardProps {
    isManage?: boolean;
    seminarId: string;
    seminarChName: string;
    seminarEnName: string;
    data: Agenda;
    showDate?: boolean;
    onEdit?: (value: Agenda) => void;
    onQrcode?: (value: Agenda) => void;
    onDelete?: (value: Agenda, seminarId: string) => void;
    showRegister?: boolean;
}

function RegisterResultWindow(props: RegisterResultWindowProps) {
    const { isOpen, isSuccess } = props;
    const { t, i18n } = useTranslation();

    return (
        <Fade in={isOpen}>
            <Card sx={{
                position: 'absolute', top: '50%', left: '50%',
                transform: 'translate(-50%, -50%)', backgroundColor: theme => theme.palette.grey[600],
                minWidth: '252px', maxWidth: '420px'
            }}>
                {isSuccess ?
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CircleOutlined sx={{ fontSize: '64px', margin: 'auto', color: '#12C735' }} />
                        <Typography variant='h5' align='center' fontWeight='bold' sx={{ color: theme => theme.palette.primary.contrastText }}>
                            {t('agenda.card.success.title')}
                        </Typography>
                        <Typography align='center' sx={{ color: theme => theme.palette.primary.contrastText }}>
                            {t('agenda.card.success.content_1')}
                        </Typography>
                        <Typography align='center' sx={{ color: theme => theme.palette.primary.contrastText }}>
                            {t('agenda.card.success.content_2')}
                        </Typography>
                    </CardContent> :
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Close sx={{ fontSize: '64px', margin: 'auto', color: '#FFAEAE' }} />
                        <Typography variant='h5' align='center' fontWeight='bold' sx={{ color: theme => theme.palette.primary.contrastText }}>
                            {t('agenda.card.error.title')}
                        </Typography>
                        <Typography align='center' sx={{ color: theme => theme.palette.primary.contrastText }}>
                            {t('agenda.card.error.content')}
                        </Typography>
                    </CardContent>
                }
            </Card>
        </Fade>
    )
}
interface RegisterResultWindowProps {
    isOpen: boolean;
    isSuccess: boolean;
}