import * as React from "react";
import { useModalControl } from "../../store";
import { AppointmentDeclineModal } from "./AppointmentDeclineModal";
import { AppointmentModal } from "./AppointmentModal";
import { ExchangeCardModal } from "./ExchangeCardModal";
import { PrivateVideoChatModal } from "./PrivateVideoChatModal";
import { ReportModal } from "./ReportModal";
import { ServiceBellModal } from "./ServiceBellModal";

export default function ModalSystem() {
    const modalControl = useModalControl();

    const MODAL_COMPONENTS = {
        'SERVICE_BELL': ServiceBellModal,
        'PRIVATE_VIDEO_CHAT': PrivateVideoChatModal,
        'EXCHANGE_CARD': ExchangeCardModal,
        'APPOINTMENT': AppointmentModal,
        'APPOINTMENT_DECLINE': AppointmentDeclineModal,
        'REPORT': ReportModal,
    }

    if (!modalControl)
        return null;
    else if (!modalControl.modalType)
        return null;
    else {
        const SpecificModal = MODAL_COMPONENTS[modalControl.modalType]
        return <SpecificModal {...modalControl.modalProps} />
    }
}