import * as React from "react";
import { styled, TextFieldProps, TextField, Tooltip, MenuItem, Box, Typography } from "@mui/material";
import { config as defaultConfig, CustomFieldConfig } from "./config"
import { Booth, BoothFieldName, CardInfo, FieldPrivacy, fieldPrivacyList, UserExtendField, UserFieldName } from "../../class";
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldPathValue, UseFormRegister, UseFormSetValue, UseFormStateReturn, UseFormWatch, Validate, ValidationRule } from "react-hook-form";
import { Trans, useTranslation } from 'react-i18next';
import { important } from "../../responsive-components";

export const CustomField = styled(TextField)(({ theme, hiddenLabel }) => ({
    '& .MuiOutlinedInput-root': hiddenLabel && {
        '& fieldset': {
            top: 0,
        },
        '& fieldset legend': {
            display: 'none',
        },
    },
}));

export const CustomPrivacyField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            top: 0,
        },
        '& fieldset legend': {
            display: 'none',
        },
    },
    '& .MuiSelect-select': {
        //paddingBlock: theme.spacing(1)
    },
    '& .MuiInputBase-root': {
        color: theme.palette.grey[500],
        background: theme.palette.grey[100],
    }
}));


export function toRecord<
    T extends { [K in keyof T]: string | number | symbol }, // added constraint
    K extends keyof T
>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => (acc[item[selector]] = item, acc), {} as Record<T[K], T>)
}

//名片
export function checkUserInfoFields(cardInfo: CardInfo, userFieldNameList: UserFieldName[]): editUserData {
    let list = [...cardInfo.infoFields];

    userFieldNameList.forEach(x => {
        if (x.cardTypeList.includes(cardInfo.type)) {
            if (!list.find(y => y.fieldId === x.fieldId)) {
                list.push({ fieldId: x.fieldId, value: '', privacy: FieldPrivacy.公開 });
            }
        }
    });
    return { ...cardInfo, infoFields: toRecord(list, "fieldId") };
};
export interface editUserData extends Omit<CardInfo, "userId" | "type" | "infoFields" | "boothIds"> {
    infoFields: Record<string, { value: string, privacy: FieldPrivacy }>
}


export const useUserExtendField = (props: useUserInputFieldProps) => {
    const { data, fieldList: fieldListProps, filterCondition, config: configProps, register, errors, control } = props;
    const config: CustomFieldConfig = {
        ...defaultConfig,
        ...configProps
    };
    const [fieldList, setFieldList] = React.useState<CustomFieldProps[]>([]);
    React.useEffect(() => {
        setFieldList(() => {
            let fieldList = [...fieldListProps];
            if (data) fieldList = fieldList.filter(x => x.cardTypeList.includes(data.type));
            if (filterCondition) fieldList = fieldList.filter(filterCondition);
            return fieldList.filter(x => !config.editFieldExcluded.user.includes(x.fieldId));
        });
    }, [fieldListProps, data, filterCondition]);
    
    const { t, i18n } = useTranslation();
    return (
        fieldList.map((field, index) => {
            if (field.overrideComponent)
                return <Controller key={field.fieldId} name={`infoFields.${field.fieldId}.value`} control={control} render={field.overrideComponent} />;
            else if (field.fieldStyle === "separate") {
                const { required, ...textFieldProps } = field.textFieldProps; //剔除required
                return (<div key={field.fieldId}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" fontWeight="bold" fontSize={{ xs: "0.95rem", sm: "1rem" }}>
                            {(i18n.language === 'en' ? field.fieldName[1] : field.fieldName[0]) || ""}
                            {required && important}
                        </Typography>
                        {field.privacyControllable && <Tooltip title={t('user.editDialog.fieldPrivacy.tooltip')} placement='top'>
                            <CustomPrivacyField
                                variant="filled"
                                size="small"
                                select
                                SelectProps={{
                                    renderValue: (selected) => t(fieldPrivacyList.find(x => x.value === selected)?.nameKey),
                                    inputProps: { sx: { paddingTop: '4px', paddingBottom: '4px' } }
                                }}
                                inputRef={register(`infoFields.${field.fieldId}.privacy`).ref}
                                defaultValue={field.privacy}
                                {...register(`infoFields.${field.fieldId}.privacy`)}
                            >
                                {fieldPrivacyList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {t(option.fullNameKey)}
                                    </MenuItem>
                                ))}
                            </CustomPrivacyField>
                        </Tooltip>
                        }
                    </Box>
                    <CustomField
                        size="small"
                        hiddenLabel={true}
                        {...textFieldProps}
                        error={errors.infoFields && !!errors.infoFields[field.fieldId]?.value}
                        helperText={(errors.infoFields && errors.infoFields[field.fieldId]?.value?.message) || ' '}
                        inputProps={{
                            ...register(`infoFields.${field.fieldId}.value`, {
                                required: { value: required, message: t("user.editDialog.error_required") },
                                maxLength: {
                                    value: field.maxStringLength,
                                    message: t("user.editDialog.error_maxStringLengthWithNumber").replace("{n}", field.maxStringLength.toString())
                                },
                                ...field.registerOptions
                            })
                        }}
                    />
                </div>)
            }
            else return (
                <Box key={field.fieldId} display={field.privacyControllable ? "grid" : "block"} gridTemplateColumns={{ xs: "3fr 1fr", sm: "1fr 100px" }} columnGap="10px">
                    <CustomField
                        size="small"
                        label={(i18n.language === 'en' ? field.fieldName[1] : field.fieldName[0]) || ""}
                        {...field.textFieldProps}
                        error={errors.infoFields && !!errors.infoFields[field.fieldId]?.value}
                        helperText={(errors.infoFields && errors.infoFields[field.fieldId]?.value?.message) || ' '}
                        inputProps={{
                            ...register(`infoFields.${field.fieldId}.value`, {
                                required: { value: field.textFieldProps.required, message: t("user.editDialog.error_required") },
                                maxLength: {
                                    value: field.maxStringLength,
                                    message: t("user.editDialog.error_maxStringLengthWithNumber").replace("{n}", field.maxStringLength.toString())
                                },
                                ...field.registerOptions
                            })
                        }}
                    />
                    {field.privacyControllable && <Tooltip title={t('user.editDialog.fieldPrivacy.tooltip')} placement='top'>
                        <CustomPrivacyField
                            variant="filled"
                            size="small"
                            select
                            SelectProps={{
                                renderValue: (selected) => t(fieldPrivacyList.find(x => x.value === selected)?.nameKey),
                                inputProps: { sx: { paddingTop: '8px', paddingBottom: '8px' } }
                            }}
                            inputRef={register(`infoFields.${field.fieldId}.privacy`).ref}
                            defaultValue={field.privacy}
                            {...register(`infoFields.${field.fieldId}.privacy`)}
                        >
                            {fieldPrivacyList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {t(option.fullNameKey)}
                                </MenuItem>
                            ))}
                        </CustomPrivacyField>
                    </Tooltip>}
                </Box>
            )
        })
    );
};
export interface useUserInputFieldProps {
    data: CardInfo;
    fieldList: CustomFieldProps[];
    filterCondition?: (field: CustomFieldProps) => boolean;
    config?: Partial<CustomFieldConfig>;
    register: UseFormRegister<editUserData>;
    //watch: UseFormWatch<editUserData>;
    //setValue: UseFormSetValue<editUserData>;
    errors: any;
    control?: Control<editUserData>;
}

export interface CustomFieldProps extends UserFieldName {
    fieldStyle?: 'default' | 'separate';
    privacyControllable?: boolean;
    textFieldProps?: TextFieldProps;
    registerOptions?: {
        min?: ValidationRule<number | string>;
        max?: ValidationRule<number | string>;
        maxLength?: ValidationRule<number>;
        minLength?: ValidationRule<number>;
        pattern?: ValidationRule<RegExp>;
        validate?: Validate<FieldPathValue<editUserData, any>> | Record<string, Validate<FieldPathValue<editUserData, any>>>;
        valueAsNumber?: boolean;
        valueAsDate?: boolean;
    };
    overrideComponent?: ({ field, fieldState, formState, }: {
        field: ControllerRenderProps<editUserData, any>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<editUserData>;
    }) => React.ReactElement;
};

//攤位
export function checkBoothFieldList(booth: Booth, boothFieldNameList: BoothFieldName[]): editBoothData {
    let list = [...booth.fieldList];

    boothFieldNameList.forEach(x => {
        if (x.boothTypeList.includes(booth.type)) {
            if (!list.find(y => y.fieldId === x.fieldId)) {
                list.push({ fieldId: x.fieldId, value: '' });
            }
        }
    });
    return { ...booth, fieldList: toRecord(list, "fieldId") };
};
export interface editBoothData extends Omit<Booth, "boothId" | "type" | "fieldList" | "whiteboardRoomCode"> {
    fieldList: Record<string, { value: string }>
}

export const useBoothExtendField = (props: useBoothInputFieldProps) => {
    const { data, fieldList: fieldListProps, filterCondition, config: configProps, register, errors, control } = props;
    const config: CustomFieldConfig = {
        ...defaultConfig,
        ...configProps
    };
    const [fieldList, setFieldList] = React.useState<CustomBoothFieldProps[]>([]);
    React.useEffect(() => {
        setFieldList(() => {
            let fieldList = [...fieldListProps];
            if (data) fieldList = fieldList.filter(x => x.boothTypeList.includes(data.type));
            if (filterCondition) fieldList = fieldList.filter(filterCondition);
            return fieldList.filter(x => !config.editFieldExcluded.booth.includes(x.fieldId));
        });
    }, [fieldListProps, data, filterCondition]);

    const { t, i18n } = useTranslation();
    return (
        fieldList.map((field, index) => {
            if (field.overrideComponent)
                return <Controller key={field.fieldId} name={`fieldList.${field.fieldId}.value`} control={control} render={field.overrideComponent} />;
            else if (field.fieldStyle === "separate") {
                const { required, ...textFieldProps } = field.textFieldProps; //剔除required
                return (<div key={field.fieldId}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" fontWeight="bold" fontSize={{ xs: "0.95rem", sm: "1rem" }}>
                            {(i18n.language === 'en' ? field.fieldName[1] : field.fieldName[0]) || ""}
                            {required && important}
                        </Typography>
                    </Box>
                    <CustomField
                        size="small"
                        hiddenLabel={true}
                        {...textFieldProps}
                        error={errors.fieldList && !!errors.fieldList[field.fieldId]?.value}
                        helperText={(errors.fieldList && errors.fieldList[field.fieldId]?.value?.message) || ' '}
                        inputProps={{
                            ...register(`fieldList.${field.fieldId}.value`, {
                                required: { value: field.textFieldProps.required, message: t("booth.editDialog.error_required") },
                                maxLength: {
                                    value: field.maxStringLength,
                                    message: t("booth.editDialog.error_maxStringLength").replace("{n}", field.maxStringLength.toString())
                                },
                                ...field.registerOptions
                            })
                        }}
                    />
                </div>)
            }
            else return (
                <Box key={field.fieldId}>
                    <CustomField
                        size="small"
                        label={(i18n.language === 'en' ? field.fieldName[1] : field.fieldName[0]) || ""}
                        {...field.textFieldProps}
                        error={errors.fieldList && !!errors.fieldList[field.fieldId]?.value}
                        helperText={(errors.fieldList && errors.fieldList[field.fieldId]?.value?.message) || ' '}
                        inputProps={{
                            ...register(`fieldList.${field.fieldId}.value`, {
                                required: { value: field.textFieldProps.required, message: t("booth.editDialog.error_required") },
                                maxLength: {
                                    value: field.maxStringLength,
                                    message: t("booth.editDialog.error_maxStringLength").replace("{n}", field.maxStringLength.toString())
                                },
                                ...field.registerOptions
                            })
                        }}
                    />
                </Box>
            )
        })
    );
};
export interface useBoothInputFieldProps {
    data: Booth;
    fieldList: CustomBoothFieldProps[];
    filterCondition?: (field: CustomBoothFieldProps) => boolean;
    config?: Partial<CustomFieldConfig>;
    register: UseFormRegister<editBoothData>;
    //watch: UseFormWatch<editBoothData>;
    //setValue: UseFormSetValue<editBoothData>;
    errors: any;
    control?: Control<editBoothData>;
}

export interface CustomBoothFieldProps extends BoothFieldName {
    fieldStyle?: 'default' | 'separate';
    textFieldProps?: TextFieldProps;
    registerOptions?: {
        min?: ValidationRule<number | string>;
        max?: ValidationRule<number | string>;
        maxLength?: ValidationRule<number>;
        minLength?: ValidationRule<number>;
        pattern?: ValidationRule<RegExp>;
        validate?: Validate<FieldPathValue<editBoothData, any>> | Record<string, Validate<FieldPathValue<editBoothData, any>>>;
        valueAsNumber?: boolean;
        valueAsDate?: boolean;
    };
    overrideComponent?: ({ field, fieldState, formState, }: {
        field: ControllerRenderProps<editBoothData, any>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<editBoothData>;
    }) => React.ReactElement;
};