﻿import * as React from 'react';

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
}

export function generateRoomCode() {
    return new Promise<string>((resolve, reject) => {
        generateRandomID().then((id) => {
            generateEncryptionKey().then((key) => {
                resolve(id + ',' + key);
            }, (error) => { console.error(error); reject(); });
        }, (error) => { console.error(error); reject(); });
    });
}

const generateCollaborationLinkData = async () => {
    const roomId = await generateRandomID();
    const roomKey = await generateEncryptionKey();

    if (!roomKey) {
        throw new Error("Couldn't generate room key");
    }

    return { roomId, roomKey };
};

const byteToHex = (byte: number): string => `0${byte.toString(16)}`.slice(-2);
const generateRandomID = async () => {
    const arr = new Uint8Array(10);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, byteToHex).join("");
};

const generateEncryptionKey = async () => {
    const key = await window.crypto.subtle.generateKey(
        {
            name: "AES-GCM",
            length: 128,
        },
        true, // extractable
        ["encrypt", "decrypt"],
    );
    return (await window.crypto.subtle.exportKey("jwk", key)).k;
};

//確認是否為行動裝置
export function CheckIsMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);
}

//倒數計時器
export function CountDownTimer(second: number, callback: () => void) {
    // 產生 Timer
    const countDownSecond = second;
    const startTime = Date.now();
    const countDownTimer = setInterval(() => {
        // 計算剩餘秒數
        const pastSeconds = (Date.now() - startTime) / 1000;
        const remain = (countDownSecond - pastSeconds)
        // 檢查是否結束
        if (remain <= 0) {
            clearInterval(countDownTimer)
            callback();
        }
    }, 1000)
    return () => {
        // 清除 Timer
        clearInterval(countDownTimer)
    }
}

/*
//確認使用者資料類型
export function CheckIsStudentData(value: StudentData | NonStudentData | AdminData | GuestData): value is StudentData {
    return (value as StudentData).school !== undefined;
}
export function CheckIsNonStudentData(value: StudentData | NonStudentData | AdminData | GuestData): value is NonStudentData {
    return (value as NonStudentData).companyName !== undefined;
}
export function CheckIsAdminData(value: StudentData | NonStudentData | AdminData | GuestData): value is AdminData {
    return value.type === CardType.admin;
}
export function CheckIsNotGuestData(value: StudentData | NonStudentData | AdminData | GuestData): value is StudentData | NonStudentData | AdminData {
    return value.type !== CardType.unknown;
}

//確認攤位類型
export function CheckIsNormalBooth(value: NormalBoothData | InformationDeskBoothData): value is NormalBoothData {
    return (value as NormalBoothData).unifiedBusinessNo !== undefined;
}
*/

//視窗控制
export function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

//轉型工具
export function toRecord<
    T extends { [K in keyof T]: string | number | symbol }, // added constraint
    K extends keyof T
>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => (acc[item[selector]] = item, acc), {} as Record<T[K], T>)
}

//可以看成做過Debounce處理的setTimeout的hook
export const useDebounceCallback = (delay = 100, cleaning = true) => { // or: delayed debounce callback
    const ref = React.useRef<NodeJS.Timeout>();
    React.useEffect(() => {
        if (cleaning) {
            // cleaning uncalled delayed callback with component destroying
            return () => {
                if (ref.current) clearTimeout(ref.current);
            };
        }
    }, []);
    return (callback) => {
        if (ref.current) clearTimeout(ref.current);
        ref.current = setTimeout(callback, delay);
    };
};

//setInterval的hook
//(P.S.當delay為null或0，代表停止執行)
export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = React.useRef<() => void>()

    React.useEffect(() => {
        savedCallback.current = callback
    }, [])

    // Set up the interval.
    React.useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}