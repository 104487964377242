import * as React from 'react';
import { Backdrop, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GeneralTextField, PaperWrap, SubmitButton, TextButton } from './LoginWindow';
import ErrorPage from '../components/ErrorPage/404Page';
import { useDispatchWithType, useUser } from '../store';import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';
import { loginUser } from '../store/rootReducer';
import { logoPath } from '../config';
import { useAttendQRCodeMutation } from '../components/services/qrcode';

type formData = {
    code: string;
}
export default function AttendWindow() {
    const { search } = useLocation();
    const user = useUser();
    const userId = user.userId;
    let hash = new URLSearchParams(search).get("hash") ?? "";
    let seminarId = new URLSearchParams(search).get("seminarId") ?? "";
    let visitId = new URLSearchParams(search).get("visitId") ?? "";
    const [attend, { isLoading }] = useAttendQRCodeMutation();
   const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        code: yup.string().required(t('verify.code.error1')).min(6, t('verify.code.error2')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { code: "" }
    });

    const [message, setMessage] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    //初始化：讀入&驗證
    React.useLayoutEffect(() => {
        init();
    }, [userId, hash]);
    async function init() {
        try {
            if (userId === "") {
                setError(true);
                return;
            }
            if (hash !== "") {
                //說明會報到
                if (seminarId != "") {
                    let hashResult = await attend({ seminarId: seminarId, hash: hash }).unwrap();
                    if (hashResult.isSuccess) {
                        setSuccess(true);
                        setMessage(`【${hashResult.data.title}】報到成功。`)
                        return;
                    } else {
                        setErrorMessage('【' + hashResult.data.title +'】'+hashResult.message)
                    }
                } 
            } else {
                setErrorMessage("連結遺失，請重新掃描。")
            }
        
        } catch (error) {
            setError(true);
            console.error(error);
        } finally {

        }
    }

    if (error) {
        return (<ErrorPage />)
    }
    else if (isLoading ) {
        return (
            <Backdrop color="primary" open={true}>
                <CircularProgress />
            </Backdrop>
        )
    }
    else if (success) {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {message}
                        </Typography>
                        <Typography variant="body1" >
                            單位:  {user?.infoFields.find(x => x.fieldId =='school/organization').value}
                        </Typography>
                        <Typography variant="body1" >
                            姓名: {user?.name}
                        </Typography>
                        <Typography variant="body1" >
                            職稱: {user?.infoFields.find(x => x.fieldId =='jobTitle').value}
                        </Typography>
                        <Link to="/">點我回首頁</Link>
                    </Stack>
                </PaperWrap>
            </div >
        )
    }
    else {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {errorMessage}
                        </Typography>
                        <Link to="/">點我回首頁</Link>
                    </Stack>
                </PaperWrap>
            </div >
        )
    }
    
}