import * as React from 'react';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { ConfirmButton, Dialog, DialogActions, DialogContent, important, Upload } from '../responsive-components';
import { Edit, Publish } from '@mui/icons-material';
import { CardType, FieldPrivacy, ItemType, CardInfo, UserExtendField } from '../class';
import { Trans, useTranslation } from "react-i18next";
import { useForm, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch } from 'react-hook-form';
import { Avatar, Backdrop, Box, BoxProps, Button, CircularProgress, DialogTitle, Divider, Fab, MenuItem, styled, TextField, TextFieldProps, Toolbar, Typography, TypographyProps } from '@mui/material';
import { SelectPrivacyField, uniqueUserFieldList, userCustomFieldList } from '../CustomField';
import { ErrorMessage } from '@hookform/error-message';
import { useUser, useUserFieldNameList } from '../../store/index';
//import { SaveImage } from '../Admin/Utils';
import { isNullOrUndefined } from '../Admin/Utils';
import { DropzoneRef } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { useUploadUserLogoMutation } from '../services/file';
import { defaultAvatar } from '../../config';
import Cropper from '../Cropper';
import { LazyImg } from '../LazyImg';

const paddingValue = '0px';
const spaceValue = '18px';
const phoneRegExp = /^$|^(((\+\d{3})|(\(\+\d{3}\))) ?)?((\d{1,4})|(\(\d{2,4}\)))[- ]?\d{1,4}[- ]?\d{2,4}( ?#(\d+)){0,2}$/i;
const emailRegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/i;


const StyledTitle: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    margin: '12px 0px',
}));
const StyledEditTitle: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
}));
const StyledTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}));
const StyledTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    //height: '35px',
    '& .MuiInputLabel-root': {
        '& .MuiInputLabel-asterisk': {
            color: 'red',
        }
    }
}));

export default function EditUserDataDialog(props: EditUserDataProps & Omit<MuiDialogProps, keyof EditUserDataProps>) {
    const { userId, data, onClose, isLoading, ...other } = props;
    const { t, i18n } = useTranslation();

    return (
        <Dialog {...other}>
            <DialogTitle>
                <Box className="d-flex flex-row flex-wrap justify-content-end" paddingRight={paddingValue}>
                    <Button onClick={() => onClose()}>{t('user.editDialog.discard')}</Button>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditUserData
                    userId={userId}
                    data={data}
                    onClose={onClose}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}

export type EditUserDataRef = {
    handleSubmit: () => Promise<boolean>;
}
export const EditUserData = React.forwardRef(
    function EditUserData(props: EditUserDataProps, ref) {
        const { userId, data, onClose, isLoading, editType, ...other } = props;
        const { t, i18n } = useTranslation();

        const { register, unregister, watch, formState: { errors }, handleSubmit, setValue } = useForm({
            defaultValues: data, mode: 'onChange'
        });

        //Ref
        React.useImperativeHandle(ref, () => ({
            async handleSubmit() {
                let tempResult = false
                console.log(errors)
                await handleSubmit(async (obj) => {
                    await onSubmit(obj).then((result) => {
                        tempResult = result;
                    });
                })().catch((error) => {
                    tempResult = false;
                })
                console.log(`tempResult: ${tempResult}`);
                return tempResult;
            }
        }));

        //進行圖片上傳
        const [uploadUserLogo, { isLoading: isUploading }] = useUploadUserLogoMutation();
        const logoUrlValue = watch("logoUrl");
        const handleLogoUrlChange = (url: string) => setValue("logoUrl", url);

        function handleUseDefaultAvatar(url: string) {
            handleLogoUrlChange(url);
        }
        const onSubmit = async (data: any): Promise<boolean> => {
            let tempResult = false
            await onClose(data).then(result => {
                tempResult = result;
                console.log(`onClose: ${tempResult}`);
            });
            //console.log(`onSubmit: ${tempResult}`);
            return tempResult;
        }

        //file select
        const handleImageSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
            let file = event.target.files?.item(0);
            if (FileReader && file) {
                const reader = new FileReader();
                reader.onload = function () {
                    setImgSrc({ ...file as File, preview: reader.result?.toString() as string });
                    //setWaiting(false);
                }
                reader.readAsDataURL(file);
                //setWaiting(true);

                event.target.value = "";
            }
        }

        //crop result dialog
        const [waiting, setWaiting] = React.useState(false);
        const [openDialog, setOpenDialog] = React.useState(false);
        const [dialogMessage, setDialogMessage] = React.useState("");
        //on crop
        const [imgSrc, setImgSrc] = React.useState<File & { preview: string; }>();
        async function handleImageCrop(file: Blob) {
            setImgSrc(undefined);

            const formData = new FormData();
            formData.append("file", file, "file.jpeg");

            try {
                await uploadUserLogo({ userId: userId, formData: formData }).unwrap().then((result) => {
                    if (result.isSuccess && result.data.urls[0]) {
                        setDialogMessage("上傳成功");
                        handleLogoUrlChange(result.data.urls[0]);
                    }
                    else {
                        setDialogMessage("請重整後再嘗試");
                    }
                });
            } catch (error) {
                setDialogMessage("請重整後再嘗試");
            } finally {
                setWaiting(false);
                setOpenDialog(true);
            }
        }

        //欄位名稱
        const userFieldNameList = useUserFieldNameList();
        function userFieldIdToName(fieldId: string) {
            let fieldName = '';
            let userFieldName = userFieldNameList.find(x => x.fieldId === fieldId);
            if (userFieldName) {
                if (i18n.language === 'en') {
                    if (userFieldName.fieldName[1])
                        fieldName = userFieldName.fieldName[1];
                }
                else {
                    if (userFieldName.fieldName[0])
                        fieldName = userFieldName.fieldName[0];
                }
            }
            return fieldName;
        }
        function userFieldValueToType(fieldId: string, value: string) {
            let fieldValue = null;
            let userFieldName = userFieldNameList.find(x => x.fieldId === fieldId);
            if (userFieldName) {
                switch (userFieldName.fieldType) {
                    case ItemType.String:
                        fieldValue = String(value);
                        break;
                    case ItemType.Int:
                        fieldValue = Number(value);
                        break;
                    case ItemType.Bool:
                        fieldValue = Boolean(value);
                        break;
                    default:
                        fieldValue = '';
                }
            }
            return fieldValue;
        }

        //客製化欄位之權限
        const watchCustomField = watch(`infoFields`);
        const handleCustomPrivacyChange = (privacy: FieldPrivacy, index: number) => {
            if (index >= 0 && index < watchCustomField.length)
                setValue(`infoFields.${index}.privacy`, privacy);
        }

        //電話權限編輯
        const watchPhonePrivacyField = watch("phonePrivacy");
        const handlePhonePrivacyChange = (privacy: FieldPrivacy) => setValue("phonePrivacy", privacy);

        //信箱權限編輯
        const watchEmailPrivacyField = watch("emailPrivacy");
        const handleEmailPrivacyChange = (privacy: FieldPrivacy) => setValue("emailPrivacy", privacy);

        //客製化欄位調整
        const watchAll = watch();
        React.useEffect(() => {
            if (data && userFieldNameList) {
                let tempList = [...data.infoFields];
                let change = false;
                for (let index = 0; index < userFieldNameList.length; index++) {
                    if (!tempList.find(x => x.fieldId === userFieldNameList[index].fieldId) &&
                        userFieldNameList[index].cardTypeList.includes(data.type)) {
                        tempList.splice(index, 0, { fieldId: userFieldNameList[index].fieldId, value: '', privacy: FieldPrivacy.公開 });
                        change = true;
                    }
                }
                if (change) setValue('infoFields', cloneDeep(tempList));
            }
        }, [data, userFieldNameList])

        return (
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box className="d-flex flex-row flex-wrap" sx={{ width: '100%' }}>
                    <Box className="d-flex flex-row flex-wrap" sx={{ width: '100%' }}>
                        {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.secondary) &&
                            <Box className="d-flex flex-column m-auto">
                                <Button component="label" sx={{ width: 'fit-content', margin: 'auto', position: "relative", d: 'flex', flex: 'column', mt: '2px' }}>
                                    <input hidden accept="image/*" type="file" onChange={handleImageSelected} />
                                    <Box sx={{
                                        position: 'absolute', top: 0, right: 0, background: "rgba(255,255,255,0.8)", zIndex: 10,
                                        borderRadius: '50%', padding: '12px', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);'
                                    }}>
                                        <Edit fontSize='large' />
                                    </Box>
                                    {watch("logoUrl") ?
                                        <LazyImg width="158px" height="158px" src={`${watch("logoUrl")}`} style={{ objectFit: "contain", borderRadius: '50%', margin: "auto" }} />
                                        : <>
                                            {
                                                logoUrlValue ?
                                                    <LazyImg width="158px" height="158px" src={`${logoUrlValue}`} style={{ objectFit: "contain", borderRadius: '50%', margin: "auto" }} /> :
                                                    <Avatar sx={{ width: "158px", height: "158px" }} />
                                            }
                                        </>
                                    }
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleUseDefaultAvatar(defaultAvatar)}
                                    sx={{ margin: 'auto', mt: 3, width: 'fit-content' }}
                                >
                                    <Avatar src={defaultAvatar} />
                                    <Typography noWrap ml={1}>
                                        {t('user.editDialog.presetPhoto')}
                                    </Typography>
                                </Button>
                                <Cropper
                                    imgSrc={imgSrc}
                                    aspect={1}
                                    onConfirm={handleImageCrop}
                                    onCancel={() => setImgSrc(undefined)}
                                />
                                <Backdrop open={waiting} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}><CircularProgress /></Backdrop>
                                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                                    <Typography px={3} py={2} color="text.primary">
                                        {dialogMessage}
                                    </Typography>
                                </Dialog>
                            </Box>}

                        {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.primary) &&
                            <>
                                <StyledTitle flex='1 0 100%'>
                                    {t('user.editDialog.title_1')}
                                </StyledTitle>
                                <Box flex='1 0 100%' paddingLeft={paddingValue} paddingRight={paddingValue}
                                    className="d-flex flex-row mt-2 flex-wrap" sx={{ maxWidth: '100%' }}>
                                    {/*姓名*/}
                                    <Box flex='1 0 50%' className="d-flex flex-column justify-content-end mw-100" marginBottom='1.75rem'>
                                        <StyledTextField
                                            required
                                        label={t('user.editDialog.name')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                        {...register("name", {
                                                required: `${t('user.editDialog.error_required')}`
                                            })}
                                        />
                                </Box>
                                {/*暱稱*/}
                                    <Box flex='1 0 50%' className="d-flex flex-column justify-content-end mw-100" marginBottom='1.75rem'>
                                        <StyledTextField
                                            required
                                        label={t('user.editDialog.alias')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                        error={!!errors.alias}
                                        helperText={errors.alias?.message}
                                        {...register("alias", {
                                                required: `${t('user.editDialog.error_required')}`
                                            })}
                                        />
                                    </Box>

                               

                                    {/*電話*/}
                                    <Box flex='0 0 100%' className="d-flex flex-column justify-content-end mw-100" marginBottom='1.75rem'>
                                        <StyledEditTitle>
                                            <SelectPrivacyField
                                                value={watchPhonePrivacyField}
                                                onChange={(event) => handlePhonePrivacyChange(Number(event.target.value))}
                                            />
                                        </StyledEditTitle>
                                        <StyledTextField
                                            //required
                                            label={t('user.editDialog.phone')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                            error={!!errors.phone}
                                            helperText={errors.phone?.message}
                                            {...register("phone", {
                                                //required: `${t('user.editDialog.error_required')}`,
                                                pattern: {
                                                    value: phoneRegExp,
                                                    message: `${t('user.editDialog.error_isPhone')}`
                                                }
                                            })}
                                        />
                                    </Box>

                                    {/*信箱*/}
                                    <Box flex='1 0 100%' className="d-flex flex-column justify-content-end mw-100" marginBottom='1.75rem'>
                                        <StyledEditTitle>
                                            <SelectPrivacyField
                                                value={watchEmailPrivacyField}
                                                onChange={(event) => handleEmailPrivacyChange(Number(event.target.value))}
                                            />
                                        </StyledEditTitle>
                                        <StyledTextField
                                            required
                                            label={t('user.editDialog.email')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                            {...register("email", {
                                                required: `${t('user.editDialog.error_required')}`,
                                                pattern: {
                                                    value: emailRegExp,
                                                    message: `${t('user.editDialog.error_isEmail')}`
                                                }
                                            })}
                                        />
                                        {/*data.type === CardType.student &&
                                        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
                                            {`${t('user.editDialog.notice_phone_email')}`}
                                        </Typography>
                                    */}
                                    </Box>

                                    {/*上方客製化欄位*/}
                                    <EditInfo
                                        data={watchAll}
                                        register={register}
                                        unregister={unregister}
                                        setValue={setValue}
                                        watch={watch}
                                        errors={errors}
                                        isLoading={isLoading}
                                        userFieldIdToName={userFieldIdToName}
                                        watchCustomField={watchCustomField}
                                        handleCustomPrivacyChange={handleCustomPrivacyChange}
                                    />
                                </Box>
                            </>
                        }
                    </Box>

                    {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.primary) &&
                        <>
                            {data.type === CardType.student &&
                                <Box style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    width: '100%'
                                }}>
                                    <StyledTitle>
                                        {t('user.editDialog.title_2')
                                    /*cardType === CardType.nonStudent &&
                                    t('user.editDialog.title_3')*/}
                                    </StyledTitle>

                                    {(isNullOrUndefined(editType) || editType === UserEditType.all) &&
                                        <Divider style={{ flexGrow: 1, margin: '0px 20px' }} />
                                    }
                                </Box>
                            }
                            <Box className="d-flex flex-row flex-wrap" sx={{ flexGrow: 1, width: '100%' }}>
                                {/*底部客製化欄位*/}
                                <EditInfoBottom
                                    data={watchAll}
                                    register={register}
                                    unregister={unregister}
                                    setValue={setValue}
                                    watch={watch}
                                    errors={errors}
                                    isLoading={isLoading}
                                    userFieldIdToName={userFieldIdToName}
                                    watchCustomField={watchCustomField}
                                    handleCustomPrivacyChange={handleCustomPrivacyChange}
                                />
                            </Box>
                        </>
                    }
                </Box>
                {!ref &&
                    <DialogActions>
                        <ConfirmButton type="submit" disabled={isUploading || isLoading}>
                            <Trans i18nKey={'user.editDialog.saveButton'} />
                        </ConfirmButton>
                    </DialogActions>
                }
            </Box>
        )
    }
);
interface EditUserDataProps {
    userId: string;
    data: CardInfo;
    onClose: (result?: CardInfo) => Promise<boolean>;
    isLoading?: boolean;
    editType?: UserEditType;
}
export enum UserEditType {
    all = 0,
    primary = 1,
    secondary = 2
}

function EditInfo(props: EditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors,
        userFieldIdToName, watchCustomField, handleCustomPrivacyChange } = props;
    const { t, i18n } = useTranslation();
    const user = useUser();

 
    //學校
    const schoolIndex = data.infoFields.findIndex(x => x.fieldId === "school")
    const schoolElement = schoolIndex != -1 ? userCustomFieldList.find(x => x.id === "school") : null;

 
    return (<>   
        {/*學校*/}
        {schoolElement &&
            <Box flex='1 0 100%' className="d-flex flex-column justify-content-end mw-100" paddingX={paddingValue} marginBottom='1.75rem'>
                {schoolElement.editComponent({
                    //displayTitle: userFieldIdToName(field.id),
                   // disabled: isNtu,
                    label: userFieldIdToName(schoolElement.id),
                    required: schoolElement.validation?.required ? true : false,
                    value: watch(`infoFields.${schoolIndex}.value`),
                    onChange: (event) => setValue(`infoFields.${schoolIndex}.value`, event.target.value),
                    helperText: errors.infoFields && errors.infoFields[schoolIndex]?.value?.message,
                    error: errors.infoFields && !!errors.infoFields[schoolIndex]?.value?.message,
                    inputProps: {
                        maxLength: 3001,
                        ...register(`infoFields.${schoolIndex}.value`, schoolElement.validation)
                    },
                })}
            </Box>
        }
    </>)
}
function EditInfoBottom(props: EditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors, unregister,
        userFieldIdToName, watchCustomField, handleCustomPrivacyChange } = props;
    const { t, i18n } = useTranslation();

    //條件列表
    const targetListIndex = data.infoFields.findIndex(x => x.fieldId === "targetList")
    const targetListElement = targetListIndex != -1 ? userCustomFieldList.find(x => x.id === "targetList") : null;

    return (<>
        {/*條件列表*/}
        {targetListElement &&
            <Box flex='1 0 100%' className="d-flex flex-column justify-content-end mw-100" paddingX={paddingValue} marginBottom='1.75rem'>
                {targetListElement.editComponent({
                    //displayTitle: userFieldIdToName(field.id),
                    label: userFieldIdToName(targetListElement.id),
                    required: targetListElement.validation?.required ? true : false,
                    value: watch(`infoFields.${targetListIndex}.value`),
                    onChange: (event) => setValue(`infoFields.${targetListIndex}.value`, event.target.value),
                    helperText: errors.infoFields && errors.infoFields[targetListIndex]?.value?.message,
                    error: errors.infoFields && !!errors.infoFields[targetListIndex]?.value?.message,
                    inputProps: {
                        //maxLength: 3001,
                        ...register(`infoFields.${targetListIndex}.value`, targetListElement.validation)
                    },
                })}
            </Box>
        }

        {/*客製化欄位*/}
        {data.infoFields.map((userField, index) => {
            let field = userCustomFieldList.find(x => x.id === userField.fieldId);
            if (field && !uniqueUserFieldList.includes(userField.fieldId))
                return (
                    <Box flex='1 0 100%' className="d-flex flex-column justify-content-end mw-100" paddingX={paddingValue} marginBottom='1.75rem' key={field.id}>
                        {field.editComponent({
                            //displayTitle: userFieldIdToName(field.id),
                            label: userFieldIdToName(field.id),
                            required: field.validation?.required ? true : false,
                            value: watch(`infoFields.${index}.value`),
                            onChange: (event) => setValue(`infoFields.${index}.value`, event.target.value),
                            helperText: errors.infoFields && errors.infoFields[index]?.value?.message,
                            error: errors.infoFields && !!errors.infoFields[index]?.value?.message,
                            inputProps: {
                                maxLength: 3001,
                                ...register(`infoFields.${index}.value`, field.validation)
                            },
                        })}
                    </Box>
                )
            else
                return (
                    <div key={userField.fieldId} />
                )
        })}
    </>)
}
interface EditInfoProps {
    data: CardInfo;
    register: UseFormRegister<CardInfo>;
    unregister: UseFormUnregister<CardInfo>;
    watch: UseFormWatch<CardInfo>;
    setValue: UseFormSetValue<CardInfo>;
    errors: any;
    isLoading: boolean;
    userFieldIdToName: (fieldId: string) => string;
    watchCustomField: UserExtendField[];
    handleCustomPrivacyChange: (privacy: FieldPrivacy, index: number) => void;
}