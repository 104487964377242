import * as React from 'react';
import { useUser } from '../../store';
import { important, SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { BoothManageInvitation, UserExtendField } from '../../components/class';
import { useCancelBoothInviteMutation, useGetBoothAllInvitesQuery, useNewBoothInviteMutation } from '../../components/services/invite';
import { cloneDeep } from 'lodash';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Dialog, IconButton, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { isNullOrUndefined } from 'util';
import { ErrorMessage } from '@hookform/error-message';
import { ConfirmButton } from '../../components/responsive-components';
import { DataGrid, GridCellParams, GridColDef, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { zhTWGrid } from '../data-grid-locale';
import { format } from 'date-fns';
import { renderCellExpand } from '../../components/DataGrid/renderCellExpand';

export default function BoothMembersManage(props: BoothMembersManageProps) {
    const { boothId } = props;
    const { t, i18n } = useTranslation();
    const user = useUser();

    const [boothInviteList, setBoothInviteList] = React.useState<BoothManageInvitation[]>([]);
    const { data: boothInviteListData, isSuccess: isGetListSuccess, isLoading: isGetListLoading } = useGetBoothAllInvitesQuery(boothId, {
        refetchOnMountOrArgChange: true
    });
    React.useEffect(() => {
        if (!boothInviteListData || !isGetListSuccess) {
            return;
        }
        setBoothInviteList(cloneDeep(boothInviteListData.data).sort((a, b) => new Date(a.inviteTime) > new Date(b.inviteTime) ? -1 : 0));
    }, [boothInviteListData])

    const [newBoothInvite, { isLoading: isNewLoading }] = useNewBoothInviteMutation();
    function handleNewInvite(email: string) {
        newBoothInvite({ toEmail: email, boothId: boothId }).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    setBoothInviteList([result.data, ...boothInviteList]);
                    setOpenDialog(true);
                    setDialogMessage(`已送出邀請：${email}`);
                }
                else {
                    setOpenDialog(true);
                    setDialogMessage(`${result.message}：${email}`);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
                setOpenDialog(true);
                setDialogMessage("發生錯誤");
            });
    }

    const [cancelBoothInvite, { isLoading: isCancelLoading }] = useCancelBoothInviteMutation();
    function handleCancelInvite(email: string, inviteTime: string) {
        cancelBoothInvite({ toEmail: email }).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    let tempList = cloneDeep(boothInviteList);
                    let index = tempList.findIndex(x => x.toEmail === email && x.inviteTime === inviteTime);
                    if (index !== -1) {
                        let temp = tempList[index];
                        temp.expireTime = new Date().toISOString();
                        tempList[index] = temp;
                        setBoothInviteList([...tempList]);
                        setOpenDialog(true);
                        setDialogMessage(`邀請碼已取消：${email}`);
                    }
                }
                else {
                    setOpenDialog(true);
                    setDialogMessage(`${result.message}：${email}`);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
                setOpenDialog(true);
                setDialogMessage("發生錯誤");
            });
    }

    //彈出結果視窗
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.page.prepTool.boothMembersManage')}
            />

            <Box className="d-flex p-3" sx={{ "& .cell-blue": { color: "royalblue" }, "& .cell-green": { color: "green" }, "& .cell-red": { color: "red" } }}>
                <InvitationTable
                    dataList={boothInviteList.map(item => ({ ...item, id: item.inviteTime } as InvitationTableData))}
                    onDelete={handleCancelInvite}
                />
            </Box>

            <Box minHeight='24px' />

            <NewBoothMember onSubmit={handleNewInvite} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGetListLoading || isNewLoading || isCancelLoading}
            >
                <CircularProgress />
            </Backdrop>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
        </div>
    )
};
interface BoothMembersManageProps {
    boothId: string;
}

const statusList = [
    { name: "manage.boothMembersManage.type.0", class: "cell-blue" },
    { name: "manage.boothMembersManage.type.1", class: "cell-green" },
    { name: "manage.boothMembersManage.type.2", class: "cell-red" }
];
interface InvitationTableData extends BoothManageInvitation {
    id: string;
}
function InvitationTable(props: { dataList: InvitationTableData[], onDelete: (email: string, inviteTime: string) => void; }) {
    const { dataList, onDelete } = props;
    const { t, i18n } = useTranslation();

    function handleDelete(email: string, inviteTime: string) {
        onDelete(email, inviteTime);
    }

    const columns: GridColDef[] = [
        {
            field: 'toEmail',
            headerName: t('manage.boothMembersManage.options.toEmail'),
            flex: 1,
            minWidth: 180,
            renderCell: renderCellExpand
        },
        {
            field: 'inviterChName',
            headerName: t('manage.boothMembersManage.options.inviterChName'),
            width: 140,
            valueGetter: (params: GridValueGetterParams<InvitationTableData, InvitationTableData>) => `${params.row.inviter.name}`,
        renderCell: renderCellExpand
        },
        {
            field: 'inviterEnName',
            headerName: t('manage.boothMembersManage.options.inviterEnName'),
            width: 140,
            valueGetter: (params: GridValueGetterParams<InvitationTableData, InvitationTableData>) => {
                let fields: UserExtendField[] = params.row.inviter.infoFields;
                return `${fields?.filter((item) => item.fieldId === "enFirstName").pop()?.value || ''} ${fields?.filter((item) => item.fieldId === "enLastName").pop()?.value || ''}`;
            },
            renderCell: renderCellExpand
        },
        {
            field: 'inviteTime',
            headerName: t('manage.boothMembersManage.options.inviteTime'),
            type: 'dateTime',
            width: 160,
            valueGetter: (params: GridValueGetterParams<InvitationTableData, InvitationTableData>) => format(new Date(params.row.inviteTime.toString()), "yyyy/MM/dd HH:mm:ss"),
            renderCell: renderCellExpand
        },
        {
            field: 'expireTime',
            headerName: t('manage.boothMembersManage.options.expireTime'),
            type: 'dateTime',
            width: 160,
            valueGetter: (params: GridValueGetterParams<InvitationTableData, InvitationTableData>) => format(new Date(params.row.expireTime.toString()), "yyyy/MM/dd HH:mm:ss"),
            renderCell: renderCellExpand
        },
        {
            field: 'status',
            headerName: t('manage.boothMembersManage.options.status'),
            width: 100,
            type: 'singleSelect',
            valueOptions: statusList.map(x => `${t(x.name)}`),
            valueGetter: (params: GridValueGetterParams<InvitationTableData, InvitationTableData>) =>
                `${t(statusList[params.row.isAccept ? 1 : new Date(params.row.expireTime) < new Date() ? 2 : 0].name)?? ''}`,
            cellClassName: (params: GridCellParams<InvitationTableData, InvitationTableData>) =>
                `${statusList[params.row.isAccept ? 1 : new Date(params.row.expireTime) < new Date() ? 2 : 0].class?? ''}`
        },
        {
            field: 'action',
            headerName: t('manage.boothMembersManage.options.action'),
            minWidth: 100,
            disableExport: true,
            renderCell: (params) => (
                <Box>
                    {!(new Date(params.row.expireTime) < new Date() || params.row.isAccept) &&
                        <Tooltip title='取消邀請'>
                            <IconButton
                                onClick={() => handleDelete(params.row.toEmail, params.row.inviteTime)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            )
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
        />
    )
}

function NewBoothMember(props: NewBoothMemberProps) {
    const { onSubmit } = props;
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        toEmail: yup.string().trim().required('Required').email("Must be email."),
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, setValue, reset } = useForm<{ toEmail: string }>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: { toEmail: "" }
    });

    const handleSubmit = (data: { toEmail: string }) => {
        if (data)
            onSubmit(data.toEmail);
        reset();
    };

    return (
        <CardContent component="form" onSubmit={handleOnSubmit(handleSubmit)}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
                {t('manage.boothMembersManage.form.title')}
            </Typography>

            <Box display='flex'>
                <Box flex='1' maxWidth='520px' marginRight='16px'>
                    <TextField
                        variant='standard'
                        placeholder={t('manage.boothMembersManage.form.toEmail.placeholder')}
                        error={!!errors.toEmail}
                        {...register("toEmail")}
                        //disabled={isLoading || success}
                        style={{ width: '100%' }}
                        InputProps={{ style: { fontSize: '20px' } }}
                    />
                </Box>

                <ConfirmButton type='submit'>
                    <Typography>
                        {t('manage.boothMembersManage.form.submit')}
                    </Typography>
                </ConfirmButton>
            </Box>

            <div style={{ minHeight: '24px' }}>
                <ErrorMessage
                    errors={errors}
                    name={'toEmail'}
                    render={({ message }) =>
                        <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                            {`${t(message)}`}
                        </Typography>
                    }
                />
            </div>
        </CardContent>
    )
}
interface NewBoothMemberProps {
    onSubmit: (email: string) => void;
}
