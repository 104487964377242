import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@mui/material';
import { display } from '@mui/system';

export interface HomeSliderProps {
    contents: JSX.Element[]
}

export interface HomeStepperContent {
    message: string;
    link: string;
}

export default function HomeSlider(props: HomeSliderProps) {
    const { t } = useTranslation();
    const hoverStyle = {
        "&:hover": {
            backgroundColor: '#ffffff30',
        },
        "&::before": {
         display:"none"
        },
        "& img": {
        padding:'40px'
        },
        height: '128px',
        width: '128px',
        borderRadius: '50%',
        textAlign: 'center',
        justifyContent: "center"
    }
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <Box
                sx={hoverStyle}
                className={className}
                style={{ ...style, right: '15px' }}
                onClick={onClick} 
            >
                <img src='images/sliderArrow-R.svg' />
             
        </Box>
       
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (<Box
            sx={hoverStyle}
            className={className}
            style={{ ...style, zIndex: '99', left: "15px" }}
            onClick={onClick}
        >
            <img
                src='images/sliderArrow-L.svg'            
            />
            </Box>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const style = {
        maxWidth: "1440px",
        margin:"0 auto",
        "& .slick-dots": {
            bottom:'23px',
            "&>li": {
            background: "rgba(169, 169, 169, 0.5)",
            height: "10px",
            width: "44px",
            overflow: "hidden",
            borderRadius:"20px",
            "& button": { width:'100%' },
            "& button:before": { display: "none" },
            "&.slick-active": { background:"#0096FA"}
                }
        },

        }

    return (<Box sx={style }>
        <Slider {...settings} >
            {props.contents.map((x, index) => <Box key={index}>{ x}</Box>) }      
        </Slider>
        </Box>
    )
}