import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Announce, CardInfo, Report, SignIn, User, UserRole } from '../class'
import { ResultType } from './utils';

export interface ReportData{
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    description: string;
    jsonData: string;
    createTime: string; 
}

export interface AnnounceData {
    dataId: string;
    content: string;
    link: string;
    isEnd: boolean;
    updateTime: string; //方便轉換型態(string->Date)
}

export interface UserSignData {
    id: string;
    level: UserRole;
    cardInfo: CardInfo;
    time: string;
}

export interface SeminarData {
    titleName: string;
    time: string
}

//上線狀態
export interface UserPresence {
    userId: string;
    isOnline: boolean;
}

export interface LocatedAt {
    location: PageLocation;
    refId: string;
}
export enum PageLocation {
    首頁,
    最新消息,
    活動議程,
    列表總覽,
    遊戲逛展,
    海報牆
}

//號碼牌
export interface NumberPlate {
    userId: string;
    firstNumber: number;
    secondNumber: number;
}

export type NumberPlateType = "first" | "second";

//通用api
const baseApi = createApi({
    reducerPath: 'systemApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/system' }),
    endpoints: (builder) => ({
        checkServerState: builder.query<ResultType<null>, void>({
            query: () => `state`,
        }),
        postReport: builder.mutation<ResultType<null>, Report>({
            query: ({ ...patch }) => ({
                url: `report`,
                method: 'POST',
                body: patch,
            }),
        }),
        getAnnounces: builder.query<ResultType<AnnounceData[]>, void>({
            query: () => `announce`,
            /*transformResponse: (response: { result: ResultType<AnnounceData[]> }) => {
                let source = response.result.data;
                source.forEach((element) => {
                    element.updateTime = new Date(element.updateTime);
                })
                response.result.data = source
                return response.result;
            },*/
        }),
        getIsSignIn: builder.query<ResultType<boolean>, string>({
            query: (userId) => `signin/${userId}`,
        }),
        signIn: builder.mutation<ResultType<null>, string>({
            query: (userId) => ({
                url: `signin/${userId}`,
                method: 'PUT',
            }),
        }),
        getSeminarData: builder.query<ResultType<SeminarData[]>, { userId: string, location: string }>({
            query: (data) => `getSeminarData/${data.userId}/${data.location}`,
        }),
        updateSeminarTimer: builder.mutation<ResultType<null>, string>({
            query: (location) => ({
                url: `seminarTimer`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: location
            }),
        }),
        redeem: builder.mutation<ResultType<null>, string>({
            query: (code) => ({
                url: `redeemCode`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: code
            }),
        }),
        getPresenceInfos: builder.mutation<ResultType<UserPresence[]>, { userIds: string[] }>({
            query: (patch) => ({
                url: `getPresenceInfos`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        getOnlineList: builder.mutation<ResultType<string[]>, LocatedAt>({
            query: (location) => ({
                url: `getOnlineList`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: location
            }),
        }),
        getNumberPlate: builder.mutation<ResultType<NumberPlate>, void>({
            query: () => `myNumber`,
        }),
        takeANumber: builder.mutation<ResultType<NumberPlate>, NumberPlateType>({
            query: (type) => ({
                url: `takeNumber/${type}`,
                method: 'POST',
            }),
        }),
    })
});

//superuser或admin用api
export const systemApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReports: builder.query<ResultType<ReportData[]>, void>({
            query: () => `report`,
        }),
        putAnnounce: builder.mutation<ResultType<null>, Announce>({
            query: ({ ...patch }) => ({
                url: `announce`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deleteAnnounce: builder.mutation<ResultType<null>, Announce>({
            query: ({ ...patch }) => ({
                url: `announce`,
                method: 'DELETE',
                body: patch,
            }),
        }),
        getUserSignIn: builder.query<ResultType<string[]>, string>({
            query: (userId) => `signin/user/${userId}`,
        }),
        getDateInSign: builder.query<ResultType<string[]>, void>({
            query: () => `signin/date`,
        }),
        getSignInList: builder.query<ResultType<UserSignData[]>, string>({
            query: (date) => `signin/list/${date}`,
        }),
        sendMessage: builder.mutation<ResultType<null>, { messageContent: string, link: string }>({
            query: ({ ...patch }) => ({
                url: `sendMessage`,
                method: 'POST',
                body: patch,
            }),
        })
    }),
    overrideExisting: false
});

export const { useCheckServerStateQuery } = systemApi;
export const { useLazyCheckServerStateQuery } = systemApi;
export const { usePostReportMutation } = systemApi;
export const { useGetAnnouncesQuery } = systemApi;
export const { useGetIsSignInQuery } = systemApi;
export const { useGetUserSignInQuery } = systemApi;
export const { useLazyGetIsSignInQuery } = systemApi; //Lazy
export const { useSignInMutation } = systemApi;
export const { useGetSeminarDataQuery } = systemApi;
export const { useUpdateSeminarTimerMutation } = systemApi;
export const { useRedeemMutation } = systemApi;
export const { useGetPresenceInfosMutation } = systemApi;
export const { useGetOnlineListMutation } = systemApi;
export const { useGetNumberPlateMutation } = systemApi;
export const { useTakeANumberMutation } = systemApi;
//superuser或admin用api
export const { useGetReportsQuery } = systemApi;
export const { usePutAnnounceMutation } = systemApi;
export const { useDeleteAnnounceMutation } = systemApi;
export const { useGetDateInSignQuery } = systemApi;
export const { useLazyGetSignInListQuery } = systemApi;
export const { useSendMessageMutation } = systemApi;
