import * as React from "react";
import { AppBar, Avatar, Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import Translation from "../../Translation/Translation";
import { useTranslation } from "react-i18next";
import { useUser } from "../../store";
import { useHistory } from "react-router-dom";
import ChatSystem from "../Chat/ChatSystem";
import { NotificationBell } from "../Notification/Bell/NotificationBell";
import { UserRole } from "../class";
import { ScrollToTop } from "../ScrollToTop";
import { Menu } from "@mui/icons-material";
import { SxProps } from '@mui/system';
import { useGetAllSeminarsQuery } from "../services/seminar";
import { ExpandedButton, PopOutButton } from "./NavBarButton";
import { logEvent } from "../Analytics";
import { manualFolder } from "../../config";

const Manual_General = manualFolder + "使用手冊-參加者.pdf";
const Manual_Booth = manualFolder + "使用手冊-攤位.pdf";
const Manual_Manage = manualFolder + "使用手冊-策展者.pdf";
const Manual_Brochure = manualFolder + "線上場刊.pdf";

export const NavBar = (props: NavBarProps) => {
    const user = useUser();
    const history = useHistory();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const toggleClose = () => setIsOpen(false);

    //開啟使用手冊
    const handleClickManual = () => {
        let target = Manual_General;
        if (user.role >= UserRole.administrator)
            target = Manual_Manage;
        else if (user.boothIds.length > 0)
            target = Manual_Booth;

        logEvent(`AppBar`, `Manual`, `${target}`);
        window.open(target, "_blank");
    }

    //開啟場刊
    const handleClickBrochure = () => {
        let target = Manual_Brochure;
        logEvent(`AppBar`, `Manual`, `${target}`);
        window.open(target, "_blank");
    }
    const handleClickLink = (link: string, event) => {
        event.preventDefault();
        if (link) {
            logEvent(`AppBar`, `Link`, `${link}`);
            history.push(link);
        }
        toggleClose();
    }
    const handleClickItem = (item: NavBarLinkItem, event) => {
        event.preventDefault();
        if (item.link) {
            logEvent(`AppBar`, `Link`, `${item.link}`);
            history.push(item.link);
        }
        if (item.onClick) {
            item.onClick(event);
        }
        toggleClose();
    };

    const stageItemList: NavBarLinkItem[] = [
        { name: 'navbar.menu_item.stage.1', link: '/Event', t: true },
    ];
    const stageItemListAppend: NavBarLinkItem[] = [
           //交通平面圖
          { name: 'event.venue', link: '/Venue', t: true },
        { name: 'event.transportation', link: '/Transportation', t: true },
        { name: 'navbar.menu_item.brochure.name', /*link: '/ComingSoon',*/onClick: handleClickBrochure, t: true },
    ];
    const listItemList: NavBarLinkItem[] = [
        { name: 'navbar.menu_item.list.boothList', link: '/BoothList', t: true },
        { name: 'navbar.menu_item.list.infoCardList', link: '/InfoCardList', t: true },
        //{ name: 'navbar.menu_item.list.userList', link: '/UserList', managerOnly: true, t: true }
    ];
    const oldBoothsItemList: NavBarLinkItem[] = [
        { name: '2022', link: '/BoothList2022', t: true },
        //{ name: 'navbar.menu_item.list.userList', link: '/UserList', managerOnly: true, t: true }
    ];
    const navItemList: NavBarItem[] = [
        // { name: 'navbar.menu_item.manual.name', link: '/ComingSoon', /*onClick: handleClickManual, */t: true },
        { name: 'navbar.menu_item.article.name', link: '/ArticleList', t: true },
        { name: 'navbar.menu_item.stage.name', itemList: [], label: "活動議程",/* excludeAnonymous: true,*/ t: true },
        // { name: 'navbar.menu_item.list.name', itemList: listItemList, label: "列表總覽", t: true},
        { name: 'navbar.menu_item.list.boothList', link: '/BoothList', t: true },
        { name: 'navbar.menu_item.game.name',  link: '/Game', t: true},
        // { name: 'navbar.menu_item.other.posters', link: '/Posters', t: true },
        { name: 'navbar.menu_item.oldBooths.name', itemList: oldBoothsItemList,/* excludeAnonymous: true,*/ t: true },

    
        { name: 'navbar.menu_item.login.name', link: '/Login', anonymousOnly: true, t: true },
    ];
    const [items, setItems] = React.useState<NavBarItem[]>([]);
    const { data: seminarList, isFetching, refetch } = useGetAllSeminarsQuery();
    function isPass(item: NavBarItem) {
        if (!item.anonymousOnly || user?.role === UserRole.anonymous || !user) {
            if (!item.excludeAnonymous || (user && user.role !== UserRole.anonymous)) {
                if (!item.managerOnly || user?.boothIds.length > 0 || user?.role >= UserRole.administrator) {
                    if (!item.adminOnly || user?.role >= UserRole.administrator) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    function checkNavItemList(itemList: NavBarItem[]) {
        let navItems: NavBarItem[] = [];
        itemList.forEach(navItem => {
            if (isPass(navItem)) {
                if (isMenuItem(navItem) && navItem.label === "活動議程") {
                    navItem.itemList = stageItemList.filter(isPass);
                }
                else if (isMenuItem(navItem)) {
                    navItem.itemList = navItem.itemList.filter(isPass);
                }
                navItems.push(navItem);
            }
        });
        return navItems;
    }
    React.useLayoutEffect(() => {
        let items: NavBarItem[] = [];
        items = checkNavItemList(navItemList);
        setItems(() => {
            //如果已有活動議程的話，一同初始化活動議程
            let newItems = [...items];
            var index = newItems.findIndex(x => x.label === "活動議程");
            if (index > -1) {
                let temp = newItems[index];
                if (isMenuItem(temp)) {
                    temp.itemList = stageItems;
                    newItems[index] = temp;
                }
            }
            return newItems;
        });
        if (user) {
            if (!isFetching)
                refetch();
        }
    }, [user]);

    //多紀錄起來活動議程的值以防初始化完Navbar前就初始化完活動議程
    const [stageItems, setStageItems] = React.useState<NavBarLinkItem[]>([]);
    React.useEffect(() => {
        if (seminarList) {
            let newStageItems: NavBarLinkItem[] = [...stageItemList.filter(isPass),
                ...seminarList.data.map(x => ({ name: x.chAbbrev, link: `/Stage?id=${x.seminarId}`, enName: x.enAbbrev })),
                ...stageItemListAppend
             
            ];
            setStageItems(newStageItems);
            setItems((items) => {
                let newItems = [...items];
                var index = newItems.findIndex(x => x.label === "活動議程");
                if (index > -1) {
                    let temp = newItems[index];
                    if (isMenuItem(temp)) {
                        temp.itemList = newStageItems;
                        newItems[index] = temp;
                    }
                }
                return newItems;
            });
        }
    }, [seminarList]);

    return (<>
        <AppBar sx={{
            backgroundColor: (theme) => theme.palette.appBar.main,
            color: (theme) => theme.palette.appBar.contrastText,
            "& a": {
                color: "inherit", textDecoration: "none", "&:hover": { color: "inherit", textDecoration: "none" }
            },
            "background":  "linear-gradient(to right,#fff 0% ,#fff 20%,#2961AD 20%)",
            boxShadow: ' rgba(0, 0, 0, 0.2) 0px 4px 3px'
        }}>
            <Toolbar>
                {/*Menu Button*/}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton edge="start" color="primary" aria-label="menu" sx={{ mr: 1, display: { xs: 'flex', md: 'none' } }} onClick={toggle}>
                        <Menu />
                    </IconButton>
                    {/*MOBILE LOGO*/}
                    <Box sx={{ background: "#FFF", "justifyContent": "flex-start", "width": "max-content", "height": { md: "64px", xs: "56px" }, display: 'flex', "alignItems": "center", "borderRadius": "0px 54px 54px 0px", padding: "5px 0" }}>
                        <Typography variant="h6" noWrap component="a"
                            onClick={(event) => handleClickLink("/", event)} href="/"
                            sx={{ display: 'flex', mr: 2, "alignItems": "center", "height": "100%" }}>
                            {props.logo}{props.name}
                        </Typography>
                    </Box>
                </Box>
                {/*LOGO*/}
                <Box sx={{ background: "#FFF", "width": isMobile ? "100%" : "25%", "height": { md: "64px", xs: "56px" }, display: { xs: 'none', md: 'flex' }, "alignItems": "center", "justifyContent": "flex-end", "borderRadius": "0px 54px 54px 0px", padding: "5px 0" }}>
                    <Typography variant="h6" noWrap component="a"
                        onClick={(event) => handleClickLink("/", event)} href="/"
                        sx={{ display: 'flex', mr: 2, "alignItems": "center", "height": "100%" }}>
                        {props.logo}{props.name}
                    </Typography>
                </Box>


                {/*Translation*/}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, paddingLeft: "13px" }}>
                    <Translation />
                </Box>

                {/*Pages*/}
                {isMobile ?
                    <Drawer anchor="top" open={isOpen} onClose={toggleClose}
                        PaperProps={{
                            sx: {
                                backgroundColor: (theme) => theme.palette.appBar.light,
                                color: (theme) => theme.palette.appBar.contrastText
                            }
                        }}>
                        <List>
                            <ListItemButton href="/" onClick={(event) => handleClickLink("/", event)}>
                                <ListItemText>
                                    {t('navbar.menu_item.home.name')}
                                </ListItemText>
                            </ListItemButton>
                            {items.map((item, index) => {
                                if (isMenuItem(item)) return (
                                    <ExpandedButton
                                        key={item.name} item={item}
                                        onClickItem={handleClickItem}
                                    />
                                );
                                else return (
                                    <ListItemButton key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)} sx={{ ...item.sx }}>
                                        <ListItemText>
                                            {item.t ? t(item.name) : (i18n.language === "ch" ? item.name : item.enName)}
                                        </ListItemText>
                                    </ListItemButton>
                                );
                            })}
                            <ListItem>
                                <ListItemText>
                                    <Translation />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Drawer> :
                    <Box>
                        {items.map((item, index) => {
                            if (isMenuItem(item)) return (
                                <PopOutButton
                                    key={item.name} item={item}
                                    onClickItem={handleClickItem}
                                />
                            );
                            else return (
                                <Button key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)} sx={{ color: "inherit", ...item.sx }}>
                                    {item.t ? t(item.name) : (i18n.language === "ch" ? item.name : item.enName)}
                                </Button>
                            );
                        })}
                    </Box>
                }

                {/*Chat*/}
                {user?.role > UserRole.anonymous &&
                    <ChatSystem />
                }

                {/*To Top Button*/}
                <ScrollToTop />

                {/*Notification Bell*/}
                {user?.role > UserRole.anonymous &&
                    <NotificationBell />
                }

                {/*User Logo*/}
                {user?.role > UserRole.anonymous &&
                    <IconButton href="/Manage" onClick={(event) => handleClickLink("/Manage", event)}>
                        <Avatar style={{ width: '30px', height: '30px' }} src={user?.logoUrl ?? ''} />
                    </IconButton>
                }
            </Toolbar>
        </AppBar>
        <Toolbar disableGutters />
    </>)
}
export interface NavBarProps {
    logo?: React.ReactElement;
    name?: React.ReactElement;
}
export type NavBarMenuItem = {
    name: string;
    itemList: NavBarLinkItem[];
    anonymousOnly?: boolean;
    excludeAnonymous?: boolean;
    managerOnly?: boolean;
    adminOnly?: boolean;
    label?: string;
    sx?: SxProps<Theme>;
    t?: boolean;
    enName?: string;
}
export type NavBarLinkItem = {
    name: string; //顯示的文字
    link?: string;
    anonymousOnly?: boolean; //預設為false
    excludeAnonymous?: boolean; //預設為false
    managerOnly?: boolean; //預設為false，是否只准許有攤位管理以上權限閱覽
    adminOnly?: boolean; //預設為false，是否只准許管理員以上權限閱覽
    label?: string; //基本上是拿來做key的，所以除了活動議程，其他項目可有可無
    onClick?: (event: MouseEvent | TouchEvent) => void;
    sx?: SxProps<Theme>;
    t?: boolean; //預設為false，是否要翻譯
    enName?: string; //為了活動議程設計的= =
    disabled?: boolean;
}
export type NavBarItem = NavBarMenuItem | NavBarLinkItem;
export function isMenuItem(object: NavBarItem): object is NavBarMenuItem {
    return 'itemList' in object;
}