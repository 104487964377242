import * as React from "react";
import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, IconButton, styled, Toolbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import 青年局Logo from "./images/04 青年事務局LOGO1.png";
import { SubTitle } from "../components/responsive-components/Typography";
import { HTMLAttributes } from "react";


export default function Transportation() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (<>

        <Toolbar sx={{ justifyContent: "center" }}>
            <SubTitle icon='circle' text={t("event.transportation")} />
        </Toolbar>
        <Container>
            <Box bgcolor={theme.palette.background.default} p={3}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14468.081618460326!2d121.2254629!3d24.9654204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346822304d65fd8b%3A0x31c90e9c8479f129!2z5qGD5ZyS5biC5pS_5bqc6Z2S5bm05LqL5YuZ5bGA!5e0!3m2!1szh-TW!2stw!4v1682319214355!5m2!1szh-TW!2stw" width="600" height="450" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Box>
            <Box bgcolor={theme.palette.background.default} p={3}>
                <SubTitle icon='circle' text={"交通資訊:"} />
                <Box p={3 }>
                開車:<br />
                1.中山高內壢交流道&gt;中原路&gt;延平路&gt;環北路&gt;青年事務局<br />
                2.中山高中壢交流道&gt;民族路&gt;環西路&gt;環北路&gt;青年事務局<br />
                附近的停車場：和雲中壢環北停車場、uTagGO 日月亭中壢七和停車場、國雲停車泊車趣(廣停一停車場)、萬和停車場<br />
                </Box>
                <Box p={3 }>
                公車:<br />
                桃園客運(1號公車)&gt;延平路與元化路交界口處下車(聯合辦公大樓騎Youbike)&gt;環北路&gt;中壢區公所(Youbike停車)&gt;青年事務局<br />
                </Box>
                <Box p={3 }>
                火車:<br />
                中壢火車站(前站右側騎Youbike)&gt;中正路&gt;大同路接延平路&gt;元化路&gt;慈惠三街&gt;中壢區公所(Youbike停車)&gt;青年事務局
                </Box>
            </Box>

        </Container>
        <br />
    </>)
}
