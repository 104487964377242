import { alpha, Box, List, ListItem, Toolbar, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import BoothPagingManage from './BoothPagingManage';
import UploadPoster from './UploadPoster';
import InfoCardManage from './InfoCardManage';
import BoothArticleListManage from './BoothArticleListManage';
import BoardCardManage from './BoardCardManage';
import BoothMembersManage from './BoothMembersManage';
import { Booth, BoothType } from '../../components/class';
import { useGetManageBoothListQuery } from '../../components/services/user';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

import ErrorPage from '../../components/ErrorPage/404Page';
import scrollProps from '../scrollProps';
import { VideoManage } from './VideoManage';
import { CompanyIntroManage, EventIntroManage, GameIntroManage, TeamIntroManage } from './IntroManage';

interface BoothSideBarItem extends IBoothItem{
    name: string; //顯示名稱
    className: string; //使用scroll跳轉的對應className
    pageList?: BoothSideBarItem[]; //子頁面
}
export interface BoothPageItem extends IBoothItem {
    className: string,
    content: (props: { boothId: string }) => JSX.Element,
}
interface IBoothItem {
    normalOnly?: boolean; //預設為false，是否一般攤位獨有
    sponsorOnly?: boolean; //預設為false，是否贊助商獨有
    serviceDesktopOnly?: boolean; //預設為false，是否服務台獨有
    excludeServiceDesktop?: boolean; //預設為false，是否必須非服務台
}
const prefix = "manage.boothTool.";
const sideBarItems: BoothSideBarItem[] = [
    { name: prefix + "editBooth", className: prefix + "editBooth" },
   // { name: prefix + "uploadPoster", className: prefix + "uploadPoster", normalOnly: true },
    { name: prefix + "promotionalVideo", className: prefix + "promotionalVideo", excludeServiceDesktop: true },
  //  { name: prefix + "gameIntro", className: prefix + "gameIntro", normalOnly: true },
   // { name: prefix + "teamIntro", className: prefix + "teamIntro", normalOnly: true },
    { name: prefix + "companyIntro", className: prefix + "companyIntro", sponsorOnly: true },
    { name: prefix + "eventIntro", className: prefix + "eventIntro", serviceDesktopOnly: true },
//  { name: prefix + "infoCardManage", className: prefix + "infoCardManage", excludeServiceDesktop: true },
    { name: prefix + "boothArticleList", className: prefix + "boothArticleList" },
    { name: prefix + "boardCardTable", className: prefix + "boardCardTable" },
    { name: prefix + "boothMembersManage", className: prefix + "boothMembersManage" }
];
const pageItems: BoothPageItem[] = [
    { className: prefix + "editBooth", content: BoothPagingManage},
 //   { className: prefix + "uploadPoster", content: UploadPoster, normalOnly: true },
    { className: prefix + "promotionalVideo", content: VideoManage, excludeServiceDesktop: true },
   // { className: prefix + "gameIntro", content: GameIntroManage, normalOnly: true },
   // { className: prefix + "teamIntro", content: TeamIntroManage, normalOnly: true },
    { className: prefix + "companyIntro", content: CompanyIntroManage, sponsorOnly: true },
    { className: prefix + "eventIntro", content: EventIntroManage, serviceDesktopOnly: true },
    //  { className: prefix + "infoCardManage", content: InfoCardManage, excludeServiceDesktop: true },
    { className: prefix + "boothArticleList", content: BoothArticleListManage },
    { className: prefix + "boardCardTable", content: BoardCardManage },
    { className: prefix + "boothMembersManage", content: BoothMembersManage }
];
const sideBarWidth: number = 150;
export default function BoothInfoOverview() {
    const { manageBoothList } = useGetManageBoothListQuery(undefined, {
        selectFromResult: ({ data }) => ({ manageBoothList: data?.data })
    });
    const location = useLocation()
    let query = new URLSearchParams(useLocation().search);

    //整理當前攤位顯示
    const [currentBooth, setCurrentBooth] = React.useState<Booth>();
    const [allowSideBarList, setAllowSideBarList] = React.useState<BoothSideBarItem[]>([]);
    const [pageItemList, setPageItemList] = React.useState<BoothPageItem[]>(pageItems);
    React.useEffect(() => {
        let boothId = query.get('boothId');
        let booth = manageBoothList?.find(x => x.boothId === boothId)
        setCurrentBooth(booth);
        function isPass(item: IBoothItem) {
            if (!item.normalOnly || booth?.type === BoothType.一般攤位) {
                if (!item.sponsorOnly || booth?.type === BoothType.廣告贊助) {
                    if (!item.serviceDesktopOnly || booth?.type === BoothType.服務台) {
                        if (!item.excludeServiceDesktop || booth?.type !== BoothType.服務台) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        function checkPageList(pageList: BoothSideBarItem[]) {
            let pages: BoothSideBarItem[] = [];
            pageList.forEach(page => {
                if (isPass(page)) {
                    if (page.pageList) page.pageList = page.pageList.filter(isPass);
                    pages.push(page);
                }
            });
            return pages;
        }
        setAllowSideBarList(checkPageList(sideBarItems));
        setPageItemList(pageItems.filter(isPass));
    }, [manageBoothList, location]);

    if (!manageBoothList || !currentBooth)
        return null;
    if (currentBooth)
        return (<>
            <Box paddingRight={`${sideBarWidth}px`}>
                {pageItemList.map((item) => (
                    <Box key={item.className} className={item.className} style={{ minHeight: 500 }}>
                        <item.content boothId={currentBooth.boothId} />
                        <Toolbar />
                    </Box>
                ))}
            </Box>
            <Box position="fixed" top="0" bottom="0" right="17px" paddingTop={14} width={sideBarWidth} bgcolor="background.paper">
                <BoothSideBar sideBarList={allowSideBarList} />
            </Box>
        </>);
    else return <ErrorPage />
}

export function BoothInfoOverviewWithoutQuery(props: {booth: Booth}) {
    const { booth } = props;

    //整理當前攤位顯示
    const [allowSideBarList, setAllowSideBarList] = React.useState<BoothSideBarItem[]>([]);
    const [pageItemList, setPageItemList] = React.useState<BoothPageItem[]>(pageItems);
    React.useEffect(() => {
        function isPass(item: IBoothItem) {
            if (!item.normalOnly || booth?.type === BoothType.一般攤位) {
                if (!item.sponsorOnly || booth?.type === BoothType.廣告贊助) {
                    if (!item.serviceDesktopOnly || booth?.type === BoothType.服務台) {
                        if (!item.excludeServiceDesktop || booth?.type !== BoothType.服務台) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        function checkPageList(pageList: BoothSideBarItem[]) {
            let pages: BoothSideBarItem[] = [];
            pageList.forEach(page => {
                if (isPass(page)) {
                    if (page.pageList) page.pageList = page.pageList.filter(isPass);
                    pages.push(page);
                }
            });
            return pages;
        }
        if (booth) {
            setAllowSideBarList(checkPageList(sideBarItems));
            setPageItemList(pageItems.filter(isPass));
        }
    }, [booth]);
    
    if (booth)
        return (<>
            <Box paddingRight={`${sideBarWidth}px`}>
                {pageItemList.map((item) => (
                    <Box key={item.className} className={item.className} style={{ minHeight: 500 }}>
                        <item.content boothId={booth.boothId} />
                        <Toolbar />
                    </Box>
                ))}
            </Box>
            <Box position="fixed" top="0" bottom="0" right="17px" paddingTop={14} width={sideBarWidth} bgcolor="background.paper">
                <BoothSideBar sideBarList={allowSideBarList} />
            </Box>
        </>);
    else return <ErrorPage />
}

interface BoothSideBarProps {
    sideBarList: BoothSideBarItem[];
}
function BoothSideBar(props: BoothSideBarProps) {
    const { sideBarList } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box paddingTop={3} sx={{
            height: "100%",
            overflow: 'hidden',
            "&:hover": {
                overflowY: 'auto'
            },
            "&::-webkit-scrollbar": {
                width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 5,
                background: theme.palette.grey[400],
            },
            "& a": {
                textDecoration: 'none',
                mt: 0.5, mb: 1, pl: 1.25, pr: 1,
                borderLeft: "1px solid transparent",
                '&:hover': {
                    borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    '& > span': {
                        color: theme.palette.grey[500],
                    }
                },
            },
            "& a.active": {
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                '&:hover': {
                    borderLeft: `1px solid ${theme.palette.primary.main}`,
                    '& > span': {
                        color: theme.palette.primary.main,
                    }
                },
            }
        }}>
            <List>
                {sideBarList.map(item => (
                    <ListItem disablePadding key={item.name}>
                        <Link to={item.name} {...scrollProps}>
                            <Typography component="span" variant="subtitle2">{t(item.name)}</Typography>
                        </Link>
                        {item.pageList?.map(item => (<>
                            <Link to={item.name} {...scrollProps}>
                                <Typography component="span" variant="subtitle2">{t(item.name)}</Typography>
                            </Link>
                        </>))}
                    </ListItem>
                ))}
            </List>
        </Box >
    )
}