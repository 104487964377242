import * as React from 'react';
import { Backdrop, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { PaperWrap, SubmitButton, TextButton } from './LoginWindow';
import ErrorPage from "../components/ErrorPage/404Page";
import { useChooseCardMutation } from '../components/services/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';
import { CardType } from '../components/class';
import { useDispatchWithType, useUser } from '../store';
import { updateUser } from '../store/rootReducer';
import { logoPath } from '../config';

export default function ChooseCardWindow() {
    const user = useUser();
    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);

    const [chooseCard, { isLoading }] = useChooseCardMutation();
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const [type, setType] = React.useState('true');
    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType((event.target as HTMLInputElement).value);
    };
    const schema = yup.object().shape({
    });
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: null
    });

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [isError, setIsError] = React.useState(false); //404
    React.useEffect(() => {
        if (user && user.type != CardType.unknown)
            setIsError(true);
    }, [user]);

    function onChooseSubmit(data) {
        setErrorMessage("");
        chooseCard({ cardType: type === "true" ? CardType.student : CardType.nonStudent })
            .unwrap()
            .then(result => {
                if (result.isSuccess) {
                    dispatch(updateUser({
                        ...result.data,
                        userId: user.userId,
                        role: user.role,
                        saveCardIds: user.saveCardIds,
                        saveBoothIds: user.saveBoothIds,
                        saveInfoCardIds: user.saveInfoCardIds,
                    }));
                    history.push('/QuickStart' + (query.get('redirect') ? "?redirect=" + query.get('redirect') : ""));
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (isError) {
        return <ErrorPage fullHeight />;
    } else if (user) {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        <div className="d-flex align center w-100">
                            <img src={logoPath} style={{ height: '36px', maxWidth: '100%' }} />
                            <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                        </div>

                        <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                {t('chooseCard.subtitle1')}
                            </Typography>
                            <Typography variant="h5">
                                {t('chooseCard.subtitle2')}
                            </Typography>
                            <Typography variant="h5">
                                {t('chooseCard.subtitle3')}
                            </Typography>
                        </div>

                        <Stack component="form" sx={{ minWidth: 200 }} onSubmit={handleSubmit(onChooseSubmit)}>
                            <Typography variant='body1'>{t('chooseCard.question.title')}</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup row value={type} onChange={handleTypeChange}>
                                    <FormControlLabel value="true" control={<Radio />} label={t('chooseCard.question.radioLabel1').valueOf()} />
                                    <FormControlLabel value="false" control={<Radio />} label={t('chooseCard.question.radioLabel2').valueOf()} />
                                </RadioGroup>
                            </FormControl>

                            <div className="d-flex center" style={{ height: 30 }}>
                                <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                            </div>
                            <SubmitButton type="submit" variant="contained" loading={isLoading}>
                                {t('chooseCard.button')}
                            </SubmitButton>
                        </Stack>

                        <div style={{ height: 12 }} />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>Click to</Typography>
                            {i18n.language == 'ch' ?
                                <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                                <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                            }
                        </div>
                    </Stack>
                </PaperWrap>
            </div >
        );
    } else {
        return (
            <div className="root bg-lavender">
                <Backdrop open sx={{ color: "#fff" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >
        )
    }
}