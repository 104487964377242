import { Backdrop, Box, Button, CircularProgress, Dialog, Divider, Stack, styled, ThemeProvider, Typography, TypographyProps } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "../../components/Admin/Utils";
import { SubTitle } from "../../components/responsive-components";
import { NumberPlate, NumberPlateType, useGetNumberPlateMutation, useTakeANumberMutation } from "../../components/services/system";

const RedText = styled((props: TypographyProps) =>
    <Typography component="span" color="error.light" fontWeight="bold" {...props} />)(() => ({}));

export default function UserNumberPlate() {
    const { t } = useTranslation();

    const [numberPlate, setNumberPlate] = React.useState<NumberPlate>();
    const [getNumber, { isLoading: isGetDataLoading }] = useGetNumberPlateMutation();
    const [takeNumber, { isLoading: isUpdateLoading }] = useTakeANumberMutation();
    React.useEffect(() => {
        getNumber().unwrap().then((result) => {
            if (result.isSuccess)
                setNumberPlate(result.data);
        })
    }, []);

    async function handleClickTakeNumber(type: NumberPlateType) {
        try {
            const result = await takeNumber(type).unwrap();
            if (result.isSuccess) {
                setNumberPlate(result.data);
            }
            setDialogMessage(result.message);
            setOpenDialog(true);
        } catch (err) {
            setDialogMessage("無法連線");
            setOpenDialog(true);
        }
    }

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = React.useState<string>("");
    const dialogContents = (
        <Box px={4} py={2} >
            <Typography textAlign="center">
                {dialogMessage}
            </Typography>
        </Box>
    );

    //限制領取時間
    let dateString = "2022-08-20T10:00:00+08:00";
    const [isEnableTakeNumber, setIsEnableTakeNumber] = React.useState<boolean>(new Date() >= new Date(dateString));
    useInterval(() => {
        setIsEnableTakeNumber(new Date() >= new Date(dateString));
    }, 10000);

    return (<>
        <SubTitle
            icon='circle'
            text="報名方式"
        />
        <Typography color="primary.main" mb={2} gutterBottom>
            ※ 領取號碼牌，指定時間至2樓PBL教室報到。
        </Typography>
        <SubTitle
            icon='circle'
            text="領取號碼牌"
        />
        <Box mb={3}>
            <Typography variant="h6" mb={1}>
                場次：上午場
            </Typography>
            <Typography variant="h6" mb={1}>
                主題：遊戲發想體驗課程
            </Typography>
            <Typography variant="h6" mb={1}>
                號碼：
                <RedText variant="h6">
                    {numberPlate === undefined ? "--" :
                        numberPlate.firstNumber ? numberPlate.firstNumber.toString().padStart(3, '0') : "無"
                    }
                </RedText>
            </Typography>
            <Typography mb={1}>
                {/*  領取開放時間：2022/8/20 早上10點*/}
            </Typography>
            <Button variant="contained" size="large" onClick={() => handleClickTakeNumber("first")}
                disabled={!numberPlate || !!numberPlate.firstNumber || !isEnableTakeNumber}
            >領取號碼牌</Button>
        </Box>
        <Divider />
        <Box mt={3}>
            <Typography variant="h6" mb={1}>
                場次：下午場
            </Typography>
            <Typography variant="h6" mb={1}>
                主題：議題討論工作坊
            </Typography>
            <Typography variant="h6" mb={1}>
                號碼：
                <RedText variant="h6">
                    {numberPlate === undefined ? "--" :
                        numberPlate.secondNumber ? numberPlate.secondNumber.toString().padStart(3, '0') : "無"
                    }
                </RedText>
            </Typography>
            <Typography mb={1}>
                {/*領取開放時間：2022/8/20 早上10點*/}
            </Typography>
            <Button variant="contained" size="large" onClick={() => handleClickTakeNumber("second")}
                disabled={!numberPlate || !!numberPlate.secondNumber || !isEnableTakeNumber}
            >領取號碼牌</Button>
        </Box>
        <Backdrop open={isGetDataLoading || isUpdateLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress />
        </Backdrop>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
    </>)
}