import { buttonClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

export interface TypeOfCard {
    unknown: string;
    student: string;
    nonStudent: string;
    admin: string;
}

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        secondary: string;
        black: string;
        fixed: string;
        content: string;
        light: string;
    }
    interface TypeText {
        white: string;
        card: TypeOfCard;
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        main: true;
        light: true;
        danger: true;
        dark: true;
        reverse: true;
    }
}
 
declare module '@mui/styles' {
    interface DefaultTheme extends Theme { }
}
declare module '@mui/material/styles' {
    interface Palette {
        appBar: Palette['primary'];
    }
    interface PaletteOptions {
        appBar: PaletteOptions['primary'];
    }
}
let mainTheme = createTheme({
    palette: {
        primary: {
            light: '#D9ECFF',
            main: '#2573DE',
            dark: '#1045af',
            contrastText: '#ffffff',
        },
        secondary: {
            main : '#f73378',
            light: '#FF9BAC',
            dark: '#ab003c',
            contrastText: '#ffffff',
        },
        background: {
            paper: '#fff',
            content: '#F5F5F5',
            default: '#fff',
            fixed: 'linear-gradient(180deg, #E2F1FF 0%, #FCFAF5 100%)',
            secondary: grey[200],
            black: '#101010',
            light:'#0096FA'
        },
        appBar: {
            light: '#2961AD',
            main: "#343a40",
            contrastText:"#f5ffff"
        },
        text: {
            card: {
                unknown: grey[400],
                student: '#1763FB',
                nonStudent: '#1045af',
                admin: '#ff8f20'
            }
        },
      
    },
    typography: {
        fontFamily: 'Noto Sans TC',
    },
    components: {
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    margin: 'auto'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap'
				}
			}
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'main' },
                    style: (theme: any) => {
                        return {
                            borderRadius: '5px',
                            backgroundColor: theme.theme.palette.primary.light,
                            color: theme.theme.palette.primary.contrastText,
                            '&:hover': {
                                backgroundColor: theme.theme.palette.primary.main,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                            },
                        };
                    },
                },
                {
                    props: { variant: 'light' },
                    style: (theme: any) => {
                        return {
                            borderRadius: '5px',
                            backgroundColor: theme.theme.palette.background.light,
                            color: theme.theme.palette.primary.light,
                            border: `1px solid ${theme.theme.palette.primary.light}`,
                            '&:hover': {
                                backgroundColor: theme.theme.palette.primary.light,
                                color: theme.theme.palette.primary.contrastText,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                                borderColor: "rgba(0, 0, 0, 0.12)",
                            },
                        };
                    },
                },
                {
                    props: { variant: 'danger' },
                    style: (theme: any) => {
                        return {
                            borderRadius: '5px',
                            backgroundColor: "rgba(255, 0, 0, 1)",
                            color: "#fff",
                            border: `1px solid #ff000`,
                            '&:hover': {
                                backgroundColor: "#f58989",
                                color: theme.theme.palette.primary.contrastText,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                                borderColor: "rgba(0, 0, 0, 0.12)",
                            },
                        };
                    },
                },
                {
                    props: { variant: 'dark' },
                    style: (theme: any) => {
                        return {
                            borderRadius: '5px',
                            backgroundColor: '#23527C',
                            color: theme.theme.palette.primary.contrastText,
                            '&:hover': {
                                backgroundColor: '#2E3642',
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                            },
                        };
                    },
                },
                {
                    props: { variant: 'reverse' },
                    style: (theme: any) => {
                        return {
                            borderRadius: '5px',
                            backgroundColor: theme.theme.palette.primary.main,
                            color: theme.theme.palette.primary.contrastText,
                            '&:hover': {
                                backgroundColor: theme.theme.palette.primary.light,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                            },
                        };
                    },
                },
            ],
        },
    },
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
