import { Backdrop, CircularProgress, Container} from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage/404Page';
import { MediaSection, Whiteboard } from '../components/MediaSection';
import { IRoom } from '../components/class';
import { Col, Row } from 'reactstrap';
import { useSystemConnection, useUser } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { useGetRoomQuery, useLazyGetRoomTokenQuery } from '../components/services/room';
import { Videocam } from '@mui/icons-material';
import { SubTitle } from '../components/responsive-components/Typography';

export default function Room() {
    const location = useLocation();
    let query = new URLSearchParams(useLocation().search);
    const user = useUser();

    const [roomId, setRoomId] = React.useState<string>(query.get('roomid'));
    React.useLayoutEffect(() => { setRoomId(query.get('roomid')) }, [location]);
    const { room, isLoading } = useGetRoomQuery(roomId, {
        selectFromResult: ({ data, isLoading }) => ({ room: data?.data, isLoading })
    });
    const [getToken, { token }] = useLazyGetRoomTokenQuery({
        selectFromResult: ({ data }) => ({ token: data?.data })
    });
    React.useEffect(() => { if (room != null) getToken(room.roomId) }, [room]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.EnterVideoChatRoom(roomId);
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.send.LeaveVideoChatRoom(roomId);
            }
        })
    }, [systemConnection]);

    if (isLoading || !user) {
        return (
            <Backdrop open sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    } else if (room && (room.allowAnonymous || room.owner.includes(user.userId))) {
        return (
            <Container>
                <Row>
                    <Col className="mt-2">
                        <SubTitle icon={<Videocam color='primary' fontSize='large' />} text='視訊聊天室' style={{ paddingRight: '120px', paddingBottom: '0px' }} />
                        <div style={{ position: 'relative', height: "700px", maxHeight: "calc(100vh - 64px - 2rem)", marginBlock: "1rem" }}>
                            <Whiteboard room={room.whiteboardRoomCode} />
                            <MediaSection roomId={query.get('roomid')} roomName={"聊天室"} token={token} limited={2} varient="private" />
                            <div style={{ float: 'right' }}>※請注意個人隱私並尊重他人言論。</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return <ErrorPage />;
    }
}