import * as React from 'react';
import { useBoothFieldNameList } from '../../store';
import { useLazyGetBoothQuery, usePutBoothMutation } from '../../components/services/booth';
import { useTranslation } from 'react-i18next';
import { Booth, BoothExtendField, BoothType } from '../../components/class';
import EditIntroDialog from "../../Booth/BoothEdit";
import { SubTitle } from '../../components/responsive-components/Typography';
import { BoothCard } from '../../components/BoothCard/BoothCard';
import * as lodash from 'lodash';
import { Dialog, DialogContent } from '@mui/material';

export default function BoothPagingManage(props: BoothPagingManageProps) {
    const { boothId } = props;
    const { t } = useTranslation();

    const boothFieldNameList = useBoothFieldNameList();
    const [data, setData] = React.useState<Booth>(null);
    function handleDataChange(value: Booth) {
        let tempBooth = value;

        let tempList = [...tempBooth.fieldList];
        let newFields: BoothExtendField[] = [];

        boothFieldNameList.forEach((field) => {
            if (!tempList.find(x => x.fieldId === field.fieldId) &&
                field.boothTypeList.includes(value.type))
                newFields.push({ fieldId: field.fieldId, value: '' });
        });

        tempBooth = {
            ...tempBooth,
            fieldList: tempList.concat(newFields)
        };

        setData(tempBooth);
    }
    const [getBooth, { data: fetchData }] = useLazyGetBoothQuery();
    React.useEffect(() => {
        if (fetchData && fetchData.data) {
            handleDataChange(fetchData.data);
        }
    }, [fetchData])
    React.useEffect(() => {
        if (boothId && boothId != '') {
            getBooth({ boothId: boothId });
        }
    }, [boothId])

    const [putBooth, { isLoading: isPutLoading }] = usePutBoothMutation();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    /*const handleClose = (value?: Booth) => {
        if (value) {
            let tempData = { boothId: data.boothId, ...value };
            putBooth(tempData).unwrap().then((result) => {
                if (result.isSuccess) {
                    handleDataChange(value);
                    setOpenEditDialog(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            setOpenEditDialog(false);
        }
    }*/
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    async function handleClose(value) {
        console.log(value)
        console.log(value?.fieldList["edm"]?.value)
        if (value) {
            if (value?.bannerUrl == null || value?.bannerUrl == "") {
                setOpen(true)
                setMessage("未上傳banner")
                return
            } else if (value?.logoUrl == null || value?.logoUrl == "") {
                setOpen(true)
                setMessage("未上傳logo")
                return
            } else if (value.type == BoothType.一般攤位 && (value?.fieldList["edm"].value == null || value?.fieldList["edm"].value == "")) {
                setOpen(true)
                console.log("未上傳相關圖片")
                setMessage("未上傳相關圖片")
                return
            }
            try {
                let fieldList: BoothExtendField[] = [];
                for (let key in value.fieldList) {
                    fieldList.push({ fieldId: key, ...value.fieldList[key] });
                }
                let boothData = {
                    ...data,
                    ...value,
                    fieldList: lodash.unionBy(fieldList, data.fieldList, "fieldId")
                };
                let result = await putBooth(boothData).unwrap();
                if (!result.isSuccess) return false;
                setData(boothData);
                setOpenEditDialog(false);
            }
            catch (error) {
                console.error(error);
            }
        } else {
            setOpenEditDialog(false);
        }
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.boothTool.editBooth')}
        />
        <BoothCard
            data={data}
            editable
            onClickEdit={() => { setOpenEditDialog(true) }}
        />
        <Dialog
            open={open}
            onClose={() => { setOpen(false)} }
        >
            <DialogContent>
                    {message }
            </DialogContent>
        </Dialog>
        {data &&
            <EditIntroDialog
                open={openEditDialog}
                data={data}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                isLoading={isPutLoading}
            />
        }
    </>)
};
interface BoothPagingManageProps {
    boothId: string;
}