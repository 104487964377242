import * as React from 'react';
import { Box, Button, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Announce } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { EditDialog } from './StartAnnounceDialog';
import { Card, CardActions, CardContent } from '../responsive-components';
import { InfoCardState } from '../responsive-components/Card';
import { logEvent } from '../Analytics';
import CloseIcon from '@mui/icons-material/Close';

const FloatButton = styled(IconButton)({
    margin: "8px 8px 0 0",
    '&:focus': {
        outline: 'none',
    }
});

export default function StartAnnounce(props: announceItemProps) {
    const { data, isManage, deleteAnnouce, saveAnnouce } = props;
    const matches = useMediaQuery('(min-width:768px)');
    const { t } = useTranslation();

    const state = data.isEnd ? InfoCardState.overdue
        : (data.link != '' ? InfoCardState.haveLink : InfoCardState.noLink);

    const linkText = [t('start_announce.card.link'), t('start_announce.card.nolink'), t('start_announce.card.over')]

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenLink = (event: React.SyntheticEvent, link: string) => {
        event.preventDefault();
        logEvent(`Home`, `HomeStepper`, `Link: ${link}`);
        window.open(link, '_blank');
    };

    return (<Box width='100%' >
        <Typography
            variant="h6"
            gutterBottom
            maxWidth="174px"
            sx={{ borderBottom: "3px solid", borderColor: "primary.main", "textAlign": "center", "margin": "0 auto", "marginBottom": "15px",fontWeight: '700' }}
        >
            <Trans i18nKey={'start_announce.card.title'} />
        </Typography>
        <Card ismobile={(!matches) ? "true" : "false"} sx={{ margin: 'auto', backgroundColor: "background.content", "padding": "24px", "borderRadius": "10px", boxShadow: 'none' }}>
            <CardContent>
                <Typography variant="h6" fontWeight="bold" sx={{ wordBreak: "break-all", overflow: "auto" }}>
                    {data.content}
                </Typography>
                {(matches && isManage) &&
                    <Box display="flex" position="absolute" right="0" top="0">
                        <FloatButton onClick={handleClickOpen}>
                            <Edit />
                        </FloatButton>
                        <FloatButton onClick={() => deleteAnnouce(data)}>
                            <DeleteForever />
                        </FloatButton>
                    </Box>
                }
            </CardContent>
            <CardActions state={state}>
                <Button
                    href={data.link}
                    onClick={(e) => handleOpenLink(e, data.link)}
                    disabled={data.isEnd}
                    variant='light'
                    sx={{
                        maxWidth: '250px',
                        width: '100%',
                        height: '100%',
                        color: '#fff',
                        backgroundColor: 'background.light',
                        borderRadius: '40px',
                        visibility: data.link ? 'visibile' : 'hidden',
                        margin:'0 auto'
                    }}>
                    <Typography variant="h6" fontWeight="bold">
                        {linkText[state]}
                    </Typography>
                </Button>
            </CardActions>
            <EditDialog
                data={data}
                open={open}
                handleSubmit={saveAnnouce}
                handleClose={handleClose}
            />
        </Card>
    </Box>

    )
};
interface announceItemProps {
    data: Announce;
    isManage?: boolean;
    saveAnnouce?: (value: Announce) => Promise<void>;
    deleteAnnouce?: (value: Announce) => Promise<void>;
}