import * as React from 'react';
import { useDispatchWithType, useUser } from '../../../store';
import { Booth, BoothPresence, IInfoCard } from '../../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { useChangeBookMarkMutation, useChangeInfoCardBookMarkMutation, useGetSaveBoothsMutation, useGetSaveInfoCardsMutation } from '../../../components/services/user';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { Box, Divider, IconButton, Paper, Toolbar, Typography, useTheme } from '@mui/material';
import { useGetBoothPresenceInfosMutation } from '../../../components/services/booth';
import { useHistory } from 'react-router-dom';
import { updateUser } from '../../../store/rootReducer';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { BoothCard } from '../../../components/BoothCard/BoothCard';
import { InfoCard as BoothInfoCard } from "../../../components/InfoCard/InfoCard";
import { InfoCardDialog } from '../../../components/InfoCard/InfoCardDialog';

export default function UserFavorite(props: { noPadding?: boolean }) {
    const { t } = useTranslation();
    const user = useUser();

    const [getSaveBooths] = useGetSaveBoothsMutation();
    const [getBoothPresenceInfos] = useGetBoothPresenceInfosMutation();
    const [getSaveInfoCards] = useGetSaveInfoCardsMutation();

    const [saveBooths, setSaveBooths] = React.useState<Booth[]>([]);
    const [presenceList, setPresenceList] = React.useState<BoothPresence[]>([]);
    const [saveInfoCards, setSaveInfoCards] = React.useState<IInfoCard[]>([]);
    React.useEffect(() => {
        if (user.saveBoothIds.length > 0) {
            getSaveBooths().unwrap().then((result) => {
                if (result.isSuccess) {
                    setSaveBooths(result.data);
                }
            }).catch((error) => {
                console.log(error);
            });
            getBoothPresenceInfos({ boothIds: user.saveBoothIds }).unwrap().then((result) => {
                if (result.isSuccess) {
                    setPresenceList(result.data);
                }
            });
        }
    }, [user]);

    React.useEffect(() => {
        if (user.saveInfoCardIds.length > 0) {
            getSaveInfoCards().unwrap().then((result) => {
                if (result.isSuccess) {
                    setSaveInfoCards(result.data);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        else setSaveInfoCards([]);
    }, [user]);

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.profile.favorite')}
        />
        <Box display="flex" alignItems="center">
            <Typography fontWeight='bold' color="primary.main">
                {t('manage.favorite.saveBooths')}
            </Typography>
            <Divider sx={{ flexGrow: 1, margin: '0px 20px', borderColor: theme => theme.palette.primary.main }} />
        </Box>
        <SaveBoothList boothList={saveBooths} presenceList={presenceList} />

        <Toolbar variant='dense' />

        {/*<Box display="flex" alignItems="center">
            <Typography fontWeight='bold' color="primary.main">
                {t('manage.favorite.saveInfoCards')}
            </Typography>
            <Divider sx={{ flexGrow: 1, margin: '0px 20px', borderColor: theme => theme.palette.primary.main }} />
        </Box>
        <SaveInfoCardList infoCards={saveInfoCards} />*/}
    </>)
}

interface SaveBoothListProps {
    boothList: Booth[];
    presenceList: BoothPresence[];
}
function SaveBoothList(props: SaveBoothListProps) {
    const { boothList, presenceList } = props;
    const theme = useTheme();

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState<number>(4);

    //responsive pageSize
    React.useLayoutEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < theme.breakpoints.values.md
                ? setPageSize(4) : setPageSize(12);
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    let totalCount = boothList?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize) || 1;
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleNext = () => setPage(page => page + 1);
    const handlePrevious = () => setPage(page => page - 1);

    //❤️按鈕
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeBookMark] = useChangeBookMarkMutation();
    function handleClickFavorite(data: Booth) {
        changeBookMark({ boothId: data.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    //更多按鈕
    const history = useHistory();
    function handleClickShowMore(event: React.MouseEvent<HTMLElement>, data: Booth) {
        event.preventDefault();
        history.push(`/Booth?id=${data.boothId}`);
    }

    return (<>
        <Box display="flex" alignItems="center" mb={1}>
            <Typography color="text.secondary">
                <Trans i18nKey="userList.totalNum" values={{ totalNum: totalCount }} />
            </Typography>

            <Paper sx={{
                marginInline: 'auto 2px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <IconButton onClick={handlePrevious} disabled={!hasPrevious}>
                    <KeyboardArrowLeft />
                </IconButton>
                <Divider variant="middle" flexItem orientation="vertical" />
                <Box style={{ padding: '6px 14px' }}>
                    <Typography variant="body2">
                        <span>{currentPage}</span>
                        {` / ${totalPages}`}
                    </Typography>
                </Box>
                <Divider variant="middle" flexItem orientation="vertical" />
                <IconButton onClick={handleNext} disabled={!hasNext}>
                    <KeyboardArrowRight />
                </IconButton>
            </Paper>
        </Box>
        <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }}>
            {boothList.map((booth, index) => (
                <Box key={booth.boothId}>
                    <BoothCard
                        data={booth}
                        isOnline={presenceList?.find(x => x.boothId === booth.boothId)?.isOnline}
                        simpleMode
                        onClickFollow={handleClickFavorite}
                        onClickShowMore={handleClickShowMore}
                    />
                </Box>
            ))}
        </Box>
    </>)
}

interface SaveInfoCardListProps {
    infoCards: IInfoCard[];
}
function SaveInfoCardList(props: SaveInfoCardListProps) {
    const { infoCards } = props;
    const theme = useTheme();

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState<number>(3);

    //responsive pageSize
    React.useLayoutEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < theme.breakpoints.values.md
                ? setPageSize(2) : setPageSize(6);
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    let showList = infoCards?.slice((page - 1) * pageSize, page * pageSize);
    let totalCount = infoCards?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize) || 1;
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleNext = () => setPage(page => page + 1);
    const handlePrevious = () => setPage(page => page - 1);

    //❤️按鈕
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeInfoCardBookMark] = useChangeInfoCardBookMarkMutation();
    function handleClickFavorite(data: IInfoCard) {
        changeInfoCardBookMark({ dataId: data.dataId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveInfoCardIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    //彈窗檢視
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentData, setCurrentData] = React.useState<IInfoCard>();
    function handleClick(data: IInfoCard) {
        setCurrentData(data);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    function handleDisplayChange(changeIndex: number) {
        let currentIndex = infoCards.findIndex(x => x.dataId === currentData.dataId);
        if (infoCards.length > 0) {
            if (currentIndex + changeIndex < 0) {
                setCurrentData(infoCards[totalCount - 1]);
            }
            else if (infoCards.length > currentIndex + changeIndex) {
                setCurrentData(infoCards[currentIndex + changeIndex]);
            }
            else {
                setCurrentData(infoCards[0]);
            }
        }
    }

    //點進攤位
    const history = useHistory();
    function handleClickLinkBooth(event: React.MouseEvent<HTMLElement>, data: IInfoCard) {
        event.preventDefault();
        history.push(`/Booth?id=${data.boothId}`);
    }

    return (<>
        <Box display="flex" alignItems="center" mb={1}>
            <Typography color="text.secondary">
                <Trans i18nKey="userList.totalNum" values={{ totalNum: totalCount }} />
            </Typography>

            <Paper sx={{
                marginInline: 'auto 2px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <IconButton onClick={handlePrevious} disabled={!hasPrevious}>
                    <KeyboardArrowLeft />
                </IconButton>
                <Divider variant="middle" flexItem orientation="vertical" />
                <Box style={{ padding: '6px 14px' }}>
                    <Typography variant="body2">
                        <span>{currentPage}</span>
                        {` / ${totalPages}`}
                    </Typography>
                </Box>
                <Divider variant="middle" flexItem orientation="vertical" />
                <IconButton onClick={handleNext} disabled={!hasNext}>
                    <KeyboardArrowRight />
                </IconButton>
            </Paper>
        </Box>
        <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}>
            {showList.map((card, index) => (
                <BoothInfoCard
                    key={card.dataId}
                    data={card}
                    simpleMode
                    onClick={handleClick}
                    onClickFollow={handleClickFavorite}
                    onClickLinkBooth={handleClickLinkBooth}
                />
            ))}
        </Box>
        <InfoCardDialog
            data={currentData}
            open={open}
            onClose={handleClose}
            handleChangePrev={() => handleDisplayChange(-1)}
            handleChangeNext={() => handleDisplayChange(1)}
        />
    </>)
}