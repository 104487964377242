import { East, Email, Facebook, Favorite, FavoriteBorderRounded, Home, Instagram, Language, Phone, Place } from "@mui/icons-material";
import { Badge, BadgeProps, Box, Button, ButtonBase, Dialog, DialogActions, IconButton, Link, Skeleton, styled, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useProgressiveImage } from "../../Posters/LazyBackground";
import { useUser } from "../../store";
import { Booth, BoothType } from "../class";
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import {  useLazyGetVoteRecordQuery, usePutVoteBoothMutation } from "../services/booth";
import { useHistory } from "react-router";
import { strLength } from "../../Booth/Booth";
type cardOption = { nameKey: string; styleKey: string; };
const cardStyleList: cardOption[] = [
];

export interface BoothCardProps<T extends Booth> {
	data: T;
	isOnline?: boolean;
	simpleMode?: boolean; //是否為小卡模式
	darkMode?: boolean;
	editable?: boolean;
	fixed?: boolean; //固定顯示方式，不做簡介rwd
	className?: string;

	onClickEdit?: (data: T) => void; //非小卡模式時，放入編輯按鈕並觸發此函式
	onClickFollow?: (data: T) => void;
	onClickShowMore?: (event: React.MouseEvent<HTMLElement>, data: T) => void;

	disableFollow?: boolean; //不顯示「❤️」按鈕
	disableShowMore?: boolean; //小卡模式時，不顯示「更多」按鈕

	votes?: number;//得票數
}
export function BoothCard<T extends Booth>(props: BoothCardProps<T>) {
	const { data, isOnline, simpleMode, darkMode, editable, fixed, className, onClickEdit, onClickFollow, onClickShowMore, disableFollow, disableShowMore, votes } = props;
	const { t, i18n } = useTranslation();

	const user = useUser();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const history = useHistory();
	//按鈕功能
	function handleClickEdit() {
		if (data?.boothId) {
			if (onClickEdit) onClickEdit(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickFollow() {
		if (data?.boothId) {
			if (onClickFollow) onClickFollow(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickShowMore(event: React.MouseEvent<HTMLElement>) {
		if (data?.boothId) {
			if (onClickShowMore) onClickShowMore(event, data);
		}
		else console.log(`boothId is null`);
	}
	//投票功能
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [putVoteBooth] = usePutVoteBoothMutation();
	async function handleClickVote() {
		if (data?.boothId) {
			let res = await putVoteBooth(data.boothId).unwrap();
			setOpen(true)
			setMessage(res.message)

		}
		else console.log(`boothId is null`);
	}

	const banner = useProgressiveImage(data?.bannerUrl + "/thumbnail");
	const [logoSize, setLogoSize] = React.useState<{ width: number; height: number }>({ width: 450, height: 150 });
	const container = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			let width = entries[0].target.clientWidth;
			if (width <= 900)
				setLogoSize({ width: 450, height: 150 });
			else
				setLogoSize({ width: 480, height: 160 });
		});
		resizeObserver.observe(container.current)
		return (() => {
			resizeObserver.disconnect();
		})
	}, []);
	const [getVoteRecord, { data: votesData }] = useLazyGetVoteRecordQuery();
	React.useEffect(() => {
		if (data && votes == null) {
			getVoteRecord(data.boothId)
		}
	}, [data]);
	const cataStyle = {
		"minWidth": "88px",
		"padding": "0px 10px",
		"border": "1px solid",
		borderColor: "primary.main",
		"borderRadius": "5px",
		"width": "max-content",
		"color": "primary.main",
		"marginTop": "10px",
		textAlign: 'center',
		marginBottom: '10px',
		minHeight: '1.5rem'
	}

	return (<>
		<Box bgcolor={(!simpleMode && darkMode) ? theme.palette.background.black : theme.palette.background.paper}
			//border={simpleMode && `1.5px solid ${theme.palette.grey[400]}`}
			//boxShadow={theme.shadows[3]}
			borderRadius={simpleMode && 3}
			color={!simpleMode && darkMode && theme.palette.text.white}
			className={className}
			position='relative'
			sx={{ "&:hover .more": { opacity: '1', transition: '.5s' }, cursor: 'pointer', boxShadow: ' 0px 3px 6px rgba(0, 0, 0, 0.2)' }}
			onClick={() => { editable ? handleClickEdit() : history.push(`/Booth?id=${data?.boothId}`) }}

		>
			{/*編輯 or ❤️*/}
			<Box display="flex" justifyContent="flex-end" alignItems="flex-start" height={simpleMode ? "22px" : "30px"}
				pl={simpleMode && 2}
			>
				{editable && data && user ?
					<Button variant="text" onClick={(e) => { e.stopPropagation(); handleClickEdit() }}>{t('booth.button.edit')}</Button>
					: !disableFollow && data && user ?
						<Box bgcolor={(!simpleMode && darkMode) ? theme.palette.background.black : theme.palette.background.paper} zIndex={1} borderRadius="50%">
							<IconButton onClick={(e) => { e.stopPropagation(); handleClickFollow() }}>
								{user.saveBoothIds.includes(data.boothId) ?
									<Favorite style={{ color: '#fa6980' }} /> :
									<FavoriteBorderRounded style={{ color: '#545569' }} />
								}
							</IconButton>
						</Box>
						: null
				}
			</Box>
			<Box ref={container} display="grid" gridTemplateColumns={(!fixed && (isMobile || simpleMode)) ? "1fr" : `${logoSize.width}px 1fr`} gap={2} px={2}>
				{/*LOGO*/}
				<Box >
					<BoothCardBadge isOnline={isOnline} ripple>
						<ButtonBase sx={{ width: "100%", "&:hover": { color: theme.palette.text.secondary } }} {...(simpleMode ? { onClick: handleClickShowMore, href: `/Booth?id=${data?.boothId}` } : { disabled: true })}>
							<Box position="relative" width={"100%"/*isMobile ? "100%" : logoSize.width*/} maxWidth="100%" paddingTop={(logoSize.height / logoSize.width * 100) + "%"}>
								<Box position="absolute" top={0} right={0} bottom={0} left={0} borderRadius='5px' overflow='hidden'>
									{data && data.bannerUrl && data.bannerUrl !== "" && banner ?
										<img src={banner} width="100%" height="100%" style={{ objectFit: "contain" }} />
										: data && (data.bannerUrl === "" || data.bannerUrl === null) ?
											<Box bgcolor={darkMode ? "background.black" : "grey.200"} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" border={darkMode && `2px solid ${theme.palette.primary.main}`}>
												<Typography color={darkMode && "white"}>NO BANNER</Typography>
											</Box>
											: <Skeleton variant="rectangular" width="100%" height="100%" />
									}
								</Box>
							</Box>
						</ButtonBase>
					</BoothCardBadge>
				</Box>
				{/*簡介*/}
				<Box pb={1} px={1}>
					{/*名字 and 攤位位置*/}
					{simpleMode ? <>
						{data ? <>
							<Tooltip title={strLength(data.chName) > 30 && !isMobile ? data.chName : ""}>
								<Typography variant="h5" fontWeight="400" color='#0060CE' sx={{ fontSize: { md: '22px', xs: '1.25rem' }, "maxWidth": { xs: "100%", md: "15em",lg:"22em" }, "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": { xs: "wrap", md: "nowrap" } }}>{data.chName || " "}</Typography>
							</Tooltip>
							<Tooltip title={strLength(data.enName) > 60 && !isMobile ? data.enName : ""}>
								<Typography sx={{ fontSize: { md: '14px', xs: '12px' }, "maxWidth": { xs: "100%", md: "15em", lg: "22em" }, "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": { xs: "wrap", md: data.enName ?"nowrap":"wrap" } }}>{data.enName || " "}</Typography>
							</Tooltip>
							<Typography sx={{
								"position": "absolute",
								"top": "0",
								"padding": "4px 15px",
								"color": "#fff",
								"backgroundColor": "#0096FA",
								"borderRadius": "0 0 5px 5px",
								"left": "33px"
							}}>
								<Home sx={{
									"fontSize": "1.25rem",
									"verticalAlign": "sub"
								}} />

								{data.boothNo}
							</Typography>
							{/*<Typography fontWeight="bold">
								<Box component="span" color="primary.main" mr={1}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{data.boothLocation}
							</Typography>*/}
						</> : <>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
						</>}</> : <>
						{data ? <>
							<Typography variant="subtitle1" mb={0.5}>
								<Box component="span" color="secondary.main" fontWeight="bold" mr={1}>
									{t('booth.content.boothNo')}：
								</Box>
								{data.boothNo}
								{/*<Box display={{ xs: "block", md: "none" }} width="100%" />
								<Box component="span" color="primary.main" fontWeight="bold" ml={{ md: 3 }} mr={0.5}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{data.boothLocation}*/}
							</Typography>
							<Typography variant="h5" fontWeight="bold">{data.chName || " "}</Typography>
							<Typography variant="h6">{data.enName || " "}</Typography>
						</> : <>
							<Typography variant="h5"><Skeleton variant="text" /></Typography>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
						</>}
					</>}

					{/*其他攤位資料*/}
					{data?.type == BoothType.一般攤位 ? <>
						<Box sx={cataStyle}>{data?.category != "" && data?.category != null ? data?.category : "沒有分類"}</Box>
						<Box>所屬團隊：{data?.fieldList?.find(x => x.fieldId == (i18n.language === "ch" ? "chTeamName" : "enTeamName"))?.value}</Box>
					</>
						:
						data?.type == BoothType.廣告贊助 ? <>
							<Box sx={cataStyle}>
								{data.category}
							</Box>
							<Typography variant="body1">
								<Box component="span" fontWeight="bold" color={darkMode && "secondary.main"} mr={1}>
									攤位類型：
								</Box>
								{t("boothList.type.sponsor")}
							</Typography>
						</>
							:
							data?.type == BoothType.服務台 ? <>

								<Box sx={cataStyle}>
									{t('articleList.noBoothIdName')}
								</Box>
								<Box display="flex" gap={1}>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'fb')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
										onClick={(e) => { e.stopPropagation(); }}
									>
										<Facebook />
									</Link>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'ig')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
										onClick={(e) => { e.stopPropagation(); }}
									>
										<Instagram />
									</Link>
								</Box>
							</>
								:
								<></>
					}
					{data?.type == BoothType.一般攤位 ? <Box> 得票數：{votes ?? votesData?.data?.recordList?.length ?? 0} &nbsp;
						{!editable && user && <IconButton onClick={(e) => { e.stopPropagation(); handleClickVote() }} sx={{ padding: '0' }}>
							<HowToVoteIcon style={{ color: '#fa6980' }} />
						</IconButton>}
					</Box> : <br />}
				</Box>
			</Box>
			{/*更多按鈕*/}
			{simpleMode && !disableShowMore &&
				<Box px={2} display="flex" justifyContent="flex-end" color="primary.main" height="14px" className="more" sx={{ opacity: '0' }}>
					<Box alignSelf="flex-end" mb={1} sx={{
						"marginBottom": "30px",
						"padding": "4px 14px",
						"border": "1px solid #B7B7B7",
						"borderRadius": "50px",
						"marginRight": "8px"
					}}>
						<Box

							//	href={`/Booth?id=${data?.boothId}`} underline="hover" onClick={handleClickShowMore}
							sx={{ cursor: "pointer", "color": "#B7B7B7", fontSize: "14px"/* "&:hover": { color: theme.palette.secondary.main }*/ }}
						>
							{`MORE `}<East sx={{ fontSize: ".9rem", verticalAlign: 'middle', paddingBottom: '2px' }} />
						</Box>
						{ /*<Button variant="outlined" href={`/Booth?id=${data?.boothId}`} onClick={handleClickShowMore} sx={{borderRadius: "6px"}}>
						{`${t('user.card.more')} >>`}
					</Button>*/}
					</Box>
				</Box>
			}
		</Box>
		<Dialog open={open}>
			<div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
				<span style={{ fontWeight: 'bold' }}>
					{message}
				</span>
			</div>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button autoFocus onClick={() => { setOpen(false); }}>{t('onlineSeminar.ok')}</Button>
			</DialogActions>
		</Dialog>
	</>);
}

const BoothCardBadge = styled(({ isOnline, ripple, ...otherProps }: BoothCardBadgeProps) =>
	<Badge
		variant="dot"
		invisible={isOnline === null || isOnline === undefined}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		{...otherProps}
	/>
)(({ theme, isOnline, ripple }) => ({
	width: "100%",
	'& .MuiBadge-badge': {
		width: "20px",
		height: "20px",
		border: `3px solid ${theme.palette.background.paper}`,
		borderRadius: "50%",
		backgroundColor: isOnline ? theme.palette.success.light : theme.palette.grey[400],
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: (isOnline === true && ripple === true) && 'ripple 1.2s infinite ease-in-out',
			border: (isOnline === true && ripple === true) && `1px solid ${theme.palette.success.light}`,
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.6)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(1.8)',
			opacity: 0,
		},
	}
}));
interface BoothCardBadgeProps extends BadgeProps {
	isOnline?: boolean;
	ripple?: boolean;
}