import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Stack, styled, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Seminar } from '../../components/class';
import { CardContent, ConfirmButton, important, TextField } from '../../components/responsive-components';
import * as yup from "yup";
import { ResultType } from '../../components/services/utils';
import { CountDownTimer, isNullOrUndefined } from '../../components/Admin/Utils';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';

export function SeminarEditDialog(props: SeminarEditDialogProps) {
    const { open, defaultValue, handleSubmit, handleClose } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            PaperProps={{
                style: {
                    overflow: 'initial'
                }
            }}
            maxWidth={"md"} fullWidth
        >
            <SeminarEdit
                defaultValue={defaultValue}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />
        </Dialog>
    )
}
interface SeminarEditDialogProps {
    open: boolean;
    defaultValue: Seminar;
    handleSubmit: (value: Seminar) => Promise<ResultType<Seminar>>;
    handleClose: () => void;
}

export function SeminarEdit(props: SeminarEditProps) {
    const { defaultValue, handleClose, handleSubmit } = props;
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const schema = yup.object().shape({
        chTitle: yup.string().trim().required('Required'),
        enTitle: yup.string().trim().required('Required'),
        chAbbrev: yup.string().trim().required('Required'),
        enAbbrev: yup.string().trim().required('Required'),
        youtubeLink: yup.lazy((value) => {
            console.log(value)
            if (!isNullOrUndefined(value))
                return yup.string().trim().url().required('Required')
            return yup.string().nullable().notRequired()
        })
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, setValue, reset } = useForm<Seminar>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: defaultValue
    });

    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    async function onSubmit(data: Seminar) {
        setIsLoading(true);
        await handleSubmit(data).then((result) => {
            if (result.isSuccess) {
                CountDownTimer(1, handleClose);
                setSuccess(true);
            } else {
                setError(true);
            }
        }).catch((error) => {
            console.log(error);
            setError(true);
        });
        setIsLoading(false);
    }

    const youtubeLinkValue = watch("youtubeLink");
    const handleYoutubeLinkChange = (url: string) => setValue("youtubeLink", url);

    //更改線上線下
    const [isOnline, setIsOnline] = React.useState<string>((watch().youtubeLink !== null ? 'true' : 'false'));
    async function handleTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        let click: string = (event.target as HTMLInputElement).value;
        switch (click) {
            case 'true':
                if (youtubeLinkValue === null) {
                    setIsOnline(click);
                    handleYoutubeLinkChange("");
                }
                break;
            case 'false':
                if (youtubeLinkValue !== null) {
                    setIsOnline(click);
                    handleYoutubeLinkChange(null);
                }
                break;
            default:
                console.log(`Error Type`);
        }
    };

    return (
        <Box component="form" onSubmit={handleOnSubmit(onSubmit)} style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <CardContent>
                <Typography align='center' sx={{ fontSize: '18px' }}>
                    {t('seminar.form.newStage')}
                </Typography>
                <Box className="d-flex flex-row flex-wrap justify-content-end" style={{ position: 'absolute', top: '24px', right: '24px' }}>
                    <Button onClick={() => handleClose()}>{t('seminar.form.back')}</Button>
                </Box>
            </CardContent>
            <CardContent style={{ flex: 1, overflow: 'auto' }}>
                <Stack spacing={1}>
                    <Box flex='1' minWidth='300px' className="d-flex flex-row flex-wrap align-content-start">
                        {/*中文標題*/}
                        <Box flex='1 0 50%' className="d-flex flex-column">
                            <div id='chTitle-field' style={{ display: 'flex', marginLeft: '-6px' }}>
                                {important}
                                <TextField
                                    variant='standard'
                                    label={t('seminar.form.chTitle.placeholder')}
                                    error={!!errors.chTitle}
                                    {...register("chTitle")}
                                    disabled={isLoading || success}
                                    style={{ width: '100%' }}
                                    InputProps={{ style: { fontSize: '22px' } }}
                                />
                            </div>
                            <div style={{ minHeight: '24px' }}>
                                <ErrorMessage
                                    errors={errors}
                                    name={'chTitle'}
                                    render={({ message }) =>
                                        <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                            {`${t(message)}`}
                                        </Typography>
                                    }
                                />
                            </div>
                        </Box>

                        {/*英文標題*/}
                        <Box flex='0 0 50%' className="d-flex flex-column"
                            paddingLeft={'18px'}>
                            <div id='enTitle-field' style={{ display: 'flex', marginLeft: '-6px' }}>
                                {important}
                                <TextField
                                    variant='standard'
                                    label={t('seminar.form.enTitle.placeholder')}
                                    error={!!errors.enTitle}
                                    {...register("enTitle")}
                                    disabled={isLoading || success}
                                    style={{ width: '100%' }}
                                    InputProps={{ style: { fontSize: '22px' } }}
                                />
                            </div>
                            <div style={{ minHeight: '24px' }}>
                                <ErrorMessage
                                    errors={errors}
                                    name={'enTitle'}
                                    render={({ message }) =>
                                        <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                            {`${t(message)}`}
                                        </Typography>
                                    }
                                />
                            </div>
                        </Box>
                    </Box>

                    <Box flex='1' minWidth='300px' className="d-flex flex-row flex-wrap align-content-start">
                        {/*中文簡稱*/}
                        <Box flex='1 0 50%' className="d-flex flex-column">
                            <div id='chAbbrev-field' style={{ display: 'flex', marginLeft: '-6px' }}>
                                {important}
                                <TextField
                                    variant='standard'
                                    label={t('seminar.form.chAbbrev.placeholder')}
                                    error={!!errors.chAbbrev}
                                    {...register("chAbbrev")}
                                    disabled={isLoading || success}
                                    style={{ width: '100%' }}
                                    InputProps={{ style: { fontSize: '22px' } }}
                                />
                            </div>
                            <div style={{ minHeight: '24px' }}>
                                <ErrorMessage
                                    errors={errors}
                                    name={'chAbbrev'}
                                    render={({ message }) =>
                                        <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                            {`${t(message)}`}
                                        </Typography>
                                    }
                                />
                            </div>
                        </Box>

                        {/*英文簡稱*/}
                        <Box flex='0 0 50%' className="d-flex flex-column"
                            paddingLeft={'18px'}>
                            <div id='enAbbrev-field' style={{ display: 'flex', marginLeft: '-6px' }}>
                                {important}
                                <TextField
                                    variant='standard'
                                    label={t('seminar.form.enAbbrev.placeholder')}
                                    error={!!errors.enAbbrev}
                                    {...register("enAbbrev")}
                                    disabled={isLoading || success}
                                    style={{ width: '100%' }}
                                    InputProps={{ style: { fontSize: '22px' } }}
                                />
                            </div>
                            <div style={{ minHeight: '24px' }}>
                                <ErrorMessage
                                    errors={errors}
                                    name={'enAbbrev'}
                                    render={({ message }) =>
                                        <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                            {`${t(message)}`}
                                        </Typography>
                                    }
                                />
                            </div>
                        </Box>
                    </Box>

                    <FormControl component="fieldset" disabled={isLoading || success}>
                        <RadioGroup
                            row
                            value={isOnline}
                            onChange={handleTypeChange}
                        >
                            <FormControlLabel value={'false'} control={<Radio />} label={`${t('seminar.editList.onsite')}`} />
                            <FormControlLabel value={'true'} control={<Radio />} label={`${t('seminar.editList.online')}`} />
                        </RadioGroup>
                    </FormControl>
                    {isOnline === 'true' && <>
                        {/*連結*/}
                        <div id='youtubeLink-field' style={{ display: 'flex', marginLeft: '-6px' }}>
                            <TextField
                                variant='standard'
                                label={t('seminar.form.youtubeLink.placeholder')}
                                error={!!errors.youtubeLink}
                                {...register("youtubeLink")}
                                disabled={isLoading || success}
                                style={{ width: '100%' }}
                                InputProps={{ style: { fontSize: '22px' } }}
                            />
                        </div>
                        <div style={{ minHeight: '24px' }}>
                            <ErrorMessage
                                errors={errors}
                                name={'youtubeLink'}
                                render={({ message }) =>
                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                        {`${t(message)}`}
                                    </Typography>
                                }
                            />
                        </div>
                    </>}
                </Stack>
            </CardContent>
            <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ConfirmButton type='submit' loading={isLoading} disabled={success} >
                    {success ?
                        <Typography color='green'>
                            {t('seminar.form.success')}
                        </Typography> :
                        <Typography>
                            {t('seminar.form.submit')}
                        </Typography>
                    }
                </ConfirmButton>
                {error &&
                    <Typography color='error'>
                        {t('seminar.form.error')}
                    </Typography>
                }
            </CardContent>
        </Box>
    )
}
interface SeminarEditProps {
    defaultValue: Seminar;
    handleSubmit: (value: Seminar) => Promise<ResultType<Seminar>>;
    handleClose: () => void;
}