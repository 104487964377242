export const config: CustomFieldConfig = {
    editFieldExcluded: {
        user: [],
        booth: ['edm', 'poster', 'video', 'gameIntro', 'teamIntro', 'companyIntro', 'eventIntro'],
    },
    watchFieldExcluded: {
        user: [],
        booth: ['edm', 'poster', 'video', 'gameIntro', 'teamIntro', 'companyIntro', 'eventIntro'],
    }
};

export interface CustomFieldConfig {
    editFieldExcluded: {
        user: string[];
        booth: string[];
    },
    watchFieldExcluded: {
        user: string[];
        booth: string[];
    }
}