import * as React from "react";
import { Avatar, Box, Button, Link, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from "@mui/material";
import { BroadcastTextObject, NotAnswerTextObject, NoticeMessageContent, NoticeTextCode, QuestStatusTextObject, UpcomingEventTextObject } from "../../class";
import { Circle } from "@mui/icons-material";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { useLazyGetPathQuery } from "../../services/imageFile";
import { useGetBoothQandAQuery, useGetBoothQuery } from "../../services/booth";
import { logEvent } from "../../Analytics";

interface NotificationMessageProps {
    message: NoticeMessageContent;
}

export function NotificationMessage(props: NotificationMessageProps) {
    const { message } = props;
    const { t, i18n } = useTranslation();

    const [getPath, { avatarUrl }] = useLazyGetPathQuery({
        selectFromResult: ({ data }) => ({ avatarUrl: data?.data }),
    });

    const [eventId, setEventId] = React.useState<string>();
    /*const { exchangeCard } = useGetExchangeCardQuery(exchangeCardId, {
        selectFromResult: ({ data }) => ({ exchangeCard: data?.data })
    });*/
    const [questId, setQuestId] = React.useState<string>();
    const { qandA } = useGetBoothQandAQuery(questId, {
        selectFromResult: ({ data }) => ({ qandA: data?.data })
    });
    const { booth } = useGetBoothQuery({ boothId: qandA?.boothId }, {
        selectFromResult: ({ data }) => ({ booth: data?.data })
    });
    React.useEffect(() => {
        if (message.textCode === NoticeTextCode.廣播通知) {
            getPath({
                userId: (message.textObject as BroadcastTextObject).senderId
            });
        }
        else if (message.textCode === NoticeTextCode.活動通知) {
            setEventId((message.textObject as UpcomingEventTextObject).agendaId);
        }
        else if (message.textCode === NoticeTextCode.活動更改通知) {
            setEventId((message.textObject as UpcomingEventTextObject).agendaId);
        }
        else if (message.textCode === NoticeTextCode.問題狀態改變通知) {
            setQuestId((message.textObject as QuestStatusTextObject).questId);
        }
    }, [message]);

    function handleLinkOnClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) {
        logEvent('Notification', `Message`, `${link}`);
    }

    switch (message.textCode) {
        case NoticeTextCode.廣播通知:
            let broadcastTextObject = (message.textObject as BroadcastTextObject);
            return (
                <NotificationMessageContent avatarUrl={avatarUrl} timestamp={message.createTime} hasBeenSeen={message.hasBeenSeen}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.conferenceNotice')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">{broadcastTextObject.content}</Typography>
                    {broadcastTextObject.link &&
                        <Link variant="body2" color="primary.main" fontWeight="inherit" underline="none" href={broadcastTextObject.link} rel="noopener" target="_blank"
                        onClick={(event) => handleLinkOnClick(event, broadcastTextObject.link)}>
                        {t('notification.bell.link')}
                        </Link>
                    }
                </NotificationMessageContent>
            )
        case NoticeTextCode.活動通知:
            let upcomingEventTextObject = (message.textObject as UpcomingEventTextObject);
            return (
                <NotificationMessageContent avatarUrl={avatarUrl} timestamp={message.createTime} hasBeenSeen={message.hasBeenSeen}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.upcomingAgenda')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.startIn"
                            values={{
                                agenda: upcomingEventTextObject.name
                            }}
                        />
                    </Typography>
                </NotificationMessageContent>
            )
        case NoticeTextCode.活動更改通知:
            let changeEventTextObject = (message.textObject as UpcomingEventTextObject);
            return (
                <NotificationMessageContent avatarUrl={avatarUrl} timestamp={message.createTime} hasBeenSeen={message.hasBeenSeen}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.changeNotice')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.changeTime"
                            values={{
                                agenda: changeEventTextObject.name,
                                location: changeEventTextObject.location,
                                startTime: format(new Date(changeEventTextObject.startTime), "MM/dd(eeeee) kk:mm"),
                                endTime: format(new Date(changeEventTextObject.endTime), " kk:mm")
                            }}
                        />
                    </Typography>
                </NotificationMessageContent>
            )
        case NoticeTextCode.問題狀態改變通知:
            return (
                <NotificationMessageContent avatarUrl={avatarUrl} timestamp={message.createTime} hasBeenSeen={message.hasBeenSeen}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.Q&A')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.reply"
                            values={{
                                name: booth?.chName
                            }}
                        />
                    </Typography>
                </NotificationMessageContent>
            )
        case NoticeTextCode.問答版待回覆通知:
            let notAnswerTextObject = (message.textObject as NotAnswerTextObject);
            return (
                <NotificationMessageContent avatarUrl="/images/Avatar_1.png" timestamp={message.createTime} hasBeenSeen={message.hasBeenSeen}>
                    <Typography color="inherit" fontWeight="inherit">{t('notification.bell.Q&A')}</Typography>
                    <Typography variant="body2" color="inherit" fontWeight="inherit">
                        <Trans i18nKey="notification.bell.wait_to_reply"
                            values={{
                                count: notAnswerTextObject.questIds.length
                            }}
                        />
                    </Typography>
                </NotificationMessageContent>
            )
    }
}

interface NotificationMessageContentProps {
    avatarUrl?: string;
    avatarIcon?: JSX.Element;
    timestamp: string;
    hasBeenSeen: boolean;
    children?: React.ReactNode;
}

function NotificationMessageContent(props: NotificationMessageContentProps) {
    const { avatarUrl, avatarIcon, timestamp, hasBeenSeen, children } = props;
    const { t, i18n } = useTranslation();

    return (
        <ListItem disablePadding>
            <ListItemButton disableGutters disableRipple disableTouchRipple alignItems="flex-start" sx={{ cursor: "unset" }}>
                { /*頭像*/}
                <ListItemAvatar sx={{ display: "flex", justifyContent: "center", minWidth: "58px", paddingInline: "16px 12px" }}>
                    {avatarUrl ?
                        <Avatar src={avatarUrl} sx={{ width: "38px", height: "38px" }} /> :
                        avatarIcon ?
                            <Avatar sx={{ width: "38px", height: "38px", backgroundColor: "primary.main" }}> {avatarIcon}</Avatar> :
                            <Skeleton variant="circular" width="38px" height="38px" />
                    }
                </ListItemAvatar>
                <ListItemText primary={children} secondary={t('notification.bell.postTime') + format(new Date(timestamp), "yyyy-MM-dd kk:mm")}
                    primaryTypographyProps={{ mb: "inherit", overflow: "hidden", textOverflow: "ellipsis", fontWeight: hasBeenSeen ? "normal" : "bold", color: hasBeenSeen ? "text.secondary" : "text.primary" }}
                    secondaryTypographyProps={{}}
                />
                { /*未讀標記*/}
                {!hasBeenSeen &&
                    <ListItemIcon sx={{ minWidth: "22px", color: "error.light", fontSize: "12px" }}>
                        <Circle color="inherit" fontSize="inherit" />
                    </ListItemIcon>
                }
            </ListItemButton>
        </ListItem>
    )
}