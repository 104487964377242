import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ChannelMessageContent, DialogContent, MessageContent, NoticeMessageContent, CardInfo } from '../class'
import { ResultType } from './utils';

export interface Contact {
    chatId: string;
    isGroup: boolean;
    contactInfo: ContactInfo;
    groupInfo: object;
    lastMessage: MessageContent;
}
export interface ContactInfo extends CardInfo {
    hasBusinessCard: boolean;
    isOnline: boolean;
}
export interface ExchangeCard {
    senderId: string;
    receiverId: string;
    message: string;
    status: ExchangeCardStatus;
}
export enum ExchangeCardStatus {
    待回應 = 0,
    已接受,
    已拒絕
}
export interface Appointment {
    senderId: string;
    receiverId: string;
    appointedTime: string;
    message: string;
    status: AppointmentStatus;
}
export enum AppointmentStatus {
    待回應 = 0,
    已接受,
    已拒絕
}
export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/chat' }),
    endpoints: (builder) => ({
        getContacts: builder.query<ResultType<Contact[]>, void>({
            query: () => `getContacts`,
        }),
        getChats: builder.mutation<ResultType<DialogContent[]>, { chatIds: string[] }>({
            query: ({ ...patch }) => ({
                url: `getChats`,
                method: 'POST',
                body: patch
            }),
        }),
        getFullChats: builder.query<ResultType<DialogContent[]>, void>({
            query: () => `getFullChats`,
        }),
        getMessages: builder.query<ResultType<MessageContent[]>, string>({
            query: (chatId) => `getMessages/${chatId}`,
        }),
        getNotifications: builder.query<ResultType<NoticeMessageContent[]>, void>({
            query: () => `getNotifications`,
        }),
        //交換名片
        getIsExchanged: builder.query<ResultType<boolean>, string>({
            query: (receiverId) => `getIsExchanged/${receiverId}`,
        }),
        exchangeCard: builder.mutation<ResultType<null>, { chatId: string, message: string }>({
            query: ({ ...patch }) => ({
                url: `exchangeCard`,
                method: 'POST',
                body: patch
            }),
        }),
        getExchangeCard: builder.query<ResultType<ExchangeCard>, string>({
            query: (actionId) => `exchangeCard/${actionId}`,
        }),
        getExchangeCardStatus: builder.query<ResultType<ExchangeCardStatus>, string>({
            query: (actionId) => `exchangeCard/${actionId}/status`,
        }),
        changeExchangeCardStatus: builder.mutation<ResultType<null>, { actionId: string, chatId: string, isAccept: boolean }>({
            query: ({ actionId, ...patch }) => ({
                url: `exchangeCard/${actionId}/status`,
                method: 'POST',
                body: patch
            }),
        }),
        //預約面談
        applyAppointment: builder.mutation<ResultType<null>, { chatId: string, appointedTime: Date, message: string }>({
            query: ({ ...patch }) => ({
                url: `appointment`,
                method: 'POST',
                body: patch
            }),
        }),
        getAppointment: builder.query<ResultType<Appointment>, string>({
            query: (actionId) => `appointment/${actionId}`,
        }),
        getAppointmentStatus: builder.query<ResultType<AppointmentStatus>, string>({
            query: (actionId) => `appointment/${actionId}/status`,
        }),
        changeAppointmentStatus: builder.mutation<ResultType<null>, { actionId: string, chatId: string, isAccept: boolean, option?: string, other?: string }>({
            query: ({ actionId, ...patch }) => ({
                url: `appointment/${actionId}/status`,
                method: 'POST',
                body: patch
            }),
        }),
        getChannelMessages: builder.query<ResultType<ChannelMessageContent[]>, string>({
            query: (channelId) => `getChannelMessages/${channelId}`,
        }),
    }),
})

export const { useGetContactsQuery } = chatApi;
export const { useGetChatsMutation } = chatApi;
export const { useGetFullChatsQuery } = chatApi;
export const { useGetMessagesQuery } = chatApi;
export const { useGetNotificationsQuery } = chatApi;
export const { useLazyGetNotificationsQuery } = chatApi;
export const { useGetIsExchangedQuery } = chatApi;
export const { useExchangeCardMutation } = chatApi;
export const { useGetExchangeCardQuery } = chatApi;
export const { useLazyGetExchangeCardQuery } = chatApi;
export const { useGetExchangeCardStatusQuery } = chatApi;
export const { useChangeExchangeCardStatusMutation } = chatApi;
export const { useApplyAppointmentMutation } = chatApi;
export const { useGetAppointmentQuery } = chatApi;
export const { useLazyGetAppointmentQuery } = chatApi;
export const { useGetAppointmentStatusQuery } = chatApi;
export const { useChangeAppointmentStatusMutation } = chatApi;
export const { useGetChannelMessagesQuery } = chatApi;
export const { useLazyGetChannelMessagesQuery } = chatApi;