import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Backdrop, Box, CircularProgress, Container, Divider, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IInfoCard } from '../components/class';
import { InfoCard as BoothInfoCard } from '../components/InfoCard/InfoCard';
import { InfoCardDialog } from '../components/InfoCard/InfoCardDialog';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { SearchBar } from '../components/responsive-components';
import { useGetAllBoothNamesQuery, useGetAllInfoCardQuery, useLazyGetAllInfoCardQuery } from '../components/services/booth';
import { useChangeInfoCardBookMarkMutation } from '../components/services/user';
import { useDispatchWithType, useSystemConnection, useUser } from '../store';
import { updateUser } from "../store/rootReducer";

export default function InfoCardListPage() {
	const { t, i18n } = useTranslation();

	//換連線位置
	const systemConnection = useSystemConnection();
	const hubRef = React.useRef<SystemControlHub>();
	React.useEffect(() => {
		if (systemConnection != null) {
			hubRef.current = new SystemControlHub(systemConnection);
			hubRef.current.send.ChangeLocation({ location: 3, refId: "infoCardList" });
		}
	}, [systemConnection]);

	//下載資料
	const [getAllInfoCards, { isLoading }] = useLazyGetAllInfoCardQuery();
	const [infoCards, setInfoCards] = React.useState<IInfoCard[]>([]);
	React.useEffect(() => { init() }, []);
	async function init() {
		try {
			let result = await getAllInfoCards().unwrap();
			if (result.isSuccess)
				setInfoCards(result.data);
		} catch {
			console.error("發生問題");
		}
	}

	const { boothList = [], isSuccess } = useGetAllBoothNamesQuery(null, {
		selectFromResult: ({ data, isSuccess }) => ({ boothList: data?.data ?? [], isSuccess }),
		refetchOnMountOrArgChange: true
	});

	//篩選
	const [filterBoothId, setFilterBoothId] = React.useState<string>("");
	const [filterText, setFilterText] = React.useState<string>("");
	const [filteredList, setFilteredList] = React.useState<IInfoCard[]>([]);
	React.useEffect(() => {
		setFilteredList(infoCards.filter(isPass));
	}, [infoCards, filterBoothId, filterText]);

	const handleChangeBoothId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterBoothId(event.target.value);
	};
	const handleChangeText = (value: string) => {
		if (filterText !== value) {
			setFilterText(value);
		}
	}

	function isPass(data: IInfoCard) {
		if (filterBoothId && filterBoothId !== "")
			if (data.boothId !== filterBoothId) return false;

		if (filterText && filterText !== "" && data.title && !data.title.includes(filterText))
			return false;

		return true;
	}

	const [page, setPage] = React.useState(1); //頁碼，起始值為1
	const [pageSize, setPageSize] = React.useState<number>(12); //一頁顯示之數量

	let showList = filteredList?.slice((page - 1) * pageSize, page * pageSize);
	let totalCount = filteredList?.length ?? 0;
	let currentPage = page ?? 1;
	let totalPages = Math.ceil(totalCount / pageSize) || 1;
	let hasNext = currentPage < totalPages;
	let hasPrevious = currentPage > 1;

	const handleNext = () => setPage(page => page + 1);
	const handlePrevious = () => setPage(page => page - 1);

	//顯示視窗
	const [currentData, setCurrentData] = React.useState<IInfoCard>(null);
	const [open, setOpen] = React.useState(false);
	const handleClick = (data: IInfoCard) => {
		setCurrentData(data);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	function handleDisplayChange(changeIndex: number) {
		let currentIndex = filteredList.findIndex(x => x.dataId === currentData.dataId);
		if (filteredList.length > 0) {
			if (currentIndex + changeIndex < 0) {
				setCurrentData(filteredList[totalCount - 1]);
			}
			else if (filteredList.length > currentIndex + changeIndex) {
				setCurrentData(filteredList[currentIndex + changeIndex]);
			}
			else {
				setCurrentData(filteredList[0]);
			}
		}
	}

	//❤️按鈕
	const user = useUser();
	const dispatch = useDispatchWithType();
	const [changeInfoCardBookMark] = useChangeInfoCardBookMarkMutation();
	function handleClickFavorite(data: IInfoCard) {
		changeInfoCardBookMark({ dataId: data.dataId }).unwrap()
			.then((result) => {
				if (result.isSuccess) {
					dispatch(updateUser({ ...user, saveInfoCardIds: result.data }));
				}
			}).catch((error) => {
				console.log(error);
			});
	};

	//點進攤位
	const history = useHistory();
	function handleClickLinkBooth(event: React.MouseEvent<HTMLElement>, data: IInfoCard) {
		event.preventDefault();
		history.push(`/Booth?id=${data.boothId}`);
	}

	return (<>
		<PageTitleBar titleTransKey={'title.infoCardList'}>
			<Container>
				<Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr 400px" }} gap={1} py={1} mb={2}>
					<TextField
						select
						value={filterBoothId}
						onChange={handleChangeBoothId}
						fullWidth
						SelectProps={{
							displayEmpty: true,
							MenuProps: {
								PaperProps: {
									style: { maxHeight: "400px" }
								}
							}
						}}
					>
						<MenuItem value="">
							{t('infoCardList.select.all')}
						</MenuItem>
						{boothList?.map((option) => (
							<MenuItem key={option.boothId} value={option.boothId}>
								{i18n.language == "ch" ? option.chName : option.enName}
							</MenuItem>
						))}
						{!isSuccess &&
							<MenuItem value={null}>
								{t('infoCardList.select.error')}
							</MenuItem>
						}
					</TextField>

					<SearchBar
						placeholder={t("infoCardList.search.placeholder")}
						//dataSource={["1", "2"]}
						onChange={(event) => handleChangeText(event.target.value)}
						onCancelSearch={() => handleChangeText("")}
						//onRequestSearch={handleChangeText}
						sx={{ xs: { width: "100%" }, md: { width: "400px" }, my: "auto", boxShadow: "none", border: "1px solid", borderColor: "rgba(0, 0, 0, 0.23)", p: "3px", "&:hover": { borderColor: "inherit" } }}
					/>
				</Box>

				<Box display="flex" alignItems="center">
					<Typography>
						<Trans i18nKey="infoCardList.totalNum" values={{ totalNum: totalCount }} />
					</Typography>

					<Paper style={{
						margin: '12px 2px 12px auto',
						width: 'fit-content',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end'
					}}>
						<IconButton onClick={handlePrevious} disabled={!hasPrevious}>
							<KeyboardArrowLeft />
						</IconButton>
						<Divider variant="middle" flexItem orientation="vertical" />
						<Box style={{ padding: '6px 14px' }}>
							<Typography variant="body2">
								<span>{currentPage}</span>
								{` / ${totalPages}`}
							</Typography>
						</Box>
						<Divider variant="middle" flexItem orientation="vertical" />
						<IconButton onClick={handleNext} disabled={!hasNext}>
							<KeyboardArrowRight />
						</IconButton>
					</Paper>
				</Box>

				<Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}>
					{showList.map((card, index) => (
						<BoothInfoCard
							key={card.dataId}
							data={card}
							simpleMode
							onClick={handleClick}
							onClickFollow={handleClickFavorite}
							onClickLinkBooth={handleClickLinkBooth}
						/>
					))}
				</Box>

				{showList.length > 6 &&
					<Box display="flex" alignItems="center">
						<Typography>
							<Trans i18nKey="infoCardList.totalNum" values={{ totalNum: totalCount }} />
						</Typography>

						<Paper style={{
							margin: '12px 2px 12px auto',
							width: 'fit-content',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end'
						}}>
							<IconButton onClick={handlePrevious} disabled={!hasPrevious}>
								<KeyboardArrowLeft />
							</IconButton>
							<Divider variant="middle" flexItem orientation="vertical" />
							<Box style={{ padding: '6px 14px' }}>
								<Typography variant="body2">
									<span>{currentPage}</span>
									{` / ${totalPages}`}
								</Typography>
							</Box>
							<Divider variant="middle" flexItem orientation="vertical" />
							<IconButton onClick={handleNext} disabled={!hasNext}>
								<KeyboardArrowRight />
							</IconButton>
						</Paper>
					</Box>
				}

				<InfoCardDialog
					data={currentData}
					open={open}
					onClose={handleClose}
					handleChangePrev={() => handleDisplayChange(-1)}
					handleChangeNext={() => handleDisplayChange(1)}
				/>
			</Container>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</PageTitleBar>
	</>)
}