import { Box, Button, Dialog, DialogActions, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { useSystemConnection } from '../store';

interface ClearChannelButtonProps {
    seminarId: string;
}
export default function ClearChannelButton(props: ClearChannelButtonProps) {
    const { seminarId } = props;
    const { t } = useTranslation();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
        }
    }, [systemConnection]);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    function handleClick() {
        if (hubRef.current && seminarId)
            hubRef.current.invoke.ClearChannel(seminarId)
                .then((result) => {
                    if (result.isSuccess) {
                        setMessage("Success !");
                        setOpen(true);
                    } else {
                        setMessage(result.message);
                        setOpen(true);
                    }
                });
    }

    if (!seminarId) {
        return null;
    }
    return (
        <>
            <Button variant="contained" sx={{ marginTop: 1.5 }} onClick={handleClick}>
                清空聊天室
            </Button>
            <Box position="absolute" top="auto" right="0" bottom="-32px" left="0" px={2} pt="10px" pb={1}
                sx={{ border: "1px solid rgba(0,0,0,0.12)", borderBottom: "unset", background: "#FFDA48" }}
            >
                <Typography variant="body2" lineHeight="1">歡迎來到直播間！若非必要，建議勿清空聊天室。</Typography>
            </Box>
            <Dialog open={open}>
                <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <span style={{ fontWeight: 'bold' }}>
                        {message}
                    </span>
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus onClick={() => { setOpen(false); }}>{"好的"}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}