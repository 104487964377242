import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EditUserData, EditUserDataRef, UserEditType } from '../components/Manage/UserDataEdit';
import { CardInfo, FieldPrivacy } from '../components/class';
import { useEditUserDataMutation } from '../components/services/user';
import { useDispatchWithType, useUser, useUserFieldNameList } from '../store';
import { updateUser } from '../store/rootReducer';
import { cloneDeep } from 'lodash';

const UserEditStepper = React.forwardRef(
    function UserEditStepper(props: UserEditStepperProps, ref) {
        const { editType } = props;
        const { t, i18n } = useTranslation();

        const user = useUser();
        const userFieldNameList = useUserFieldNameList();
        const [userData, setUserData] = React.useState<CardInfo | null>(null);
        function handleDataChange(value: CardInfo) {
            let tempUserData = value;

            //客製化欄位
            let tempList = [...tempUserData.infoFields];
            for (let index = 0; index < userFieldNameList.length; index++) {
                if (!tempList.find(x => x.fieldId === userFieldNameList[index].fieldId) &&
                    userFieldNameList[index].cardTypeList.includes(tempUserData.type)) {
                    tempList.splice(index, 0, { fieldId: userFieldNameList[index].fieldId, value: '', privacy: FieldPrivacy.公開 });
                }
            }
            tempUserData = {
                ...tempUserData,
                infoFields: [...tempList]
            };

            setUserData(cloneDeep(tempUserData));
        }
        const [first, setFirst] = React.useState<boolean>(true);
        React.useEffect(() => {
            if (user && first) {
                setFirst(false);
                handleDataChange({ ...user });
            }
        }, [user])

        const dispatch = useDispatchWithType();
        const [editUser, { isLoading: isEditLoading }] = useEditUserDataMutation();
        async function handleClose(value?: CardInfo) {
            let tempResult = false;
            if (value && user) {
                let tempData = {
                    ...value,
                    userId: user.userId,
                    boothIds: user.boothIds
                };
                await editUser(tempData).unwrap().then((result) => {
                    if (result.isSuccess) {
                        //這裡的infoFields一定要深複製，不然會被redux鎖起來
                        dispatch(updateUser({ ...user, ...tempData, infoFields: [...tempData.infoFields] }));
                        tempResult = true;
                    }
                    else {
                        console.log(result.message);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
            else {
                tempResult = true;
            }
            return tempResult;
        }

        return (
            <div>
                {(userData && user) && <>
                    <EditUserData
                        editType={editType}
                        userId={user.userId}
                        data={userData}
                        onClose={handleClose}
                        isLoading={isEditLoading}
                        ref={ref}
                    />
                </>}
            </div>
        );
    })
interface UserEditStepperProps {
    editType: UserEditType;
    ref: React.RefObject<EditUserDataRef>;
}
export default UserEditStepper;