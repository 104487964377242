import * as React from "react";
import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, IconButton, styled, Toolbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import 青年局Logo from "./images/04 青年事務局LOGO1.png";
import { SubTitle } from "../components/responsive-components/Typography";

export default function Event() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (<>
        <Toolbar sx={{
            backgroundImage: `radial-gradient(ellipse at 10% 100%, ${theme.palette.background.black} 0%, ${theme.palette.background.default}00 80%),
                    radial-gradient(circle at 10% 0, ${theme.palette.primary.main}80 0%, ${theme.palette.primary.main}00 100%),
                    radial-gradient(circle at 93.3% 5%, ${theme.palette.secondary.main}DD 0%, ${theme.palette.secondary.main}00 50%)`,
            justifyContent: "center"
        }}>
            <SubTitle icon='circle' text={t("title.stage")} />
        </Toolbar>
        <Box bgcolor={theme.palette.background.default} pt={{ xs: 2, sm: 3, md: 7 }} pb={{ xs: 3.75, sm: 4.75, md: 8.75 }} mb={2}>
            <Container >
                <Box display="grid" gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)", md: "repeat(5,1fr)" }} rowGap={4.75}>
                    <EventInfo />
                </Box>
            </Container>
        </Box>
        <Toolbar sx={{ justifyContent: "center" }}>
            <SubTitle icon='circle' text={t("title.stage_schedule")} />
        </Toolbar>
        <Container>
            <Box bgcolor={theme.palette.background.default} p={3}>
                <img src={"./images/2023 TSGDF 議程D1.png"} width="100%" style={{ objectFit: "contain"}} />
                <img src={"./images/2023 TSGDF 議程D2.png"} width="100%" style={{ objectFit: "contain"}} />
            </Box>
        </Container>
        <br/>
    </>)
}

const EventInfoButton = styled(Button)(({ theme }) => ({
    transform: "skewX(-20deg)",
    backgroundColor: theme.palette.warning.light,
    borderRadius: 0,
    height: "60px",
    maxWidth: "150px",
    "& > p": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `17px 14px ${theme.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(10.2px, 8.4px)",
        backgroundColor: theme.palette.warning.light,
        boxShadow: `6.8px 5.6px ${theme.palette.primary.main}`,
    }
}));
function EventInfo() {
    const { t } = useTranslation();
    const buttons = [
        { nameKey: "event.title.0", content: <專題演講 onClose={handleClose} /> },
       { nameKey: "event.title.1", content: <開發者沙龍 onClose={handleClose} /> },
        { nameKey: "event.title.2", content: <產學座談會 onClose={handleClose} /> },
        { nameKey: "event.title.3", content: <遊戲發想體驗課程 onClose={handleClose} /> },
     //   { nameKey: "event.title.4", content: <活動事件 onClose={handleClose} /> },
        { nameKey: "event.title.5", content: <其他活動 onClose={handleClose} /> },
    ];

    const [open, setOpen] = React.useState<boolean>(false);
    const [currentContent, setCurrentContent] = React.useState<React.ReactNode>();
    function handleOpenDialog(item) {
        setOpen(true);
        setCurrentContent(item.content);
    }
    function handleClose() {
        setOpen(false);
    }
    const Button = EventInfoButton;
    return (<>{buttons.map((item) => (
        <Box key={item.nameKey} display="flex" justifyContent="center">
            <Button sx={{ flexGrow: 1 }} onClick={() => handleOpenDialog(item)}>
                <Typography>{t(item.nameKey)}</Typography>
            </Button>
        </Box>
    ))}
        <Dialog open={open} onClose={handleClose} PaperProps={{
            sx: {
                "&::-webkit-scrollbar": {
                    width: 6,
                },
                "&::-webkit-scrollbar-thumb": {
                    borderRadius: 5,
                    background: (theme) => theme.palette.primary.main,
                }
            }
        }} maxWidth="md" fullWidth>
            {currentContent}
        </Dialog>
    </>
    );
}

const GoToStageButton = styled(Link)(({ theme }) => ({
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    transform: "skewX(-20deg)",
    backgroundColor: theme.palette.warning.light,
    borderRadius: 0,
    height: "32px",
    maxWidth: "calc(50vw - 5px)",
    padding: "6px 8px",
    [theme.breakpoints.up('sm')]: {
        maxWidth: "150px",
        padding: "6px 10px",
    },
    "& > p": {
        transform: "skewX(20deg)",
        wordBreak: "keep-all",
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    boxShadow: `12px 9px ${theme.palette.primary.main}`,
    transition: theme.transitions.create(['background-color', 'transform', 'box-shadow']),
    "&:hover": {
        transform: "skewX(-20deg) translate(10.2px, 8.4px)",
        backgroundColor: theme.palette.warning.light,
        boxShadow: `6.8px 5.6px ${theme.palette.primary.main}`,
        textDecoration: "none"
    }
}));

const LearnMoreLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    "&:hover": { color: theme.palette.secondary.main }
}));

const LearnMoreHyperlink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
    "&:hover": { color: theme.palette.secondary.main }
}));

function 專題演講(props: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <Box color="appBar.main">
             <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        專題演講
                    </Typography>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography fontWeight="bold">
                    預計辦理兩場專題演講。圍繞著遊戲開發為主軸，找到學生所需的資源，並且藉由講座提供學生未來就業的方向與思路。
                </Typography>
            </DialogTitle>
            <DialogContent>
            
                <br />
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" px={1}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題一：國外留學分享
                    </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                    <Avatar src="images/陳裔軒.jpg" sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                    <Box>
                        <Box display={{ xs: "block", sm: "flex" }} alignItems="center" gap={2}>
                            <Typography variant="h6" fontWeight="bold">
                                講師：陳裔軒
                                <Typography fontWeight="bold"></Typography>
                            </Typography>
                            <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                DigiPen MSCS在學生
                            </Typography>
                        </Box>
                        <Typography variant="h6">
                            曾在大學時期參與中央創遊以及TSGDF
                            ，開發文字冒險遊戲《鶴鄉戀旅》、《Fururu Project : Ruby》，而後在台任職遊戲工程師，參與營運型手遊與休閒手遊開發。
                        </Typography>

                        <br />
                        <Typography variant="h6" fontWeight="bold">
                            國外留學分享
                        </Typography>
                        <Typography variant="body2">
                            陳裔軒同學將分享在 DigiPen 就讀 Master of Science in Computer Science 的留學準備、在學生活與經驗、以及自己在遊戲開發路上探索的歷程。
                        </Typography>
                    </Box>
                </Box>

                <Divider />

                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" mt={4} px={1}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題二：獨立團隊經營
                    </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                    <Avatar src="./images/黃名廷.jpg" sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                    <Box>
                        <Box display={{ xs: "block", sm: "flex" }} alignItems="center" gap={2}>
                            <Typography variant="h6" fontWeight="bold">
                                講師：黃名廷
                                <Typography fontWeight="bold"></Typography>
                            </Typography>
                            <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                遊戲貴族 創辦人兼執行長
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            致力於開發優質的遊戲，讓玩家在其中享受遊戲樂趣的同時輕鬆學習。目前開發的產品有《晴天咖啡館》、1930浪漫譚和呷某Bar等。
                        </Typography>

                        <br />
                        <Typography variant="h6" fontWeight="bold">
                            《獨立團隊經營》
                        </Typography>
                        <Typography variant="body2">
                            曾以《晴天咖啡館》一同參與第一屆臺灣學生遊戲開發者論壇的遊戲貴族創辦人 黃名廷，將分享公司的策略規劃和經營決策，以及是如何創作遊戲來獲得多項創業和遊戲競賽獎項的肯定，並入選多個新創加速器、Google 孵創計畫、文策院等多項計畫的方法、經驗與過程。
                        </Typography>
                    </Box>
                </Box>

                <Divider />
                {/*
                <Box display="flex" justifyContent="flex-end" mb={4}>
                    <LearnMoreHyperlink href="https://www.facebook.com/pagui2" target="_blank">{`${t("event.learnMore")} >>`}</LearnMoreHyperlink>
                </Box>*/ }
            </DialogContent>
        </Box>
    )
}

function 開發者沙龍(props: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <Box color="appBar.main">
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        開發者沙龍
                    </Typography>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography fontWeight="bold">
                    公開向大眾徵稿，接受任何學生遊戲開發相關主題內容。開發者沙龍的四位講者將帶來十分多元的內容，希望讓學生遊戲開發者能聽到更多不同面向的開發經驗。
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <br />
                    <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題一：我想做遊戲，所以我花了一整年去打國外的Game Jam
                    </Typography>
                    <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                        <Avatar src={"./images/ChocolaMint.png"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                        <Box>
                            <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography variant="h6" fontWeight="bold">
                                    講師：ChocolaMint
                                </Typography>
                                <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                    國立清華大學 清華大學遊戲創作社創始人兼第一屆社長
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6">
                                Game Jam - 一種短期的遊戲開發競賽、慶典。短則72小時，抑或是一個禮拜，甚至是一個月，在有限的時間內，根據一個題目限制，設計出自己的遊戲，跟大家分享。<br />
                                <br />
                                聽起來這麼困難的活動，真的適合我嗎？<br />
                                - 要在三天內把遊戲趕出來，不就是要我爆肝嗎？<br />
                                - 我在學校沒朋友 / 沒有願意撥空出來陪我打比賽的朋友，是不是只能單打？<br />
                                - 一個人，而且只會寫程式，會不會很吃虧？<br />
                                - Game Jam真的對我在遊戲業的發展有幫助嗎？我把時間拿去躲起來做自己的專案不就好了嗎？<br />
                                - 實際參加Game Jam的感覺如何？要怎麼下手？<br />
                                <br />
                                就讓我 ─ 處在課業繁忙的資工系，卻還是執意參加了7次國外Game Jam的瘋子，一一解答你的疑惑吧！

                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
                   <Box>
                    <br />
                    <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題二：遊戲人的大學五年
                    </Typography>
                    <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                        <Avatar src={"./images/豆保.jpg"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                        <Box>
                            <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography variant="h6" fontWeight="bold">
                                    講師：豆保
                                </Typography>
                                <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                    元智大學 在學生
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6">
                                會從我的第一款遊戲魅影開始帶大家探索遊戲創作的歷程，並談到過程中如何帶領團隊與擴展人脈，以及現在的我與魅影Re:Phantom。

                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
                   <Box>
                    <br />
                    <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題三：遊戲的設計是什麼？為何重要？
                    </Typography>
                    <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                        <Avatar src={"./images/吳岳軒.png"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                        <Box>
                            <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography variant="h6" fontWeight="bold">
                                    講師：吳岳軒
                                </Typography>
                                <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                    淡江大學 淡江大學遊戲開發社第十屆社長
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6">
                                探討對學生來說， 要怎麼學習遊戲開發的過程，並從無到有自己設計遊戲關卡，當中會以遊戲企劃的角度帶領大家走入創作的世界。

                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
                   <Box>
                    <br />
                    <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                        <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                        主題四：注意看，這個遊戲這樣配樂太狠了
                    </Typography>
                    <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                        <Avatar src={"./images/楊尚峯.jpg"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto", "& img": { objectPosition: "top" } }}  />
                        <Box>
                            <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography variant="h6" fontWeight="bold">
                                    講師：楊尚峯
                                </Typography>
                                <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                    幽火的音樂工作室 遊戲配樂師
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6">
                                演講者用自己八年的獨立遊戲配樂接案實戰經驗，分享並解說各種類型的遊戲在配樂方面的規劃是否有所不同，並以實際配樂的案例和學生分享。
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>

            </DialogContent>
        </Box>
    )
}

function 產學座談會(props: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <Box color="appBar.main">
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        產學座談會
                    </Typography>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <br/>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                    <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                    主題：台灣就業經驗
                </Typography>
                <Typography fontWeight="bold">
                    邀請兩位台灣在地從業人員，以新進遊戲產業的角度，將分享新進人員在遊戲產業中所需要具備的技能與就業環境的現況。講師們將從多角度探討，包括企業需要的技能、市場趨勢、遊戲設計及開發、人才培養等方面，讓參加者能夠獲得更全面深入的了解。此次座談會也將提供互動交流的機會。
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                    <Avatar src={"./images/PO.jpg"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                    <Box>
                        <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                            <Typography variant="h6" fontWeight="bold">
                                來賓：PO
                            </Typography>
                            <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                唯晶科技 3D美術設計師
                            </Typography>
                        </Box>
                        <br />
                        <Typography variant="h6">
                            在遊戲產業近三年，參與過手遊、VR、PS5等專案製作。曾任企劃、模型、動畫、PM、整合、品管、特效。
                        </Typography>
                    </Box>
                </Box>

                <Divider />
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                    <Avatar src={"./images/多雷特.jpg"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                    <Box>
                        <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                            <Typography variant="h6" fontWeight="bold">
                                來賓：多雷特
                            </Typography>
                            <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                網銀國際 主企劃
                            </Typography>
                        </Box>
                        <br />
                        <Typography variant="h6">
                            從專員開始到主企劃，擁有豐富的企劃實務經驗。
                        </Typography>
                    </Box>
                </Box>

                <Divider />
            </DialogContent>
            <DialogContent>
                <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                    <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                    主題：現今產業趨勢
                </Typography>
                <Typography fontWeight="bold">
                    隨著AI技術的不斷發展，遊戲開發者可以透過AI優化遊戲性、增強遊戲體驗、提高遊戲品質，進一步拓展遊戲產業的發展空間。專家與學生將探討AI與遊戲開發的融合，及如何因應AI帶來的挑戰，讓觀眾了解AI技術對遊戲產業未來的影響及趨勢。
                </Typography>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                    <Avatar src={"./images/蔡明遠-馬恩.jpg"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                    <Box>
                        <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                            <Typography variant="h6" fontWeight="bold">
                                來賓：蔡明遠-馬恩
                            </Typography>
                            <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                AG6 副社長
                            </Typography>
                        </Box>
                        <br/>
                        <Typography variant="h6">
                            曾在台灣微軟任職17年<br/>
                            最高職務至伺服器與雲端部門副總經理<br />
                            現任為藍星球資訊資深業務協理<br />
                            專精 領域:人工智慧、區塊鏈、資訊安全與數位轉型
      </Typography>
                    </Box>
                </Box>

                <Divider />
            </DialogContent>
        </Box>
    )
}
function 遊戲發想體驗課程(props: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <Box color="appBar.main">
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        遊戲發想體驗課程
                    </Typography>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography fontWeight="bold">
                    以兩個實際案例（《鶴鄉戀旅》（Love Across Izumi）、《掌上新屋》（Pocket Xinwu））和三步驟（Ａ.輸入：案例分享；Ｂ.思考：創意地方遊戲創想；Ｃ.輸出：簡易小企劃草擬和遊戲提案管道），教大家如何從無到有的發想一款遊戲。
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <br />
                    <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "200px 1fr" }} justifyContent="center" gap={3} mt={1} mb={4}>
                        <Avatar src={"./images/賴振民.png"} sx={{ width: "200px", height: "200px", mt: 1, mx: "auto" }} />
                        <Box>
                            <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography variant="h6" fontWeight="bold">
                                    講師：賴振民
                                </Typography>
                                <Typography fontStyle="italic" mb={{ xs: "10px", sm: 0 }}>
                                    創夢客 創辦人
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="h6">
                                現為屏東縣青創聚落駐點業師、財團法人屏東創生基金會創生總監、社團法人屏東縣觀光創生聯盟總幹事、屏東縣勝利商圈發展協會副總幹事。
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
            </DialogContent>
        </Box>
    )
}

function 活動事件(props: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <Box color="appBar.main">
            <DialogTitle>
                敬請期待。
                {/*
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        自由分享會
                    </Typography>
                    <Box display={{ xs: "none", md: "block" }}>
                        <GoToStageButton to="/Stage?id=討論教室" sx={{ mr: 3 }}>
                            <Typography variant="subtitle1" component="p">{t("event.goToStage")}<ArrowForward fontSize="inherit" sx={{ ml: 0.5 }} /></Typography>
                        </GoToStageButton>
                    </Box>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <Box display={{ xs: "flex", md: "none" }} mb={2}>
                    <GoToStageButton to="/Stage?id=討論教室" sx={{ mr: 3 }}>
                        <Typography variant="subtitle1" component="p">{t("event.goToStage")}<ArrowForward fontSize="inherit" sx={{ ml: 0.5 }} /></Typography>
                    </GoToStageButton>
                </Box>
                <Typography fontWeight="bold">
                    引用unconference概念，採用當天報名、當天分享的彈性方式，鼓勵參加者自主提出分享主題，讓所有現場參與的開發者們都有上台分享的機會。
                </Typography>*/ }
            </DialogTitle>

                {/*
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        議題工作坊
                    </Typography>
                    <Box display={{ xs: "none", md: "block" }}>
                        <GoToStageButton to="/Stage?id=討論教室" sx={{ mr: 6.75 }}>
                            <Typography variant="subtitle1" component="p">{t("event.goToStage")}<ArrowForward fontSize="inherit" sx={{ ml: 0.5 }} /></Typography>
                        </GoToStageButton>
                    </Box>
                </Box>
                <Box display={{ xs: "flex", md: "none" }} mb={2}>
                    <GoToStageButton to="/Stage?id=討論教室" sx={{ mr: 3 }}>
                        <Typography variant="subtitle1" component="p">{t("event.goToStage")}<ArrowForward fontSize="inherit" sx={{ ml: 0.5 }} /></Typography>
                    </GoToStageButton>
                </Box>
                <Typography fontWeight="bold">
                    主辦單位於活動前蒐集各方需求提供議案主題（如：若政府有1000萬可以透過甚麼方式協助學生進行遊戲開發？）。邀請產官學三方人員以討論工作坊的形式進行跨界討論，促進不同觀點間的交流，並在最後提出建議方案，供相關單位做後續政策規劃參考。
                </Typography>
            </DialogTitle>*/}
            <DialogContent />
        </Box>
    )
}

function 其他活動(props: { onClose: () => void }) {
    const { t } = useTranslation();
    let item = (titile: string, content: string) => {
        return (<Box mb={4}>
            <Typography variant="h5" fontWeight="bold" pl={1} gutterBottom>
                <Box display="inline-block" component="span" bgcolor="primary.main" mr={1} sx={{ transform: "skewX(-15deg)", width: "10px", height: "33px" }}> </Box>
                {titile}
            </Typography>
            <Box mt={1} mb={4}>
                {content}
            </Box>
            <Divider />
        </Box>)
    }
    let itemList = [
        {
            title: "議題討論工作坊",
            content: "主辦單位於活動前蒐集各方需求提供議案主題，並邀請產官學三方人員以討論工作坊的形式進行跨界討論，促進不同觀點間的交流，並在最後提出建議方案，供相關單位做後續政策規劃參考。"
        },
        {
            title: "產學媒合會",
            content: "透過邀請各大企業前來徵才，讓學生有機會與企業面對面交流，了解各產業的需求與趨勢，並尋找發揮自身長才的方向，助力學生職涯發展。"
        },
        {
            title: "學生團隊攤位展",
            content: "學生進行作品展出，藉由展出的過程，讓學生可以發現自己作品的不足並進行改進，同時也可以增加對外界溝通介紹的能力。此外，透過這個活動，學生還可以促成團隊與業界交流，共同創造合作可能。"
        },
        {
            title: "DEMO秀",
            content: "學生能將完整的遊戲上台呈現，不僅能夠得到相關意見與建議，更能透過回饋持續改進自己的作品，提升開發技能。"
        },
        {
            title: "團隊甘苦談",
            content: "讓學生團隊互相交流，透過分享與討論，找出開發障礙並共同尋求解決方案。此活動不僅可以幫助學生們學習團隊合作，更能夠謀取資源、拓展人脈，進而提高團隊的開發能力與實力。"
        }

    ]
    return (
        <Box color="appBar.main">
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography flex="1" component="span" variant="h4" fontWeight="bold" display="flex" gap={1} mt={2} gutterBottom>
                        <Box component="span" display="inline-block" bgcolor="primary.main" sx={{ transform: "skewX(-15deg)", width: "18px", height: "38px" }} />
                        其他活動
                    </Typography>
                    <IconButton onClick={props.onClose} disableFocusRipple sx={{ alignSelf: "flex-start", marginRight: "-8px" }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography fontWeight="bold">
                </Typography>
            </DialogTitle>
            <DialogContent>
                <br />
                {itemList.map(i => item(i.title, i.content))}
            </DialogContent>
        </Box>
    )
}