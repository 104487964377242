import { Delete, Send } from "@mui/icons-material";
import { Avatar, Badge, Box, Collapse, Fab, IconButton, List, ListItem, ListItemText, Stack, Zoom } from "@mui/material";
import * as React from "react";
import { setCookie, getCookie } from "../../cookie-utils";
import { useDialogControl, useDispatchWithType, useSystemConnection, useUser } from "../../store";
import { bubbleToDialog, closeBubble, closeDialog, dialogToBubble, openDialog } from "../../store/middleware/dialogControl";
import { CheckIsMobile } from "../Admin/Utils";
import { ChatDialog, ContactDialog } from "./ChatDialog";
import { CardType, DialogContent, MessageContent, Participant, User, UserPresence } from "../class";
import { ChatMessage, SystemControlHub, TextCode } from "../react-signalr/systemControlHub";
import { chatApi, Contact, useGetChatsMutation, useGetContactsQuery } from "../services/chat";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import UserOnlineBadge from "../responsive-components/UserOnlineBadge";
import { showModal, updateUser } from "../../store/rootReducer";
import { useSwipeable } from 'react-swipeable';
import "./ChatSystem.css";
import { logEvent } from "../Analytics";

//紀錄對話框狀態到cookie
//(目前保留core只是為了概念，實作已經搬到dialogControl了)
export class ChatCore {
    private dialogList: StoredObject[]; //有序: 由左至右
    private bubbleList: StoredObject[]; //有序: 從下至上
    set dialogs(value: StoredObject[]) {
        this.dialogList = value;
    }
    set bubbles(value: StoredObject[]) {
        this.bubbleList = value;
    }
    get dialogs() {
        return this.dialogList;
    }
    get bubbles() {
        return this.bubbleList;
    }
    private equal(o, v) {
        return o.id === v;
    }
    private record() {
        let odl: StoredObject[] = [], bbl: StoredObject[] = [];
        for (let item of this.dialogList) { odl.push(item); }
        for (let item of this.bubbleList) { bbl.push(item); }
        let encodeString = encodeURI(JSON.stringify(
            { odl: odl, bbl: bbl }
        ))
        setCookie("chat", encodeString);
    }
    constructor() {
        let cookieString = getCookie("chat");
        if (cookieString) {
            let cookie = JSON.parse(decodeURI(cookieString));
            this.dialogList = cookie.odl; //Opening Dialog List
            this.bubbleList = cookie.bbl; //Bubble List
        }
        else {
            this.dialogList = [];
            this.bubbleList = [];
        }
        setInterval(
            this.record.bind(this),
            5000
        )
    }

    //打開對話框
    public OpenDialog(id: string) {
        let index = this.bubbleList.findIndex(element => this.equal(element, id));
        if (index > -1)
            this.bubbleList.splice(index, 1);
        if (this.dialogList.length > 1) {
            //把最後一個對話框變成泡泡
            this.bubbleList.push(this.dialogList.pop());
        }
        let obj: StoredObject = { id: id };
        this.dialogList.unshift(obj);
        this.record.bind(this)
    }
    //縮成泡泡
    public DialogToBubble(id: string) {
        let index = this.dialogList.findIndex(element => this.equal(element, id));
        if (index > -1)
            this.dialogList.splice(index, 1);
        let obj: StoredObject = { id: id };
        this.bubbleList.push(obj);
    }
    //關閉對話框
    public CloseDialog(id: string) {
        let index = this.dialogList.findIndex(element => this.equal(element, id));
        if (index > -1)
            this.dialogList.splice(index, 1);
    }

    //打開泡泡
    public OpenBubble(id: string) {
        let obj: StoredObject = { id: id };
        this.bubbleList.push(obj);
        console.log(this.bubbleList);
    }
    //展開成對話框
    public BubbleToDialog(id: string) {
        let index = this.bubbleList.findIndex(element => this.equal(element, id));
        if (index > -1)
            this.bubbleList.splice(index, 1);
        if (this.dialogList.length > 1) {
            //把最後一個對話框變成泡泡
            this.bubbleList.push(this.dialogList.pop());
        }
        let obj: StoredObject = { id: id };
        this.dialogList.unshift(obj);
    }
    //關閉泡泡
    public CloseBubble(id: string) {
        let index = this.bubbleList.findIndex(element => this.equal(element, id));
        if (index > -1)
            this.bubbleList.splice(index, 1);
    }
}

export interface StoredObject {
    id: string;
}

export class ChatCoreM {
    private openDialog: StoredObject;
    get dialog() {
        return this.openDialog;
    }

    //打開對話框
    public OpenDialog(id: string) {
        this.openDialog.id = id;
    }

    //關閉對話框(其實是返回時觸發，按關閉不會觸發這個)
    public CloseDialog() {
        this.openDialog = null;
    }
}

export type DialogState = { VideoChatingIds: string[] }
//負責跟後端溝通
//根據對話框狀態，渲染對話框
export default function ChatSystem() {
    const dispatch = useDispatchWithType();
    const user = useUser();
    const dialogControl = useDialogControl();

    const [contacts, setContacts] = React.useState<Contact[]>([]); //聯絡人資料
    const { data, refetch } = useGetContactsQuery();
    React.useLayoutEffect(() => { if (data) setContacts(data.data) }, [data]);

    const [getChats] = useGetChatsMutation();

    //聯絡人視窗
    const [isOpenContact, setIsOpenContact] = React.useState(false);
    const openContactPanel = () => setIsOpenContact(true), closeContactPanel = () => setIsOpenContact(false);
    const toggleContactPanel = () => {
        if (!isOpenContact) refetch();
        setIsOpenContact((prev) => !prev);
    };

    const [chatIds, _setChatIds] = React.useState<string[]>(); //處理dialogControl儲存的id，並排序
    const chatIdsRef = React.useRef(chatIds); //為了讓signalR handler能探聽到state所做的ref
    const setChatIds = (data: string[]) => {
        chatIdsRef.current = data;
        _setChatIds(data);
    }
    const [chatList, setChatList] = React.useState<Record<string, DialogContent>>({}); //聊天室資料庫(就是緩存)
    const [chatState, setChatState] = React.useState<Record<string, DialogState>>({});

    React.useEffect(() => {
        if (dialogControl) {
            let array: string[] = [...dialogControl.readableDialogs, ...dialogControl.unreadableDialogs].sort();
            if (chatIds && array.length === chatIds.length && array.every((value, index) => value === chatIds[index])) { }
            else setChatIds(array);
        }
    }, [dialogControl]);
    React.useEffect(() => {
        if (chatIds && chatIds.length > 0) {
            getChats({ chatIds: chatIds }).unwrap()
                .then((result) => {
                    //200 OK
                    if (result.isSuccess) {
                        let dict: Record<string, DialogContent> = { ...chatList };
                        let stateDict: Record<string, DialogState> = { ...chatState };
                        result.data.forEach((item) => {
                            dict[item.chatId] = { ...item };
                            if (!stateDict[item.chatId])
                                stateDict[item.chatId] = { VideoChatingIds: [] };
                        });
                        setChatList(dict);
                        setChatState(stateDict);
                    }
                })
                .catch((error) => {
                    //後端報錯 ex:500、404
                    console.error("發生錯誤", error)
                });
        }
    }, [chatIds]);

    const [first, setFirst] = React.useState(false);
    React.useEffect(() => {
        if (dialogControl && first) {
            setFirst(true);
            if (dialogControl.readableDialogs && dialogControl.readableDialogs.length > 0) {
                getChats({ chatIds: dialogControl.readableDialogs }).unwrap()
                    .then((result) => {
                        //200 OK
                        if (result.isSuccess) {
                            let dict: Record<string, DialogContent> = { ...chatList };
                            let stateDict: Record<string, DialogState> = { ...chatState };
                            result.data.forEach((item) => {
                                dict[item.chatId] = { ...item };
                                stateDict[item.chatId] = { VideoChatingIds: [] };
                            });
                            setChatList(dict);
                            setChatState(stateDict);
                        } else {

                        }
                    })
                    .catch((error) => {
                        //後端報錯 ex:500、404
                        console.error("發生錯誤", error)
                    });
            }
        }
    }, [dialogControl]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            console.log("systemConnection")
            hubRef.current = new SystemControlHub(systemConnection);

            //從名片、遊戲中打開對話框
            hubRef.current.addHandler.OnChatDialogOpen((chatId) => {
                console.log("從名片、遊戲中打開對話框")
                //手機是直接開聊天系統介面
                if (CheckIsMobile()) {
                    //do something...
                    setIsOpen(true)
                }
                //電腦則是增加對話視窗
                dispatch(openDialog(chatId));
                //紀錄已讀
                //handleDialogActive(chatId);
            });

            //收到新訊息，更新資料
            hubRef.current.addHandler.ReceiveMessage((json) => {
                console.log("收到新訊息，更新資料")
                let message = JSON.parse(json) as MessageContent;
                //檢查視窗是否開著
                if (chatIdsRef.current.includes(message.chatId)) {
                    setChatList(chatList => {
                        let dict: Record<string, DialogContent> = { ...chatList };
                        let messages = [...dict[message.chatId].messages];
                        messages.push(message);
                        dict[message.chatId].messages = messages;
                        return dict;
                    });
                }
                else {
                    dispatch(openDialog(message.chatId));
                }
                if (isOpen || CheckIsMobile()) refetch(); //聯絡人資料更新
            });

            //收到訊息更新，更新資料
            hubRef.current.addHandler.UpdateMessage((json) => {
                console.log("收到訊息更新，更新資料")
                let message = JSON.parse(json) as MessageContent;
                //檢查視窗是否開著
                if (chatIdsRef.current.includes(message.chatId)) {
                    setChatList(chatList => {
                        let dict: Record<string, DialogContent> = { ...chatList };
                        let messages = [...dict[message.chatId].messages];
                        let index = messages.findIndex(x => x.messageId === message.messageId);
                        if (index > -1)
                            messages[index] = message;
                        dict[message.chatId].messages = messages;
                        return dict;
                    });
                }
            });

            //對話框上線資料更新
            hubRef.current.addHandler.UpdateOnline((json) => {
                let statuses = JSON.parse(json) as UserPresence[];
                setChatList(chatList => {
                    let dict = { ...chatList };
                    statuses.forEach((item) => {
                        let key = (Object.keys(dict) as Array<string>).find(key => dict[key].participants.find(x => x.info.userId === item.userId));
                        if (key) {
                            let participants = [...dict[key].participants];
                            let index = dict[key].participants.findIndex(x => x.info.userId === item.userId);
                            if (index > -1) {
                                participants[index] = { ...participants[index], isOnline: item.isOnline };
                                dict[key].participants = participants;
                            }
                        }
                    });
                    return dict;
                });
                setContacts(contacts => {
                    let infos = [...contacts.filter(x => !x.isGroup)];
                    statuses.forEach((item) => {
                        let index = infos.findIndex(x => x.contactInfo.userId === item.userId);
                        if (index > -1)
                            infos[index] = {
                                ...infos[index], contactInfo: { ...infos[index].contactInfo, isOnline: item.isOnline }
                            };
                    });
                    return infos;
                });
            })

            //交換名片成功
            hubRef.current.addHandler.ExchangeCardSuccess((chatId) => {
                if (isGroup(chatId) === false) {
                    setChatList(chatList => {
                        let dict = { ...chatList };
                        let participants = [...dict[chatId].participants];
                        let index = participants.findIndex(x => x.info.userId !== user.userId);
                        if (index > -1) {
                            participants[index] = { ...participants[index], hasBusinessCard: true };
                            dict[chatId].participants = participants;

                            dispatch(updateUser({ ...user, saveCardIds: [...user.saveCardIds, participants[index].info.userId] }));
                        }
                        return dict;
                    });
                }
                if (isOpen || CheckIsMobile()) refetch(); //聯絡人資料更新
            })

            //詢問視訊房間狀態
            //hubRef.current.invoke.GetEnterVideoChatRoomIds(chatId)

            //有人進入視訊房間
            hubRef.current.addHandler.OnEnterVideoChatRoom((chatId, userId) => {
                setChatState(chatState => {
                    let stateDict: Record<string, DialogState> = { ...chatState };
                    if (stateDict[chatId])
                        stateDict[chatId].VideoChatingIds = [...stateDict[chatId].VideoChatingIds, userId];
                    return stateDict;
                });
            })

            //有人離開視訊房間
            hubRef.current.addHandler.OnLeaveVideoChatRoom((chatId, userId) => {
                setChatState(chatState => {
                    let stateDict: Record<string, DialogState> = { ...chatState };
                    if (stateDict[chatId])
                        stateDict[chatId].VideoChatingIds = stateDict[chatId].VideoChatingIds.filter(x => x != userId);
                    return stateDict;
                });
            })

            //有人瀏覽文字聊天室
            hubRef.current.addHandler.OnReadDialog((chatId, userId) => {
                setChatList(chatList => {
                    let dict = { ...chatList };
                    if (dict[chatId]) {
                        let participants = [...dict[chatId].participants];
                        let index = participants.findIndex(x => x.info.userId === userId);
                        if (index > -1) {
                            participants[index] = { ...participants[index], lastSeenTime: new Date().toISOString() };
                            dict[chatId].participants = participants;
                        }
                    }
                    return dict;
                });
            })

            return (() => {
                if (hubRef.current) {
                    hubRef.current.removeHandler.OnChatDialogOpen();
                    hubRef.current.removeHandler.ReceiveMessage();
                    hubRef.current.removeHandler.UpdateMessage();
                    hubRef.current.removeHandler.UpdateOnline();
                    hubRef.current.removeHandler.ExchangeCardSuccess();
                    hubRef.current.removeHandler.OnEnterVideoChatRoom();
                    hubRef.current.removeHandler.OnLeaveVideoChatRoom();
                    hubRef.current.removeHandler.OnReadDialog();
                }
            })
        }
    }, [systemConnection]);
    React.useEffect(() => {
        if (Object.keys(chatState).length > 0) {
            if (hubRef.current) {
                //詢問視訊房間狀態
                hubRef.current.invoke.GetEnterVideoChatRoomIdsList(Object.keys(chatState)).then((result) => {
                    if (result.isSuccess) {
                        setChatState(chatState => {
                            let stateDict: Record<string, DialogState> = { ...chatState };
                            Object.keys(result.data).forEach((key) => {
                                stateDict[key].VideoChatingIds = result.data[key];
                            });
                            return stateDict;
                        });
                    }
                });
            }
        }
    }, [Object.keys(chatState).length]);

    function handleSelectUser(userId: string) {
        if (hubRef.current) {
            hubRef.current.invoke.OpenChatDialog(userId);
            logEvent('Chat', `Bubble`, `${userId}`);
        }
    }
    function handleSelectGroup(chatId: string) {
        dispatch(openDialog(chatId));
        if (hubRef.current) {

        }
    }

    function getChatData(id: string): DialogContent {
        if (chatList && chatList[id]) {
            return { ...chatList[id] };
        }
        return null;
    }
    function getChatState(id: string): DialogState {
        if (chatState && chatState[id]) {
            return chatState[id];
        }
        return null;
    }
    function checkIsDisableAppointment(id: string): boolean {
        if (chatList && chatList[id]) {
            return user.type == CardType.student &&
                chatList[id].participants.every(x => x.info.type == CardType.student);
        }
        return null;
    }

    function handleBecomingBubble(id: string) {
        dispatch(dialogToBubble(id));
    }
    function handleCloseDialog(id: string) {
        dispatch(closeDialog(id));
    }
    function handleBecomingDialog(id: string) {
        dispatch(bubbleToDialog(id));
    }
    function handleCloseBubble(id: string) {
        dispatch(closeBubble(id));
    }
    function handleSubmit(id: string, text: string) {
        let newMessage: ChatMessage = {
            chatId: id, textCode: TextCode.純文字及連結, textObject: { content: text }
        };
        if (hubRef.current)
            hubRef.current.send.SendMessage(newMessage);
        else
            console.error("傳送失敗！請重新整理");
    }
    function handleDeleteMessage(id: string, messageId: string) {
        if (hubRef.current)
            hubRef.current.send.DeleteMessage(id, messageId);
        else
            console.error("傳送失敗！請重新整理");
    }

    function handleClickVideoChat(chatId: string) {
        logEvent('VideoChat', `Chat`, `${chatId}`);
        dispatch(showModal({ modalType: "PRIVATE_VIDEO_CHAT", modalProps: { chatId: chatId } }));
    };
    function handleClickAppointment(receiverId: string) {
        logEvent('Appointment', `Chat`, `${receiverId}`);
        dispatch(showModal({ modalType: "APPOINTMENT", modalProps: { receiverId: receiverId } }));
    };
    function handleClickExchange(receiverId: string) {
        logEvent('ExchangeCard', `Chat`, `${receiverId}`);
        dispatch(showModal({ modalType: "EXCHANGE_CARD", modalProps: { receiverId: receiverId } }));
    };
    function handleClickStamp(id: string) {
        //onClickStamp(chatData.chatId);
    };
    function handleRingBell(boothId: string, receiverId: string) {
        logEvent('ServiceBell', `Chat`, `${boothId}`);
        dispatch(showModal({ modalType: "SERVICE_BELL", modalProps: { boothId: boothId, receiverId: receiverId } }));
    }

    function handleDialogActive(id: string) {
        //更新前端
        if (chatList && chatList[id]) {
            setChatList((chatList) => {
                let dict: Record<string, DialogContent> = { ...chatList };
                dict[id].messages = dict[id].messages.map(x => x.hasBeenSeen ? x : { ...x, hasBeenSeen: true });
                return dict;
            });
            setContacts((contacts) => {
                let infos = [...contacts];
                let index = infos.findIndex(x => x.chatId === id);
                if (index > -1 && infos[index].lastMessage)
                    infos[index] = {
                        ...infos[index], lastMessage: { ...infos[index].lastMessage, hasBeenSeen: true }
                    };
                return infos;
            });
        }
        //更新後端
        if (hubRef.current) {
            hubRef.current.send.ReadDialog(id);
        }
    }


    //手機版視窗
    const [isOpen, setIsOpen] = React.useState(false);
    const resetTop = () => {
        containerRef.current.style.top = `${top + 100}px`;
        containerRef.current.style.transition = null;
        containerRef.current.style.opacity = null;
        setTop(heightestBoundary);
    }
    const togglePanel = () => {
        if (isOpen) {
            resetTop();
        }
        setIsOpen((prev) => !prev)
    };

    const eventHandlers = useSwipeable({
        preventDefaultTouchmoveEvent: true,
        onSwipeStart: (eventData) => { eventData.event.stopPropagation(); },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
            if (top + eventData.deltaY > heightestBoundary) {
                containerRef.current.style.top = `${top + eventData.deltaY}px`;
                containerRef.current.style.transition = `top 0ms`;
                if (top + eventData.deltaY > threshold) {
                    if ((+containerRef.current.style.opacity) > 0.5) {
                        containerRef.current.style.opacity = "0.5";
                        setOpacity(0.5);
                    }
                } else {
                    containerRef.current.style.opacity = "1";
                    setOpacity(1);
                }
            }
        },
        onSwiped: (eventData) => {
            if (opacity < 1) {
                containerRef.current.style.top = `${top + eventData.deltaY + 100}px`;
                containerRef.current.style.transition = null;
                containerRef.current.style.opacity = null;
                setIsOpen(false);
                setTop(heightestBoundary);
            }
            else
                setTop(top + eventData.deltaY);
        }
    });
    const containerRef = React.useRef<HTMLDivElement>();
    const draggableRef = React.useRef<HTMLDivElement>();
    const ref = (element) => {
        eventHandlers.ref(element);
        draggableRef.current = element;
    }

    const heightestBoundary = 52;
    const [top, setTop] = React.useState(heightestBoundary);
    const [opacity, setOpacity] = React.useState(0);
    const threshold = window.innerHeight * 2 / 3;

    const [originLength, setOriginLength] = React.useState<number>(dialogControl.readableDialogs.length);
    const [isForward, setIsForward] = React.useState<boolean>(true);
    React.useLayoutEffect(() => {
        if (dialogControl.readableDialogs.length >= originLength)
            setIsForward(true);
        else
            setIsForward(false);
        setOriginLength(dialogControl.readableDialogs.length);
    }, [dialogControl.readableDialogs]);

    //手機版 未讀訊息顯示
    const [read, setRead] = React.useState<boolean>(true);
    React.useLayoutEffect(() => {
        if (contacts) {
            setRead(contacts.every(x => !x.lastMessage || x.lastMessage.hasBeenSeen));
        }
    }, [contacts]);

    if (CheckIsMobile()) {
        //手機版


        //(真)Dialog (偽)頁面
        return (
            <>
                <Box display="flex" width="46px" height="46px" justifyContent="center" alignItems="center" >
                    <Badge variant="dot" invisible={read} sx={{
                        "& > .MuiBadge-badge": {
                            inset: "14% 14% auto auto",
                            width: "9px", height: "9px",
                            borderRadius: "50%",
                            color: (theme) => theme.palette.background.paper,
                            background: (theme) => theme.palette.error.light
                        }
                    }}>
                        <IconButton sx={{
                            p: "6px",
                            fontSize: "18px",
                            backgroundColor: "rgba(0,0,0,0.1)", color: "primary.contrastText",
                            "&:hover": { backgroundColor: "rgba(0,0,0,0.2)", color: "rgba(255,255,255,0.75)" }
                        }} onClick={togglePanel}>
                            <Send fontSize="inherit" sx={{ transform: "rotateZ(321deg) translateX(3px)" }} />
                        </IconButton>
                    </Badge >
                </Box>
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="chat"
                    unmountOnExit
                >
                    <Box id="chat" ref={containerRef} position="fixed" top="52px" right="0" bottom="0" left="0" sx={{ transition: (theme) => theme.transitions.create(['all']) }}>
                        <Box position="relative" width="100%" height="100%">
                            <CSSTransition
                                in={dialogControl.readableDialogs.length === 0}
                                timeout={350}
                                classNames="contact-dialog"
                                unmountOnExit
                            >
                                <ContactDialog contactList={contacts} onClose={togglePanel} onSelectUser={handleSelectUser} onSelectGroup={handleSelectGroup}
                                    mobile draggableRef={ref}
                                />
                            </CSSTransition>

                            <TransitionGroup className={isForward ? "forward" : "backward"}>
                                {dialogControl.readableDialogs.map((dialogId, index, array) => (
                                    <CSSTransition
                                        key={dialogId}
                                        timeout={350}
                                        classNames="dialog"
                                    >
                                        {state => (
                                            <Box position="absolute" sx={{ inset: 0 }} className={index < (dialogControl.readableDialogs.length - 1) ? `${state === "entering" ? "dialog-enter dialog-enter-active" : state === "entered" ? "dialog-enter-done" : state === "exiting" ? "dialog-exit dialog-exit-active" : "dialog-exit-done"} hide` : `${state === "entering" ? "dialog-enter dialog-enter-active" : state === "entered" ? "dialog-enter-done" : state === "exiting" ? "dialog-exit dialog-exit-active" : "dialog-exit-done"} show`}>
                                                <ChatDialog chatData={getChatData(dialogId)} chatState={getChatState(dialogId)}
                                                    onMinimize={null} onClose={togglePanel}
                                                    onSend={handleSubmit} onDeleteMessage={handleDeleteMessage}
                                                    shouldFocus={true}

                                                    onRingBell={handleRingBell}
                                                    onClickVideoChat={handleClickVideoChat} onClickAppointment={handleClickAppointment}
                                                    onClickExchange={handleClickExchange} onClickStamp={handleClickStamp}

                                                    disableAppointment={checkIsDisableAppointment(dialogId)}
                                                    disableStamp

                                                    onActive={handleDialogActive}

                                                    mobile draggableRef={ref}
                                                    onBack={handleCloseDialog}
                                                />
                                            </Box>
                                        )
                                        }
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        </Box>
                    </Box>
                </CSSTransition>
            </>
        )
    }
    else {
        //電腦版


        //泡泡+聊天室視窗
        if (dialogControl) {
            return (
                <Box id="chat" position="fixed" bottom="0" right="0">
                    {/*泡泡區*/}
                    <Box position="absolute" bottom="calc(1rem * 2 + 56px)" right="1rem">
                        <List disablePadding>
                            <TransitionGroup>
                                {dialogControl.unreadableDialogs.map((bubbleId, index) => (
                                    <Zoom key={bubbleId}>
                                        <ListItem key={`b:${bubbleId}`} sx={{ px: 0, pt: 0, pb: 2 }}>
                                            <Bubble chatData={getChatData(bubbleId)} onClick={handleBecomingDialog} onClose={handleCloseBubble} />
                                        </ListItem >
                                    </Zoom>
                                ))}
                            </TransitionGroup>
                            <ChatNavigator contactList={contacts} onClick={toggleContactPanel} />
                        </List>
                    </Box>
                    {/*聊天室區*/}
                    <Box position="absolute" right="calc(1rem * 2 + 56px)" bottom="0"
                        display="flex" flexDirection="row-reverse">
                        {isOpenContact &&
                            <ContactDialog contactList={contacts} onClose={closeContactPanel} onSelectUser={handleSelectUser} onSelectGroup={handleSelectGroup} />
                        }
                        {dialogControl.readableDialogs.map((dialogId, index) => (
                            <ChatDialog chatData={getChatData(dialogId)} chatState={getChatState(dialogId)} key={`d:${dialogId}`}
                                onMinimize={handleBecomingBubble} onClose={handleCloseDialog}
                                onSend={handleSubmit} onDeleteMessage={handleDeleteMessage}
                                shouldFocus={index === dialogControl.readableDialogs.length - 1 ? true : false}

                                onRingBell={handleRingBell}
                                onClickVideoChat={handleClickVideoChat} onClickAppointment={handleClickAppointment}
                                onClickExchange={handleClickExchange} onClickStamp={handleClickStamp}

                                disableAppointment={checkIsDisableAppointment(dialogId)}
                                disableStamp

                                onActive={handleDialogActive}
                            />
                        ))}
                    </Box>
                </Box>
            )
        } else { return (<></>) }
    }
}

interface ChatNavigatorProps {
    contactList: Contact[];
    onClick: () => void;
}
function ChatNavigator(props: ChatNavigatorProps) {
    const { contactList, onClick } = props;
    const [read, setRead] = React.useState<boolean>(true);
    React.useLayoutEffect(() => {
        if (contactList) {
            setRead(contactList.every(x => !x.lastMessage || x.lastMessage.hasBeenSeen));
        }
    }, [contactList]);
    return (
        <Badge variant="dot" invisible={read} sx={{
            "& > .MuiBadge-badge": {
                zIndex: 1051,
                inset: "14% 14% auto auto",
                width: "14px", height: "14px",
                borderRadius: "50%",
                color: (theme) => theme.palette.background.paper,
                background: (theme) => theme.palette.error.light
            }
        }}>
            <Fab sx={{
                backgroundColor: (theme) => theme.palette.primary.contrastText,
                color: (theme) => theme.palette.primary.main,
                fontSize: "2rem"
            }} onClick={onClick}><Send fontSize="inherit" sx={{ transform: "rotateZ(321deg) translateX(3px)" }} /></Fab>
        </Badge >
    )
}

interface BubbleProps {
    chatData: DialogContent;
    onClick: (id: string) => void;
    onClose: (id: string) => void;
}
export function Bubble(props: BubbleProps) {
    const { chatData, onClick, onClose } = props;
    const user = useUser();

    const [userDatas, setUserDatas] = React.useState<Participant[]>([]);
    const [unreadNum, setUnreadNum] = React.useState(0);

    React.useLayoutEffect(() => {
        if (chatData) {
            setUserDatas(chatData ? chatData.participants.filter(x => x.info.userId !== user.userId) : null);
            setUnreadNum(chatData.messages.filter(x => !x.hasBeenSeen).length);
        }
    }, [chatData]);

    function handleClick() {
        if (chatData) {
            onClick(chatData.chatId);
            setUnreadNum(0);
        }
    }

    return (
        <Badge badgeContent={unreadNum} sx={{
            "& > .MuiBadge-badge": {
                zIndex: 1051,
                inset: "14% 14% auto auto",
                width: "19px", height: "19px",
                borderRadius: "50%",
                color: (theme) => theme.palette.background.paper,
                background: (theme) => theme.palette.error.light
            }
        }}>
            {userDatas && userDatas.length == 1 &&
                <UserOnlineBadge isOnline={userDatas[0].isOnline} badgeWidth="18px" badgeHeight="18px" badgeStyle={{ zIndex: 1051 }}>
                    <Fab disableRipple onClick={handleClick}>
                        <Avatar src={userDatas[0].info.logoUrl} sx={{ width: "56px", height: "56px" }} />
                    </Fab>
                </UserOnlineBadge>
            }
            {userDatas && userDatas.length > 1 &&
                <Fab disableRipple onClick={handleClick} sx={{ background: "transparent" }}>
                    <Avatar src={userDatas[0].info.logoUrl} />
                    <Avatar src={userDatas[1].info.logoUrl} />
                </Fab>
            }
        </Badge >
    )
}

export const isGroup = (chatId: string) => {
    let prefix = chatId.charAt(0);
    if (prefix == "G")
        return true;
    else if (prefix == "U")
        return false;
    else
        console.error("錯誤！");
};