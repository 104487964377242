import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Booth, CardInfo, CardType, IInfoCard, OnlineTime, User, UserFieldName, UserRole } from '../class';
import { BellCallData } from './booth';
import { AppointmentStatus } from './chat';
import { ResultType } from './utils';

export interface UserDetailData {
    id: string;
    account: string;
    cardInfo: CardInfo;
    createTime: string;
}

export interface AppointmentData {
    actionId: string;
    chatId: string;
    sender: CardInfo;
    receiver: CardInfo;
    appointedTime: string;
    message: string;
    status: AppointmentStatus;
}

export interface ApplyAppointment {
    boothId: string;
    time: Date;
    remark: string;
}

export interface ApiAppointment {
    id: string;
    userId: string;
    boothId: string;
    appointTime: string;
    remark: string;
    rejectReason: string;
    status: AppointmentStatus;
    updateTime: string;
}

export interface ImportUserModel {
    account: string;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
    boothId: string;
    isStudent: boolean;
    isAdmin: boolean;
}

export interface UserCardInfoModel extends CardInfo {
    isOnline: boolean;
}

export interface UserManageModel extends CardInfo {
    role: UserRole;
}

export interface UserLevelModel {
    userId: string;
    role: UserRole;
}

export interface UserBoothManagementModel {
    userId: string;
    boothId: string;
    state: boolean;
}


const baseApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/user' }),
    endpoints: (builder) => ({
        getUser: builder.query<ResultType<CardInfo>, Pick<User, 'userId'>>({
            query: ({ userId }) => `GetById/${userId}`,
        }),
        chooseCard: builder.mutation<ResultType<CardInfo>, { cardType: CardType }>({
            query: ({ ...patch }) => ({
                url: `chooseCard`,
                method: 'PUT',
                body: patch,
            }),
        }),
        editUserData: builder.mutation<ResultType<null>, CardInfo>({
            query: ({ ...patch }) => ({
                url: `edit`,
                method: 'PUT',
                body: patch,
            }),
        }),
        getUserFieldNameList: builder.query<ResultType<UserFieldName[]>, void>({
            query: () => `fieldname`,
        }),
        addSaveCards: builder.mutation<ResultType<null | string[]>, Pick<User, 'userId'>>({
            query: ({ userId }) => ({
                url: `saveCards/${userId}`,
                method: 'GET'
            }),
        }),
        changeBookMark: builder.mutation<ResultType<null | string[]>, Pick<Booth, 'boothId'>>({
            query: ({ boothId }) => ({
                url: `bookmark/${boothId}`,
                method: 'GET'
            }),
        }),
        changeInfoCardBookMark: builder.mutation<ResultType<null | string[]>, Pick<IInfoCard, 'dataId'>>({
            query: ({ dataId }) => ({
                url: `bookmarkInfoCard/${dataId}`,
                method: 'GET'
            }),
        }),
        getSaveCards: builder.mutation<ResultType<CardInfo[]>, void>({
            query: () => ({
                url: `getSaveCards`,
                method: 'GET'
            }),
        }),
        getSaveBooths: builder.mutation<ResultType<Booth[]>, void>({
            query: () => ({
                url: `getSaveBooths`,
                method: 'GET'
            }),
        }),
        getSaveInfoCards: builder.mutation<ResultType<IInfoCard[]>, void>({
            query: () => ({
                url: `getSaveInfoCards`,
                method: 'GET'
            }),
        }),
        getOnlineTime: builder.mutation<ResultType<OnlineTime[]>, void>({
            query: () => ({
                url: `onlinetime`,
                method: 'GET'
            }),
        }),
        checkAccount: builder.query<ResultType<{ account: string, password: boolean, google: boolean, googleAccount: string }>, void>({
            query: () => `password/check`,
        }),
        editPassword: builder.mutation<ResultType<null>, { oldPassword: string, newPassword: string }>({
            query: (patch) => ({
                url: `password/edit`,
                method: 'PUT',
                body: patch,
            }),
        }),
        setPassword: builder.mutation<ResultType<null>, { newPassword: string }>({
            query: (patch) => ({
                url: `password/edit`,
                method: 'POST',
                body: patch,
            }),
        }),
        getAppointmentData: builder.query<ResultType<AppointmentData[]>, void>({
            query: () => `appointment`,
        }),
        getServiceBellRecord: builder.query<ResultType<BellCallData[]>, void>({
            query: () => `serviceBell`
        }),
        getAllUsers: builder.query<ResultType<UserCardInfoModel[]>, { cardType: CardType }>({
            query: ({ cardType }) => `allusers?cardType=${cardType}`
        }),
        getUserWithList: builder.mutation<ResultType<CardInfo[]>, string[]>({
            query: (patch) => ({
                url: `userList`,
                method: 'POST',
                body: patch,
            }),
        }),
        getManageBoothList: builder.query<ResultType<Booth[]>, void>({
            query: () => ({
                url: `getManageBoothList`,
                method: 'GET',
            }),
        }),
        editBoothManagement: builder.mutation<ResultType<null>, UserBoothManagementModel>({
            query: (patch) => ({
                url: `boothManage`,
                method: 'PUT',
                body: patch,
            }),
        }),
    }),
});

//superuser或admin用api
export const userApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserList: builder.query<ResultType<UserDetailData[]>, UserRole>({
            query: (role) => `getList/${role}`,
        }),
        postNewUsers: builder.mutation<ResultType<null>, ImportUserModel[]>({
            query: (patch) => ({
                url: `addlist`,
                method: 'POST',
                body: patch,
            }),
        }),
        getAllAppointmentDatas: builder.query<ResultType<AppointmentData[]>, void>({
            query: () => `appointment/all`,
        }),
        getUsersWithLevel: builder.query<ResultType<UserManageModel[]>, void>({
            query: () => `level`,
        }),
        editUserLevel: builder.mutation<ResultType<null>, UserLevelModel>({
            query: (patch) => ({
                url: `level`,
                method: 'PUT',
                body: patch,
            }),
        }),
    }),
    overrideExisting: false
});

export const { useGetUserQuery } = userApi;
export const { useLazyGetUserQuery } = userApi;
export const { useGetUserFieldNameListQuery } = userApi;
export const { useLazyGetUserFieldNameListQuery } = userApi;
export const { useChooseCardMutation } = userApi;
export const { useEditUserDataMutation } = userApi;
export const { useAddSaveCardsMutation } = userApi;
export const { useChangeBookMarkMutation } = userApi;
export const { useChangeInfoCardBookMarkMutation } = userApi;
export const { useGetSaveCardsMutation } = userApi;
export const { useGetSaveBoothsMutation } = userApi;
export const { useGetSaveInfoCardsMutation } = userApi;
export const { useGetOnlineTimeMutation } = userApi;
export const { useCheckAccountQuery } = userApi;
export const { useEditPasswordMutation } = userApi;
export const { useSetPasswordMutation } = userApi;
export const { useGetAppointmentDataQuery } = userApi;
export const { useGetServiceBellRecordQuery } = userApi;
export const { useGetAllUsersQuery } = userApi;
export const { useLazyGetAllUsersQuery } = userApi;
export const { useGetUserWithListMutation } = userApi;
export const { useGetManageBoothListQuery } = userApi;
export const { useEditBoothManagementMutation } = userApi;

export const { useGetUserListQuery } = userApi;
export const { usePostNewUsersMutation } = userApi;
export const { useGetAllAppointmentDatasQuery } = userApi;
export const { useGetUsersWithLevelQuery } = userApi;
export const { useEditUserLevelMutation } = userApi;