import * as React from 'react';
import { Box, Divider, Link, Pagination, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import {  TableRowProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface BoothArticleTableData {
    id: string;
    boothId: string;
    boothChName: string;
    boothEnName: string;
    name: string;
    time: Date;
    pin: boolean;
}


//表格
export default function ArticleListArticleTable(props: BoothArticleTableProps) {
    const { isManage, rows, onOpen, onEdit, onDelete, showPin, className, paginationClassName } = props;
    const { t } = useTranslation();

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 10;

    const [emptyRows, setEmptyRows] = React.useState([]);
    React.useEffect(() => {
        if (page > 0) {
            let tempRows = [];
            for (let count = 0; count < (Math.max(0, (1 + page) * rowsPerPage - rows.length)); count++) {
                tempRows.push(count);
            }
            setEmptyRows(tempRows);
        }
        else {
            setEmptyRows([]);
        }
    }, [page])

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
    };
    const theme = useTheme();
    const isMatchesSm = useMediaQuery(theme.breakpoints.up('sm'));

    const paginationSyle = {
        "display": "flex", "justifyContent": "center",
        "& .Mui-selected": {
            backgroundColor: '#0096FA'
        },
        "& .MuiPaginationItem-previousNext": {
            borderRadius: '50%',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
            color: '#2573DE'
        }
    }

    return (<>
        <Stack spacing={2} /*minHeight={isMatchesSm ? '320px' : "300px"}*/ >
            {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
            ).map((row) => (
                <BoothArticleTableRow
                    isManage={isManage}
                    row={row}
                    onOpen={onOpen}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    key={row.id}
                    showPin={showPin}
                />
            ))}
            {/*emptyRows.map((empty, index) => (
                <TableRow key={index}>
                    <TableCell style={{ border: 0 }}>{'　'}</TableCell>
                </TableRow>
            ))*/}
        </Stack>
        {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
        ).length > 0 ? < Stack pt={2}>
            <Pagination
                count={Math.max(1, Math.ceil(rows.length / rowsPerPage))}
                shape="rounded"
                page={page + 1}
                color='primary'
                onChange={(event: React.ChangeEvent<unknown>, value: number) => { handleChangePage(event, value) }}
                boundaryCount={3}
                sx={paginationSyle}
            />
        </Stack> : <Stack>尚無消息</Stack>}
    </>)
}
interface BoothArticleTableProps {
    isManage?: boolean;
    rows: BoothArticleTableData[];
    onOpen?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    showPin?: boolean;
    className?: string;
    paginationClassName?: string;
}

//列
function BoothArticleTableRow(props: BoothArticleTableRowProps) {
    const { isManage, row, TableRowProps, onOpen, onEdit, onDelete, showPin } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMatchesSm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box>
            <Stack direction='row' alignItems='center' spacing={.5 } marginBottom='10px' fontSize={isMatchesSm ? "14px" : "13px"}>
                <Stack direction='row' spacing='7px' alignItems='center'  >
                    <Stack direction='row' width={isMatchesSm ? '126px' : (showPin && row.pin) ? '103px' : '72px'} textAlign='center' >
                        {(showPin && row.pin) &&
                            <Box p='3px 5px' style={{ color: 'red', border: 'red 1px solid', marginRight: '2px', borderRadius: '5px' }}>TOP</Box>
                        }
                        <Box p='3px 5px' sx={{ color: '#2573DE', border: '#2573DE 1px solid', borderRadius: '5px', width: (showPin && row.pin) ? "auto" : '100%' }}>{row.boothId ? "攤位資訊" : "活動資訊"}</Box>
                    </Stack>
                    <Box>
                        {new Date(row.time).toLocaleString(
                            i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </Box>
                </Stack>
                <Stack direction='row' spacing='7px' alignItems='center' >
                    <Divider sx={{ width: '26px', borderColor: '#D8D8D8' }} />
                    <Box sx={{ "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap", "maxWidth": "9rem" } }>
                        {row.boothId ? (i18n.language == "ch" ? row.boothChName : row.boothEnName) : t("articleList.noBoothIdName")
                        }
                    </Box>
                </Stack>
            </Stack>
            <Box pt={1} pb={3}>
                {onOpen ?
                    <Link
                        component="button"
                        onClick={() => onOpen(row.id)}
                        underline="hover"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}
                    >
                        {(row.boothId != null && row.boothId != '') &&
                            `【${i18n.language == 'ch' ? row.boothChName : row.boothEnName}】`
                        }
                        {row.name}
                    </Link> :
                    <>
                        {(row.boothId != null && row.boothId != '') &&
                            `【${i18n.language == 'ch' ? row.boothChName : row.boothEnName}】`
                        }
                        {row.name}
                    </>
                }
            </Box>
            <Divider sx={{ borderColor: '#D8D8D8' }} />
        </Box>
    )
}

interface BoothArticleTableRowProps {
    row: BoothArticleTableData;
    TableRowProps?: TableRowProps;
    isManage?: boolean;
    onOpen?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    showPin?: boolean;
}